import React, { Component, Fragment } from 'react';
import { InputNumber, message, Modal } from 'antd';

import styles from './index.less';

export default class Paypal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.showPay) {
      this.showPay();
    }
  }

  showPay = () => {
    this.timer = setTimeout(() => {
      this.payBtnFn();
    }, 60);
  };

  payBtnFn = () => {
    const _this = this;
    if (!window.paypal) {
      return;
    }

    const header = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
      Authorization: localStorage.getItem('dser-pro-authority')
    };

    paypal.Button.render(
      {
        locale: 'en_US',
        style: {
          size: 'large'
        },
        env: 'production', // sandbox
        payment() {
          const CREATE_URL = `${window.DSERS_API_CONFIG.HOST}api/v1/payment`;
          return paypal
            .request({
              method: 'post',
              url: CREATE_URL,
              headers: header,
              json: {
                amount: `${_this.props.paypalNumber  }`
              }
            })
            .then(rep => {
              if (rep.data.PaymentID) {
                return rep.data.PaymentID;
              } else {
                message.error('Server error, please try again later.');
              }
            });
        },
        onAuthorize(data, actions) {
          const EXECUTE_URL = `${window.DSERS_API_CONFIG.HOST}api/v1/payment/approved`;
          return paypal
            .request({
              method: 'post',
              url: EXECUTE_URL,
              headers: header,
              json: {
                payment_id: data.paymentID,
                payer_id: data.payerID
              }
            })
            .then((res) => {
              if (res.code == 1) {
                message.success('Payment Complete!');
                if (_this.props.callback) {
                  _this.props.callback.forEach(fn => {
                    fn();
                  });
                }
              } else {
                message.success(
                  'There was a problem with the payment. Please try again later.'
                );
              }
            });
        },
        onCancel(data, actions) {
          message.error('The payment was interrupted.');
        },
        onError(error) {
          message.error('Server error, please try again later.');
        }
      },
      '#paypal-button-container'
    );
  };

  showInputPayNumberTip = () => {
    message.error('Please enter the amount and recharge at least $1 USD');
  };

  clearTimer = () => {
    clearTimeout(this.timer);
  };

  timer = null;

  render() {
    const { showPay, hidePay, changePaypal, paypalNumber } = this.props;

    return (
      <Modal
        visible={showPay}
        footer={null}
        className={styles.payModal}
        maskClosable={false}
        onCancel={hidePay}
        destroyOnClose
        // forceRender={ true }
        afterClose={this.clearTimer}
      >
        <h2>Enter the amount</h2>
        <div className={styles.money}>
          <InputNumber
            style={{ width: '100%' }}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            precision={0}
            min={1}
            size="large"
            placeholder="Enter the account ($)"
            onChange={changePaypal}
          />
        </div>
        <div className={styles.payBtn}>
          <div
            className={styles.cover}
            style={{ display: paypalNumber ? 'none' : '' }}
            onClick={this.showInputPayNumberTip}
          />
          <div id="paypal-button-container" />
        </div>
      </Modal>
    );
  }
}
