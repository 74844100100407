import React, { useEffect, useState } from "react"
import intl from 'react-intl-universal';
import { Modal, Button, Form, Input, Select } from 'antd';
import { connect } from 'dva';
const FormItem = Form.Item;
const { Option } = Select;
import { payConfig } from './payConfig';
import './form.css'
const PayForm = ({ form, value, visible, closeModal,submit }) => {
  const { getFieldDecorator } = form;
  const [stateVal, setStateVal] = useState([])
  const [visibleForm, setVisibleForm] = useState(visible)
  const country = JSON.parse(localStorage.getItem('countryList')) || []
  useEffect(() => {
    initPayValue()
  }, [value])

  useEffect(() => {
    setVisibleForm(visible)
  }, [visible])

  // 初始化数据
  const initPayValue = () => {
    payConfig.forEach((item, index) => {
      payConfig[index].value = value[item.key]
    })
    setStateVal([...payConfig])
  }

  const renderPayItem = (item) => {
    switch (item.type) {
      case 'input':
        return <Input
          placeholder={item.placeHolder}
          maxLength={item.max}
        />
      case 'select':
        return <Select
         getPopupContainer={triggerNode => triggerNode.parentNode}
          showSearch
          optionFilterProp="children"
          placeholder={item.placeHolder}
        >
          {country.map((item) => {
            return <Option value={item.Code} key={item.Code}>{item.Name}</Option>
          })}
        </Select>
      default:
        return null
    }
  }

  const submitPayVal = () => {
    const errorList = form.getFieldsError();
    const newErrorList = Object.values(errorList).filter(
      item => item !== undefined
    );
    if (newErrorList.length !== 0) {
      return;
    }
    // 走保存的接口
    let value = form.getFieldsValue()
    submit(value)
  }

  return <Modal
    visible={visibleForm}
    width={560}
    maskClosable={false}
    footer={
      <div>
        <Button
          type="primary"
          onClick={() => {
            submitPayVal()
        }}>
          SAVE
        </Button>
      </div>
    }
    onCancel={() => {
      closeModal()
      form.resetFields();
      initPayValue()
    }}
    title="Business Information"
  >
    <div className="customizeForm">
      <Form inline>
        {stateVal.map((item, index) => {
          return (
            <FormItem
              {...{ labelCol: { span: 24 }, wrapperCol: { span: item.width } }}
              name={item.key}
              label={item.name}
            >
              {
                getFieldDecorator(item.key,
                  {
                    initialValue: item.value,
                    rules: item.rules ? item.rules : {}
                  })(
                    renderPayItem(item)
                  )
              }
            </FormItem>


          )
        })}
      </Form>
    </div>
  </Modal>
}
export default connect()(Form.create()(PayForm));