/* eslint react/jsx-props-no-spreading: 0 react/display-name: 0 */

import React, { useReducer, useState, useRef } from 'react';
import { Modal, Input, Divider, Popconfirm, Button, Table, Icon } from 'antd';
import { connect } from 'dva';

import styles from './CategoryModal.less';

const originData = [
  {
    key: '1',
    name: 'John Brown'
  },
  {
    key: '2',
    name: 'Jim Green'
  }
];

const EditableCell = React.forwardRef(
  (
    { editing, dataIndex, title, record, index, children, ...restProps },
    ref
  ) => {
    return <td {...restProps}>{editing ? <Input ref={ref} /> : children}</td>;
  }
);

const CategoryModal = ({ visible, onCancel }) => {
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  const inputRef = useRef();
  const cacheCreatedItem = useRef(null);

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    setEditingKey(record.key);

    setTimeout(() => {
      inputRef.current.input.value = record.name;
    });
  };

  const save = key => {
    const val = inputRef.current.input.value;
    if (!val) {
      return;
    }
    // inputRef.current.input.value = undefined;
    cacheCreatedItem.current = null;

    const newData = [...data];
    const index = newData.findIndex(item => key === item.key);

    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, name: val });
      setData(newData);
      setEditingKey('');
    } else {
      // newData.push({
      //   key: Date.now(),
      //   name: val
      // });
      // setData(newData);
      // setEditingKey('');
    }
  };

  function deleteItem(key) {
    const newData = [...data];
    const index = newData.findIndex(item => key === item.key);
    if (index > -1) {
      newData.splice(index, 1);
      setData(newData);
    }
  }

  function cancel(key) {
    if (cacheCreatedItem.current === key) {
      deleteItem(key);
    }

    setEditingKey('');
  }

  function handleCancel() {
    onCancel();
  }

  function handleCreate() {
    const newData = [...data];
    const key = Date.now();

    newData.push({
      key,
      name: undefined
    });
    setData(newData);
    setEditingKey(key);

    cacheCreatedItem.current = key;

    setTimeout(() => {
      inputRef.current.input.focus();
    });
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      width: '80%'
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      width: '20%',
      // eslint-disable-next-line
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              role="button"
              tab-index="0"
              onClick={() => save(record.key)}
            >
              <Icon type="save" />
            </a>
            <Divider type="vertical" />
            <Popconfirm title="Sure to cancel?" onConfirm={() => cancel(record.key)}>
              <a>
                <Icon type="undo" />
              </a>
            </Popconfirm>
          </span>
        ) : (
          <>
            <a
              role="button"
              tab-index="0"
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              <Icon type="edit" />
            </a>
            <Divider type="vertical" />
            <Popconfirm
              disabled={editingKey !== ''}
              title={
                <span>
                  Are you sure to delete this category, <br /> spend item under
                  this category will be deleted as well.
                </span>
              }
              onConfirm={() => deleteItem(record.key)}
            >
              <a disabled={editingKey !== ''}>
                <Icon type="delete" />
              </a>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  return (
    <Modal
      visible={visible}
      footer={null}
      width={650}
      maskClosable={false}
      keyboard={false}
      onCancel={handleCancel}
      bodyStyle={{ padding: '32px 48px' }}
      destroyOnClose
    >
      <h3>Manage category</h3>
      <div style={{ marginTop: 12 }}>
        <Button type="primary" icon="plus" onClick={handleCreate} disabled={editingKey !== ''}>
          New category
        </Button>
      </div>
      <div className={styles.con}>
        <Table
          components={{
            body: {
              cell: props => <EditableCell {...props} ref={inputRef} />
            }
          }}
          columns={mergedColumns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          size="small"
        />
      </div>
    </Modal>
  );
};

export default React.memo(CategoryModal);
