import React, { Component } from 'react';
import { connect } from 'dva';

import className from 'classnames';
import intl from 'react-intl-universal';

import Title from 'routes/Setting/Dropshpper/Title';

import RefreshMask from 'components/RefreshMask';

import { Tooltip, Modal, Input, Button  } from 'antd';

import { confirmLanguage } from '../../../../../../../common/sysconfig';


import {
  getLanguage,
  CommonDataCollect
} from '../../../../../../../utils/utils';

import styles from './index.less';
import '../../../../setting.css';

@connect(({ dropshippersetting, loading, login, user, auth }) => ({
  dropshippersetting,
  userInfo: login.userInfo,
  loading: loading.models.dropshippersetting,
  updateUserNameLoadind: loading.effects['dropshippersetting/updateUserName'],
  newUserInfo: user.newUserInfo,
  isoberlouser: auth?.isOberloUser
}))
class AliExpressAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      emailError: false,
      emailErrorMsg: '',
      changeEmail: false,
      sendSucc: false,
      verifyEmailError: null,
      disconnectShopifyStoreId: undefined, // 卸载shopify店铺id
      showDisconnectCallback: false, // 卸载shopify店铺成功后的弹窗
      showRefreshWhitelist: false,
      accountErr: false,
      saveNameModal: false,
      userName: '',
      refreshModal: false
    };
  }

  handleWhitelist = () => {
    this.setState({ showRefreshWhitelist: true });
  };

  // 确认切换AE账号
  swtichAli = () => {
    CommonDataCollect({
      event_type: 'subscription_analysis',
      action: 'click_toggle_ae_button'
    });

    this.setState({
      refreshModal: true
    });
  };

  aliAccountId = localStorage.getItem('aliAccountId');
  disconnectAEConfirm = ''; // 解绑AE账号 需输入confirm
  disconnectAEConfirms = e => {
    this.disconnectAEConfirm = e.target.value;
  };
  currentLanguage = getLanguage();

  // 解绑AE授权
  disconnectAli = () => {
    Modal.confirm({
      title: (
        <>
          <p>{intl.getHTML('setting.account.disconnect_ae_tip1')}</p>
          <p>{intl.getHTML('setting.account.disconnect_ae_tip2')}</p>
        </>
      ),
      content: (
        <>
          <p>{intl.get('setting.account.disconnect_ae_confirm')} </p>
          <Input onChange={this.disconnectAEConfirms} />
        </>
      ),
      keyboard: false,
      okText: intl.get('public.ok'),
      okType: 'primary',
      cancelText: intl.get('public.cancel'),
      width: 560,
      className: styles.disconnectAEModal,
      onOk: () => {
        if (
          this.disconnectAEConfirm.toLocaleLowerCase().trim() !==
          confirmLanguage[this.currentLanguage]
        ) {
          return Promise.reject('Please input CONFIRM to unlink.');
        }
        this.subDisconnectAli();
      }
    });
  };

  // 更新AE授权
  reconnectAli = () => {
    Modal.confirm({
      title: <p> {intl.getHTML('setting.account.reconnect_ae')} </p>,
      okText: intl.get('public.ok'),
      cancelText: intl.get('public.cancel'),
      onOk: () => {
        this.subDisconnectAli(true);
      }
    });
  };

  // 切换AE账号
  subReconnectAli = () => {
    this.props.dispatch({
      type: 'dropshippersetting/reconnectAli',
      payload: {
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000) {
            window.location.href = d.data.url;
          }
        }
      }
    });
  };

  // 确认解绑AE授权
  subDisconnectAli = update => {
    this.props.dispatch({
      type: 'dropshippersetting/unBindAli',
      payload: {
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            if (this.props.isoberlouser) {
              oberloReport('out_bind_ae', 'true')
                .then(res => {
                  if (update) {
                    this.subReconnectAli();
                  } else {
                    window.location.reload();
                  }
                })
                .catch(() => {
                  if (update) {
                    this.subReconnectAli();
                  } else {
                    window.location.reload();
                  }
                });
            } else {
              if (update) {
                this.subReconnectAli();
              } else {
                window.location.reload();
              }
            }
            // if (update) {
            //   this.subReconnectAli();
            // } else {
            //   window.location.reload();
            // }
          }
        }
      }
    });
    this.setState({
      refreshModal: false
    });
  };

  closeAeRefreshHandler=()=>{
    this.setState({
      refreshModal:false
    })
  }
  AeRefreshHandler=()=>{
    this.setState({
      refreshModal:false
    })
    this.subDisconnectAli(true);
  }

  render() {
    const { userInfo } = this.props;

    const {
      stores,
      emailError,
      emailErrorMsg,
      changeEmail,
      sendSucc,
      verifyEmailError,
      disconnectShopifyStoreId,
      showDisconnectCallback,
      showRefreshWhitelist,
      refreshModal
    } = this.state;

    const aliBeOverdueTime = localStorage.getItem('aliBeOverdueTime');
    return (
      <div className={styles.otherCont}>
        <div className={styles.otherSwitch}>
          <div className={className(styles.storeList, styles.aeAccount)}>
            {aliBeOverdueTime === null ||
            localStorage.getItem('aliExpressAuthStatus') !== 'true' ? (
              <p className={styles.noData}>
                {intl.get('product.manage_product.no_data')}
              </p>
            ) : (
              <p key="k1">
                <div className="Alice">
                  {this.aliAccountId || 'Your AliExpress account'}
                  <span
                    className={
                      aliBeOverdueTime > 0
                        ? 'connecteSuccess'
                        : 'connectedError'
                    }
                  >
                    {aliBeOverdueTime > 0
                      ? intl.get('setting.account.connected')
                      : 'Expired'}
                  </span>
                  <span
                    className={
                      userInfo.is_white ? 'connecteSuccess' : 'connectedError'
                    }
                  >
                    {userInfo.is_white
                      ? intl.get('setting.account.white_listed')
                      : intl.get('setting.account.white_list')}
                  </span>
                </div>
                <span
                  className={className({
                    [styles.troubleStore]: aliBeOverdueTime <= 0
                  })}
                >
                  {/* {aliBeOverdueTime > 0
                  ? intl.get('setting.account.connected')
                  : 'Expired'} */}
                </span>
                <span>
                  {userInfo.is_white ? null : (
                    <Tooltip title={intl.get('setting.account.jiabai_intro')}>
                      <a
                        href="https://ds.aliexpress.com/dscenter/web/join.htm"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={className(styles.updateShopifyStore, {
                          [styles.isWhite]: userInfo.is_white
                        })}
                        onClick={this.handleWhitelist}
                      >
                        <i class="material-icons notranslate">add</i>
                      </a>
                    </Tooltip>
                  )}

                  {aliBeOverdueTime > 0 ? (
                    <span>
                      <a
                        className={styles.updateShopifyStore}
                        role="button"
                        tab-index="0"
                        onClick={this.swtichAli}
                      >
                        <i class="material-icons notranslate">repeat</i>
                      </a>
                      <a
                        role="button"
                        tab-index="0"
                        onClick={this.disconnectAli}
                      >
                        <i class="material-icons notranslate">delete</i>
                      </a>
                    </span>
                  ) : (
                    <a role="button" tab-index="0" onClick={this.reconnectAli}>
                      {intl.get('public.reconnect')}
                    </a>
                  )}
                </span>
              </p>
            )}
          </div>
          <p />
        </div>
        <RefreshMask visible={showRefreshWhitelist} />
        <Modal
          visible={refreshModal}
          onCancel={this.closeAeRefreshHandler}
          className={styles.aemodel}
        >
          <div className={styles.aecontainer}>
            <p className={styles.title}>
              {intl.getHTML('setting.account.ae_account')}
            </p>
            <p className={styles.text}>
              {intl.getHTML('setting.account.switch_ae')}
            </p>
            <div className={styles.btncon}>
              <Button onClick={this.closeAeRefreshHandler}>
                {intl.get('public.cancel').toUpperCase()}
              </Button>
              <Button
                className={styles.modelbtnstyle}
                onClick={this.AeRefreshHandler}
              >
                {intl.get('public.ok').toUpperCase()}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export { AliExpressAccount };
