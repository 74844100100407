export default function PostCodeValidate(country = '', post_code = '') {
  let PostCodeMaxLength = '';
  let PostCodeError = 'success';
  let PostCodeErrorTxt = '';

  if (
    country == 'Austria' ||
    country == 'Belgium' ||
    country == 'Denmark' ||
    country == 'Hungary' ||
    country == 'Luxembourg' ||
    country == 'Norway' ||
    country == 'Slovenia'
  ) {
    // 特殊国家邮编 4位
    PostCodeMaxLength = '4';
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (!/^([0-9]{4})$/.test(post_code)) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a 4-digit zip code, such as 1234';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  } else if (
    country == 'Korea' ||
    country == 'Spain' ||
    country == 'Ukraine' ||
    country == 'United States' ||
    country == 'Czech Republic' ||
    country == 'France' ||
    country == 'Germany' ||
    country == 'Italy' ||
    country == 'Mexico' ||
    country == 'Poland' ||
    country == 'Slovakia (Slovak Republic)'
  ) {
    // 特殊国家邮编 5位
    PostCodeMaxLength = 5;
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (!/^([0-9]{5})$/.test(post_code)) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please Enter a 5-digit zip code, such as 12345';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  } else if (country == 'Russian Federation' || country == 'Singapore') {
    // 特殊国家邮编 6位
    PostCodeMaxLength = 6;
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (!/^([0-9]{6})$/.test(post_code)) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please Enter a zip code, such as 123456';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  } else if (country == 'Chile' || country == 'Israel') {
    // 特殊国家邮编 7位
    PostCodeMaxLength = 7;
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (!/^([0-9]{7})$/.test(post_code)) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a 7-digit zip code, such as 1234567';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  } else if (country == 'Belarus') {
    // 特殊国家邮编 6位 2开头 纯数字
    PostCodeMaxLength = 6;
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (!/^(2[0-9]{5})$/.test(post_code)) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please Enter a zip code, such as 234567';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  } else if (country == 'Brazil') {
    // 特殊国家邮编 5位数字：12345 8位数字：12345000 9位数字+‘-’且必须在倒数第四位12345-000  无空格
    PostCodeMaxLength = 9;
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (
      !/^((0[1-9][0-9]{3})|([1-9][0-9]{4})|(0[1-9][0-9]{3}(-)?[0-9]{3})|([1-9][0-9]{4}(-)?[0-9]{3}))$/.test(
        post_code
      )
    ) {
      PostCodeError = 'error';
      PostCodeErrorTxt =
        'Please enter your zip code in suggested format, such as 13165-000';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  } else if (country == 'Canada') {
    // 特殊国家邮编 6位 英文数字间隔，第一位英文A1B2C3 3+3位，中间空格A1B 2C3 英文必须大写
    PostCodeMaxLength = 7;
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (
      !/^([A-Z][0-9][A-Z][0-9][A-Z][0-9]|[A-Z][0-9][A-Z][\s][0-9][A-Z][0-9])$/.test(
        post_code
      )
    ) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please Enter a 6-digit zip code, such as A1B2C3';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  } else if (country == 'Netherlands') {
    // 特殊国家邮编 6位  4数字+2字母 7位 4数字+空格+2字母 前四个不能有英文
    PostCodeMaxLength = 7;
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (
      !/^(([0-9]{4}[\s][A-Za-z]{2})|([0-9]{4}[A-Za-z]{2}))$/.test(post_code)
    ) {
      PostCodeError = 'error';
      PostCodeErrorTxt =
        'please enter your zip code in suggested format, such as 1234AB';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  } else if (country == 'Portugal') {
    // 特殊国家邮编 7位数字 4数字+-+3数字 无英文
    PostCodeMaxLength = 8;
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (!/^(([0-9]{7})|([0-9]{4}-[0-9]{3}))$/.test(post_code)) {
      PostCodeError = 'error';
      PostCodeErrorTxt =
        'For international delivery, zip code with 7 digits is required. You may confirm with your local post office on this if needed.';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  } else if (country == 'Saudi Arabia') {
    // 特殊国家邮编 5位数字+-+四位数字 无英文
    PostCodeMaxLength = 10;
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (
      !/^(([0-9]{5}-[0-9]{4})|([0-9]{5})|([0-9]{7}))$/.test(post_code)
    ) {
      PostCodeError = 'error';
      PostCodeErrorTxt =
        'please enter your zip code in suggested format, such as 12345-1234';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  } else if (country == 'United Kingdom') {
    // 特殊国家邮编 4+3:1234 111 3+3:123 111 2+3:12 111
    PostCodeMaxLength = 8;
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (!/^([0-9A-Za-z]{2,4}[\s][0-9A-Za-z]{3})$/.test(post_code)) {
      PostCodeError = 'error';
      PostCodeErrorTxt =
        'please enter your zip code In suggested format, such as WC1B 3DG';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  } else if (country == 'Australia') {
    PostCodeMaxLength = 4;
    // 邮编 必填 1-16 只限拉丁字母/数字/空格/连字符 errInfo.post_code
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (!/^([0-9]{4})$/.test(post_code)) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a 4-digit zip code, such as 1234';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  } else {
    PostCodeMaxLength = 16;
    // 邮编 必填 1-16 只限拉丁字母/数字/空格/连字符 errInfo.post_code
    if (post_code.length == 0) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter a ZIP/Postal Code';
    } else if (post_code.length > 16) {
      PostCodeError = 'error';
      PostCodeErrorTxt = 'Please enter between 1 - 16 characters';
    } else if (!/^[A-Z\d-\s]*$/.test(post_code)) {
      PostCodeError = 'error';
      PostCodeErrorTxt =
        'Please enter Latin letters/numbers/spaces/hyphens only';
    } else {
      PostCodeError = 'success';
      PostCodeErrorTxt = '';
    }
  }

  return {
    PostCodeMaxLength,
    PostCodeError,
    PostCodeErrorTxt
  };
}
