import React, { useMemo } from 'react';
import classNames from 'classnames';

import styles from './index.less';

export const SettingPartitionBox = ({ children, className, ...restProps }) => {
  const calculateClassName = useMemo(() => {
    return classNames(styles.partitionBox, className);
  }, [className]);

  return (
    <div {...restProps} className={calculateClassName}>
      {children}
    </div>
  );
};

export const SettingPartitionHeader = ({
  children,
  className,
  ...restProps
}) => {
  const calculateClassName = useMemo(() => {
    return classNames(styles.partitionHeader, className);
  }, [className]);

  return (
    <div {...restProps} className={calculateClassName}>
      {children}
    </div>
  );
};

export const SettingPartitionContent = ({
  children,
  className,
  ...restProps
}) => {
  const calculateClassName = useMemo(() => {
    return classNames(styles.partitionContent, className);
  }, [className]);

  return (
    <div {...restProps} className={calculateClassName}>
      {children}
    </div>
  );
};

export const SettingPartitionTitle = ({
  children,
  className,
  ...restProps
}) => {
  const calculateClassName = useMemo(() => {
    return classNames(styles.partitionTitle, className);
  }, [className]);

  return (
    <div {...restProps} className={calculateClassName}>
      {children}
    </div>
  );
};

export const SettingItemContainer = ({
  className,
  left,
  middle,
  right,
  ...restProps
}) => {
  const calculateClassName = useMemo(() => {
    return classNames(styles.settingItemContainer, className);
  }, [className]);

  return (
    <div {...restProps} className={calculateClassName}>
      {left ? <div className={styles.itemLeft}>{left}</div> : null}
      {middle ? <div className={styles.itemMiddle}>{middle}</div> : null}
      {right ? <div className={styles.itemRight}>{right}</div> : null}
    </div>
  );
};
