import React, { useMemo } from 'react';
import classnames from 'classnames';

import { Card, CardProps } from 'antd';

import styles from './index.less';

const CardHeader = React.memo(({ title, subTitle }) => {
  return (
    <div>
      <div className={styles.title}>{title}</div>
      {subTitle ? <div className={styles.subTitle}>{subTitle}</div> : null}
    </div>
  );
});

function BackCard({ title, subTitle, className, ...restProps }) {
  const culculateClassNames = useMemo(() => {
    return classnames(styles.settingSectionCard, className);
  }, []);

  return (
    <Card
      className={culculateClassNames}
      title={<CardHeader title={title} subTitle={subTitle} />}
      {...restProps}
    ></Card>
  );
}

export default BackCard;
