import { Button,Radio,Modal,message  } from "antd"
import React, { useEffect, useState } from "react"
import PayForm from './PayForm.jsx'
import intl from 'react-intl-universal';
import style from './index.less'
import { connect } from 'dva';
import { getTMpayment, sendTMpayment,getPayList,getFileUrlLink,getFileUrl,accordIdGetLink} from '../../../../../services/dropshippersetting'
import {stateValue} from './mock'
import { setInterval } from "timers";
import { CommonDataCollect } from 'utils/utils'
const Payments = ({ userInfo, timezoneRegion,timeZone }) => {
  const [userEmail, setUserEmail] = useState(userInfo?.email);
  const [selectValue, setSelectValue] = useState(undefined);
  const [formValue, setFormValue] = useState({});
  const [stateVal, setStateVal] = useState([]);
  const [detailMes, setDetailMes] = useState({ first: "", second: "" })
  const [visiableForm, setVisiableForm] = useState(false)
  const [visiableConfirm, setVisiableConfirm] = useState(false)
  const [remeber, setRemeber] = useState(true)
  const [chooseState, setChooseState] = useState(0)
  const country = JSON.parse(localStorage.getItem('countryList')) || []
  window.timeRecord = []
  useEffect(() => {
    setUserEmail(userInfo?.email)
    setSelectValue(timeZone);
  }, [userInfo, timezoneRegion])
  
  const getCountryMes = (code) => {
    let returnMes = undefined;  //使得 select 的placeholder 生效
      country.forEach(element => {
        if (element.Code == code) {
              returnMes = element.Name
           }
      });
      return returnMes
  }
  useEffect(() => {
    window.timeRecord = [];
    CommonDataCollect({
      action: 'setting_payment_click',
      event_type:'setting_payment'
    });
    getPayList().then((res) => {
      setStateVal([... res.list.map(item => { return { ...item, state: 'request' } })])
    })
    initINformat()
    return () => {
      if (window.timeRecord.length) {
        Object.values(window.timeRecord).forEach((item) => {
              clearTimeout(item)
        })
      }
      window.timeRecord = []
    }
  }, [])

  const initINformat = () => {
    getTMpayment().then((res) => {
      let hasValue = {};
      if (res.code == 2000) {
        hasValue = res.data;
        // 调用接口
        if (hasValue.email) {
          setFormValue(hasValue)
        } else {
          hasValue.email = userEmail;
          setFormValue(hasValue)
        }
        detailMes.first = (hasValue.first_name ? hasValue.first_name + "," : "") +
          (hasValue.last_name ? hasValue.last_name + "," : "") +
          (hasValue.business_name ? hasValue.business_name + "," : "")  +
          hasValue.email;
    
        detailMes.second = (hasValue.city ? hasValue.city + "," : "") +
          (hasValue.state ? hasValue.state + "," : "") +
          (hasValue.country ? getCountryMes(hasValue.country) + "," : "") + (hasValue.zip_code ? hasValue.zip_code : "")
          setDetailMes({...detailMes})
          }
    })
  }
  const timezoneLabel = value => {
    const tmp = timezone.find(i => i.value === value);
    return tmp && tmp.label;
  };

  const PaymentsInfo = () => {
    return <div className={style.payment}>
      <div className={style.payment_title}>{intl.get("setting.payment.top_title")}</div>
      <div className={style.payment_tip}>{intl.get("setting.payment.top_small_title")}</div>
      <div className={style.payment_base}>
        <div>
          <i class="material-icons material-icons-notClick">archive</i>
          <div className={style.paymentInfo_base_center}>
            <div>{detailMes.first.endsWith(",") ? detailMes.first.substring(0, detailMes.first.length - 1) : detailMes.first}</div>
            {detailMes.second &&
              <span>{detailMes.second.endsWith(",") ? detailMes.second.substring(0, detailMes.second.length - 1) : detailMes.second}</span>
            }
          </div>
        </div>
        <div className={style.paymentInfo_btn} onClick={() => { 
              CommonDataCollect({
                action: 'setting_payment_request',
                event_type:'setting_payment'
              });
          setVisiableForm(true) }}>EDIT</div>
      </div>
    </div>
  }

  const getUrlFromTrackingId = (id,index) => {
    (function getUrlAgain(){
      accordIdGetLink({ trackingId: id }).then(res => {
        if (!res.excelUrl || !res.pdfUrl) {
          window.timeRecord[id] = setTimeout(getUrlAgain,3000);
        } else {
          stateVal[index].state = 'download';
          stateVal[index].excelUrl = res.excelUrl;
          stateVal[index].pdfUrl = res.pdfUrl;
          setStateVal([...stateVal])  
        }
      })
  })();
  }
  const downloadFile = async (url) => {
    let response = await fetch(url);
    let blob = await response.blob();
    let objectUrl = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = objectUrl;
    a.download='bill.pdf'
    a.click()
    a.remove(); 
  };
  const requestFunc = (state,id,date,index) => {
    setChooseState(index)
    if (localStorage.getItem("paymentConfirmRemeber")) {

      getFileUrlLink({ 'billId': id }).then((res) => {
        if (!res.excelUrl || !res.pdfUrl) {
          stateVal[index].state = 'requesting';
          setStateVal([...stateVal])  
          getFileUrl({ 'date': date }).then(res => {
            if (res.trackingId) {
              getUrlFromTrackingId(res.trackingId,index)
            }
          })
        } else {
          stateVal[index].state = 'download';
          stateVal[index].excelUrl = res.excelUrl;
          stateVal[index].pdfUrl = res.pdfUrl;
          setStateVal([...stateVal])  
        }
      })
  
    } else {
      setVisiableConfirm(true)
    }
  }

  const stateGroup = (item, index) => {
    switch (item.state) {
      case 'download':
        return <>
          <a style={{ marginLeft: '20px' }}
            onClick={() => {
              downloadFile(item.pdfUrl)
            }}>
            DOWNLOAD AS PDF
          </a>
          <a
            onClick={() => {
              window.open(item.excelUrl)
            }}>
            DOWNLOAD AS CSV
          </a>
        </>
      case 'requesting':
       return <a className={style.payment_base_notLink}>{intl.get("setting.payment.bottom_state2")}</a>
      case 'request':
        return <a onClick={() => {
                  requestFunc(item.state,item.billId,item.date, index)
                  CommonDataCollect({
                    action: 'setting_payment_request',
                    event_type:'setting_payment'
                  });  
                }}>
          {intl.get("setting.payment.bottom_state1")}
        </a>
      default:
        return null
    }
  }
  const PaymentsState = () => {
    return <div className={style.payment} style={{ marginTop: '20px' }}>
      <div className={style.payment_title}>{intl.get("setting.payment.bottom_title")}</div>
      <div className={style.payment_tip}>{intl.get("setting.payment.bottom_small_title")}</div>
      <div className={style.payment_stateBase}>
        {
          selectValue ?
            <>
              {
                stateVal.map((item, index) => {
                  return <div className={style.payment_base_item}>
                    <div className={style.payment_base_item_date}>
                      <span>{item.date}</span>
                      <span>${item.money}</span>
                    </div>
                    <div>
                      {stateGroup(item,index)}
                    </div>
                  </div>
                })
              }
            </>
            :
            <div className={style.payment_noTimezone}>Please<a href="/app/settings?id=1"> set up time zone </a>to activate the feature</div>
        }
      </div>
    </div>
  }

  const PaymentsConfirmBtn = () => {
    setVisiableConfirm(false)
    if (remeber) {
      localStorage.setItem("paymentConfirmRemeber", true)
    }
    stateVal[chooseState].state = 'requesting'
    setStateVal([...stateVal])
  }
  
  return (
    <div className={style.paymentContent}>
      {PaymentsInfo()}
      {PaymentsState()}
      <PayForm
        visible={visiableForm}
        value={formValue}
        closeModal={() => { setVisiableForm(false) }}
        submit={(value) => {
          setVisiableForm(false)
          sendTMpayment(value).then((res) => {
            if (res.code == 2010) {
              message.success('save success');
              initINformat()
            }
          })
        }}
      >
        </PayForm>
      <Modal
        title="Please confrm your business information"
        visible={visiableConfirm}
        onCancel={()=>{ setVisiableConfirm(false)}}
        footer={
          <Button
          type="primary"  
          onClick={() => {
            PaymentsConfirmBtn()
        }}>
           CONFIRM REQUEST
        </Button>}
      >
        <div style={{marginBottom:'10px'}}>Business information can’t be changed once a bill is requested.</div>
        <Radio checked={remeber}
               onClick={()=>{ setRemeber(!remeber) }}
        ></Radio> Don’t show again
      </Modal>
    </div>
  )
}
const mapStateToProps = ({ login, global }) => ({
  userInfo: login.userInfo,
  timezoneRegion: global.timezoneRegion,
  timeZone:global.settingTimeZone
});
export default connect(mapStateToProps)((React.memo(Payments)));
