import React, { useReducer, useState, useCallback } from 'react';
import { Modal, Select, Divider, Checkbox, Button, Tabs } from 'antd';
import { connect } from 'dva';

import styles from './AdAccountModal.less';

const bodyStyle = { padding: '32px 48px' };

function Footer({ onCancel, onSave }) {
  return (
    <div className={styles.footer}>
      <Button onClick={onCancel}>Disconnect the page</Button>
      <Button type="primary" style={{ marginLeft: 12 }} onClick={onSave}>
        Save
      </Button>
    </div>
  );
}

const AdAccountModal = ({ visible, data, onCancel }) => {
  const [activeKey, setActiveKey] = useState('1');

  function handleCancel() {
    onCancel();
  }

  const handleTabsChange = useCallback(value => {
    setActiveKey(value);
  }, []);

  return (
    <Modal
      visible={visible}
      footer={null}
      width={650}
      maskClosable={false}
      keyboard={false}
      onCancel={handleCancel}
      bodyStyle={bodyStyle}
      destroyOnClose
    >
      <Tabs
        activeKey={activeKey}
        tabPosition="left"
        onChange={handleTabsChange}
      >
        <Tabs.TabPane tab="Tab 1" key="1">
          <div>Content of Tab Pane 1</div>
          <Footer onCancel={handleCancel} onSave={handleCancel} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Tab 2" key="2">
          <div>Content of Tab Pane 2</div>
          <Footer onCancel={handleCancel} onSave={handleCancel} />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default connect(({ login }) => ({
  userInfo: login.userInfo
}))(React.memo(AdAccountModal));
