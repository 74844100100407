import React, { useState, useCallback } from 'react';
import { Divider, Button } from 'antd';

import AccountModal from './AccountModal';
import AdAccountModal from './AdAccountModal';

import styles from './AccountFB.less';

function AccountFB() {
  const [visible, setVisible] = useState(false);
  const [adVisible, setAdVisible] = useState(false);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const handleAdClose = useCallback(() => {
    setAdVisible(false);
  }, []);

  return (
    <div className={styles.wrap}>
      <h3>Add Facebook Ad account</h3>
      <div>
        Click the button below to authorize your Facebook Ad accounts into
        DSers.
        <br />
        DSers will show the data as Media cost on the Report menu
      </div>
      <div style={{ marginTop: 12 }}>
        <Button onClick={() => setVisible(true)}>Manage</Button>
      </div>
      <Divider />
      <h3>Manage Facebook Ad accounts</h3>
      <div>
        Click the button to check or manage the Facebook accounts and Facebook
        Ad accounts that you authorized to DSers.
      </div>
      <div style={{ marginTop: 12 }}>
        <Button onClick={() => setAdVisible(true)}>Manage</Button>
      </div>
      <AccountModal visible={visible} onCancel={handleClose} />
      <AdAccountModal visible={adVisible} onCancel={handleAdClose} />
    </div>
  );
}

export default AccountFB;
