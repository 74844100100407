import React,{ useEffect, useState } from 'react';
import { connect } from 'dva';
import { Switch,Radio, Button ,message } from 'antd';
import intl from 'react-intl-universal';
import { Post,Get } from '../../../../../utils/request';

import styles from './CancelRequestFulfillment.less';
const RadioGroup = Radio.Group;
const CancelRequestFulfillment = (props) => {
  const { userInfo, switchLoading } = props;
  const [list,setList] = useState([])
  const [errorFlag,setErrorFlag] = useState(false)
  const [fulfillEdit,setFulfillEdit] = useState(true)
  const [fulfillValue,setFulfillValue] = useState(null)
  useEffect(()=>{
    getList()
  },[])

  function handleSwitch(checked) {
    props.dispatch({
      type: 'dropshippersetting/cancelFulfillment',
      payload: {
        data: {
          cancel_fulfillment: checked
        },
        callback: d => {
          if (d.code === 2010) {
            props.dispatch({
              type: 'login/updateUserInfo'
            });
          }
        }
      }
    });
  }

  const getList = async () => {
    let response = await Get('/fulfillment/set/cancel')
    if(response.code === 2000){
      setList(response?.data)
      setErrorFlag(false)
    }else{
      setErrorFlag(true)
    }
    // Setting添加功能-整单Fulfill/单Item Fulfill
    let fulfillResult = await Get('/fulfillment/set/fulfill')
    if(fulfillResult.code == '2000'){
      if(fulfillResult?.data.item_fulfillment){
        setFulfillValue('item')
     }else{
        setFulfillValue('line_items')
     }
    }
  }

  const setListItem = async (value) => {

    const list_arr = list.map(item => {
      if(item.Service === value){
        return {
          ...item,
          Open: item.Open ? false : true
        }
      }else{
        return item
      }
    })

    let response = await Post('/fulfillment/set/cancel',{
      data:{
        data:list_arr
      }
    })

    if(response.code === 2010){
      getList()
    }

  }

  const saveFulfillment = async () =>{
      let response = await Post('/fulfillment/set/fulfill',{
        data:{
          "item_fulfillment" : fulfillValue == 'item' ? true : false, 
          "line_items_fulfillment" : fulfillValue == 'line_items' ? true : false, 
        }
      })
      if(response.msg == 'ok'){
        setFulfillEdit(true)
        message.success('save success!');
      }
  }

  return (
    <div className={styles.wrap}>
      <h3>{intl.get('setting.other.cancel_fulfillment_title')}</h3>
      <p className={styles.desc}>
        {intl.get('setting.other.cancel_fulfillment_desc')}
      </p>
      {/* <div className={styles.switchWrap}>
        <Switch
          checkedChildren={intl.get('public.on')}
          unCheckedChildren={intl.get('public.off')}
          defaultChecked={userInfo.cancel_fulfillment}
          onClick={handleSwitch}
          loading={switchLoading}
        />
      </div> */}
      {
        errorFlag ? <div className={styles.error}>
          DSers failed to get the list of fufillment service, please click <a onClick={getList}>here</a> to try again
          </div> : <ul className={styles.wrap_ul}>
        {
          list?.map((item,i) => {
            return <li key={i}>
                <div className={item.Open ? styles.name : styles.name2}>{item.Service}</div>
                <div>
                  <Switch
                    // checkedChildren={intl.get('public.on')}
                    // unCheckedChildren={intl.get('public.off')}
                    defaultChecked={item.Open}
                    onClick={()=>{
                      setListItem(item.Service)
                    }}
                  />
                </div>
              </li>
          })
        }
      </ul>
      }

      <div>
        
{ userInfo.user_id !== 'c4p0h2rmvtfg00b439l0'   &&
  <div className={styles.fullFillPart}>
        <div className={styles.fullFillName}> {intl.get('setting.other.order_fulfillment_module')}</div>
          <RadioGroup
                value={fulfillValue}
                onChange={e => {
                       setFulfillValue(e.target.value)
                       setFulfillEdit(false)
                    }}
          >
            <div style={{'display':'flex'}}
              onClick={() => {
                if(fulfillValue !='item'){
                  setFulfillValue('item')
                  setFulfillEdit(false)
                }
              }}
          >
              <Radio value='item'></Radio>
              <div className={styles.fullValue}>
                <span> {intl.get('setting.other.order_fulfillment_first_title')}</span>
                 <div> {intl.get('setting.other.order_fulfillment_first_content')}</div>
              </div>
            </div>
            <div 
            style={{'display':'flex','marginTop':'10px'}}
            onClick={()=>{
             if(fulfillValue != 'line_items'){
                setFulfillValue('line_items')
                setFulfillEdit(false)
              } 
               }}
            >
              <Radio value='line_items'></Radio>
              <div  className={styles.fullValue}>
                 <span> {intl.get('setting.other.order_fulfillment_second_title')}</span>
                 <div> {intl.get('setting.other.order_fulfillment_second_content')}</div>
              </div>
            </div>
          </RadioGroup>
           
           <div className={styles.btnGroup}>
            <Button type='primary' disabled={fulfillEdit} onClick={()=>{saveFulfillment()}}> 
            {intl.get('setting.other.order_save')}
            </Button>
           </div>
        </div>}

      </div>
    </div>
  );
}

export default connect(({ login, loading }) => ({
  userInfo: login.userInfo,
  switchLoading: loading.effects['dropshippersetting/cancelFulfillment']
}))(CancelRequestFulfillment);
