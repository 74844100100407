/* eslint react/jsx-props-no-spreading: 0 */

import React, { useState, useCallback } from 'react';
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Button,
  DatePicker,
  Divider
} from 'antd';
import moment from 'moment';

import NumericInput from '../../../../Bargain/component/NumericInput';
import CategoryModal from './CategoryModal';
import {
  Frequency,
  StoreSelect,
  Category
} from '../../../component/CommonSelect';

import styles from './SpendManage.less';

const originData = [];

for (let i = 0; i < 10; i++) {
  originData.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    created: '2020-09-06',
    category: 32,
    store: 125,
    start: '2020-09-06',
    end: '2020-09-06',
    frequency: 'Monthly',
    total: 57
  });
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  getFieldDecorator,
  ...restProps
}) => {
  let inputNode = <Input />;

  if (editing) {
    if (dataIndex === 'category') {
      inputNode = <Category />;
    } else if (dataIndex === 'store') {
      inputNode = <StoreSelect />;
    } else if (dataIndex === 'start') {
      inputNode = <DatePicker />;
    } else if (dataIndex === 'end') {
      inputNode = <DatePicker />;
    } else if (dataIndex === 'frequency') {
      inputNode = <Frequency />;
    } else if (dataIndex === 'total') {
      inputNode = <NumericInput addonBefore="$" />;
    }
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item style={{ margin: 0, paddingBottom: 8 }}>
          {getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: 'require'
              }
            ]
            // initialValue: record[dataIndex]
          })(inputNode)}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const initalRow = {
  key: Date.now,
  created: '',
  category: undefined,
  store: undefined,
  start: undefined,
  end: undefined,
  frequency: undefined,
  total: undefined
};

function SpendManage({ form }) {
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');
  const [visible, setVisible] = useState(false);

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    setEditingKey(record.key);

    setTimeout(() => {
      form.setFieldsValue({
        name: '',
        start: moment(),
        end: moment()
      });
    }, 200);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async key => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Created date',
      dataIndex: 'created'
      // width: '25%',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      // width: '15%',
      editable: true
    },
    {
      title: 'Store',
      dataIndex: 'store',
      // width: '40%',
      editable: true
    },
    {
      title: 'Start date',
      dataIndex: 'start',
      // width: '40%',
      editable: true
    },
    {
      title: 'End date',
      dataIndex: 'end',
      // width: '40%',
      editable: true
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      // width: '40%',
      editable: true
    },
    {
      title: 'Total spend',
      dataIndex: 'total',
      // width: '40%',
      editable: true
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      fixed: 'right',
      // eslint-disable-next-line
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              role="button"
              tab-index="0"
              onClick={() => save(record.key)}
            >
              Save
            </a>
            <Divider type="vertical" />
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <a disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </a>
        );
      }
    }
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        getFieldDecorator: form.getFieldDecorator
      })
    };
  });

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  // const x = columns.reduce((total, item) => {
  //   return total + +(item.width || 0);
  // }, 0);

  return (
    <div className={styles.wrap}>
      <h3>Manage custom spendings</h3>
      <div className={styles.header}>
        <Button type="link" icon="plus" onClick={() => setVisible(true)}>
          New category
        </Button>
        <Button type="link" icon="plus">
          New spends
        </Button>
      </div>
      <div>
        <Table
          components={{
            body: {
              cell: EditableCell
              // cell: React.memo(EditableCell)
            }
          }}
          rowKey={record => record.key}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          size="small"
          scroll={{ x: 1120 }}
        />
      </div>
      <CategoryModal visible={visible} onCancel={handleClose} />
    </div>
  );
}

export default Form.create()(SpendManage);
