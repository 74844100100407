import React from 'react';
import { connect } from 'dva';
import { Alert } from 'antd';

import TimeZone from '../Account/TimeZone';
import Currencies from '../Stores/Currencies';
import AccountFB from './AccountFB';
import SpendManage from './SpendManage';
import SpendOtherManage from './SpendOtherManage';

import styles from './index.less';

function Report({ currency, timeZone }) {
  const disable = !(currency && Object.keys(currency).length > 0) || !timeZone;

  return (
    <>
      <TimeZone />
      <Currencies />
      {/* {process.env.NODE_ENV === 'development' ? (
        <>
          {disable ? (
            <Alert
              type="error"
              message="Please set up Currency and Time Zone before using the feature."
              banner
            />
          ) : null}
          <div className={disable ? styles.disable : null}>
            <AccountFB />
            <SpendManage />
            <SpendOtherManage />
          </div>
        </>
      ) : null} */}
    </>
  );
}

export default connect(({ global }) => ({
  currency: global.storeCurrency,
  timeZone: global.settingTimeZone
}))(Report);
