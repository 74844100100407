import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Tooltip,
  Modal,
  Input,
  Radio,
  Checkbox,
  Button,
  Spin,
  message,
  Row,
  Col
} from 'antd';
import { connect } from 'dva';
import styles from './StaffAccount.less';
import '../../setting.css';
import './StaffAccount.css';
import add from 'assets/setting/staffadd.png';
import intl from 'react-intl-universal';
import { emailRegExp } from 'utils/constants';
import { Get, Post, Put } from 'utils/request';
import { GuildeAction, LinkStoreAction } from 'actions'
import { CommonDataCollect } from '../../../../../utils/utils';

function StaffAccount({ childAccount, plan, newUserInfo }) {
  const [showAddStaff, setShowAddStaff] = useState(false);
  const [radioSelect, setRadioSelect] = useState('Admin');
  const [showeditaccount, setShowEditAccount] = useState(false);
  const [stores, setStores] = useState([]);
  const [emailFormat, setEmailFormat] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [selectMenu, setSelectMenu] = useState([]);
  const [selectStores, setSelectStores] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [spinLoading, setSpinLoading] = useState(false);
  const [editAccount, setEditAccount] = useState({});
  const [editEmail, setEditEmail] = useState('');
  const [editStores, setEditStores] = useState([]);
  const [editMenu, setMenuStores] = useState([]);
  const roleMap = {
    Admin: 0,
    'Full access': 1,
    'Limited access': 2
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setStores(JSON.parse(localStorage.getItem('store')).stores);

    getAccount();
  }, []);

  const getAccount = () => {
    setSpinLoading(true);
    dispatch({
      type: 'dropshippersetting/getChildAccount',
      payload: {
        callback: d => {
          if (d && d.code === 2000) {
            setSpinLoading(false);
          } else {
            setSpinLoading(false);
          }
        }
      }
    });
  };

  const handleAddStaff = () => {
    setRadioSelect('Admin');
    setEmailFormat('');
    let Accout = childAccount.data.content.filter(item => {
      return item.status === 1 || item.status === 3 || item.status === 5;
    });
    if ((plan.type === 2 && Accout.length > 5) || [1,7,4,8,10,12,13,14,15,16,17,18,19,20].includes(plan.type)) {
            //  跳转订阅 && 弹窗拦截
            CommonDataCollect({
              action: 'Staff-account-top',
              event_type:'overlimit_pop'
            });
            // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Staff-account&${Date.now()}`, true);
            window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'StaffAccount')
            //  window.location.href = '/app/pricing?select=1';
    } else {
      setShowAddStaff(true);
    }
  };

  const handleAddCancel = () => {
    setShowAddStaff(false);
    setShowEditAccount(false);
  };

  const handleRadio = e => {
    setRadioSelect(e.target.value);
  };

  const handleEditAccount = item => {
    return async () => {
      let Accout = childAccount.data.content.filter(item => {
        return item.status === 1 || item.status === 3 || item.status === 5;
      });
      if ([1,7,4,8,10,12,13,14,15,16,17,18,19,20].includes(plan.type)) {
        //  跳转订阅 && 弹窗拦截
        // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `p-${Date.now()}`, true);
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'StaffAccount')
        return
      } 
      try {
        const res = await Get(
          `/uaa/api/1.0/user/child_account/shopify/permissions/${item.user_id}`,
          {},
          true
        );
        if (res && res.code === 2000) {
          setEditAccount(res.data);
          setEditEmail(item.email);
          setEditStores(
            res.data?.store_permissions?.map(item => {
              return item.store_id;
            })
          );
          setMenuStores(res.data?.menu_permissions);
          setShowEditAccount(true);
        }
      } catch (error) {
        console.log('fetch error', error);
      }
    };
  };

  const handleEmail = e => {
    if (!e.target.value) {
      setInputEmail('');
    } else if (!emailRegExp.test(e.target.value)) {
      setEmailFormat(intl.get('setting.subaccount.emailformat'));
      setInputEmail('');
    } else {
      setEmailFormat('');
      setInputEmail(e.target.value);
    }
  };

  const handleStore = value => {
    setSelectStores(value);
  };

  const handleMenu = value => {
    setSelectMenu(value);
  };

  const handleAddSave = async () => {
    setSaveLoading(true);
    CommonDataCollect({
      event_type: 'subscription_analysis',
      action: 'click_add_staff_account_button',
    })
    try {
      const res = await Post(
        '/uaa/api/1.0/user/child_account/shopify',
        {
          data: {
            email: inputEmail,
            user_role: roleMap[radioSelect],
            menu_permissions: selectMenu.length
              ? selectMenu
              : [1, 2, 3, 4, 5, 6, 7, 8, 9,11],
            store_permissions: selectStores.length
              ? selectStores
              : stores.map(item => item.id)
          }
        },
        true
      );
      if (res && res.code === 2010) {
        setSaveLoading(false);
        setShowAddStaff(false);
        getAccount();
        message.success('Sub Account Success ');
      } else {
        setSaveLoading(false);
        getAccount();
      }
    } catch (error) {
      console.log('fetch error', error);
    }
  };

  const handleEditRole = e => {
    editAccount.user_role = e.target.value;
    setEditAccount({ ...editAccount });
  };

  const handleEditStores = e => {
    setEditStores(e);
  };

  const handleEditMenu = e => {
    editAccount.menu_permissions = e;
    setEditAccount({ ...editAccount });
  };

  const handleEditSave = async () => {
    setSaveLoading(true);
    try {
      const res = await Put(
        `/uaa/api/1.0/user/child_account/shopify/info/${editAccount.child_user_id}`,
        {
          data: {
            user_role: editAccount.user_role,
            menu_permissions:
              editAccount.user_role === 2
                ? editAccount.menu_permissions
                : [1, 2, 3, 4, 5, 6, 7, 8, 9,11],
            store_permissions:
              editAccount.user_role === 2
                ? editStores
                : stores.map(item => item.id),
            user_status: editAccount.user_state,
            current_user_status: editAccount.user_state
          }
        },
        true
      );
      if (res && res.code === 2010) {
        setSaveLoading(false);
        setShowEditAccount(false);
        getAccount();
        message.success('Edit Account Success ');
      } else {
        setSaveLoading(false);
        getAccount();
      }
    } catch (error) {
      console.log('fetch error', error);
    }
  };

  const handleActive = item => {
    return async () => {
      let Accout = childAccount.data.content.filter(item => {
        return item.status === 1 || item.status === 3;
      });
      if ((plan.type === 2 && Accout.length > 5) || [1,7,4,8,10,12,13,14,15,16,17,18,19,20].includes(plan.type)) {
      //  跳转订阅 && 弹窗拦截
      CommonDataCollect({
        action: 'Staff-account-top',
        event_type:'overlimit_pop'
      });
      // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Staff-account&${Date.now()}`, true);
      //  window.location.href = '/app/pricing?select=1';
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'StaffAccount')
      } else {
        try {
          const res = await Put(
            `/uaa/api/1.0/user/child_account/shopify/status/${item.user_id}`,
            {
              data: {
                user_status: 1
              }
            },
            true
          );
          if (res && res.code === 2000) {
            getAccount();
            message.success('Active Account Success ');
          } else {
            getAccount();
          }
        } catch (error) {
          console.log('fetch err', error);
        }
      }
    };
  };

  const handleRemove = async () => {
    try {
      const res = await Put(
        `/uaa/api/1.0/user/child_account/remove/${editAccount.child_user_id}`,
        {},
        true
      );
      console.log(res);
      if (res && res.code === 2010) {
        getAccount();
        setShowEditAccount(false);
        message.success('Remove Account Success ');
      } else {
        setShowEditAccount(false);
        getAccount();
      }
    } catch (error) {
      console.log('fetch error', error);
    }
  };
  const handleUnbidden = async (item) => {
    try {
      const res = await Put(
        `/uaa/api/1.0/user/child_account/shopify/status/${item.user_id}`,
        {   data: {  user_status: 3  }   },
        true
      );
      getAccount();
    } catch (error) {
      console.log('fetch err', error);
    }
  }
  const handleError = async (item) => {
    try {
      const res = await Put(
        `/uaa/api/1.0/user/child_account/remove/${item.user_id}`,
        {},
        true
      );
      getAccount();
    } catch (error) {
      console.log('fetch error', error);
    }
  }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.top}  onClick={handleAddStaff}>
          <span>
            <span style={{ display: 'inline-block', marginRight: '35px' }}>
              <img src={add} alt="" />
            </span>
            {intl.get('setting.subaccount.add')}
          </span>
          <i class="material-icons notranslate">
            add
          </i>
        </div>
        <div className={styles.stafflist}>
          <Spin spinning={spinLoading}>
            {childAccount?.data?.content?.map((item, index) => {
              return (
                <div className={styles.staffitem} key={item.user_id}>
                  <span className="accountBoxIcon">
                    <i class="material-icons notranslate notranslate">
                      account_circle
                    </i>
                  </span>
                  <div className="accountBoxItemInfo">
                    <div
                      className="name"
                      style={
                        item.status === 2
                          ? { color: 'rgba(0, 0, 0, 0.24)' }
                          : null
                      }
                    >
                      <span style={{ marginRight: '9px' }}>{item.email}</span>
                      {item.status !== 6 &&
                      item.status === 2 ? (
                        <span className="disable" style={{ marginLeft: '9px' }}>
                          {intl.get('mapping.verified.disable')}
                        </span>
                      ) : (
                        <span
                          className={
                            item.status === 3
                              ? 'invite'
                              : item.user_role === 0
                                ? 'Admin'
                                : item.user_role === 1
                                  ? 'Full'
                                  : 'limited'
                          }
                        >
                          {item.status === 3
                            ? 'Invited'
                            : item.user_role === 0
                              ? 'Admin'
                              : item.user_role === 1
                                ? 'Full'
                                : 'Limted'}
                        </span>
                      )}
                    </div>
                    {/* 状态为异常时 显示错误提示 */}
                    {item.status === 6 &&
                      <div
                        className={styles.errorMsg}>
                        {item.err_msg}</div>
                    }
                  </div>
                  {item.status !== 6 && <>
                    {item.status == 2 ? (
                      <Button
                        style={{height:'32px',marginRight:'24px'}}
                        className={styles.button}
                        onClick={handleActive(item)}
                      >
                        ACTIVATE
                      </Button>
                    ) : null}
                  {/* oberlo用户待受邀状态 */}
                  {
                    item.status === 5
                    &&
                    <Button
                    style={{marginRight:'24px'}}
                    className={styles.buttonActive}
                    onClick={()=>{handleUnbidden(item)}}
                     >
                    INVITE   
                    </Button>
                  }
                    <span className="childAccountEdit">
                      {index === 0 ||
                        item.status === 2 ||
                        item.user_id === newUserInfo?.user_id ? null : (
                        <Tooltip title="Edit">
                          <span onClick={handleEditAccount(item)}>
                            <i class="material-icons notranslate">edit</i>
                          </span>
                        </Tooltip>
                      )}
                    </span>
                  </>}

                  {/* 迁移过来的Oberlo子账号在DSers平台是子账号错误状态 */}
                  {
                    item.status === 6
                    &&
                    <span
                     onClick={()=>{handleError(item)}}
                       className={styles.errorState} />
                  }
                </div>
              );
            })}
          </Spin>
        </div>
      </div>

      {/* 添加 */}
      <Modal
        visible={showAddStaff}
        onCancel={handleAddCancel}
        okText="SAVE"
        bodyStyle={
          radioSelect === 'Admin' || radioSelect === 'Full access'
            ? { height: '456px' }
            : null
        }
        footer={
          <Button
            type="primary"
            onClick={handleAddSave}
            disabled={
              radioSelect === 'Limited access'
                ? !selectMenu.length ||
                  !selectStores.length ||
                  inputEmail === ''
                : inputEmail === ''
            }
            loading={saveLoading}
          >
            {intl.get('public.save')}
          </Button>
        }
        destroyOnClose={true}
      >
        <div className={styles.modal}>
          <div className={styles.modaltitle}>
            {intl.get('setting.subaccount.add')}
          </div>
          <div className={styles.modaltext}>
            {intl.get('setting.subaccount.sendemail')}
          </div>
          <div className={styles.input}>
            <Input placeholder="Email address" onChange={handleEmail}></Input>
            <span style={{ color: '#f5222d', fontSize: '13px' }}>
              {emailFormat}
            </span>
          </div>
          <div className={styles.status}>
            <span>{intl.get('setting.account.status')}</span>
            <span className={styles.other}>*</span>
            <Radio.Group
              className={styles.statusRadio}
              defaultValue="Admin"
              onChange={handleRadio}
            >
              <Radio value="Admin">{intl.get('setting.subaccount.admin')}</Radio>
              <div>{intl.get('setting.subaccount.admintext')}</div>
              <Radio value="Full access">{intl.get('setting.subaccount.fullaccess')}</Radio>
              <div>{intl.get('setting.subaccount.fulltext')}</div>
              <Radio value="Limited access">{intl.get('setting.subaccount.Limitedaccess')}</Radio>
              <div>{intl.get('setting.subaccount.limittext')}</div>
            </Radio.Group>
          </div>
          {radioSelect === 'Admin' || radioSelect === 'Full access' ? null : (
            <div className={styles.store}>
              <span>{intl.get('setting.subaccount.Storeaccess')}</span>
              <span className={styles.other}>*</span>
              <Checkbox.Group
                className={styles.boxgroup}
                onChange={handleStore}
              >
                {stores.map(item => {
                  return (
                    <Checkbox key={item.id} value={item.id}>
                      {item.shop_name}
                    </Checkbox>
                  );
                })}
              </Checkbox.Group>
            </div>
          )}
          {radioSelect === 'Admin' || radioSelect === 'Full access' ? null : (
            <div className={styles.feature}>
              <span>{intl.get('setting.subaccount.Featureaccess')}</span>
              <span className={styles.other}>*</span>
              <div className={styles.accesscheckbox}>
                <Checkbox.Group onChange={handleMenu}>
                  <Row>
                    <Col span={24}>
                      <Checkbox value={1}>{intl.get('setting.subaccount.report')}</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Checkbox value={2}>{intl.get('setting.subaccount.ImportList')}</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value={3}>{intl.get('setting.subaccount.MyProduct')}</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Checkbox value={4}>{intl.get('setting.subaccount.Openorder')}</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value={6}>{intl.get('setting.subaccount.ArchivedOrder')}</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Checkbox value={11}>{intl.get('setting.subaccount.Tracking')}</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Checkbox value={5}>{intl.get('setting.subaccount.CSVBulkOrder')}</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value={7}>{intl.get('setting.subaccount.LinktoMoreStores')}</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Checkbox value={8}>{intl.get('setting.subaccount.Setting')}</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value={9}>{intl.get('setting.subaccount.Notification')}</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          )}
        </div>
      </Modal>

      {/* 编辑 */}
      <Modal
        visible={showeditaccount}
        onCancel={handleAddCancel}
        okText="SAVE"
        cancelText="REMOVE"
        bodyStyle={
          editAccount?.user_role === 0 || editAccount?.user_role === 1
            ? { height: '456px' }
            : null
        }
        footer={
          <>
            <Button
              type="primary"
              onClick={handleRemove}
              className={styles.delete}
            >
              {intl.get('public.delete')}
            </Button>
            <Button
              type="primary"
              onClick={handleEditSave}
              disabled={
                editAccount?.user_role === 2
                  ? !editStores?.length || !editAccount?.menu_permissions.length
                  : false
              }
              loading={saveLoading}
            >
              {intl.get('public.save')}
            </Button>
          </>
        }
      >
        <div className={styles.modal}>
          <div className={styles.modaltitle}>EDIT</div>
          <div className={styles.modaltext}>
          {intl.get('setting.subaccount.sendemail')}
          </div>
          <div className={styles.input}>
            <Input value={editEmail} disabled={true}></Input>
          </div>
          <div className={styles.status}>
            <span>{intl.get('setting.account.status')}</span>
            <span className={styles.other}>*</span>
            <Radio.Group
              className={styles.statusRadio}
              value={editAccount?.user_role}
              onChange={handleEditRole}
            >
              <Radio value={0}>{intl.get('setting.subaccount.admin')}</Radio>
              <div>{intl.get('setting.subaccount.admintext')}</div>
              <Radio value={1}>{intl.get('setting.subaccount.fullaccess')}</Radio>
              <div>{intl.get('setting.subaccount.fulltext')}</div>
              <Radio value={2}>{intl.get('setting.subaccount.Limitedaccess')}</Radio>
              <div>{intl.get('setting.subaccount.limittext')}</div>
            </Radio.Group>
          </div>
          {editAccount?.user_role === 0 ||
          editAccount?.user_role === 1 ? null : (
            <div className={styles.store}>
              <span>{intl.get('setting.subaccount.Storeaccess')}</span>
              <span className={styles.other}>*</span>
              <Checkbox.Group
                className={styles.boxgroup}
                onChange={handleEditStores}
                value={editStores}
              >
                {stores?.map(item => {
                  return (
                    <Checkbox key={item.id} value={item.id}>
                      {item.shop_name}
                    </Checkbox>
                  );
                })}
              </Checkbox.Group>
            </div>
          )}
          {editAccount?.user_role === 0 ||
          editAccount?.user_role === 1 ? null : (
            <div className={styles.feature}>
              <span>{intl.get('setting.subaccount.Featureaccess')}</span>
              <span className={styles.other}>*</span>
              <div className={styles.accesscheckbox}>
                <Checkbox.Group
                  onChange={handleEditMenu}
                  value={editAccount?.menu_permissions}
                >
                  <Row>
                    <Col span={24}>
                      <Checkbox value={1}>{intl.get('setting.subaccount.report')}</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Checkbox value={2}>{intl.get('setting.subaccount.ImportList')}</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value={3}>{intl.get('setting.subaccount.MyProduct')}</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Checkbox value={4}>{intl.get('setting.subaccount.Openorder')}</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value={6}>{intl.get('setting.subaccount.ArchivedOrder')}</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Checkbox value={11}>{intl.get('setting.subaccount.Tracking')}</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Checkbox value={5}>{intl.get('setting.subaccount.CSVBulkOrder')}</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value={7}>{intl.get('setting.subaccount.LinktoMoreStores')}</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Checkbox value={8}>{intl.get('setting.subaccount.Setting')}</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value={9}>{intl.get('setting.subaccount.Notification')}</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default connect(({ dropshippersetting, user }) => ({
  childAccount: dropshippersetting.childAccount,
  plan: user.plan,
  newUserInfo: user.newUserInfo
}))(StaffAccount);
