import React, { useState, useEffect } from 'react';
import { connect } from 'dva';
import { Button } from 'antd';
import intl from 'react-intl-universal';
import moment from 'moment';

import styles from './ActivationEmail.less';
import fail from '../../../../../assets/setting/fail.svg';
import warning from '../../../../../assets/setting/warning.svg';

let isFirstSend = false;

function ActivationEmail(props) {
  const [count, setCountdown] = useState(59);
  const [showCountdown, setShowCountdown] = useState(false);
  const [hasSend, setHasSend] = useState(false);
  const [sendResult, setSendResult] = useState(null);
  const { userInfo, resultLoading, sendLoading } = props;
  let recordTime = 60;
  useEffect(() => {
    const v = localStorage.getItem('verifyCountDown');
    if (v) {
      const verifyCountDown = JSON.parse(v);
      const diffTime =
        verifyCountDown.num - (moment().unix() - verifyCountDown.time);
      if (verifyCountDown.userId === userInfo.user_id && diffTime > 0) {
        setCountdown(diffTime);
        setShowCountdown(true);
      }
      if (verifyCountDown.userId === userInfo.user_id) {
        localStorage.removeItem('verifyCountDown');
      }
    }
  }, []);

  // 查询邮件发送结果
  function getSendEmailResult() {
    props.dispatch({
      type: 'register/sendEmailResult',
      payload: {
        data: {
          email: userInfo.email
        },
        cancelMessage: true,
        callback: d => {
          if (d.msg === 'record not found') {
            recordTime -= 2;
            if (recordTime) {
              setTimeout(getSendEmailResult, 2000);
              return;
            }
          }
          if (d.code === 5000) {
            let tip = null;
            if (typeof window.ga !== 'undefined') {
              window.ga('send', 'event', 'Send email exception at register', d.msg, new Date().toISOString());
            }

            switch (d.msg) {
              case 'invalid format':
                // '邮箱格式错误';
                tip = `Email address doesn't exist or wrong, please double check the spelling of the email.`;
                break;
              case 'unresolvable host':
                // '邮箱host无法解析';
                tip = `Email domain can't be verified, please double check the spelling of the email.`;
                break;
              case 'email rejected per SPAM policy':
                // '垃圾邮件';
                tip = `The email was considered as spam email, please double check your spam folder to locate it.`;
                break;
              case 'white list error':
                // tip = '白名单';
                tip = `Email sending failure, please add @mail.dsers.com in the whitelist of your email.`;
                break;
              case 'send failed':
                // '发送失败';
                tip = `Email sending failure due to mail service rejection. Please double check setting configuration of your mail service.`;
                break;
              case 'user not found':
                // '用户不存在';
                tip = `Email address doesn't exist or wrong, please double check the spelling of the email.`;
                break;
              case 'domain not hosted':
                // 域名问题
                tip = `Email domain can't be verified, please double check the spelling of the email.`;
                break;
              default:
                tip = 'Unknown error of your email service caused the failure of sending emails. Please change mail and try again.';
            }
            setSendResult(tip);
            return;
          }

          if (!isFirstSend || d.code !== 2010) {
            return;
          }

          const windowRef = window.open('', '_blank');
          let mailHost = userInfo.email.split('@')[1];
          if (mailHost === 'gmail.com') {
            mailHost = 'google.com';
          }
          const mail = `http://mail.${mailHost}`;
          setSendResult(null);
          if (windowRef) {
            windowRef.location = mail;
          }
        }
      }
    });
  }

  // 发邮件
  function sendEmail() {
    props.dispatch({
      type: 'register/resendMail',
      payload: {
        cancelMessage: true,
        callback: d => {
          if (!hasSend) {
            isFirstSend = true;
          } else {
            isFirstSend = false;
          }

          setShowCountdown(true);
          setHasSend(true);

          if (d.code === 2010) {
            getSendEmailResult();
          } else {
            setSendResult(d.msg);
          }
        }
      }
    });
  }

  useEffect(() => {
    let timer = null;
    if (showCountdown) {
      timer = setInterval(() => {
        setCountdown(n => {
          if (n === 0) {
            setShowCountdown(false);
            setCountdown(59);
            clearInterval(timer);
            localStorage.removeItem('verifyCountDown');
            return 0;
          }
          localStorage.setItem(
            'verifyCountDown',
            JSON.stringify({
              userId: userInfo.user_id,
              time: moment().unix(),
              num: n - 1
            })
          );
          return n - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [showCountdown]);

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        {/* <h3 className={styles.title}>
          {sendResult ? <span>{intl.get('setting.account.verify_email_title_fail')}</span> : hasSend ?
            intl.get('setting.account.verify_email_title_succ') :
            intl.get('setting.account.verify_email_title')}
        </h3> */}
        <p className={styles.desc}>
          {sendResult ? `${sendResult}` : hasSend ?
            intl.get('setting.account.verify_email_desc_succ', { email: userInfo.email }) :
            intl.get('setting.account.verify_email_desc', { email: userInfo.email })}
        </p>
        <div style={{ textAlign: 'right' }}>
          <a
            type="link"
            href="https://www.messenger.com/t/DSersOfficial" dd="src"
            target="_blank"
            size="small"
            className={styles.contactUs}
          >
            {intl.get('public.contact_us')}
          </a>
          <Button
            type="primary"
            className={styles.saveBtn}
            size="small"
            data="dx"
            onClick={sendEmail}
            loading={sendLoading || resultLoading}
            disabled={showCountdown}
          >
            {/* {hasSend
              ? intl.get('setting.account.verify_re_send')
              : intl.get('setting.account.verify_send')}
             */}
            {showCountdown ? (
              <span className={styles.countdown}>{count}S</span>
            ) : intl.get('setting.account.verify_send')}

          </Button>
          {/* {showCountdown ? (
            <span className={styles.countdown}>{count}S</span>
          ) : null}
           */}
        </div>
      </div>
      {/* <div className={styles.bgIcon}>
        <embed
          src={sendResult ? fail : warning}
          width="131"
          height="131"
          type="image/svg+xml"
          pluginspage="http://www.adobe.com/svg/viewer/install/"
        />
      </div> */}
    </div>
  );
}

export default connect(({ loading, login }) => ({
  userInfo: login.userInfo,
  sendLoading: loading.effects['register/resendMail'],
  resultLoading: loading.effects['register/resendMail']
}))(ActivationEmail);
