import { Collapse } from 'antd';
import { connect } from 'dva';
import React, { Fragment } from 'react';
import styles from './Index.less';
import StoreTool from './Store.js';

const Panel = Collapse.Panel;

@connect(({ dropshippersetting, loading }) => ({
  dropshippersetting,
  loading: loading.models.dropshippersetting
}))
export default class VerifiedSupplier extends React.PureComponent {
  componentDidMount() {
    this.getSupplier();
  }

  getSupplier = () => {
    // 获取Verified Supplier数据
    this.props.dispatch({
      type: 'dropshippersetting/supplier'
    });
  };

  saveSupplier = data => {
    // 保存supplier
    delete data.created_at;
    delete data.deleted_at;
    delete data.updated_at;

    this.props.dispatch({
      type: 'dropshippersetting/savesupplier',
      payload: {
        data: {
          ...data
        },
        callback: d => {
          if (d.code == 1) {
            this.getSupplier();
          }
        }
      }
    });
  };

  render() {
    const { dropshippersetting } = this.props;
    const { supplier = [] } = dropshippersetting;

    return (
      <Fragment>
        <div className={styles.box}>
          <div className={styles.section}>
            <h3>Verified Supplier</h3>
            <Collapse
              activeKey={supplier.map(item => item.store_id)}
              className={styles.storeBox}
            >
              {supplier.map(item => {
                return (
                  <Panel
                    header={item.store_name}
                    key={item.store_id}
                    disabled
                    showArrow={false}
                  >
                    <StoreTool data={item} saveSupplier={this.saveSupplier} />
                  </Panel>
                );
              })}
            </Collapse>
          </div>
        </div>
      </Fragment>
    );
  }
}
