import { Tooltip } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';

import BackCard from '../../../../../components/BackCard';

import { AliExpressAccount, AgencyManagement } from './components';

import styles from './index.less';

// const OtherComponent = React.lazy(() => import('./OtherComponent'));

{
  /* <Suspense fallback={<div>Loading...</div>}>
<OtherComponent />
</Suspense> */
}

function Supplier({isCsvUser}) {
  const CAINIAO_USER = localStorage.getItem('CAINIAO_USER');

  return (
    <>
      <BackCard
        title={intl.get('setting.title_ae_account')}
        subTitle={intl.get('setting.ae_account_des')}
      >
        <AliExpressAccount />
      </BackCard>

      {(window.CAINIAO_USER || CAINIAO_USER) && isCsvUser && false ? (
        <BackCard
          title={intl.get('setting.agency.title')}
          subTitle={
            <span>
              {intl.getHTML('setting.agency.subtitle_1')}

              {/* <Tooltip getPopupContainer={p => p.parentNode || document.body} title={intl.get('setting.agency.title_tip')}>
                <span style={{color: 'rgb(255, 143, 0)', fontWeight: 'bold'}}>{intl.get('setting.agency.subtitle_3')}</span> */}
                <a target='_blank' href='https://help.dsers.com/add-an-agency/'><span style={{color: '#3d5afe', fontWeight: 'bold'}}>{intl.get('setting.agency.subtitle_3')}</span></a>
              {/* </Tooltip> */}
              {intl.get('setting.agency.subtitle_2')}
            </span>
          }
        >
          <AgencyManagement />
        </BackCard>
      ) : null}
    </>
  );
}

export default Supplier;
