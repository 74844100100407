import React, { Component } from 'react';
import { connect } from 'dva';
import {
  Button,
  Icon,
  Select,
  Input,
  message,
  Tooltip,
  Switch,
  Modal,
  Collapse
} from 'antd';

import intl from 'react-intl-universal';

import '../../../../../styles/index.css';

import { paypalAuthorizeRul } from 'utils/constants';
import getAdminRole from 'utils/getAdminRole';
import styles from '../../List.less';
import { Get, Post } from 'utils/request';
import { getBaseLayoutPopupContainer } from 'utils/utils';
import { LinkStoreAction } from 'actions'

import shopImage from '../../../../../assets/setting/shop.png';
import addBtn from 'assets/setting/addBtn.png'

import { CommonDataCollect } from 'utils/utils'
const { Option } = Select;
const { Panel } = Collapse;

@connect(({ dropshippersetting, loading, login, user }) => ({
  dropshippersetting,
  userInfo: login.userInfo,
  loading: loading.models.dropshippersetting,
  plan: user.plan,
  saveTrackLoading: loading.effects['dropshippersetting/saveTrack']
}))
export default class Tracking extends Component {
  selectedPaypalForStore = {};

  constructor(props) {
    super(props);
    this.state = {
      trackingUrl: [],
      trackingData: [],
      trackingBtnDisabled: true,
      trackingErrorSta: [],
      // shippingCarrierList: [],
      id: '',
      user_id: '',
      paypalData: [],
      visivle:false
    };

    this.hasPayPalAccess = window.DSERS_P_P || getAdminRole();
  }

  componentDidMount() {
    this.getTrackingUrl();
    this.getTracking();
    this.fetchPaypal();
  }

  // 获取 Tracking Url
  getTrackingUrl = () => {
    this.props.dispatch({
      type: 'dropshippersetting/getTrackingUrl',
      payload: {
        callback: d => {
          if (d.code == 2000) {
            const trackingUrl = this.setTrackingUrlData(d.data);
            this.setState({
              trackingUrl
            });
          }
        }
      }
    });
  };

  setTrackingUrlData = data => {
    const obj = { name: 'other', url: '', display_url: '' };
    const afterShip = data.find(li => li.name === 'AfterShip');
    afterShip.display_url = '';
    afterShip.url = '';
    data.push(obj);
    return data;
  };

  // 获取 Tracking 设置
  getTracking = () => {
    this.props.dispatch({
      type: 'dropshippersetting/getTracking',
      payload: {
        callback: d => {
          if (d.code === 2000) {
            const storeList = this.hasLessStore(d.data.store_list);
            const trackingErrorSta = [];

            storeList?.forEach(() => {
              trackingErrorSta.push(false);
            });

            this.setState({
              id: d.data.id,
              user_id: d.data.user_id,
              trackingData: storeList,
              trackingErrorSta
            });
          }
        }
      }
    });
  };

  // 有没有少店铺
  hasLessStore = (data = []) => {
    const stores = this.props.userInfo.stores || [];
    const list = data?.filter(item => {
      const store = stores.find(
        li => li.shop_name === item.store_name && [1, 2].includes(li.status)
      );
      if (store) {
        if (item.store_id != store.id) {
          item.store_id = store.id;
          item.company_is_other = false;
          item.email_is_enable = false;
          item.store_id = store.id;
          item.store_name = store.shop_name;
          item.tracking_company = '';
          item.tracking_url = {
            display_url: '',
            name: '',
            url: ''
          };
        }
        return true;
      } else {
        return false;
      }
    });

    stores.forEach(store => {
      const flag = list?.some(item => item.store_name == store.shop_name);

      if (!flag && [1, 2].includes(store.status)) {
        list?.push({
          company_is_other: false,
          email_is_enable: false,
          store_id: store.id,
          store_name: store.shop_name,
          tracking_company: '',
          tracking_url: {
            display_url: '',
            name: '',
            url: ''
          }
        });
      }
    });

    return list;
  };

  // 修改并保存用户设置的 Tracking
  saveTracking = () => {
    const { id, user_id, trackingData, trackingErrorSta } = this.state;
    let copyTrackData = null;

    try {
      copyTrackData = JSON.parse(JSON.stringify(trackingData));
    } catch (error) {
      console.log('tracking save error:', error);
    }

    if (copyTrackData === null) {
      return;
    }

    copyTrackData.forEach((it, i) => {
      if (it.store_id in this.selectedPaypalForStore) {
        // eslint-disable-next-line
        it.paypal_id = this.selectedPaypalForStore[it.store_id];
      }

      if (it.tracking_url.name == 'other') {
        if (it.tracking_url.display_url) {
          trackingErrorSta[i] = false;
          this.setState({
            trackingErrorSta
          });
        } else {
          trackingErrorSta[i] = true;
          this.setState({
            trackingErrorSta
          });
        }
      }
    });
    this.props.dispatch({
      type: 'dropshippersetting/postTracking',
      payload: {
        data: {
          id,
          user_id,
          store_list: copyTrackData
        },
        callback: d => {
          if (d.code == 2000) {
            this.setState({
              trackingBtnDisabled: true
            });
            this.getTracking();
            message.success(intl.get('setting.tracking.save_succ'));
          } else {
            message.error(intl.get('setting.tracking.save_fail'));
          }
        }
      }
    });
  };

  // Tracking 开关状态
  trackingSwitch = (checked, index) => {
    let { trackingData } = this.state;

    trackingData[index].email_is_enable = checked;
    trackingData[index].checked = true;
    trackingData = [...trackingData];

    let trackingBtnDisabled = false;
    trackingData.forEach(v => {
      if (v.tracking_url.display_url.trim() == '' && v.tracking_url.name) {
        trackingBtnDisabled = true;
      }
    });

    this.setState({
      trackingData,
      trackingBtnDisabled
    });
  };

  // Tracking Select 改变时的回调
  changeTrackingUrl = (v, index) => {
    const { trackingData, trackingUrl, trackingErrorSta } = this.state;
    const list = [...trackingData];
    let trackingBtnDisabled = false;

    list[index].tracking_url.name = v;
    list[index].checked = true;

    trackingUrl.forEach(r => {
      if (r.name === v) {
        list[index].tracking_url.display_url = r.display_url;
        list[index].tracking_url.url = r.url;
      }
    });

    trackingErrorSta[index] = false;
    if (v === 'other' && list[index].tracking_url.display_url === '') {
      trackingErrorSta[index] = true;
    }
    if (v === 'AfterShip' && list[index].tracking_url.display_url === '') {

      console.log('list[index]',list[index])

      list[
        index
      ].tracking_url.display_url = `https://www.aftership.com/track?t=`;

      list[
        index
      ].tracking_url.url = `https://www.aftership.com/track?t=%s`;
    }

    list.forEach(item => {
      if (item.tracking_url.display_url === '' && item.tracking_url.name) {
        trackingBtnDisabled = true;
      }
    });

    this.setState({
      trackingData: list,
      trackingBtnDisabled,
      trackingErrorSta
    });
  };

  // Tracking Shipping Select 改变时的回调
  changeTrackShipping = (v, index) => {

    let { trackingData } = this.state;
    let flag = false;
    trackingData[index].company_is_other = false;
    trackingData[index].checked = true;
    trackingData[index].tracking_company = v;

    trackingData.forEach(item => {
      if (
        item.tracking_url.display_url.trim() === '' &&
        item.tracking_url.name
      ) {
        flag = true;
      }
    });

    trackingData = [...trackingData];


    console.log('trackingData',trackingData)

    this.setState({
      trackingData,
      trackingBtnDisabled: flag
    });
  };

  // Tracking Input的 onChange事件
  trackingInputChange = (e, index) => {
    const { trackingData, trackingErrorSta } = this.state;
    const dataList = [...trackingData];
    const errList = [...trackingErrorSta];
    const v = (e.target.value).trim();
    let flag = false;

    dataList[index].tracking_url.display_url = v;
    dataList[index].tracking_url.url = v ? `${v}%s` : v;

    dataList.forEach((item, i) => {
      if (
        item.tracking_url.display_url.trim() === '' &&
        item.tracking_url.name
      ) {
        flag = true;
        errList[i] = true;
      } else {
        errList[i] = false;
      }
    });

    this.setState({
      trackingData: dataList,
      trackingErrorSta: errList,
      trackingBtnDisabled: flag
    });
  };

  handleConnectToPapal = storeId => {
    window.localStorage.setItem('sid', storeId);

    Modal.success({
      title: intl.get('setting.tracking.refresh_page'),
      icon: null,
      content: null,
      okText: intl.get('setting.tracking.update'),
      className:'ModalTitlip',
      onOk: () => {
        window.location.href = '/app/settings?id=8';
        return Promise.resolve();
      },
      keyboard: false
    });
  };

  fetchPaypal = async () => {
    try {
      const res = await Get('/auth/paypal/list');
      if (res.code === 2000 && res.data) {
        this.setState({
          paypalData: res.data
        });
      }
    } catch (error) {
      console.log('fetchPaypal', error);
    }
  };

  handlePaypalSelect = (value, data, index) => {
    if (!data.store_id) {
      return;
    }

    //强制显示save按钮
    let { trackingData } = this.state;
    trackingData[index].checked = true;


    this.selectedPaypalForStore[data.store_id] = value;
    this.setState({
      trackingData,
      trackingBtnDisabled: false
    });
  };

  getMail = id => {
    const { paypalData } = this.state;
    const target = paypalData.find(i => i.id === id);
    return target && target.email;
  };

  callDisconnectService = async data => {
    const params = {
      paypal_id: data.paypal_id,
      store_id: data.store_id
    };

    try {
      const res = await Post('/auth/paypal/unbind_store', { data: params });
      if (res.code === 2010) {
        this.getTracking();
        this.fetchPaypal();
      }
    } catch (error) {
      console.log('disconnect error', error);
    }
  };

  handleDisconnect = data => {
    // eslint-disable-next-line
    const that = this;

    Modal.confirm({
      title: intl.get('setting.tracking.disconnect'),
      content: intl.get('setting.tracking.disconnect_tip'),
      icon: null,
      okText: intl.get('public.yes'),
      className:'ModalTitlip',
      onOk() {
        that.callDisconnectService(data);
      },
      onCancel() {
        console.log('Cancel');
      }
    });
  };
  addPayPalDot = () =>{
    CommonDataCollect({
      action: 'PayPal-top',
      event_type:'overlimit_pop'
    });
  }

  openPaypal = () => {
    const {
      paypalData
    } = this.state;

    let type = JSON.parse(localStorage.getItem('payment'))?.type
    let payLen = paypalData?.filter(item => item.status == 1) || []

    if([1,10, 12,13,14,15,16,17,18,19,20].includes(type) && payLen.length >= 1){
      this.addPayPalDot()
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'PayPal')
      return
    }

    if([2,7,4,8].includes(type) && payLen.length >= 2){
      this.addPayPalDot()
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'PayPal')
      return
    }

    if(type == 3 && payLen.length >= 5){
      this.addPayPalDot()
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'PayPal')
      return
    }

    if([6,5].includes(type) && payLen.length >= 10){
      addPayPalDot()
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'PayPal')
      return
    }

    window.open(paypalAuthorizeRul)
  }

 

  getTitA = () => {
    return <span>The number of current linked PayPal accounts has exceeded the plan limit, please click <a href="/app/pricing" style={{color:'#3d5afe'}}>here</a> to upgrade to activate the link status.</span>
  }

  render() {
    const { saveTrackLoading, shippingCarrierList, plan } = this.props;
    const {
      trackingUrl,
      trackingData,
      trackingBtnDisabled,
      trackingErrorSta,
      paypalData
    } = this.state;
    return (
      <>
        <div className={styles.trackingCont}>
          {this.props.tabname == 'PayPal' ? <div className={styles.trackTitle}>
            {/* <h3>{intl.get('setting.tracking.title')}</h3> */}
            {/* 可能需要改 */}
            <Button type="primary" className={styles.connectButton} onClick={() => {
              this.openPaypal()
            }}>
              <a   target="_blank"  rel="noopener noreferrer">
                  {intl.get('setting.tracking.connect_to_paypal')}
              </a>
            </Button>
              <p className={styles.trackTitles1}>
                <span style={{ paddingRight: 10 }}>{intl.get('setting.tracking.track_to_paypal_title1')}</span>
                <div className={styles.addPaypal}>
                  <img src={addBtn} onClick={()=>{
                    this.openPaypal()
                  }} alt="" />
                  <span>{intl.get('setting.tracking.connect_to_paypal2')}</span>
                </div>
              </p>
            <div className={styles.lBox}>
              {
                paypalData.map(item => {
                  return <>
                    <div key={item.email} className={styles.lBoxTitle}>
                     <div>
                      <img src={require('../../../../../assets/setting/paypal.png')} className={styles.concatimg}></img>  
                        {item.email} 
                        {item.status == 1 ? 
                          <span className={styles.concat}>Connected</span> : 
                          ( item.status == 4 ? <span className={styles.Inactive}><span style={{marginRight:5}}>Inactive</span>
                            <Tooltip
                              title={this.getTitA()}
                              placement="top"
                              style={{marginL:0}}
                            >
                              <Icon style={{margin:0}} type="question-circle" />
                            </Tooltip>
                          </span> : <span className={styles.noConcat}>Not connected</span> )
                        }
                     </div>
                      {
                        item.status != 1 ? <div className={styles.link}>
                        <a  onClick={this.openPaypal} target="_blank" rel="noopener noreferrer"><i class="material-icons notranslate">link_off</i></a>
                      </div> : null
                      }
                    </div>
                  </>
                })
              }
            </div>
          </div> 
          : null}
        {this.props.tabname == 'PayPal' ? 
        <>
          <p className={styles.trackTitles}>
          <span style={{ paddingRight: 10 }}>{intl.get('setting.tracking.track_to_paypal_title')}</span>
          <Tooltip
            title={intl.get('setting.tracking.connect_to_paypal_desc')}
          >
            <Icon type="info-circle" />
          </Tooltip>
        </p> 
        </>
          :null}
          <div className={styles.trackMain}>
            {trackingData?.length ? (
              <div className={styles.mainCont}>
                <Collapse expandIconPosition="right">
                  {trackingData?.map((item, index) => {
                    return (
                      <Panel key={index} header={
                        <span style={{display:'flex',alignItems:'center'}}
                        >
                          <i class="material-icons notranslate" style={{marginRight:33}}>storefront</i>
                        {item.store_name}
                        </span>
                        }
                        >
                        <div key={item.store_id} className={styles.item}>
                          {item?.checked ? <div className={styles.save} onClick={this.saveTracking}>Save</div> : null}
                          <div className={styles.itemDropdownBox}>
                            <div
                              className={styles.itemDropdown}
                            >
                              {
                                this.props.tabname != 'PayPal'?
                                <div className={styles.Lbox}>
                                <div className={styles.tiBox}>
                                  <h3>{intl.get('setting.tracking.activate_email')}</h3>
                                  <p>
                                    {intl.get(
                                      'setting.tracking.activate_email_description'
                                    )}
                                    &nbsp;
                                    <a
                                      href={`https://${item.store_name}.myshopify.com/admin/email_templates/shipping_confirmation/edit`}
                                      target="_blank"
                                      style={{ color:'#3D5AFE' }}
                                    >
                                      {intl.get('setting.tracking.here')}
                                    </a>
                                  </p>
                                </div>
                                <div className={styles.switchWraps}>
                                  <Switch
                                    // checkedChildren={intl.get('public.on')}
                                    // unCheckedChildren={intl.get('public.off')}
                                    checked={item.email_is_enable}
                                    onChange={checked => {
                                      this.trackingSwitch(checked, index);
                                    }}
                                   
                                  />
                                </div>
                              </div>:null
                              }
                              {
                                this.props.tabname == 'PayPal' ? null :
                                <div className={styles.LboxC}>
                                <p>
                                  <i class="material-icons notranslate">local_shipping</i>
                                  <span>
                                    {intl.get('setting.tracking.shipping_carrier')}
                                  </span>
                                </p>
                                <div
                                  className={`${styles.selectBox} ${styles.trackingSelect}`}
                                  style={{ width: 184 }}
                                >
                                  <Select
                                    suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                                    size="small"
                                    value={
                                      item.company_is_other
                                        ? 'other'
                                        : (item.tracking_company ? item.tracking_company : undefined)
                                    }
                                    placeholder={intl.get('setting.tracking.select_1')}
                                    onChange={e => {
                                      this.changeTrackShipping(e, index);
                                    }}
                                    getPopupContainer={getBaseLayoutPopupContainer}
                                    className={styles.syncbordercolor}
                                  >
                                    {shippingCarrierList?.map(v => {
                                      return (
                                        <Option key={v} value={v}>
                                          {v}
                                        </Option>
                                      );
                                    })}
                                    <Option key="other" value="other">
                                      other
                                    </Option>
                                  </Select>

                                </div>
                              </div>
                              }
                              
                            </div>
                            <div className={styles.itemDropdown}>
                              {this.props.tabname == 'PayPal' ? null :
                              <div className={styles.LboxC}>
                              <p>
                                {/* <i class="material-icons notranslate">local_shipping</i> */}
                                <img style={{width:24,marginTop:15}} src={require('../../../../../assets/newImportList/verified_user.png')}></img>
                                <span>
                                  {intl.get('setting.tracking.custom_tracking_url')}
                                  <Tooltip
                                    title={intl.get(
                                      'setting.tracking.custom_tracking_url_tip'
                                    )}
                                  >
                                    <Icon type="info-circle" />
                                  </Tooltip>
                                  <div className={styles.LboxCB}>
                                    {item.tracking_url.display_url}
                                  </div>
                                </span>


                              </p>
                              <div
                                className={`${styles.selectBox} ${styles.trackingSelect}`}
                              >
                                <Select
                                  suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                                  getPopupContainer={
                                    getBaseLayoutPopupContainer
                                  }
                                  style={{width:184}}
                                  size="small"
                                  value={item.tracking_url.name || undefined}
                                  className={styles.syncbordercolor}
                                  placeholder={intl.get('setting.tracking.select_2')}
                                  onSelect={e => {
                                    if(e == 'other'){
                                      this.setState({
                                        visivle:true
                                      })
                                    }
                                    this.changeTrackingUrl(e, index);
                                  }}
                                >
                                  {trackingUrl.map(v => {
                                    return (
                                      <Option key={v.name} value={v.name}>
                                        {v.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                                <Modal
                                    title={`${intl.get('setting.tracking.custom_tracking_url')}`}
                                    visible={this.state.visivle}
                                    width={560}
                                    maskClosable={true}
                                    onOk={()=>{
                                      this.setState({
                                        visivle:false
                                      })
                                    }}
                                    onCancel={()=>{
                                      this.setState({
                                        visivle:false
                                      })
                                    }}
                                    >
                                      <div className={styles.inputBox}>
                                        <Input
                                          disabled={
                                            !['other', 'AfterShip'].includes(
                                              item.tracking_url.name
                                            )
                                          }
                                          value={item.tracking_url.display_url}
                                          onChange={e => {
                                            this.trackingInputChange(e, index);
                                          }}
                                          style={
                                            trackingErrorSta[index]
                                              ? { borderColor: '#f5222d' }
                                              : {}
                                          }
                                        />
                                        <p
                                          className={styles.errorTip}
                                          style={
                                            trackingErrorSta[index]
                                              ? { display: 'block' }
                                              : { display: 'none' }
                                          }
                                        >
                                          {intl.get('setting.tracking.please_enter_a_url')}
                                        </p>
                                      </div>
                                  </Modal>
                              </div>
                            </div>}
                              
                            </div>
                            {this.hasPayPalAccess && this.props.tabname == 'PayPal' ? (
                              <div className={styles.itemDropdown}>
                                {/* <h3>{intl.get('setting.tracking.track_to_paypal_title')}</h3> */}
                                {!item.paypal_id || !paypalData?.find((i) => i.id === item.paypal_id) ? (
                                  <>
                                    {/* <p>
                                      {intl.get('setting.tracking.track_to_paypal_desc')}
                                    </p>
                                    <div className={styles.paypalBtn}>
                                      <a
                                        href={paypalAuthorizeRul}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <Button
                                          type="primary"
                                          size="large"
                                          onClick={() =>
                                            this.handleConnectToPapal(item.store_id)
                                          }
                                        >
                                          {intl.get('setting.tracking.connect_to_paypal')}
                                        </Button>
                                      </a>
                                    </div> */}
                                    {item.paypal_err
                                      ? intl.getHTML('setting.tracking.not_succ')
                                      : null}
                                    {paypalData && paypalData.length !== 0 ? (
                                      <div className={styles.LboxC}>
                                        <p>
                                          <img src={require('../../../../../assets/setting/paypal.png')} className={styles.concatimg_second}></img>
                                          <span>{intl.get('setting.tracking.use_paypal_account')}</span>

                                        </p>
                                        <div
                                          className={styles.trackingSelect}
                                        >
                                          <Select
                                            style={{minWidth:184}}
                                            getPopupContainer={
                                              getBaseLayoutPopupContainer
                                            }
                                            suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                                            size="small"
                                            placeholder={intl.get('setting.tracking.paypal_placeholder')}
                                            className={styles.syncbordercolor}
                                            allowClear
                                            onChange={e =>
                                              this.handlePaypalSelect(e, item, index)
                                            }
                                          >
                                            {paypalData.map(i => (
                                              <Option key={i.id} value={i.id} title={i.email}>
                                                {i.email}
                                              </Option>
                                            ))}
                                          </Select>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className={styles.LboxC}>
                                        <p>
                                          <img src={require('../../../../../assets/setting/paypal.png')} className={styles.concatimg_second}></img>
                                          <span>{intl.get('setting.tracking.to_connect_paypal_account')}</span>
                                        </p>
                                        <div
                                          className={styles.trackingSelect}
                                        >
                                          <Select
                                            suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                                            size="small"
                                            placeholder={intl.get('setting.tracking.paypal_placeholder')}
                                            style={{width:184}}
                                            allowClear
                                            className={styles.syncbordercolor}
                                          >
                                            <Option key="1" title={intl.get('setting.tracking.paypal_reminder')} >
                                              {intl.get('setting.tracking.paypal_reminder')}
                                            </Option>
                                          </Select>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div className={styles.LboxC}>
                                    <div className={styles.noNo}>
                                      <div className={styles.titew}>
                                        <img src={require('../../../../../assets/setting/paypal.png')} />
                                        <div className={styles.expired}>
                                          <div>{intl.get('setting.tracking.linked_paypal')}</div>
                                          <div className={styles.email}>
                                            {this.getMail(item.paypal_id)}
                                          </div>
                                          <div className={item.paypal_expired ? styles.wqr1 : styles.wqr}>
                                            {item.paypal_expired ? 'Unauthorized' : 'Connected'}
                                            {
                                              !item.paypal_expired ? null : <Tooltip
                                              title={intl.get('setting.tracking.renew_reminder')}
                                              placement="top"
                                              style={{margin:0}}
                                            >
                                              <Icon style={{margin:0}} type="question-circle" />
                                            </Tooltip>
                                            }

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={styles.disconnect}>
                                      {
                                        item.paypal_expired ? <>
                                        <a
                                          href={paypalAuthorizeRul}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            color: 'red',
                                            textDecoration: 'none',
                                            marginRight: 10
                                          }}
                                          onClick={() => {
                                            window.localStorage.setItem(
                                              'sid',
                                              item.store_id
                                            );
                                          }}
                                        >
                                          <i class="material-icons notranslate">insert_link</i>
                                          {/* {intl.get('setting.tracking.click_to_renew')} */}
                                        </a>

                                      </> :  <a
                                        onClick={() => this.handleDisconnect(item)}
                                      >
                                        <i class="material-icons notranslate">link_off</i>
                                      </a>
                                      }

                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </Panel>
                    );
                  })}
                </Collapse>
              </div>
            ) : (
            <div className={styles.noData}>
              <img  src={require('../../../../../assets/setting/noData.png')} />
              <a href='https://apps.shopify.com/dsers'>{intl.get('setting.tracking.add_a_shopify_store')}</a>
            </div>
            )}
          </div>
          {/* <div className={styles.saveBtnWrap}>
            <Button
              type="primary"
              onClick={this.saveTracking}
              loading={saveTrackLoading}
              size="small"
              className={styles.saveBtn}
              disabled={trackingBtnDisabled}
            >
              {intl.get('public.save')}
            </Button>
          </div> */}
        </div>


      </>
    );
  }
}
