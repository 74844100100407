import React from 'react';
import { Select } from 'antd';
import { connect } from 'dva';

const frequencyData = [
  { label: 'One time', key: 1 },
  { label: 'Weekly', key: 2 },
  { label: 'Monthly', key: 3 },
  { label: 'Yearly', key: 4 }
];

const categoryData = [
  { label: 'Software service fee', key: 1 },
  { label: 'KOL marketing', key: 2 }
];

const StoreSelect = connect(({ login }) => ({
  userInfo: login.userInfo
}))(({ userInfo, ...restProps }) => {
  return (
    <Select
      allowClear
      style={{ width: 130 }}
      {...restProps}
      placeholder="Select"
    >
      {userInfo &&
        userInfo.stores &&
        userInfo.stores.map(i => (
          <Select.Option key={i.id} value={i.id}>
            {i.shop_name}
          </Select.Option>
        ))}
    </Select>
  );
});

const Frequency = props => (
  <Select allowClear style={{ width: 130 }} placeholder="Select" {...props}>
    {frequencyData.map(i => (
      <Select.Option key={i.key} value={i.key}>
        {i.label}
      </Select.Option>
    ))}
  </Select>
);

const Category = props => (
  <Select allowClear style={{ width: 130 }} placeholder="Select" {...props}>
    {categoryData.map(i => (
      <Select.Option key={i.key} value={i.key}>
        {i.label}
      </Select.Option>
    ))}
  </Select>
);

export { StoreSelect, Frequency, Category };
