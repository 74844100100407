import React, { Component } from 'react';
import { connect } from 'dva';
import { Button, Modal, Icon, Input, Tooltip, message, Alert } from 'antd';
import TextField from '@material-ui/core/TextField';

import className from 'classnames';
import intl from 'react-intl-universal';

import WhyDisconnect from 'components/WhyDisconnect/WhyDisconnect';
import DisconnectCallback from 'components/WhyDisconnect/DisconnectCallback';
import ActivationEmail from './ActivationEmail.jsx';

import TimeZone from './TimeZone';
import Title from '../../Title'

import styles from './index.less';
import '../../setting.css';

import { getLanguage, CommonDataCollect } from '../../../../../utils/utils';
import { emailRegExp } from '../../../../../utils/constants';

import {oberloReport,getOberloReport} from 'utils/utils'
const { Search } = Input;

@connect(({ dropshippersetting, loading, login, user,auth}) => ({
  dropshippersetting,
  userInfo: login.userInfo,
  loading: loading.models.dropshippersetting,
  updateUserNameLoadind: loading.effects['dropshippersetting/updateUserName'],
  newUserInfo:user.newUserInfo,
  isoberlouser:auth?.isOberloUser,
}))
export default class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      emailError: false,
      emailErrorMsg: '',
      changeEmail: false,
      sendSucc: false,
      verifyEmailError: null,
      disconnectShopifyStoreId: undefined, // 卸载shopify店铺id
      showDisconnectCallback: false, // 卸载shopify店铺成功后的弹窗
      showRefreshWhitelist: false,
      accountErr: false,
      saveNameModal:false,
      userName:'',
      refreshModal:false
    };
  }

  componentDidMount() {
    const stores = this.props.userInfo.stores || [];
    this.userName = this.props.userInfo.user_name;
    // this.setState({
    //   stores
    // });
    // if (stores.length) {
    //   this.getShopifyStoreStatus(0, stores);
    // }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // 组件Props或者state改变时触发，true：更新，false：不更新
  //   if (
  //     nextState.stores &&
  //     nextState.stores.length == 0 &&
  //     (`${nextState.status  }`).length == '8' &&
  //     (`${nextState.status  }`).substring(3, 4) == 0
  //   ) {
  //     window.location.href = '/app/bind/platform';
  //   }
  //   return true;
  // }

  email = ''; 
  aliAccountId = localStorage.getItem('aliAccountId');
  hasVerifyEmail = false; // 是否修改邮箱了


  // // 检测 shopify store 授权状态是否可用
  // getShopifyStoreStatus = (index, stores) => {
  //   this.props.dispatch({
  //     type: 'dropshippersetting/shopifyStatus',
  //     payload: {
  //       data: {
  //         store_id: stores[index].id
  //       },
  //       cancelMessage: true,
  //       callback: d => {
  //         this.setStoreUpdate(d, index); // 设置店铺应不应该从新授权
  //         if (stores.length > index + 1) {
  //           this.getShopifyStoreStatus(index + 1, stores);
  //         }
  //       }
  //     }
  //   });
  // };

  // // 设置店铺应不应该从新授权
  // setStoreUpdate = (d, index) => {
  //   const { stores } = this.state;
  //   let flag = false;

  //   if (d.code != 2000) {
  //     flag = true;
  //   } else if (d.data.is_available != true || d.data.scope_ok != true) {
  //       flag = true;
  //     }

  //   if (flag) {
  //     stores[index].update = flag;
  //     this.setState({
  //       stores: [...stores]
  //     });
  //   }
  // };

  // 显示重置密码弹窗
  showResetPassword = () => {
    this.props.dispatch({
      type: 'user/setPasswordModalVisible',
      payload: true
    });
  };

  // 更改用户名
  changeUserName = e => {
    this.setState({
      userName:e.target.value
    })
  };

  // 保存用户名
  saveUersName = () => {
    const { userName } = this.state
    if (userName.length > 20 || userName.length < 5) {
      this.setState({
        accountErr: true
      });
      return;
    }

    this.props.dispatch({
      type: 'dropshippersetting/updateUserName',
      payload: {
        data: {
          name: userName
        },
        callback: d => {
          if (d.code == 2000 || d.code === 2010) {
            window.location.reload();
          }
        }
      }
    });
  };

  // 显示更改邮箱弹窗
  toggleChangeEmail = () => {
    if (this.hasVerifyEmail && !this.state.changeEmail) {
      window.location.reload();
    }
    this.setState({
      changeEmail: !this.state.changeEmail
    });
  };

  // 更改用户邮箱
  changeNewEmail = e => {
    this.email = e.target.value;
    if (!emailRegExp.test(this.email)) {
      this.setState({
        emailError: true,
        emailErrorMsg: intl.get('setting.account.new_email_err'),
        sendSucc: false
      });
    } else {
      this.setState({
        emailError: false
      });
    }
  };

  // 给修改的邮箱发code
  sendCode = () => {
    if(!this.email){
      this.setState({
        emailError:true,
        emailErrorMsg: intl.get('setting.account.new_email_err'),
      })
      return
    }
    if (this.state.emailError || !this.email) {
      return;
    }

    this.props.dispatch({
      type: 'dropshippersetting/sendCode',
      payload: {
        data: {
          email: this.email
        },
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            // window.location.href = d.data.url;
            this.setState({
              emailError: false,
              emailErrorMsg: '',
              sendSucc: true
            });
          } else {
            this.setState({
              emailError: true,
              emailErrorMsg: d.msg
            });
          }
        }
      }
    });
  };

  // 修改邮箱
  verifyNewEmail = v => {
    if (!v) {
      this.setState({
        verifyEmailError:false
      })
      return;
    }
    this.props.dispatch({
      type: 'dropshippersetting/comfirmEmailChange',
      payload: {
        data: {
          email: this.email,
          code: v
        },
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.hasVerifyEmail = true;
            this.setState({
              verifyEmailError: true
            });
          } else {
            this.setState({
              verifyEmailError: false
            });
          }
        }
      }
    });
  };

  // 更新shopify授权
  goShopify = shop_name => {
    this.props.dispatch({
      type: 'dropshippersetting/updateShopfiy',
      payload: {
        data: {
          shop_name
        },
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            if (d.msg == 'bind store quota err') {
              localStorage.setItem('passivity', 'true');
              self.location.href = '/app/pricing';
            }

            window.location.href = d.data.url;
          } else {
            message.error(d.msg);
          }
        }
      }
    });
  };





  // 展示卸载shopify店铺弹窗
  handleDisconnect = storeid => {
    this.setState({
      disconnectShopifyStoreId: storeid
    });

    window.dataLayer.push({
      event: 'setting-account-click',
      type: 'shopify-store-disconnect'
    });
  };

  // 隐藏卸载shopify店铺弹窗
  handleUnDisconnect = () => {
    this.setState({
      disconnectShopifyStoreId: undefined
    });

    window.dataLayer.push({
      event: 'setting-account-modal-click',
      type: 'stay'
    });
  };

  // 卸载shopify店铺成功回调
  handleDisconnectCallback = () => {
    this.setState({
      disconnectShopifyStoreId: undefined,
      // showDisconnectCallback: true
    });
  };



  render() {
    const { updateUserNameLoadind, userInfo } = this.props;

    const {
      stores,
      emailError,
      emailErrorMsg,
      changeEmail,
      sendSucc,
      verifyEmailError,
      disconnectShopifyStoreId,
      showDisconnectCallback,
      showRefreshWhitelist,
      refreshModal
    } = this.state;


    let mail = '';

    if (this.email) {
      let mailHost = this.email.split('@')[1];
      mailHost == 'gmail.com'
        ? (mailHost = 'google.com')
        : (mailHost = mailHost);
      mail = `http://mail.${mailHost}`;
    }

    return (
      <>
        {/* {userInfo.email_status === 0 ? <ActivationEmail /> : null} */}
        <div id="account">
        {this.props.userInfo.email_status === 0 ? (
           <div className={styles.email_status}>
             <div className="verify_email_title">{intl.get('setting.account.verify_email_title')}</div>
              <ActivationEmail />
            </div>
                  ) : null}
          <Title title={intl.get('setting.title_account')} />
          <div className="accountBox">

              <div className="accountBoxItem">
                <span className="accountBoxIcon">
                  <i class="material-icons notranslate notranslate">account_circle</i>
                </span>
                <div className="accountBoxItemInfo">
                  <div className="name">{intl.get('setting.account.account_name')}</div>
                  <div className="info">{this.props.userInfo.user_name}</div>
                </div>
                <span className="accountBoxItemEdit"
                  onClick={()=>{
                    this.setState({
                      saveNameModal:true,
                      userName: this.props.userInfo.user_name
                    })
                  }}
                >
                  <Tooltip title="Edit">
                    <span><i class="material-icons notranslate">edit</i></span>
                  </Tooltip>
                </span>
              </div>
              <Modal
                title={intl.get('setting.account.account_new_info')}
                visible={this.state.saveNameModal}
                footer={null}
                width={600}
                wrapClassName="account"
                maskClosable={false}
                footer={null}
                destroyOnClose={true}
                onCancel={()=>{
                  this.setState({
                    saveNameModal:false,
                    accountErr:false,
                    userName:this.props.userInfo.user_name
                  })
                }}
              >
                <TextField
                  variant="outlined"
                  label={intl.get('setting.account.account_info')}
                  defaultValue={this.props.userInfo.user_name}
                  onChange={this.changeUserName}
                />
                { this.state.accountErr ? (
                  <span style={{ color: 'red' }}>
                    {intl.get('setting.account.account_name_err')}
                  </span>
                ) : (
                  <></>
                )}

                <p className="generalText">
                  <Button
                    type="primary"
                    onClick={this.saveUersName}
                    className={styles.saveBtn}
                    loading={updateUserNameLoadind}
                  >
                    {intl.get('public.save')}
                  </Button>
                </p>
              </Modal>

              <div className="accountBoxItem">
                <span className="accountBoxIcon">
                  <i class="material-icons notranslate notranslate">email</i>
                </span>
                <div className="accountBoxItemInfo">
                  <div className="name">{intl.get('setting.account.account_email')}</div>
                  <div className="info">{this.props.userInfo.email}</div>
                </div>
                <span className="accountBoxItemEdit"
                  onClick={this.toggleChangeEmail}
                >
                {
                  this.props.newUserInfo?.type === 2 ? null   //这里判断一下是否是limit子账户 如果是则不可以修改email
                  :
                  <Tooltip title="Edit">
                  <span><i class="material-icons notranslate">edit</i></span>
                  </Tooltip>
                }
                </span>
              </div>

              <div className="accountBoxItem lineNone">
                <span className="accountBoxIcon">
                  <i class="material-icons notranslate notranslate">vpn_key</i>
                </span>
                <div className="accountBoxItemInfo">
                  <div className="name">{intl.get('setting.password')}</div>
                  <div className="info">......</div>
                </div>
                <span className="accountBoxItemEdit"
                  onClick={this.showResetPassword}
                >
                  <Tooltip title="Edit">
                    <span><i class="material-icons notranslate">edit</i></span>
                  </Tooltip>
                </span>
              </div>
            </div>
        </div>

        <div className="line"></div>
        {!this.props.isCsvUser && <TimeZone />}
        <div className="line"></div>



        <DisconnectCallback visible={showDisconnectCallback} />



        {changeEmail ? (
          <Modal
            visible={changeEmail}
            footer={null}
            title={intl.get('setting.account.changeEmailName')}
            width={600}
            maskClosable={true}
            footer={null}
            wrapClassName={styles.changeEmailModal}
            destroyOnClose={true}
            onCancel={()=>{
              this.email = ''
              this.setState({
                changeEmail:false,
                emailError:false,
                sendSucc:false,
                emailErrorMsg:'',
                verifyEmailError:null
              })
            }}
          >
            <div className="changeEmail">
              <p className="newEmailDesc">{intl.get('setting.account.new_email')}</p>
              <p className="newEmailDesc">{intl.get('setting.account.new_email_desc')}</p>
              <Search
                enterButton={intl.get('setting.account.send_code')}
                onChange={this.changeNewEmail}
                placeholder="Email"
                onSearch={this.sendCode}
                size="small"
              />
              {emailError ? (
                <p className={styles.emailError}>{emailErrorMsg}</p>
              ) : null}
              {sendSucc ? (
                <p className={styles.sendCodeSuccTool}>
                  <a href={mail} target="_blank">
                    {intl.get('setting.account.open_mailbox')}
                  </a>
                  <a
                    role="button"
                    tab-index="0"
                    onClick={this.sendCode}
                  >
                    {intl.get('setting.account.send_again')}
                  </a>
                </p>
              ) : null}
              <p />
              <p>{intl.get('setting.account.verify_code')}</p>
              <p>{intl.get('setting.account.verify_code_desc')}</p>
              <Search
                enterButton={intl.get('setting.account.verify')}
                onSearch={this.verifyNewEmail}
                placeholder="Code"
                size="small"
              />
              {verifyEmailError || verifyEmailError == false ? (
                <p
                  className={styles.verifyNewEmail}
                  style={{ color: verifyEmailError ? '#52c41a' : 'red' }}
                >
                  {verifyEmailError == false
                    ? intl.get('setting.account.verify_code_err')
                    : 'Email address verified'}
                </p>
              ) : null}
            </div>
          </Modal>
        ) : null}
      </>
    );
  }
}
