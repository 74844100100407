import React, { useState } from 'react';
import { connect } from 'dva';
import { Icon, Spin, Switch, Tooltip, message } from 'antd';
import { updateAgencyAutomaticPlaceStatus } from 'services/dropshippersetting';

import {
  SettingPartitionBox,
  SettingPartitionContent,
  SettingPartitionHeader,
  SettingPartitionTitle,
  SettingItemContainer
} from '../../SettingBoxCommon';

// import styles from './index.less';

export const PlaceAutomaticly = connect(reduxState => {
  return {
    status:
      reduxState?.dropshippersetting?.agencySettings?.AutomaticallyPlaceStatus,
    reduxLoading:
      reduxState?.loading?.effects?.['dropshippersetting/getAgencySettings']
  };
})(({ status = false, dispatch, reduxLoading }) => {
  const [loading, setLoading] = useState(false);

  const onSwitchChange = async e => {
    setLoading(true);
    try {
      const res = await updateAgencyAutomaticPlaceStatus({
        data: { status: e || false }
      });
      if (res.code === 2010) {
        message.success('Update success');
        dispatch({
          type: 'dropshippersetting/getAgencySettings',
          payload: {}
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <SettingPartitionBox>
      <SettingPartitionHeader>
        <SettingPartitionTitle>
          Automatically place orders to Agency
        </SettingPartitionTitle>
      </SettingPartitionHeader>
      <Spin spinning={loading || reduxLoading}>
        <SettingPartitionContent>
          <SettingItemContainer
            middle={
              <div>
                After activating this feature, DSers will automatically place
                orders to the Agency without manual operation. The order will be
                automatically transferred to Awaiting payment, please pay in
                time so that the supplier can deliver the goods.{' '}
                <Tooltip title="For orders that fail to be placed automatically, please place the orders manually after the failed orders has been modified.">
                  <Icon style={{ marginLeft: 8 }} type="question-circle" />
                </Tooltip>
              </div>
            }
            right={<Switch onChange={onSwitchChange} checked={status} />}
          />
        </SettingPartitionContent>
      </Spin>
    </SettingPartitionBox>
  );
});
