import React, { useState, useEffect } from 'react';
import { connect } from 'dva';

import { Row, Col, Select, Modal, Input, Button,InputNumber } from 'antd';

import intl from 'react-intl-universal';

import Title from '../../Title'

import { confirmLanguage } from '../../../../../common/sysconfig';
import { getLanguage } from '../../../../../utils/utils';

import styles from './Currencies.less';
import styleList from '../../List.less';
import { Get } from 'utils/request';
import { setCurrenciesCustom } from '../../../../../services/customize';
const { Option } = Select;

const with100 = { width: '100%' };
const currentLanguage = getLanguage();

function Currencies(props) {
  const [showSave, setShowSave] = useState(false);
  const [disableOk, setDisabled] = useState(true);
  const [selectedArr, setSelectedArr] = useState([]);
  const [customizeUser,setCustomizeUser] = useState(false);
  const [customizeVal,setCustomizeVal]  = useState('');
  const [customizeCurrency,setCustomizeCurrency]  = useState('');
  const [customizeValChange,setCustomizeValChange]  = useState(false);
  const {
    userInfo: { stores },
    okLoading,
    newUserInfo,
    customizeCur
  } = props;

  useEffect(() => {
    setCustomizeUser(!Object.keys(customizeCur).length ? true : false)
    setSelectedArr(new Array(stores.length).fill(''));

    const storeids = stores.reduce((acc, cur) => {
      acc.push(cur.id);
      return acc;
    }, []);

    updateStore(storeids)
  }, []);
  
  useEffect(() => {
    customInit()
    }, [customizeCur.currency]);


  const customInit = () =>{
    if(!Object.keys(customizeCur).length || newUserInfo.org_id != customizeCur.id){
        return
    }
     setCustomizeCurrency(customizeCur.currencyType)
     setCustomizeVal(Number(customizeCur.currency))
  }
  
  const updateStore = async (storeids)=>{
    await Get('/users/refresh_token',{})
    props.dispatch({
      type: 'dropshippersetting/updateStore',
      payload: {
        data: {
          storeids
        },
        callback: d => {
          if (d.code === 2000) {
            props.dispatch({ type: 'login/updateUserInfo' });
          }
        }
      }
    });
  }

  function inputConfirm(e) {
    setDisabled(e.target.value.trim().toLocaleLowerCase() === confirmLanguage[currentLanguage] ? false : true);
  }

  function handleSelect(v, i) {
    const arr = [...selectedArr];
    arr[i] = v;
    setSelectedArr(arr);
  }

  function handleOk() {
   const arr = [];
    if(customizeValChange){
      setCurrenciesCustom({data:
          {"store_id":customizeCur.storeId,
          "exchange_rate":customizeVal+ "",
          "currency":customizeCurrency}}).then((res)=>{
          setShowSave(false);
          setCustomizeValChange(false)
      })
      props.dispatch({ type: 'login/updateUserInfo' });
    }
    if(!selectedArr.length){
      return
    }
    selectedArr.forEach((item, i) => {
      if (item !== '') {
        arr.push({
          currency_code: item,
          storeid: stores[i].id
        });
      }
    });

    if (arr.length === 0) {
      return;
    }

    props.dispatch({
      type: 'dropshippersetting/saveCurrency',
      payload: {
        data: arr,
        callback: d => {
          if (d.code === 2000) {
            localStorage.setItem('hasUpdateCurrency', true);
            props.dispatch({ type: 'login/updateUserInfo' });
            setSelectedArr(new Array(stores.length).fill(''));
            setShowSave(false);
          }
        }
      }
    });
  }

  return (
    <>
    <Title title={intl.get('setting.title_currencies')} description={intl.getHTML('setting.report.currencies.desc')}/>
    <div className={styles.wrap}>
      {/* <h3>{intl.get('setting.report.currencies.title')}</h3>
      <p className={styles.dec}>
        {intl.getHTML('setting.report.currencies.desc')}
      </p> */}
      {/* {stores.length ? (
        <Row gutter={[8, 16]}>
          <Col span={12}>
            <h3>{intl.get('setting.report.currencies.store')}</h3>
          </Col>
          <Col span={12}>
            <h3>{intl.get('setting.report.currencies.currency')}</h3>
          </Col>
        </Row>
      ) : (
        <p className={styles.noStoresTip}>{intl.get('setting.report.currencies.no_store_tip')}</p>
      )} */}
      <div>
      {stores.map((store, i) => {
        return (
        <div className={styles.storeListContent}>
          <div  key={store.id} className={styles.storeList}>
            <i class="material-icons notranslate">storefront</i>
            <div className={styles.storeListName}>
              <p className={styles.shopName}>
                {`${store.shop_name}.myshopify.com`}
              </p>
            </div>
            <div className={styles.storeListSelect}>
              <Select
                style={with100}
                disabled={!!store.ali_currency}
                className={styles.syncbordercolor}
                placeholder="Ali Currency on DSers"
                defaultValue={store.ali_currency || 'USD'}
                onSelect={v => handleSelect(v, i)}
                suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              >
                <Option value={store.currency}>{store.currency}</Option>
              </Select>
            </div>
          </div>
        {store.id == customizeCur.storeId &&
        <div className={styles.addCustmizeSelect}>
          Exchange Rate ：1 USD  =  
            <span>
              <InputNumber
                 min={0}
                 precision={2}
                 value={customizeVal}
                 onChange={(e)=>{
                  setCustomizeValChange(e ? true : false)
                    setCustomizeVal(e)
                 }}
            />
            </span>
            {store.ali_currency || 'KWD'}
          </div>}
        </div>
        );
      })}
      </div>
      {!stores.length &&
      <div className={styleList.noData}>
      <img  src={require('../../../../../assets/setting/noData.png')} />
      <a href='https://apps.shopify.com/dsers'>{intl.get('setting.tracking.add_a_shopify_store')}</a>
      </div>
      } 
      <div className={styles.saveLine}>
        <Button
          type="primary"
          disabled={(!selectedArr.some(item => item !== '')) && !customizeValChange}
          onClick={() => setShowSave(!showSave)}
          style={{textTransform:'uppercase'}}
        >
          {intl.get('public.save')}
        </Button>
      </div>
      <Modal
        width={536}
        closable={true}
        maskClosable={false}
        onCancel={()=>{
          setShowSave(!showSave);
          setDisabled(true);
        }}
        className={styles.modalstyle}
        visible={showSave}
        footer={null}
        destroyOnClose
      >
        <h3 className={styles.confirmTitle}>
          {intl.get('setting.report.currencies.save_modal_title')}
        </h3>
        <p className={styles.confirmTip}>
          {intl.getHTML('setting.report.currencies.save_modal_desc')}
        </p>
        <p className={styles.confirmInput}>
          <Input
            placeholder={intl.get('setting.report.currencies.input_confirm')}
            onChange={inputConfirm}
          />
        </p>
        <div className={styles.confirmButtonLine}>
          <Button
            type="default"
            onClick={() => {
              setShowSave(!showSave);
              setDisabled(true);
            }}
            data="bk"
          >
            {intl.get('public.cancel')}
          </Button>
          <Button
            disabled={disableOk}
            type="primary"
            loading={okLoading}
            data="dx"
            className={styles.okstyle}
            onClick={handleOk}
          >
            {intl.get('public.ok')}
          </Button>
        </div>
      </Modal>
    </div>
    </>
  );
}

export default connect(({ dropshippersetting, login, loading,user }) => ({
  code: dropshippersetting.paypalCheckCode,
  userInfo: login.userInfo,
  okLoading: loading.effects['dropshippersetting/saveCurrency'],
  newUserInfo:user.newUserInfo,
  customizeCur:login.customizeCurrency
}))(Currencies);
