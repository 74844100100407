import { Button, Icon, message, Modal, Table, Tooltip } from 'antd';
import Paypal from 'components/Paypal';
import { connect } from 'dva';
import moment from 'moment';
import React, { Fragment } from 'react';
import BillingInformation from './Billing.js';
import styles from './Index.less';
import PrimaryContact from './Primary.js';

@connect(({ dropshippersetting, loading }) => ({
  dropshippersetting,
  loading: loading.models.dropshippersetting,
  transactionsRecordLoading: loading.effects['dropshippersetting/transactions'],
  billsRecordLoading: loading.effects['dropshippersetting/bills']
}))
export default class BillingBalance extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPay: false,
      showAuth: false,
      paypalNumber: 0,
      showBilling: false,
      primarySampleStatus: true
    };
  }

  componentDidMount() {
    this.getPrimaryContact();
    this.getTransactions();
    this.getBills();
    this.getBalance();
    this.getLastPayment();
    this.getUseAddress();
  }

  getUseAddress = () => {
    // 获取用哪个样品地址
    this.props.dispatch({
      type: 'dropshippersetting/useAddress',
      payload: {
        callback: d => {
          this.setState({
            primarySampleStatus: d.data.address_info == 1
          });
        }
      }
    });
  };

  getAuth = () => {
    // 获取二次验证信息
    this.props.dispatch({
      type: 'dropshippersetting/gauth'
    });
  };

  getLastPayment = () => {
    // 获取最后一次的充值情况
    this.props.dispatch({
      type: 'dropshippersetting/paypalStatus'
    });
  };

  changePaypal = v => {
    this.setState({
      paypalNumber: Number(v) || 0
    });
  };

  getBalance = () => {
    // 获取余额
    this.props.dispatch({
      type: 'dropshippersetting/balance'
    });
  };

  getPrimaryContact = () => {
    // 获取联系人信息
    this.props.dispatch({
      type: 'dropshippersetting/primary',
      payload: {
        callback: d => {
          this.setState({
            showBilling:
              d.data.primary_contact_info.billing_info_status != 1
          });
        }
      }
    });
  };

  getTransactions = () => {
    // 获取每天开销记录
    this.props.dispatch({
      type: 'dropshippersetting/transactions',
      payload: {
        data: {
          current: this.currentPage,
          page_size: this.pageSize
        }
      }
    });
  };

  getBills = () => {
    // 获取每月发票
    this.props.dispatch({
      type: 'dropshippersetting/bills',
      payload: {
        data: {
          current: this.billsCurrentPage,
          page_size: this.billsPageSize
        }
      }
    });
  };

  changePage = v => {
    this.currentPage = v;
    this.getTransactions();
  };

  changeBillsPage = v => {
    this.billsCurrentPage = v;
    this.getBills();
  };

  showPay = () => {
    // 显示支付弹窗
    this.setState({
      showPay: true,
      paypalNumber: 0
    });
  };

  hidePay = () => {
    // 隐藏支付弹窗
    this.setState({
      showPay: false
    });
  };

  saveSampleAddress = v => {
    // 保存用哪个地址发样品
    this.props.dispatch({
      type: 'dropshippersetting/useSample',
      payload: {
        data: {
          address_info: v
        },
        cancelMessage: true,
        callback: d => {
          if (d.code == 1) {
            // message.success('save success');
          } else {
            // message.error('save error');
          }
        }
      }
    });
  };

  savePrimarycontact = data => {
    // 保存联系人信息
    Object.assign(this.data.primary_contact_info, data);
    delete this.data.primary_contact_info.created_at;
    delete this.data.primary_contact_info.deleted_at;
    delete this.data.primary_contact_info.updated_at;
    delete this.data.primary_contact_info.sample_status;
    this.props.dispatch({
      type: 'dropshippersetting/savePrimary',
      payload: {
        data: this.data.primary_contact_info,
        cancelMessage: true,
        callback: d => {
          if (d.code == 1) {
            message.success('save success');
          } else {
            message.error('save error');
          }
        }
      }
    });
    this.saveSampleAddress(this.state.primarySampleStatus ? 1 : 2);
  };

  saveBillinginformation = data => {
    // 保存billing信息
    Object.assign(this.data.billing_info, data);
    delete this.data.billing_info.created_at;
    delete this.data.billing_info.deleted_at;
    delete this.data.billing_info.updated_at;
    this.props.dispatch({
      type: 'dropshippersetting/saveBilling',
      payload: {
        data: this.data.billing_info,
        cancelMessage: true,
        callback: d => {
          if (d.code == 1) {
            message.success('save success');
          } else {
            message.error('save error');
          }
        }
      }
    });
    this.data.primary_contact_info.billing_info_status = 0;
    this.savePrimarycontact(this.data.primary_contact_info);
  };

  changeWhichOneAddress = v => {
    // 选择联系地址
    this.setState({
      showBilling: !!v,
      primarySampleStatus: !v ? true : this.state.primarySampleStatus
    });
  };

  setMakeAPaymentDisabled = data => {
    // 充值按钮是否可点
    const { primary_contact_info = {}, billing_info = {} } = data;

    let flag = true;

    if (primary_contact_info.billing_info_status && primary_contact_info.name) {
      flag = false;
    }

    if (
      primary_contact_info.billing_info_status == 0 &&
      primary_contact_info.name &&
      billing_info.business_name
    ) {
      flag = false;
    }
    return flag;
  };

  hideAuth = () => {
    this.setState({
      showAuth: false
    });
  };

  showAuth = () => {
    this.getAuth();
    this.setState({
      showAuth: true
    });
  };

  changeGAuthCode = e => {
    this.gauthcode = e.target.value;
  };

  changeSample = e => {
    this.setState({
      primarySampleStatus: !e.target.checked
    });
  };

  changeSamplePrimary = e => {
    this.setState({
      primarySampleStatus: e.target.checked
    });
  };

  transactionsColumns = [
    {
      title: 'Date',
      dataIndex: 'Date'
    },
    {
      title: 'Description',
      dataIndex: 'Direction',
      render: (v, record) => {
        return v == 1 ? (
          'Payment'
        ) : (
          <a
            href={`${window.DSERS_API_CONFIG.HOST}/api/v1/supply/setting/transactions/${
              record.Date
            }?token=${this.user}`}
            target="_blank"
          >
            Purchase record {record.Date}
          </a>
        );
      }
    },
    {
      title: 'Amount (USD)',
      dataIndex: 'Amount',
      render: (v, record) => {
        return record.Direction == 0 ? (
          `-USD${v}`
        ) : (
          <span className={styles.transactionsPayment}>+USD{v}</span>
        );
      }
    }
  ];

  allBillsColumns = [
    {
      title: 'Date',
      dataIndex: 'CreatedAt',
      render: v => {
        return moment(v * 1000).format('YYYY-MM-DD');
      }
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      render: v => {
        return `$${v} USD`;
      }
    },
    {
      render: (v, record) => {
        return (
          <a
            href={`${window.DSERS_API_CONFIG.HOST}/api/v1/supply/setting/bill_pdf/${
              record.InvoiceNumber
            }?token=${localStorage.getItem('dser-pro-authority')}`}
          >
            Download
          </a>
        );
      }
    }
  ];

  currentPage = 1;
  pageSize = 10;
  billsCurrentPage = 1;
  billsPageSize = 10;
  user = localStorage.getItem('dser-pro-authority');
  gauthcode = '';

  data = {
    primary_contact_info: {},
    billing_info: {}
  };

  lastpayment = data => {
    if (!Number(data.Amount)) {
      return null;
    } else if (data.SaleState == 'completed') {
        return (
          <p className={styles.lastpayment}>{`Your last payment was on ${moment(
            data.CreatedAt * 1000
          ).format('YYYY-MM-DD')} for USD ${data.Amount}`}
          </p>
        );
      } else if (data.SaleState == 'pending') {
        return (
          <p className={styles.lastpayment}>{`Your last payment was on ${moment(
            data.CreatedAt * 1000
          ).format('YYYY-MM-DD')} for USD ${
            data.Amount
          } and is pending on paypal.`}
          </p>
        );
      } else {
        return (
          <p className={styles.lastpayment}>{`Your last payment was on ${moment(
            data.CreatedAt * 1000
          ).format('YYYY-MM-DD')} for USD ${
            data.Amount
          } payment faild on paypal. please check and payment again.`}
          </p>
        );
      }
  };

  render() {
    const {
      billsRecordLoading,
      transactionsRecordLoading,
      country,
      dropshippersetting = {}
    } = this.props;

    const {
      primaryData = {},
      transactionsRecord = {},
      billsRecord = {},
      balance = 0,
      gQRcode = {},
      lastpayment = {}
    } = dropshippersetting;

    const {
      paypalNumber,
      showPay,
      showBilling,
      showAuth,
      primarySampleStatus
    } = this.state;

    Object.assign(
      this.data.primary_contact_info,
      primaryData.primary_contact_info
    );
    Object.assign(this.data.billing_info, primaryData.billing_info);

    const transactionsPagination = {
      position: 'top',
      onChange: this.changePage,
      total: transactionsRecord.total || 0
    };

    const allBillsPagination = {
      position: 'top',
      onChange: this.changeBillsPage,
      total: billsRecord.total || 0
    };

    return (
      <Fragment>
        <div className={styles.box}>
          <div className={styles.section}>
            <h3>
              We strongly recommend you to Activate Google<br /> Auth to ensure
              your account security
            </h3>
            <Button className={styles.positionBtn} onClick={this.showAuth}>
              ACTIVATE
            </Button>
          </div>
          <div className={styles.section}>
            <h3>Remaining credit</h3>
            <h1>USD $ {balance}</h1>
            {this.lastpayment(lastpayment)}
            {this.setMakeAPaymentDisabled(primaryData) ? (
              <Tooltip title="Please fill in your billing address before you can use it.">
                <Button
                  className={styles.positionBtn}
                  onClick={this.showPay}
                  disabled
                >
                  MAKE A PAYMENT <Icon type="question-circle" />
                </Button>
              </Tooltip>
            ) : (
              <Button className={styles.positionBtn} onClick={this.showPay}>
                MAKE A PAYMENT
              </Button>
            )}
          </div>
          <PrimaryContact
            country={country}
            data={primaryData.primary_contact_info}
            changeWhichOneAddress={this.changeWhichOneAddress}
            savePrimarycontact={this.savePrimarycontact}
            useBilling={showBilling}
            primarySampleStatus={primarySampleStatus}
            changeSamplePrimary={this.changeSamplePrimary}
          />
          {showBilling ? (
            <BillingInformation
              country={country}
              data={primaryData.billing_info}
              saveBillinginformation={this.saveBillinginformation}
              primarySampleStatus={primarySampleStatus}
              changeSample={this.changeSample}
            />
          ) : null}
          <div className={styles.section}>
            <h3>Transactions</h3>
            <Table
              columns={this.transactionsColumns}
              dataSource={transactionsRecord.list}
              pagination={transactionsPagination}
              loading={transactionsRecordLoading}
              rowKey="date"
              size="small"
            />
          </div>
          <div className={styles.section}>
            <h3>All bills</h3>
            <Table
              columns={this.allBillsColumns}
              dataSource={billsRecord.list}
              pagination={allBillsPagination}
              loading={billsRecordLoading}
              rowKey="date"
              size="small"
            />
          </div>
        </div>

        {showPay ? (
          <Paypal
            showPay={showPay}
            hidePay={this.hidePay}
            paypalNumber={paypalNumber}
            changePaypal={this.changePaypal}
            callback={[this.getTransactions, this.getBalance, this.hidePay]}
          />
        ) : null}

        <Modal
          className={styles.authModal}
          footer={null}
          visible={showAuth}
          maskClosable={false}
          onCancel={this.hideAuth}
        >
          <h2>Step 1 : Download Google Authenticator</h2>
          <p>
            The IOS user logs in to the AppStore to search for “Google
            Authenticator", and the Android user logs into the app store or uses
            the mobile browser to search for "Google Authenticator" download.
          </p>
          <h2>Step 2 : Bind Google Authenticator</h2>
          <p>IOS/Andorid：Scan QR code</p>
          <p style={{ textAlign: 'center' }}>
            <img src={gQRcode.img} />
          </p>
          <p>
            If the scan code does not work, you can enter the verification code.
          </p>
          <h3 style={{ textAlign: 'center' }}>{gQRcode.code}</h3>
          <p>Used to retrieve google auth, please save.</p>
        </Modal>
      </Fragment>
    );
  }
}
