import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'dva';
import {
  Button,
  Icon,
  Input,
  Select,
  Spin,
  Tooltip,
  message,
  Modal
} from 'antd';
import { updateAgencyAutomaticPlaceStatus } from 'services/dropshippersetting';

import {
  SettingPartitionBox,
  SettingPartitionContent,
  SettingPartitionHeader,
  SettingPartitionTitle,
  SettingItemContainer
} from '../../SettingBoxCommon';

import styles from './index.less';
import { Delete, Get, Post } from 'utils/request';

let autoIndexId = 0;

const createAutoIndexId = () => ++autoIndexId;

export const AgencyTax = connect(({ dropshippersetting, loading }) => {
  return {
    countries: dropshippersetting.countrys
  };
})(({ countries }) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([{ country: undefined, taxNumber: '' }]);

  const [isEditing, setIsEditing] = useState(false);

  const couldSave = useMemo(() => {
    return data.some(i => i.country && i.taxNumber);
  }, [data])

  const countriesWithGlobal = useMemo(() => {
    if (countries.find(i => i.Code === 'Global')) {
      return [...countries];
    }

    return [{ Name: 'Global', Code: 'Global' }, ...countries];
  }, [countries]);

  const selectedCountryMap = useMemo(() => {
    return data.reduce((prev, current) => {
      prev[current?.country] = true;
      return prev;
    }, {});
  }, [data]);

  const initData = async () => {
    setLoading(true);
    try {
      const res = await Get('/set/merchant/tax');
      if (res.code === 2000 && res.data) {
        setData(
          res.data
            .map(i => ({
              country: i.country_code,
              taxNumber: i.tax,
              id: i.country_code,
              key: createAutoIndexId(),
            }))
            .concat({ country: undefined, taxNumber: '', key: createAutoIndexId(), })
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onSelectChange = (value, index) => {
    const newData = [...data];
    const newItem = { ...newData[index] };
    newItem.country = value;
    newData[index] = newItem;

    setData(newData);
    setIsEditing(true);
  };

  const onInputChange = (value, index) => {
    const newData = [...data];
    const newItem = { ...newData[index] };
    newItem.taxNumber = value;
    newData[index] = newItem;

    setData(newData);
    setIsEditing(true);
  };

  const addRow = () => {
    setData([...data, { country: undefined, taxNumber: '', key: createAutoIndexId() }]);
  };

  const deleteRow = async index => {
    if (data.length === 1) return;

    Modal.confirm({
      title: 'Confirm Delete',
      icon: null,
      closeable: true,
      content:
        'This operation will permanently delete the tax ID setting. Do you want to continue?',
      onOk: async () => {
        try {
          setLoading(true);
          if (data?.[index]?.id) {
            const res = await Delete('/set/merchant/tax', {
              data: { country_code: data?.[index]?.id },
              // body: JSON.stringify({ country_code: data?.[index]?.id }),
            });
            if (res.code !== 2010) {
              message.error('Update failed');
              return;
            } else {
              message.success('Update success');
            }
          }
        } catch (error) {
          message.error('Update failed');
          return;
        }
        setLoading(false);
        const newData = data?.filter((_, i) => i !== index) || [];
        setData(newData);
      }
    });
  };

  useEffect(() => {
    initData();
  }, []);

  const handleSave = async () => {
    setLoading(true);

    const params = data
      .filter(i => i.country && i.taxNumber)
      .map(i => ({ country_code: i.country, tax: i.taxNumber }));
    console.log(params);

    try {
      const res = await Post('/set/merchant/tax', { data: params });
      if(![2000, 2010].includes(res.code)) {
        message.error('Update failed')
        setLoading(false);
        return;
      }

      await initData();
      setIsEditing(false);
    } catch (error) {
      message.error('Update failed')
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <SettingPartitionBox>
      <SettingPartitionHeader>
        <SettingPartitionTitle>
          Tax setting for Agency orders
        </SettingPartitionTitle>
      </SettingPartitionHeader>
      <Spin spinning={loading}>
        <SettingPartitionContent>
          <div style={{ marginBottom: 16 }}>IOSS</div>
          <div>
            {data.map((i, index, arr) => {
              const isLast = index === arr.length - 1;
              return (
                <div className={styles.listItemContainer} key={i.key}>
                  <div>
                    <Select
                      placeholder="Choose a country"
                      value={i.country}
                      showSearch
                      optionFilterProp='label'
                      className={styles.select}
                      onChange={e => onSelectChange(e, index)}
                    >
                      {countriesWithGlobal.map(country => {
                        return (
                          <Select.Option
                            value={country.Code}
                            label={country.Name}
                            key={country.Code}
                            disabled={
                              selectedCountryMap[country.Code] &&
                              i.country !== country.Code
                            }
                          >
                            {country.Name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className={styles.inputContainer}>
                    <Input
                      value={i.taxNumber}
                      onChange={e => onInputChange(e.target.value, index)}
                      className={styles.input}
                    />
                  </div>
                  <div>
                    {!isLast && arr.length > 1 ? (
                      <Tooltip title="Delete country setting">
                        <span
                          onClick={() => deleteRow(index)}
                          key="delete"
                          className="material-icons"
                        >
                          delete
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add country">
                        <span
                          onClick={addRow}
                          key="add"
                          className="material-icons"
                        >
                          add
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button
              loading={loading}
              onClick={handleSave}
              data="dx"
              disabled={!isEditing || !couldSave}
              type="primary"
            >
              SAVE
            </Button>
          </div>
        </SettingPartitionContent>
      </Spin>
    </SettingPartitionBox>
  );
});
