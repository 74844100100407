import React, { Component } from 'react';
import { connect } from 'dva';
import { Button, Select, message, Radio } from 'antd';

const { Option } = Select;

import intl from 'react-intl-universal';

import styles from './Index.less';

import { webCustomCollect } from 'utils/utils'

import { GuildeAction, LinkStoreAction } from 'actions'
import { CommonDataCollect } from 'utils/utils';

const RadioGroup = Radio.Group;

@connect(({ dropshippersetting, loading, login }) => ({
  dropshippersetting,
  loading: loading.models.dropshippersetting,
  userInfo: login.userInfo,
  saveNotifyAutoLoading: loading.effects['dropshippersetting/saveNotifyAuto']
}))
export default class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auto_sync_stock: 1,
      inventory_product_invalid: 1,
      inventory_variant_invalid:1,
      autoUpdateSaveDisabled: true
    };
  }

  componentDidMount() {
    this.getAutoUpdatesSetting();
  }

  // 获取默认设置
  getAutoUpdatesSetting = () => {
     if (this.props.orderType == 1) {
      this.setState({
        auto_sync_stock: 1,
        inventory_product_invalid: 1,
        inventory_variant_invalid:1
        })
    } else {
      this.props.dispatch({
        type: 'dropshippersetting/notifyAuto',
        payload: {
          cancelMessage: true,
          callback: d => {
            if (d.code == 2000 || d.code == 2010) {
              this.setState({
                ...d.data
              });
            }
          }
        }
      });
    }
  };

  // 保存自动更新库存
  saveStock = () => {
    const { auto_sync_stock, inventory_product_invalid,inventory_variant_invalid } = this.state;

    this.props.dispatch({
      type: 'dropshippersetting/saveNotifyAuto',
      payload: {
        data: {
          auto_sync_stock,
          inventory_product_invalid,
          inventory_variant_invalid
        },
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              autoUpdateSaveDisabled: true
            });
            // message.success(d.msg)
            webCustomCollect({
              action:"setting_sync_auto_update",
              custom_info:[
                {
                  name:'inventory_product_invalid',
                  value:inventory_product_invalid == 1 ? 'doNothing' :(inventory_product_invalid == 2 ? 'set_draft' : 'set_zero')
                },
                {
                  name:'inventory_variant_invalid',
                  value:inventory_variant_invalid == 1 ? 'doNothing' :(inventory_variant_invalid == 2 ? 'remove' : 'set_zero')
                }
              ]
            })
            message.success(`Save setting success`)
          }
        }
      }
    });
  };

  // 改变 inventory_product_invalid
  changeProductOutOfStock = v => {
    if ([1,12,13,14,15,16,17,18,19,20].includes(this.props.orderType)) {
      CommonDataCollect({
        action: 'Autoupdate-product-top',
        event_type:'overlimit_pop'
      });
      // window.EVENT.emit(GuildeAction.TurnPlan_Pop,`Autoupdate-product&${Date.now()}`, true); 
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'AutoupdateInventory')
      return
    }
      this.setState({
        inventory_product_invalid: v?.target?.value,
        autoUpdateSaveDisabled: false
      });
  };
  changeVariantOutOfStock = v => {
    if ([1,12,13,14,15,16,17,18,19,20].includes(this.props.orderType)) {
      CommonDataCollect({
        action: 'Autoupdate-variant-top',
        event_type:'overlimit_pop'
      });
      // window.EVENT.emit(GuildeAction.TurnPlan_Pop,`Autoupdate-variant&${Date.now()}`, true); 
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'AutoupdateInventory')
      return
    }
      this.setState({
        inventory_variant_invalid: v?.target?.value,
        autoUpdateSaveDisabled: false
      });
  };

  // 改变 auto_sync_stock
  changeAutoSyncStock = v => {
    if ([1,12,13,14,15,16,17,18,19,20].includes(this.props.orderType)) {
         CommonDataCollect({
                          action: 'Autoupdate-Inventory-top',
                          event_type:'overlimit_pop'
                        });
      // window.EVENT.emit(GuildeAction.TurnPlan_Pop,`Autoupdate-Inventory&${Date.now()}`, true); 
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'AutoupdateInventory')
      return
    }
      this.setState({
        auto_sync_stock: v?.target?.value,
        autoUpdateSaveDisabled: false
        });
  };

  render() {
    const { saveNotifyAutoLoading, userInfo } = this.props;

    const {
      auto_sync_stock,
      inventory_product_invalid,
      inventory_variant_invalid,
      autoUpdateSaveDisabled
    } = this.state;


    return (
      <div className={styles.notificationBox}>
        {/* <div className={styles.UpdatesTitle}>
          <h3>{intl.get('setting.notification.auto_updates')}</h3>
          <p>{intl.get('setting.notification.auto_updates_tip')}</p>
        </div> */}
        <div className={styles.UpdatesCont}>
          <div className={styles.UpdatesInventory}>
            <h5>{intl.get('setting.notification.product_out')}</h5>
            <RadioGroup className={styles.radiostyle} onChange={this.changeProductOutOfStock} value={inventory_product_invalid}>
              <Radio value={1}>{intl.get('setting.notification.do_nothing')}</Radio>
              <Radio value={2}>{intl.get('setting.notification.set_as_draft')}</Radio>
              <Radio value={3}>{intl.get('setting.notification.set_quantity_to_zero_on_Shopify')}</Radio>
            </RadioGroup>
            {/* <Select
              size="small"
              value={inventory_product_invalid}
              className={styles.selectstyle}
              onChange={this.changeProductOutOfStock}
              suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              <Option key="a" value={1}>
                {intl.get('setting.notification.do_nothing')}
              </Option>
              <Option key="b" value={2}>
                {intl.get('setting.notification.set_as_draft')}
              </Option>
              <Option key="c" value={3}>
                {intl.get('setting.notification.set_quantity_to_zero_on_Shopify')}
              </Option>
            </Select> */}
          </div>
          <div className={styles.UpdatesInventory}>
            <h5>{intl.get('setting.notification.variant_out')}</h5>
            <RadioGroup className={styles.radiostyle} onChange={this.changeVariantOutOfStock} value={inventory_variant_invalid}>
              <Radio value={1}>{intl.get('setting.notification.do_nothing')}</Radio>
              <Radio value={2}>{intl.get('setting.notification.remove_Variant')}</Radio>
              <Radio value={3}>{intl.get('setting.notification.set_quantity_to_zero_on_Shopify')}</Radio>
            </RadioGroup>
            {/* <Select
              size="small"
              value={inventory_variant_invalid}
              onChange={this.changeVariantOutOfStock}
              className={styles.selectstyle}
              suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              <Option key="a" value={1}>
                {intl.get('setting.notification.do_nothing')}
              </Option>
              <Option key="b" value={2}>
                {intl.get('setting.notification.remove_Variant')}
              </Option>
              <Option key="c" value={3}>
                {intl.get('setting.notification.set_quantity_to_zero_on_Shopify')}
              </Option>
            </Select> */}
          </div>
          <div className={styles.UpdatesInventory}>
            <h5>{intl.get('setting.notification.inventory_change')}</h5>
            <RadioGroup className={styles.radiostyle1} onChange={this.changeAutoSyncStock} value={auto_sync_stock}>
              <Radio value={1}>{intl.get('setting.notification.do_nothing')}</Radio>
              <Radio value={2}>{intl.get('setting.notification.update_to_shopify')}</Radio>
            </RadioGroup>
            {/* <Select
              size="small"
              value={auto_sync_stock}
              className={styles.selectstyle}
              onChange={this.changeAutoSyncStock}
              suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              <Option key="a" value={1}>
                {intl.get('setting.notification.do_nothing')}
              </Option>
              <Option key="b" value={2}>
                {intl.get('setting.notification.update_to_shopify')}
              </Option>
            </Select> */}
          </div>
          <div className={styles.UpdatesText}>
            <Button
              type="primary"
              loading={saveNotifyAutoLoading}
              className={styles.saveBtn}
              onClick={this.saveStock}
              disabled={autoUpdateSaveDisabled}
              data="dx"
            >
              {intl.get('public.save')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
