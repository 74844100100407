import React, { useState, useEffect, useCallback, useReducer } from 'react';
import intl from 'react-intl-universal';
import { Button, Modal, Form, Input, Checkbox, Spin } from 'antd';

import styles from './index.less';
import { emailRegExp } from 'utils/constants';
import { Post, Get } from 'utils/request';
import SuccessFeedback from 'components/SuccessFeedback';

const initState = {
  loading: false,
  data: null
};

function reducer(state, action) {
  switch (action.type) {
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setData':
      return { ...state, data: action.payload };
    default:
      throw new Error();
  }
}

function Supply({ form }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [state, dispatch] = useReducer(reducer, initState);

  const handleCancel = useCallback(() => {
    setVisible(false);
    setSubmitted(false);
  }, []);

  const fetchData = useCallback(async () => {
    dispatch({ type: 'setLoading', payload: true });

    try {
      const res = await Get('/set/agent');

      if (res && res.code === 2000 && res.data) {
        dispatch({ type: 'setData', payload: res.data });
      }
    } catch (error) {
      console.log('fetch error:', error);
    }

    dispatch({ type: 'setLoading', payload: false });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function hasErrors() {
    const { getFieldsValue, getFieldError } = form;
    const values = getFieldsValue();
    const errors = getFieldError('email');
    let result = false;

    if (values.email === undefined) {
      result = true;
    }

    if (!values.agreement) {
      result = true;
    }

    if (errors && errors.length > 0) {
      result = true;
    }
    return result;
  }

  function handleSubmit(e) {
    e.preventDefault();
    const { validateFields } = form;
    validateFields(async (errors, values) => {
      if (errors) {
        return;
      }

      window.dataLayer.push({
        category: 'supply entrust',
        action: 'submit Entrust form',
        label: values.email
      });

      setLoading(true);

      const { email: connect } = values;

      try {
        const res = await Post('/set/agent', {
          data: { connect }
        });
        if (res.code === 2010) {
          setSubmitted(true);
          fetchData();
          form.resetFields();
        }
      } catch (error) {
        console.log('handleSubmit', error);
      }

      setLoading(false);
    });
  }

  function handleOpen() {
    window.dataLayer.push({
      category: 'supply entrust',
      action: 'open Entrust form'
    });
    setVisible(true);
  }

  const { getFieldDecorator } = form;

  return (
    <div className={styles.wrap}>
      <Spin spinning={state.loading}>
        <h3>{intl.get('setting-supply.title')}</h3>
        <p>{intl.getHTML('setting-supply.content')}</p>
        <div className={styles.footer}>
          <Button onClick={handleOpen} type="primary" disabled={state.data}>
            {state.data
              ? intl.get('setting-supply.activated')
              : intl.get('setting-supply.activate')}
          </Button>
          {state.data ? (
            <div className={styles.help_link}>
              <a
                href="https://www.blog.dserspro.com/post/introduction-to-dsers-supply-service-entrust"
                target="_blank"
              >
                {intl.get('setting-supply.sse_agreement')}
              </a>
            </div>
          ) : null}
        </div>
      </Spin>
      <Modal
        visible={visible}
        footer={null}
        width={580}
        maskClosable={false}
        keyboard={false}
        onCancel={handleCancel}
        bodyStyle={{ padding: '36px 36px' }}
        destroyOnClose
      >
        {submitted ? (
          <SuccessFeedback>
            <h2>{intl.get('setting-supply.feedback_title')}</h2>
            <p className={styles.content}>
              {intl.getHTML('setting-supply.feedback_con')}
            </p>
          </SuccessFeedback>
        ) : (
          <>
            <h2>{intl.get('setting-supply.modal_title')}</h2>
            <p>{intl.get('setting-supply.modal_intro')}</p>
            <Form onSubmit={handleSubmit} layout="vertical">
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [
                    { required: true, message: 'required' },
                    {
                      pattern: emailRegExp,
                      message:
                        'Please fill in the correct format of email address.'
                    },
                    {
                      max: 100,
                      message: 'Please fill in maximum 100 characters.'
                    }
                  ]
                })(
                  <Input
                    size="large"
                    placeholder="Input your contact email"
                    type="email"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('agreement', {
                  valuePropName: 'checked',
                  initialValue: true,
                  rules: [{ required: true, message: 'required' }]
                })(
                  <Checkbox>
                    <span className={styles.agreement}>
                      {intl.getHTML('setting-supply.modal_agreement')}
                    </span>
                  </Checkbox>
                )}
              </Form.Item>
              <div className={styles.footer}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors()}
                  loading={loading}
                >
                  {intl.get('common.submit')}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Modal>
    </div>
  );
}

export default Form.create()(React.memo(Supply));
