import { emailRegExp } from 'utils/constants';
import intl from 'react-intl-universal';
export const payConfig = [
    {
        name:intl.get("setting.payment.top_form_item1") ,
        key: 'first_name',
        width: 22,
        value: "",
        placeHolder:intl.get("setting.payment.top_form_item1"),
        type: 'input',
        rules: [],
        max: 50
    }, {
        name:intl.get("setting.payment.top_form_item2") ,
        key: 'last_name',
        width: 24,
        placeHolder:intl.get("setting.payment.top_form_item2"),
        type: 'input',
        rules: [],
        max: 50
    }, {
        name:intl.get("setting.payment.top_form_item3") ,
        key: 'business_name',
        width: 24,
        placeHolder: "your company name, etc.",
        type: 'input',
        rules: [],
        max: 50
    }, {
        name:intl.get("setting.payment.top_form_item4") ,
        key: 'email',
        width: 24,
        type: 'input',
        rules: [
            { required: true, message: intl.get('register.input_your_email') },
            { pattern: emailRegExp, message: intl.get('register.input_your_email') }
        ],
    }, {
        name:intl.get("setting.payment.top_form_item5") ,
        key: 'country',
        width: 24,
        placeHolder: "Select a country",
        rule: [],
        type: 'select',
        rules: []
    }, {
        name:intl.get("setting.payment.top_form_item6") ,
        key: 'address',
        width: 24,
        placeHolder: intl.get("setting.payment.top_form_item6"),
        type: 'input',
        rules: [{ pattern: '[a-zA-Z]', message: 'Address must contain letters' }],
        max:200
    }, {
        name:intl.get("setting.payment.top_form_item7") ,
        key: 'apartment',
        width: 24,
        placeHolder:intl.get("setting.payment.top_form_item7") ,
        type: 'input',
        rules: [],
        max: 200
    }, {
        name:intl.get("setting.payment.top_form_item8") ,
        width: 24,
        key: 'city',
        placeHolder: intl.get("setting.payment.top_form_item8") ,
        type: 'input',
        max: 50,
        rules: [{ pattern: '[a-zA-Z]', message: 'City must contain letters' }],
    }, {
        name:intl.get("setting.payment.top_form_item9") ,
        width: 24,
        key: 'state',
        placeHolder: "",
        max: 50,
        rules: [{ pattern: '[a-zA-Z]', message: 'State must contain letters' }],
        type: 'input',
    }, {
        name:intl.get("setting.payment.top_form_item10") ,
        width: 24,
        key: 'zip_code',
        type: 'input',
        rules: [],
        max: 50
    }]