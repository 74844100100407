import React, { Component } from 'react';

import { connect } from 'dva';

import intl from 'react-intl-universal';

import classnames from 'classnames';

import { Collapse, message, Tooltip, Modal, Button } from 'antd';

import { Get, Delete } from 'utils/request';

import add from 'assets/setting/staffadd.png';

import WorkingArea from './WorkingArea';

import styles from './index.less';

const { Panel } = Collapse;
@connect(({ login, global }) => ({
  userInfo: login.userInfo,
  agencyManagementData: global.agencyManagementData,
  worldTelephoneAreaCode: global.worldTelephoneAreaCode
}))
class AgencyManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workingAreaData: [],
      erpData: [],
      delAgency: {},
      delete_model: false,
      delete_item: {},
      delete_index: null
    };
  }

  componentDidMount() {
    this.getAgencyList();
    this.getPredefineAgencies();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const flag = [{}, ...newProps.agencyManagementData];

    this.setState({
      workingAreaData: flag
    });
  }

  getAgencyList = async () => {
    const { agencyManagementData } = this.props;

    const flag = [{}, ...agencyManagementData];

    this.setState({
      workingAreaData: flag
    });
  };

  getPredefineAgencies = async () => {
    try {
      const result = await Get('/set/predefine/agencies');
      if (result.code === 2000) {
        this.setState({
          erpData: result.data?.filter(item=>item.agency_id == '104366433' || item.agency_id == '125729653')
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  addData = () => {
    this.setState({
      workingAreaData: [{}, ...this.state.workingAreaData]
    });
  };

  delData = async (e) => {
    e.stopPropagation();
    const { dispatch} = this.props;
    const {delete_item, delete_index} = this.state;
    console.log(delete_item, delete_index,"456123");
   

    this.setState({
      delAgency: { [delete_index]: true },
      delete_model: false,
    });

    if (delete_item?.id) {
      try {
        const result = await Delete('/set/agency', {
          data: {
            id: delete_item.id
          }
        });
        if (result.code === 2000) {
          // message.success('success');
          dispatch({ type: 'global/getAgencyList' });
        }
        this.setState({
          delAgency: { [delete_index]: false },
          // delete_model: true
        });
      } catch (error) {
        this.setState({
          delAgency: { [delete_index]: false },
          // delete_model: true
        });
        console.log(error);
      }
    }
  };
  headerContent = (item, index, delAgency) => {
    const delAgencyIndex = delAgency[index];

    return (
      <div
        className={classnames(styles.headerBox, {
          [styles.headerBoxOne]: index === 0
        })}
      >
        {index === 0 ? (
            <div className={styles.name}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ display: 'inline-block', marginRight: '8px' }}>
                  <img src={add} alt="" />
                </span>
                Add agency information
                <Tooltip
                  getPopupContainer={p => p.parentNode || document.body}
                  title={
                    <span
                      onClickCapture={e => {
                        e.stopPropagation();
                      }}
                    >
                      After clicking the Save button, we will send an email to
                      your supplier within 5 minutes, so the supplier can
                      configure relevant data such as product price and shipping
                      cost in the email. After successful configuration, DSers
                      can calculate the purchase price for you when you place an
                      order to further simplify your purchasing process. If the
                      supplier does not configure information such as product
                      prices in the table, you can still use the original
                      features. If you want to disconnect the DSers account from
                      the supplier, just delete the agency information. Click{' '}
                      <a
                        target="_blank"
                        className="ds-link"
                        href="https://help.dsers.com/add-an-agency/"
                      >
                        here
                      </a>{' '}
                      to learn more.
                    </span>
                  }
                >
                  <i
                    style={{ fontSize: 18, marginLeft: 8, marginTop: 2 }}
                    className="material-icons"
                  >
                    help_outline
                  </i>
                </Tooltip>
              </div>
              <div>
                <i className="material-icons notranslate">add</i>
              </div>
            </div>
        ) : (
          <>
            <div className={styles.name}>
              {item?.agency_name || intl.get('setting.agency.name')}
            </div>
            <div className={styles.operation}>
              <div className={styles.del}>
                {item?.id ? (
                  <>
                    {delAgencyIndex ? (
                      <i className={classnames('material-icons', styles.loop)}>
                        loop
                      </i>
                    ) : (
                      <i
                        className="material-icons"
                        // onClick={e => this.delData(e,item,index)}
                        onClick={e => {
                          console.log(e,item,index,"123");
                          e.stopPropagation();
                          setTimeout(()=>{
                            this.setState({
                              delete_model: true,
                              delete_item: item,
                              delete_index: index
                          });
                          })
                          
                        }}
                      >
                        delete
                      </i>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  render() {
    const { workingAreaData, erpData, delAgency, delete_model } = this.state;
    const { worldTelephoneAreaCode, dispatch } = this.props;

    console.log(workingAreaData);

    return (
      <div className={styles.content}>
        <Modal
          visible={delete_model}
          title={intl.get('setting.agency.deletetitle')}
          open={() => {
            this.setState({ delete_model: true });
          }}
          onCancel={() => {
            this.setState({ delete_model: false });
          }}
          footer={
            <Button type="primary" onClick={e => this.delData(e)}>
              OK
              {/* {intl.get('mapping.verified.delete')} */}
            </Button>
          }
        >
          {intl.get('setting.agency.deletetext')}
        </Modal>

        <Collapse bordered={false} destroyInactivePanel>
          {workingAreaData?.map((item, index) => (
            <Panel
              header={this.headerContent(item, index, delAgency)}
              key={item?.id ? item.id : 9999999999999}
            >
              <WorkingArea
                item={item}
                index={index}
                key={item.id || 9999999999999}
                worldTelephoneAreaCode={worldTelephoneAreaCode}
                erpData={erpData}
                dispatch={dispatch}
                workingAreaData={workingAreaData}
              />
            </Panel>
          ))}
        </Collapse>
      </div>
    );
  }
}

export { AgencyManagement };
