import React, { Component } from 'react';
import { connect } from 'dva';
import { Button, Select, message, Checkbox, InputNumber, Radio } from 'antd';
import intl from 'react-intl-universal';

import { getPriceCheck, setPriceSubmit } from '../../../../../services/productmy';
import styles from './Index.less';
import { CommonDataCollect } from 'utils/utils';

const { Option } = Select;
const  CheckboxGroup = Checkbox.Group;

const PriceFluctuationOptions = [intl.get('setting.priceUpdate.increase'),intl.get('setting.priceUpdate.decrease')];

const PriceFluctuation = {
  1: [intl.get('setting.priceUpdate.increase'), intl.get('setting.priceUpdate.decrease')],
  2: [intl.get('setting.priceUpdate.increase')],
  3: [intl.get('setting.priceUpdate.decrease')]
}
import { GuildeAction, LinkStoreAction } from 'actions'
export default class PriceUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceChecked: 1,
      disabledStatus: false,
      fluctuationList: [intl.get('setting.priceUpdate.increase'), intl.get('setting.priceUpdate.decrease')],
      priceNumber: 'number',
      priceNum: 1,
      disSave: true,
    };
  }
  
  componentDidMount(){
    this.getDefaultData();
    this.props.onRef(this);
  }

  refreshStatusHandler(){
   this.getDefaultData() 
  }

  /**
   * 获取默认数据和情况
   * @returns 
   */
  getDefaultData = () => {
    getPriceCheck().then(res => {
      if(res.code === 2000 && res.data){
        const { data } = res;
        this.setState({
          // setting 同步价格 功能是否置灰
          disabledStatus: data?.setting_bool,
          // 默认选择 的那个选项
          priceChecked: this.props.orderType == 1 ? 1 : data?.type,
          // 是否选择全部更新。
          priceAllChange: data?.price_threshold_switch,
          priceNum: data?.price_threshold,
          fluctuationList: PriceFluctuation[data?.strategy],
          priceNumber: data?.price_threshold_switch ? 'any' : 'number'
        })
      }
    })
  }
    

  fluctuationChange = (e) => {
    const { fluctuationChange } = this.state;

    if(e.length === 0){
      message.error(intl.get('setting.priceUpdate.check_error'))
      return;
    }

    this.setState({
      fluctuationList: e,
      disSave: false
    })
  }

  priceChange = (e) => {
    if ([1,12,13,14,15,16,17,4,8,18,19,20].includes(this.props.orderType)) {
      CommonDataCollect({
        action: 'Autoupdate-price-top',
        event_type:'overlimit_pop'        
      });
      // window.EVENT.emit(GuildeAction.TurnPlan_Pop,`Autoupdate-price&${Date.now()}`, true); 
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'AutoupdatePrice')
      return
  }
    this.setState({
      priceChecked: e,
      disSave: false
    })
  }

  numberChange = (e) => {
    this.setState({
      priceNumber: e.target.value,
      disSave: false
    })
  }

  savePrice = () => {
    const {priceChecked, priceNum, fluctuationList, priceNumber} = this.state;

    if((priceNum == 0 || priceNum == null) && priceChecked !== 1 && priceNumber !== 'any'){
      message.error(intl.get('setting.priceUpdate.range'));
      return;
    }

    let strategy = 1;

    if(fluctuationList.includes(intl.get('setting.priceUpdate.increase'))) strategy = 2;

    if(fluctuationList.includes(intl.get('setting.priceUpdate.decrease'))) strategy = 3;

    if(fluctuationList.length == 2) strategy = 1;

    setPriceSubmit({data: {
      type: priceChecked,
      strategy: strategy, 
      price_threshold: priceNum,
      price_threshold_switch: priceNumber === 'any' ? true : false,
    }
    }).then(res => {
      if(res.code === 2000){
        this.setState({
          disSave: true
        })
        message.success('SUCCESS');
        return;
      }
    })
  }

  priceNumChange = (e) => {
    this.setState({
      priceNum: e,
      disSave: false
    })
  }

  render() {
    const { saveNotifyAutoLoading, userInfo } = this.props;

    const {
      disabledStatus,
      priceChecked,
      fluctuationList,
      priceNumber,
      priceNum,
      disSave
    } = this.state;
    
    return (
      <div className={styles.priceUpdateBox}>
        <div >
        {!disabledStatus && (
          <p style={{color:'#FF8F00'}} className={styles.noRuleTip}>{intl.get('setting.priceUpdate.note')}</p>
        )}
        </div>
        <div className={styles.priceUpdateCont}>
          <div className={styles.priceUpdateInventory}>
            <h5>{intl.get('setting.priceUpdate.supplier')}</h5>
            <Select
              size="small"
              value={priceChecked}
              onChange={this.priceChange}
              suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              disabled={!disabledStatus}
            >
              <Option key="a" value={1}>
                {intl.get('setting.priceUpdate.do_nothing')}
              </Option>
              <Option key="b" title={intl.get('setting.priceUpdate.updateCon')} value={2}>
                {intl.get('setting.priceUpdate.updateCon')}
              </Option>
              <Option key="c" value={3}>
                {intl.get('setting.priceUpdate.to_shopify')}
              </Option>
            </Select>
          </div>
          <div className={styles.priceUpdateInventory}>
            <h5>{intl.get('setting.priceUpdate.suppliers_price')}</h5>
            <CheckboxGroup
              options={PriceFluctuationOptions}
              value={fluctuationList}
              onChange={this.fluctuationChange}
              disabled={priceChecked === 1 || !disabledStatus}
            />
          </div>
          <div className={styles.priceUpdateInventory1}>
            <h5>{intl.get('setting.priceUpdate.pricing_change')}</h5>
            <Radio.Group 
              defaultValue="number"
              buttonStyle="solid"
              onChange={this.numberChange}
              value={priceNumber}
              disabled={priceChecked === 1 || !disabledStatus}
            >
              <div style={{margin:'15px 0'}}>

              <Radio value="number">
                <InputNumber
                  defaultValue={1}
                  precision={2}
                  max={9999999999}
                  disabled={priceNumber === 'any' || priceChecked === 1}
                  formatter={value => `${value}%`.replace("/^\d+(\.\d{0,2})?$/", '')}
                  parser={value => value.replace('%','')}
                  onChange={this.priceNumChange}
                  value={Number(priceNum)}
                />
              </Radio>
              </div>
              <div >
              <Radio value="any">
              {intl.get('setting.priceUpdate.anychange')}
              </Radio>

              </div>
            </Radio.Group>
          </div>
          
          <div className={styles.UpdatesText}>
            <Button
              type="primary"
              loading={saveNotifyAutoLoading}
              className={styles.saveBtn}
              disabled={!disabledStatus || disSave}
              onClick={this.savePrice}
              data="dx"
            >
              {intl.get('public.save')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
