import React, { useState, useCallback } from 'react';
import { Table, Divider, Popconfirm, Form, Button, DatePicker } from 'antd';

import styles from './SpendOtherManage.less';

const data = [
  {
    key: '1',
    name: 'John Brown'
  },
  {
    key: '2',
    name: 'Jim Green'
  }
];

function SpendOtherManage() {
  const [visible, setVisible] = useState(false);

  const edit = record => {
    //
  };

  function deleteItem(key) {
    //
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      // width: '30%'
    },
    {
      title: 'intro',
      dataIndex: 'intro',
      // width: '50%',
      // eslint-disable-next-line
      render: () => <span style={{ color: 'rgba(0,0,0,.25)', fontSize: 12 }}>Click Edit to add the cost for each SKU</span>
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      // width: '20%',
      // eslint-disable-next-line
      render: (_, record) => {
        return (
          <span>
            <a
              role="button"
              tab-index="0"
              onClick={() => edit(record.key)}
            >
              Edit
            </a>
            <Divider type="vertical" />
            <Popconfirm title="Sure to cancel?" onConfirm={deleteItem}>
              <a>Delete</a>
            </Popconfirm>
          </span>
        );
      }
    }
  ];

  return (
    <div className={styles.wrap}>
      <h3>Manage Non-AliExpress product spendings</h3>
      <div className={styles.header}>
        <Button type="link" icon="plus" onClick={() => setVisible(true)}>
          Add Shopify Product
        </Button>
      </div>
      <div>
        <Table
          title={() => 'Shopify Product'}
          showHeader={false}
          rowKey={record => record.key}
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
          size="small"
        />
      </div>
    </div>
  );
}

export default SpendOtherManage;
