import React from 'react';

import { Icon, Select, Tooltip, InputNumber, Switch } from 'antd';
import intl from 'react-intl-universal';

import pricingCost from '../../../../../assets/setting/pricingCost.png';
import styles from './pricing.less';

const { Option } = Select;

const BasicRule = ({
  index,
  pricingItem,
  changePricingPattern,
  changePricingPrice,
  pricingComparedShow
}) => {
  return (
    <>
      {/* <div className={styles.more}>
        {intl.getHTML('setting.pricing.basic_more')}
      </div> */}
      <div className={styles.pricingCont1}>
        <div className={styles.pricingCont1_content}>
          <span>{intl.get('setting.pricing.pricing_cost')}</span>
          <Select
            size="small"
            value={pricingItem.default_pricing.pattern}
            suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
            onChange={value => {
              changePricingPattern(value, index, 1)
            }}
          >
            <Option key="1" value="add">
              <Icon type="plus" />
            </Option>
            <Option key="2" value="mul">
              <Icon type="close" />
            </Option>
          </Select>
          <InputNumber
            min={0}
            precision={2}
            style={
              pricingItem.default_pricing.price ||
              pricingItem.default_pricing.price == 0
                ? {}
                : { borderColor: '#f5222d' }
            }
            value={pricingItem.default_pricing.price}
            onChange={value => {
              changePricingPrice(value, index, 1);
            }}
            className={styles.NumInput}
          />
          <p style={{marginLeft:10}}>
            = {intl.get('setting.pricing.price')}
            <Tooltip title={intl.get('setting.pricing.price_tip')}>
              <Icon type="info-circle" />
            </Tooltip>
          </p>
        </div>
      </div>
      <div className={styles.pricingCont1}>
        {pricingItem.default_pricing.compared_status ? (
          <div className={styles.pricingCont1_content}>
            <span>{intl.get('setting.pricing.pricing_cost')}</span>
            <Select
              size="small"
              value={pricingItem.default_pricing.compared_pattern}
              suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
              onChange={value => {
                changePricingPattern(value, index, 2);
              }}
            >
              <Option key="1" value="add">
                <Icon type="plus" />
              </Option>
              <Option key="2" value="mul">
                <Icon type="close" />
              </Option>
            </Select>
            <InputNumber
              min={0}
              precision={2}
              style={
                pricingItem.default_pricing.compared_price ||
                pricingItem.default_pricing.compared_price == 0
                  ? {}
                  : { borderColor: '#f5222d' }
              }
              value={pricingItem.default_pricing.compared_price}
              onChange={value => {
                changePricingPrice(value, index, 2);
              }}
              className={styles.NumInput}
            />
            <p style={{marginLeft:10}}>
              ={' '} {intl.get('setting.pricing.compared_at_price')}
              <Tooltip
                title={intl.getHTML('setting.pricing.compared_at_price_tip')}
              >
                <Icon type="info-circle" />
              </Tooltip>
            </p>
          </div>
        ) : (
          <div className={styles.pricingCont1_content}>{intl.get('setting.pricing.set_rules')}</div>
        )}
        <div className={styles.pricingCont1_Title}>
          <Switch
            checked={pricingItem.default_pricing.compared_status}
            onChange={checked => {
              pricingComparedShow(checked, index);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default BasicRule;
