import React, { useState, useEffect } from 'react';
import { connect } from 'dva';
import { Select, Modal, Input, Button } from 'antd';
import intl from 'react-intl-universal';
import moment from 'moment-timezone';

import { confirmLanguage } from '../../../../../common/sysconfig';
import { getLanguage } from '../../../../../utils/utils';
import timezone from '../../../../../common/timezone';
import Title from '../../Title'

import styles from './TimeZone.less';

const { Option } = Select;

const with100 = { width: '100%' };
const currentLanguage = getLanguage();

const timezoneLabel = value => {
  const tmp = timezone.find(i => i.value === value);
  return tmp && tmp.label;
};

function TimeZone(props) {
  const [showSave, setShowSave] = useState(false);
  const [disableOk, setDisabled] = useState(true);
  const [selectValue, setSelectValue] = useState(undefined);
  const { okLoading, settingTimeZone, timezoneRegion } = props;

  useEffect(() => {
    setSelectValue(timezoneRegion ? timezoneLabel(timezoneRegion) : moment.tz.guess());
  }, [timezoneRegion]);

  function inputConfirm(e) {
    setDisabled(e.target.value.trim().toLocaleLowerCase() === confirmLanguage[currentLanguage] ? false : true);
  }

  function handleSave() {
    props.dispatch({
      type: 'dropshippersetting/timeZone',
      payload: {
        data: {
          time_zone: timezoneLabel(selectValue)
        },
        callback: d => {
          if (d.code === 2000) {
            setShowSave(false);
            props.dispatch({
              type: 'global/timezone'
            });
          }
        }
      }
    });
  }

  return (
    <>
    <Title title={intl.get('setting.title_time_zone')} description={intl.get('setting.time_zone_des')}/>
    <div className={styles.wrap}>
     
      <div className={styles.select}>
        <Select
          style={with100}
          disabled={!!settingTimeZone}
          value={selectValue}
          onSelect={setSelectValue}
          getPopupContainer={triggerNode => triggerNode.parentNode}
          suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
        >
          {timezone.map(item => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            );
          })}
        </Select>
      </div>
      <div className={styles.saveLine}>
        <Button
          type="primary"
          disabled={!!settingTimeZone}
          onClick={() => setShowSave(!showSave)}
          style={{textTransform:'uppercase'}}
          className={styles.saveBtn}
        >
          {intl.get('public.save')}
        </Button>
      </div>
      <Modal
        width={536}
        closable={false}
        maskClosable={false}
        visible={showSave}
        footer={null}
        destroyOnClose
      >
        <h3 className={styles.confirmTitle}>
          {intl.getHTML('setting.report.timezone.save_modal_desc')}
        </h3>
        <p className={styles.confirmTip}>
          {intl.get('setting.report.timezone.save_modal_tip')}
        </p>
        <p className={styles.confirmInput}>
          <Input
            placeholder={intl.get('setting.report.currencies.input_confirm')}
            onChange={inputConfirm}
          />
        </p>
        <div className={styles.confirmButtonLine}>
          <Button
            disabled={disableOk}
            type="primary"
            loading={okLoading}
            onClick={handleSave}
          >
            {intl.get('public.save')}
          </Button>
          <Button
            type="default"
            onClick={() => {
              setShowSave(!showSave);
              setDisabled(true);
            }}
            data="bk"
          >
            {intl.get('public.cancel')}
          </Button>
        </div>
      </Modal>
    </div>
    </>
  );
}

export default connect(({ dropshippersetting, global, loading }) => ({
  code: dropshippersetting.paypalCheckCode,
  settingTimeZone: global.settingTimeZone,
  timezoneRegion: global.timezoneRegion,
  okLoading: loading.effects['dropshippersetting/saveCurrency']
}))(TimeZone);
