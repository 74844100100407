import React, { useEffect, useState, useRef, memo } from 'react';
import intl from 'react-intl-universal';

import {
  Input,
  Select,
  InputNumber,
  Collapse,
  Button,
  Tooltip,
  Icon,
  message
} from 'antd';
const { Option } = Select;
const { Panel } = Collapse;

import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { Post, Get } from 'utils/request';

import styles from '../Dropshpper/List.less';
import './orderSplit.css';

const OrderSplit = ({ countrys, orderHidden,orderVal,setOrderSplitList }) => {
  const [list, setList] = useState([]);

  const [btnDisabled, setBtnDisabled] = useState(true);

  const refList = useRef(list);

  let propsEvent = {
    onInputChange: (id, value) => {
      onInputChange(id, value);
    },
    onInputNumberChange: (id, value) => {
      onInputNumberChange(id, value);
    },
    onSelectCountrys: (id, value) => {
      onSelectCountrys(id, value);
    }
  };

  useEffect(() => {
    getOrderSplitList();
  }, [orderVal]);

  const getOrderSplitList = async () => {
   let data =
       orderVal?.map(item => {
          return {
            kid: Math.random(),
            aliId: item.ali_url,
            aliNum: item.ali_num,
            countrysList: item.country_list,
            occurredAt: item.occurred_at,
            ...propsEvent
          };
        }) || [];

      if (data.length != 10) {
        data.push({
          kid: Math.random(),
          aliId: '',
          aliNum: '',
          countrysList: [],
          occurredAt: Math.floor(+new Date() / 1000),
          ...propsEvent
        });
      }
      refList.current = data;
      setList(data);
      setBtnDisabled(true);
  };


  const editorList = (operation, id) => {
    if (operation === 1) {
      if (list.length >= 10) {
        return;
      }

      let item = [
        {
          kid: Math.random(),
          aliId: '',
          aliNum: '',
          countrysList: [],
          onInputChange: onInputChange,
          onInputNumberChange: onInputNumberChange,
          onSelectCountrys: onSelectCountrys
        }
      ];
      refList.current = [...list, ...item];
      setList(refList.current);
    } else {
      refList.current = list.filter(item => item.kid !== id);
      setList(refList.current);
      setBtnDisabled(false);
    }
  };

  const onInputChange = (id, value) => {
    const newList = refList.current.map(item => {
      if (item.kid == id) {
        return {
          ...item,
          aliId: value,
          occurredAt: Math.floor(+new Date() / 1000)
        };
      } else {
        return item;
      }
    });
    refList.current = newList;
    setList(refList.current);
    changeVal();
  };

  const onInputNumberChange = (id, value) => {
    const newList = refList.current.map(item => {
      if (item.kid == id) {
        return {
          ...item,
          aliNum: value,
          occurredAt: Math.floor(+new Date() / 1000)
        };
      } else {
        return item;
      }
    });
    refList.current = newList;
    setList(refList.current);
    changeVal();
  };

  const onSelectCountrys = (id, value) => {
    const newList = refList.current.map(item => {
      if (item.kid == id) {
        return {
          ...item,
          countrysList: value.length > 2 ? value.slice(0, 10) : value,
          occurredAt: Math.floor(+new Date() / 1000)
        };
      } else {
        return item;
      }
    });
    refList.current = newList;
    setList(refList.current);
    changeVal();
  };

  const changeVal = () => {
    let save = false;
    let data = refList.current;

    for (let i = 0; i < data.length; i++) {
      if (!data[i].aliId && !data[i].aliNum && !data[i].countrysList.length) {
        save = true;
        setBtnDisabled(false);
      } else {
        if (data[i].aliId && data[i].aliNum && data[i].countrysList.length) {
          save = true;
          setBtnDisabled(false);
        } else {
          save = false;
          setBtnDisabled(true);
          break;
        }
      }
    }

    return save;
  };
  
  // https://www.aliexpress.com/item/4001217083060.html....
  const submit = () => {
    let instalVal = false;

    let data = refList.current.map(item => {
      if (item.aliId && !item.aliId.includes('https://')) {
        return {
          ...item,
          aliId: `https://${item.aliId}`
        };
      } else {
        return item;
      }
    });

    if (changeVal()) {
      let result = [];

      data.map(item => {
        if (item.aliId && item.aliNum && item.countrysList.length) {
          //检测输入的url是否符合规则
          if (
            !/^https:\/\/\w+\.aliexpress\.com\/item\/.+\.html.*/.test(
              item.aliId
            )
          ) {
            instalVal = true;
          }
          result.push(item);
        }
      });

      if (instalVal) {
        message.error(intl.get('setting.order.incorrect'));
        return;
      }

      let responseData = result.map(item => {
        return {
          ali_url: item.aliId,
          ali_num: String(item.aliNum),
          country_list: item.countrysList,
          occurred_at: item.occurredAt
        };
      });
      setOrderSplitList(responseData)
    }
  };

  return (
    <div>
      <div className="orderSplitList">
        {!orderHidden ? <div className="zzc"></div> : null}
        <Collapse expandIconPosition="right" collapsible="disabled" defaultActiveKey={[0,1,2,3,4,5,6,7,8,9]}>
          {list.map((item, index) => {
            return (
              <Panel
                key={index}
                header={
                  <div className="headerItem">
                    <Input
                      value={item.aliId}
                      onClick={e => e.stopPropagation()}
                      onChange={e => {
                        item.onInputChange(item.kid, e.target.value);
                      }}
                      placeholder={intl.get('setting.order.input_placeholder')}
                    />
                    <div
                      className="editor"
                      onClick={e => {
                        e.stopPropagation();
                        editorList(list.length - 1 === index ? 1 : 0, item.kid);
                      }}
                    >
                      {list.length - 1 === index ? (
                        <i class="material-icons">add</i>
                      ) : (
                        <i class="material-icons">delete</i>
                      )}
                    </div>
                  </div>
                }
                collapsible="disabled"
              >
                <RenderDom orderHidden={orderHidden} countrys={countrys} item={item} />
              </Panel>
            );
          })}
        </Collapse>

        <div className="aniu" style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            disabled={btnDisabled}
            data="dx"
            onClick={submit}
          >
            SAVE
          </Button>
        </div>
      </div>
    </div>
  );
};

const RenderDom = memo(({ countrys, item,orderHidden }) => {
  return (
    <div className="orderSplitBox">
      <div className="aliNum">
        <span>
          {intl.get('setting.order.delivery_period')}
          <Tooltip
            title={intl.get('setting.order.delivery_period_tip')}
          >
            <Icon
              style={{ marginLeft: 5 }}
              type="question-circle"
              theme="outlined"
            />
          </Tooltip>
        </span>
        <InputNumber
          style={{ width:80 }}
          disabled={!orderHidden}
          min={2}
          max={10}
          value={item.aliNum}
          onChange={value => {
            item.onInputNumberChange(item.kid, value);
          }}
        />
      </div>
      <div className="countrys">
        <span>{intl.get('setting.choose_country_placeholder')}</span>
        <Select
          style={{ width: 336 }}
          getPopupContainer={triggerNode => triggerNode.parentNode}
          mode="multiple"
          className={styles.syncbordercolor}
          disabled={!orderHidden}
          onChange={value => {
            item.onSelectCountrys(item.kid, value);
          }}
          suffixIcon={
            <img
                src={require('../../../assets/newImportList/icon_expand_more-24px.png')}
              ></img>
          }
          placeholder={intl.get('setting.choose_country_placeholder')}
          value={item.countrysList}
          maxTagTextLength={100}
          showArrow={true}
          showSearch={false}
          max={2}
        >
          {countrys.map((item, index) => {
            return <Option key={item.Code}>{item.Name}</Option>;
          })}
        </Select>
      </div>
    </div>
  );
});

export default memo(OrderSplit);
