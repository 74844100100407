import React, { Component } from 'react';
import { connect } from 'dva';
import {
  Button,
  Select,
  Checkbox,
  Switch,
  InputNumber,
  Collapse,
  Modal,
  Radio,
  Tooltip,
  Input,
  message
} from 'antd';

import Title from '../../Title'

import cloneDeep from 'lodash/cloneDeep';
import intl from 'react-intl-universal';

import styles from './pricing.less';
import styleList from '../../List.less';
import { getLanguage } from '../../../../../utils/utils';
import {confirmLanguage} from '../../../../../common/sysconfig';

import AdvancedModel from './AdvancedModel';
import BasicRule from './BasicRule';
import StandardRule from './StandardRule';
import loadingIcon from 'assets/setting/loadingIcon.png'
import _ from 'lodash'
import { GuildeAction, LinkStoreAction } from 'actions'
import { CommonDataCollect } from 'utils/utils';
const { Option } = Select;
const { Panel } = Collapse;

const paramMap = {
  1: 'profit',
  2: 'fix_profit',
  3: 'minimum_profit',
  4: 'breakeven',
  5: 'compared_price'
};
@connect(({ dropshippersetting, loading, login, user, global }) => ({
  dropshippersetting,
  userInfo: login.userInfo,
  loading: loading.models.dropshippersetting,
  switchLoading: loading.effects['dropshippersetting/changePricingStatus'],
  paymentPlan: user.plan,
  newUserInfo:user.newUserInfo,
  unsupportedShipping: global.unsupportedShipping,
  updataPriceStatus:dropshippersetting.storyPriceStatus,
  customizeCur:login.customizeCurrency
}))
export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PricingList: [],
      AssignCentsData: {},
      assignCentsDisabled: true,
      pricingBtnDisabled: [], // 每个店铺是否显示保存
      AdvancedErr: false,
      AdvancedErrTxt1: [],
      AdvancedErrTxt2: [],
      activeKey: [],
      ProErrTxt1: [],
      ProErrTxt2: [],
      showUpdataPrcie:false,
      clickStoreId:'',
      allStoryId2:'',
      disabledStatus:false,
      storyIfUse:[],    //所有店铺列表
      showConnect:false, 
      selectStory:[],   //所选更新店铺列表
      buttonLoading:false,
      isCustomizeUser:false
      // supportTrack: [],
    };
  }
  checked = {  
    basic:false,
    standard:false,
    advanced:false
  }


  componentDidMount() {
    this.setState({
      isCustomizeUser:(this.props.newUserInfo?.org_id || this.props.newUserInfo?.user_id ) === this.props.customizeCur.id
    })
    this.getPricingList();
    this.getPricingCurrency();
    this.getStoryIfuse();
  }

  componentWillUnmount(){
    clearInterval(this.interval)
  }

  getPricingCurrency = () => {
    this.props.dispatch({
      type: 'dropshippersetting/pricingCurrencyChecked'
    });
  };

  getStoryIfuse=()=>{
    const that=this
    this.props.dispatch({
      type:'guidance/getInfo',
      payload:{
        callback:d=>{      
          if(d.code === 2000){
            that.setState({
              storyIfUse:d.data.stores
            })
          }
        }
      }
    })
  }

  //店铺状态轮询
  getStoreStatus=(list)=>{
    let allStoryId=''
    list.forEach(item=>{
      allStoryId+=item.store_id+","
    })
    allStoryId=allStoryId.substring(0,allStoryId.length-1)
    this.setState({
      allStoryId2:allStoryId
    })
    this.getStatus(allStoryId)
    const that=this
    this.interval = setInterval(()=>{
      this.getStatus(allStoryId)
    },60000)
  }

  getStatus=(storyid)=>{
    this.props.dispatch({
      type:'dropshippersetting/updataPricing',
      payload:{
        data:{
          store_ids:storyid
        }  
      }
    }) 
  }

  // 获取数据
  getPricingList = () => {

    const {
      userInfo: { stores },
      dropshippersetting: { currencyChecked, countrys, logistics_services,logistics_services_tm },
      // paymentPlan: { type, status }
    } = this.props;
    const { type = 0, status } = JSON.parse(localStorage.getItem('payment'))
    const {
      PricingList,
      AssignCentsData,
      assignCentsDisabled,
      pricingBtnDisabled,
      AdvancedErrTxt1,
      AdvancedErrTxt2,
      ProErrTxt1,
      ProErrTxt2,
      activeKey,
      showModal,
      showUpdataPrcie,
      showConnect,
      clickStoreId,
      storyIfUse,
      selectStory,
      buttonLoading
    } = this.state;


    this.props.dispatch({
      type: 'dropshippersetting/getPricingList',
      payload: {
        callback: d => {
          if (d.code == 2000) {
            const PricingList = this.hasLessStore(d.data.plist) || [];
            PricingList?.forEach(i => {
              if (i.program) {
                i.pricing_rank = 1;
              }
            });
            const AssignCentsData = d.data.cents;
            const pricingBtnDisabled = [];

            const showModal = [];
            const AdvancedErrTxt1 = [];
            const AdvancedErrTxt2 = [];
            const advancedItem = [
              {
                min: '0',
                max: '10',
                pattern: 'add',
                price: '0',
                compared_pattern: 'add',
                compared_price: '0'
              },
              {
                min: '10.01',
                max: '',
                pattern: 'add',
                price: '0',
                compared_pattern: 'add',
                compared_price: '0'
              }
            ];

            const ProErrTxt1 = [];
            const ProErrTxt2 = [];
            const proItem = [
              {
                min: '0',
                max: '10',
                profit: '0',
                fix_profit: '0',
                minimum_profit: '0',
                is_shipping_cost: false,
                breakeven: '0',
                compared_pattern: 'add',
                compared_price: '0'
              },
              {
                min: '10.01',
                max: '',
                profit: '0',
                fix_profit: '0',
                minimum_profit: '0',
                is_shipping_cost: false,
                breakeven: '0',
                compared_pattern: 'add',
                compared_price: '0'
              }
            ];

            PricingList?.forEach(item => {
              pricingBtnDisabled.push(true);
              showModal.push(false);
              AdvancedErrTxt1.push(false);
              AdvancedErrTxt2.push(false);
              ProErrTxt1.push(false);
              ProErrTxt2.push(false);
              item.default_pricing.pattern =
                item.default_pricing.pattern || 'add'; // 默认为加号
              item.default_pricing.compared_pattern =
                item.default_pricing.compared_pattern || 'add'; // 默认为加号
              item.advanced_pricing.advanced_rest.pattern =
                item.advanced_pricing.advanced_rest.pattern || 'add'; // 默认为加号
              item.advanced_pricing.advanced_rest.compared_pattern =
                item.advanced_pricing.advanced_rest.compared_pattern || 'add'; // 默认为加号

              // 判断standard 为空
              if (
                !item.advanced_pricing.advanced_item ||
                item.advanced_pricing.advanced_item.length == 0
              ) {
                item.advanced_pricing.advanced_item = [...advancedItem];
              } else if (item.advanced_pricing.advanced_item.length < 10) {
                // 判断standard 小于10，新加一个进去
                const advancedItem1 = {
                  min: `${Number(
                    item.advanced_pricing.advanced_item[
                      item.advanced_pricing.advanced_item.length - 1
                    ].max
                  ) + 0.01}`,
                  max: '',
                  pattern: 'add',
                  price: '0',
                  compared_pattern: 'add',
                  compared_price: '0'
                };
                item.advanced_pricing.advanced_item.push(advancedItem1);
              }

              // 判断advanced 为空
              if (
                !item.pro_pricing.pro_item ||
                item.pro_pricing.pro_item.length == 0
              ) {
                item.pro_pricing.pro_item = [...proItem];
              } else if (item.pro_pricing.pro_item.length < 10) {
                // 判断advanced 小于10，新加一个进去
                const proItem1 = {
                  min: `${Number(
                    item.pro_pricing.pro_item[
                      item.pro_pricing.pro_item.length - 1
                    ].max
                  ) + 0.01}`,
                  max: '',
                  profit: '0',
                  fix_profit: '0',
                  minimum_profit: '0',
                  is_shipping_cost: false,
                  breakeven: '0',
                  compared_pattern: 'add',
                  compared_price: '0'
                };
                item.pro_pricing.pro_item.push(proItem1);
              }
            });
            

            PricingList?.map((pricingItem, index) => {
              if ([1, 4].includes(type) || status !== 2) {
                if (!pricingItem.program && pricingItem.pricing_rank == 2) {
                  // 用户从advanced套餐降级 如果已选advanced则切换为 Basic
                  pricingItem.pricing_rank = 0;
                }
              }
      
              if (
                (!pricingItem.program && pricingItem.pricing_rank == 1) ||
                (pricingItem.program && pricingItem.pricing_rank == 2)
              ) {
                // 当standard和advanced都没有被选中的时候 默认展开 basic
                pricingItem.program = false;
                pricingItem.pricing_rank = 0;
              }
      
              const showBasic =
                !pricingItem.program && pricingItem.pricing_rank == 0;
              const showStandard =
                pricingItem.program && pricingItem.pricing_rank == 1;
              const showAdvanced =
                !pricingItem.program && pricingItem.pricing_rank == 2;
              

              if(showBasic){
                this.checked = {
                  basic:true,
                  standard:false,
                  advanced:false
                }
              }
              if(showStandard){
                this.checked = {
                  basic:false,
                  standard:true,
                  advanced:false
                }
              }
              if(showAdvanced){
                this.checked = {
                  basic:false,
                  standard:false,
                  advanced:true
                }
              }
            })

            this.setState({
              PricingList,
              AssignCentsData,
              pricingBtnDisabled,
              showModal,
              AdvancedErrTxt1,
              AdvancedErrTxt2,
              ProErrTxt1,
              ProErrTxt2
            });
            // this.getStoreStatus(PricingList);
          }
        }
      }
    });
  };

  // 有没有少店铺
  hasLessStore = (data = []) => {
    const stores = this.props.userInfo.stores || [];
    const list = data?.filter(item =>
      stores.some(
        store => store.id == item.store_id && [1, 2].includes(store.status)
      )
    );

    stores.forEach(store => {
      const flag = list?.some(item => item.store_id == store.id);

      if (!flag && [1, 2].includes(store.status)) {
        list?.push({
          store_id: store.id,
          store_name: store.shop_name,
          status: false,
          program: false,
          default_pricing: {
            compared_status: true,
            pattern: 'add',
            price: '0',
            compared_pattern: 'add',
            compared_price: '0'
          },
          advanced_pricing: {
            compared_status: true,
            advanced_item: [],
            advanced_rest: {
              pattern: '',
              price: '0',
              compared_pattern: '',
              compared_price: '0'
            }
          },

          pro_pricing: {
            pro_item: [],
            pro_rest: {
              profit: '0',
              fix_profit: '0',
              minimum_profit: '0',
              is_shipping_cost: false,
              breakeven: '0'
            }
          },
          pricing_cents: {
            price_status: false,
            price_cents: '0',
            compared_status: false,
            compared_cents: '0'
          }
        });
      }
    });

    return list;
  };

  // Basic大开关
  BasicShow = (checked, index) => {
    if (!checked) {
      return;
    }
    let { PricingList, pricingBtnDisabled } = this.state;
    PricingList[index].program = !checked;
    PricingList[index].pricing_rank = 0;
    pricingBtnDisabled[index] = false;

    PricingList = [...PricingList];
    pricingBtnDisabled=[...pricingBtnDisabled]

    this.setState({
      PricingList,
      pricingBtnDisabled
    });
  };

  // Standard 大开关
  AdvancedShow = (checked, index) => {
    if (!checked) {
      return;
    }
    let { PricingList, pricingBtnDisabled } = this.state;
    PricingList[index].program = checked;
    PricingList[index].pricing_rank = 1;
    pricingBtnDisabled[index] = false;

    PricingList = [...PricingList];
    pricingBtnDisabled = [...pricingBtnDisabled];
    this.setState({
      PricingList,
      pricingBtnDisabled
    },() => {
      
    });
  };

  // Advanced 大开关
  ProShow = (checked, index,disabledStatus) => {
    // if (disabledStatus) {
    //   return
    // }
    // const { type, status } = this.props.paymentPlan;
    const { type, status } = JSON.parse(localStorage.getItem('payment'))
    if ([1, 4, 10].includes(type) || status !== 2) {
            //  跳转订阅 && 弹窗拦截
            CommonDataCollect({
              action: 'Advanced-PR-top',
              event_type:'overlimit_pop'
            });
            window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Advanced-PR&${Date.now()}`, true);
            //  self.location.href = '/app/pricing?select=1';
    } else {
      if (!checked) {
        return;
      }
      let { showModal, PricingList,pricingBtnDisabled } = this.state;
      showModal[index] = true;
      PricingList[index].program = !checked;
      PricingList[index].pricing_rank = 2;
      showModal = [...showModal];
      PricingList = [...PricingList];
      pricingBtnDisabled[index] = false;
      pricingBtnDisabled = [...pricingBtnDisabled];

      this.setState({
        PricingList,
        pricingBtnDisabled,
        showModal,
        disabledStatus
      },() => {
        
      });
    }
  };

  showCheckAd = (checked, index) => {
    let typeId = JSON.parse(localStorage.getItem('payment')).type;
    if (!checked) {
      return;
    }
    if([1,4,8,10].includes(typeId)){
      return
    }
    let { showModal, PricingList ,pricingBtnDisabled} = this.state;
    PricingList[index].program = !checked;
    PricingList[index].pricing_rank = 2;
    PricingList = [...PricingList];
    pricingBtnDisabled[index] = false;
    pricingBtnDisabled=[...pricingBtnDisabled]
    this.setState({
      PricingList,
      pricingBtnDisabled
    }, () => {
      
    });
  };

  checkType = () => {
    let typeId = JSON.parse(localStorage.getItem('payment')).type;
    if (typeId == 1 || typeId == 4 || typeId == 10) {
      window.localStorage.setItem('passivity', true)
          CommonDataCollect({
             action: 'Advanced-PR-top',
             event_type:'overlimit_pop'
            });
            //  跳转订阅 && 弹窗拦截
            // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Advanced-PR&${Date.now()}`, true);
            // window.location.href = '/app/pricing?select=1';
            window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'AdvancedPricingRule')
    }
  };

  // 点击advanced panel 显示advanced 弹窗
  handlePricingCollapse = (v, index) => {
    let { showModal, PricingList } = this.state;
    // const { type, status } = this.props.paymentPlan;
    const { type, status } = JSON.parse(localStorage.getItem('payment'))

    if ([1, 4].includes(type) || status !== 2) {
      window.location.href = '/app/pricing';
    } else if (PricingList[index].pricing_rank == 2) {
      showModal[index] = true;
    }
    showModal = [...showModal];
    this.setState({
      showModal
    });
  };

  // Advanced Compared Price 多选按钮
  ProComparedPriceShow = (e, index) => {
    let { PricingList } = this.state;
    PricingList[index].pro_pricing.compare_status = e.target.checked;

    PricingList = [...PricingList];
    this.setState({
      PricingList
    }, () => {
      
    });
  };

  changeProPattern = (value, index, ind,sta) => {
    let { PricingList } = this.state;

    PricingList[index].pro_pricing.pro_item[ind].compared_pattern = value;

    if(value == 'mul' && PricingList[index].pro_pricing.pro_item[ind][paramMap[sta]] == 0){
      PricingList[index].pro_pricing.pro_item[ind][paramMap[sta]] = 1
    }

    PricingList = [...PricingList];
    this.setState({
      PricingList
    }, () => {
      
    });
  };

  changeProRestPattern = (value, index,sta) => {
    let { PricingList } = this.state;
    PricingList[index].pro_pricing.pro_rest.compared_pattern = `${value}`;

    if(value == 'mul' && PricingList[index].pro_pricing.pro_rest[paramMap[sta]] == 0){
      PricingList[index].pro_pricing.pro_rest[paramMap[sta]] =1
    }

    PricingList = [...PricingList];
    this.setState({
      PricingList
    },() => {
      
    });
  };

  hideModal = index => {
    let { showModal } = this.state;
    showModal[index] = false;
    showModal = [...showModal];
    this.setState({
      showModal
    });
  };

  cancelAdvanced = index => {
    this.hideModal(index);
    // 可能有问题
    this.getPricingList();
  };

  // pricing + *
  changePricingPattern = (value, index, sta) => {
    let { PricingList, pricingBtnDisabled } = this.state;
    if (sta == 1) {
      PricingList[index].default_pricing.pattern = value;
    } else {
      PricingList[index].default_pricing.compared_pattern = value;
    }
    pricingBtnDisabled[index] = false;
    if(value == 'mul' && sta == 1 && PricingList[index].default_pricing.price == 0){
      PricingList[index].default_pricing.price = 1
    }else if(value == 'mul' && sta == 2 && PricingList[index].default_pricing.compared_price == 0){
      PricingList[index].default_pricing.compared_price = 1
    }
    PricingList = [...PricingList];
    pricingBtnDisabled = [...pricingBtnDisabled];
    this.setState({
      PricingList,
      pricingBtnDisabled
    },() => {
      
    });
  };

  // pricing 钱
  changePricingPrice = (value, index, sta) => {
    let { PricingList, pricingBtnDisabled } = this.state;
    pricingBtnDisabled[index] = false;
    
    if (value || value == 0) {
      if (sta == 1) {
        PricingList[index].default_pricing.price = `${value}`;
      } else {
        PricingList[index].default_pricing.compared_price = `${value}`;
      }
    } else if (sta == 1) {
      PricingList[index].default_pricing.price = undefined;
    } else {
      PricingList[index].default_pricing.compared_price = undefined;
    }
    
    if(PricingList[index].default_pricing.pattern == "mul" && sta == 1 && !value){
      PricingList[index].default_pricing.price = 1
    }else if(PricingList[index].default_pricing.compared_pattern == "mul" && sta == 2 && !value){
      PricingList[index].default_pricing.compared_price = 1
    }
    PricingList = [...PricingList];
    pricingBtnDisabled = [...pricingBtnDisabled];
    this.setState({
      PricingList,
      pricingBtnDisabled
    });
  };

  // pricing Compared 开关
  pricingComparedShow = (checked, index) => {
    let { PricingList, pricingBtnDisabled } = this.state;
    PricingList[index].default_pricing.compared_status = checked;
    pricingBtnDisabled[index] = false;

    PricingList = [...PricingList];
    pricingBtnDisabled = [...pricingBtnDisabled];
    this.setState({
      PricingList,
      pricingBtnDisabled
    }, () => {
      
    });
  };

  // 选择全选Shipping cost checkbox 按钮
  AllSelectShippingCost = (e, index) => {
    
    let { PricingList } = this.state;
    PricingList[index].pro_pricing.pro_item.forEach(item => {
      item.is_shipping_cost = e.target.checked;
    });
    PricingList[index].pro_pricing.pro_rest.is_shipping_cost = e.target.checked;

    PricingList = [...PricingList];
    this.setState({
      PricingList
    }, () => {
      
    });
  };

  // standard Compared Price 多选按钮
  AdvancedComparedPriceShow = (checked, index) => {
    let { PricingList, pricingBtnDisabled } = this.state;
    PricingList[index].advanced_pricing.compared_status = checked.target.checked;
    pricingBtnDisabled[index] = false;

    PricingList = [...PricingList];
    pricingBtnDisabled = [...pricingBtnDisabled];
    this.setState({
      PricingList,
      pricingBtnDisabled
    }, () => {
      
    });
  };

  AdvancedMinMax = (value, index, ind, sta) => {
    let {
      PricingList,
      pricingBtnDisabled,
      AdvancedErrTxt1,
      AdvancedErrTxt2
    } = this.state;
    const advancedItem = {
      min: `${Number(value) + 0.01}`,
      max: '',
      pattern: 'add',
      price: '0',
      compared_pattern: 'add',
      compared_price: '0'
    };
    if (sta == 'min') {
      if (value || value == 0) {
        PricingList[index].advanced_pricing.advanced_item[ind].min = `${value}`;
      } else {
        PricingList[index].advanced_pricing.advanced_item[ind].min = undefined;
      }
    } else {
      if (value || value == 0) {
        PricingList[index].advanced_pricing.advanced_item[ind].max = `${value}`;
      } else {
        PricingList[index].advanced_pricing.advanced_item[ind].max = undefined;
      }
      // 如果最后一行的最大有值，并且list小于50个，push一个进去
      if (
        value &&
        value >= PricingList[index].advanced_pricing.advanced_item[ind].min &&
        PricingList[index].advanced_pricing.advanced_item.length == ind + 1 &&
        PricingList[index].advanced_pricing.advanced_item.length < 50
      ) {
        PricingList[index].advanced_pricing.advanced_item.push(advancedItem);
      }
    }

    pricingBtnDisabled[index] = false;

    let AdvancedErr1 = 0;
    let AdvancedErr2 = 0;

    PricingList[index].advanced_pricing.advanced_item.forEach((item, i) => {
      // 判断修改的不是第一个，输入的值不能小于上一个的最大值(min)
      // 如果不是最后一行，不能大于下一行的最小值(max)
      if (
        i != 0 &&
        Number(item.min) <=
          Number(PricingList[index].advanced_pricing.advanced_item[i - 1].max)
      ) {
        AdvancedErr1 += 1;
      }
      // 判断输入的值为真，并且不能大于同级的最大值（最小值和最大值可以相同）
      // 判断输入的值为真，并且不能小于同级的最小值（最小值和最大值可以相同）
      if (
        item.min != '' &&
        item.max != '' &&
        Number(item.min) > Number(item.max)
      ) {
        AdvancedErr2 += 1;
      }
    });
    if (AdvancedErr1 != 0) {
      AdvancedErrTxt1[index] = true;
    } else {
      AdvancedErrTxt1[index] = false;
    }
    if (AdvancedErr2 != 0) {
      AdvancedErrTxt2[index] = true;
    } else {
      AdvancedErrTxt2[index] = false;
    }

    PricingList = [...PricingList];
    pricingBtnDisabled = [...pricingBtnDisabled];
    this.setState({
      PricingList,
      pricingBtnDisabled,
      AdvancedErrTxt1,
      AdvancedErrTxt2
    },() => {
      
    });
  };

  ProMinMax = (value, index, ind, sta) => {
    let {
      PricingList,
      pricingBtnDisabled,
      ProErrTxt1,
      ProErrTxt2
    } = this.state;
    const proItem = {
      min: `${Number(value) + 0.01}`,
      max: '',
      profit: '0',
      fix_profit: '0',
      minimum_profit: '0',
      is_shipping_cost: false,
      breakeven: '0',
      compared_pattern: 'add',
      compared_price: '0'
    };
    if (sta == 'min') {
      if (value || value == 0) {
        PricingList[index].pro_pricing.pro_item[ind].min = `${value}`;
      } else {
        PricingList[index].pro_pricing.pro_item[ind].min = undefined;
      }
    } else {
      if (value || value == 0) {
        PricingList[index].pro_pricing.pro_item[ind].max = `${value}`;
      } else {
        PricingList[index].pro_pricing.pro_item[ind].max = undefined;
      }
      // 如果最后一行的最大有值，并且list小于50个，push一个进去
      if (
        value &&
        value >= PricingList[index].pro_pricing.pro_item[ind].min &&
        PricingList[index].pro_pricing.pro_item.length == ind + 1 &&
        PricingList[index].pro_pricing.pro_item.length < 50
      ) {
        PricingList[index].pro_pricing.pro_item.push(proItem);
      }
    }

    pricingBtnDisabled[index] = false;

    let ProErr1 = 0;
    let ProErr2 = 0;

    PricingList[index].pro_pricing.pro_item.forEach((item, i) => {
      // 判断修改的不是第一个，输入的值不能小于上一个的最大值(min)
      // 如果不是最后一行，不能大于下一行的最小值(max)
      if (
        i != 0 &&
        Number(item.min) <=
          Number(PricingList[index].pro_pricing.pro_item[i - 1].max)
      ) {
        ProErr1 += 1;
      }
      // 判断输入的值为真，并且不能大于同级的最大值（最小值和最大值可以相同）
      // 判断输入的值为真，并且不能小于同级的最小值（最小值和最大值可以相同）
      if (
        item.min != '' &&
        item.max != '' &&
        Number(item.min) > Number(item.max)
      ) {
        ProErr2 += 1;
      }
    });
    if (ProErr1 != 0) {
      ProErrTxt1[index] = true;
    } else {
      ProErrTxt1[index] = false;
    }
    if (ProErr2 != 0) {
      ProErrTxt2[index] = true;
    } else {
      ProErrTxt2[index] = false;
    }

    PricingList = [...PricingList];

    pricingBtnDisabled = [...pricingBtnDisabled];
    this.setState({
      PricingList,
      pricingBtnDisabled,
      ProErrTxt1,
      ProErrTxt2
    }, () => {
      
    });
  };

  changeAdvancedPattern = (value, index, ind, sta) => {
    let { PricingList, pricingBtnDisabled } = this.state;
    if (value || value == 0) {
      if (sta == 1) {
        PricingList[index].advanced_pricing.advanced_item[
          ind
        ].pattern = `${value}`;
      } else {
        PricingList[index].advanced_pricing.advanced_item[
          ind
        ].compared_pattern = `${value}`;
      }
    } else if (sta == 1) {
      PricingList[index].advanced_pricing.advanced_item[
        ind
      ].pattern = undefined;
    } else {
      PricingList[index].advanced_pricing.advanced_item[
        ind
      ].compared_pattern = undefined;
    }
    
    if(value == 'mul' && PricingList[index].advanced_pricing.advanced_item[ind].price == 0 && sta == 1){
      PricingList[index].advanced_pricing.advanced_item[ind].price = 1
    }else if(value == 'mul' && PricingList[index].advanced_pricing.advanced_item[ind].compared_price == 0 && sta == 2){
      PricingList[index].advanced_pricing.advanced_item[ind].compared_price = 1
    }

    pricingBtnDisabled[index] = false;

    PricingList = [...PricingList];
    pricingBtnDisabled = [...pricingBtnDisabled];
    this.setState({
      PricingList,
      pricingBtnDisabled
    }, () => {
      
    });
  };

  changeAdvancedPrice = (value, index, ind, sta) => {
    let { PricingList, pricingBtnDisabled } = this.state;
    pricingBtnDisabled[index] = false;
    
    if (value || value == 0) {
      if (sta == 1) {
        PricingList[index].advanced_pricing.advanced_item[
          ind
        ].price = `${value}`;
      } else {
        PricingList[index].advanced_pricing.advanced_item[
          ind
        ].compared_price = `${value}`;
      }
      // pricingBtnDisabled[index] = false;
    } else {
      if (sta == 1) {
        PricingList[index].advanced_pricing.advanced_item[
          ind
        ].price = undefined;
      } else {
        PricingList[index].advanced_pricing.advanced_item[
          ind
        ].compared_price = undefined;
      }
      // pricingBtnDisabled[index] = true;
    }
    
    if(PricingList[index].advanced_pricing.advanced_item[ind].pattern == 'mul' && !value && sta == 1){
      PricingList[index].advanced_pricing.advanced_item[ind].price = 1
    }else if(PricingList[index].advanced_pricing.advanced_item[ind].compared_pattern == 'mul' && !value && sta == 2){
      PricingList[index].advanced_pricing.advanced_item[ind].compared_price = 1
    }

    PricingList = [...PricingList];
    pricingBtnDisabled = [...pricingBtnDisabled];
    this.setState({
      PricingList,
      pricingBtnDisabled
    }, () => {
      
    });
  };

  // 删除 Standard
  deleteAdvancedItem = (index, ind) => {
    let { PricingList, pricingBtnDisabled } = this.state;
    const advancedItem = [
      {
        min: '0',
        max: '10',
        pattern: 'add',
        price: '1',
        compared_pattern: 'add',
        compared_price: '0'
      },
      {
        min: '10.01',
        max: '',
        pattern: 'add',
        price: '0',
        compared_pattern: 'add',
        compared_price: '0'
      }
    ];
    if (PricingList[index].advanced_pricing.advanced_item.length > 2) {
      PricingList[index].advanced_pricing.advanced_item.splice(ind, 1);
    } else {
      PricingList[index].program = false;
      PricingList[index].advanced_pricing.advanced_item = advancedItem;
    }

    pricingBtnDisabled[index] = false;

    PricingList = [...PricingList];
    pricingBtnDisabled = [...pricingBtnDisabled];

    this.setState({
      PricingList,
      pricingBtnDisabled
    },() => {
      
    });
  };

  // 修改advanced的值
  changeProParam = (value, index, ind, sta) => {
    // PricingList[index].pro_pricing.pro_item[ind].compared_pattern
    
    let { PricingList } = this.state;
    if (value || value == 0) {
      // 兼容输入100报错
      if(value == 100 && sta == 4){
        PricingList[index].pro_pricing.pro_item[ind].checked = true;
      }else{
        PricingList[index].pro_pricing.pro_item[ind].checked = false;
      }
      PricingList[index].pro_pricing.pro_item[ind][paramMap[sta]] = `${value}`;
    } else {
      PricingList[index].pro_pricing.pro_item[ind][paramMap[sta]] = 0;
    }
    
    if(PricingList[index].pro_pricing.pro_item[ind].compared_pattern == 'mul' && value == 0 && sta == 5){
      PricingList[index].pro_pricing.pro_item[ind][paramMap[sta]] = 1
    }

    console.log(value, index, ind, sta, PricingList)
    
    PricingList = [...PricingList];
    this.setState({
      PricingList
    }, () => {
      
    });
  };
  
  changeProRestParam = (value, index, sta) => {
    let { PricingList } = this.state;
    
    if (value || value == 0) {
      PricingList[index].pro_pricing.pro_rest[paramMap[sta]] = `${value}`;
    } else {
      PricingList[index].pro_pricing.pro_rest[paramMap[sta]] = 0;
    }
    if(PricingList[index].pro_pricing.pro_rest.compared_pattern == 'mul' && value == 0 && sta == 5){
      PricingList[index].pro_pricing.pro_rest[paramMap[sta]] = 1
    }
    PricingList = [...PricingList];
    this.setState({
      PricingList
    });
  };

  changeShippingCost = (e, index, ind) => {
    let { PricingList } = this.state;
    PricingList[index].pro_pricing.pro_item[ind].is_shipping_cost =
      e.target.checked;

    PricingList = [...PricingList];
    this.setState({
      PricingList
    }, () => {
      
    });
  };

  changeRestShippingCost = (e, index) => {
    let { PricingList } = this.state;
    PricingList[index].pro_pricing.pro_rest.is_shipping_cost = e.target.checked;

    PricingList = [...PricingList];
    this.setState({
      PricingList
    }, () => {
      
    });
  };

  changeFixShoppingCost = (value, index) => {
    let { PricingList } = this.state;
    if (value || value == 0) {
      PricingList[index].pro_pricing.fix_ship_cost = value;
    } else {
      PricingList[index].pro_pricing.fix_ship_cost = 0;
    }
    PricingList = [...PricingList];
    this.setState({
      PricingList
    }, () => {
      
    });
  };

  changeShipppingStatus = (e, index) => {
    let { PricingList } = this.state;
    const value = e.target.value;
    PricingList[index].pro_pricing.fix_ship_cost_status = value;
    PricingList = [...PricingList];
    this.setState({
      PricingList
    }, () => {
      
    });
  };

  // 更改国家
  changeCountry = (e, index) => {
    let { PricingList } = this.state;
    PricingList[index].pro_pricing.country_code = e;
    PricingList = [...PricingList];
    this.setState({
      PricingList
    });
  };

  // 更改物流
  changeCountryTrack = (e, index) => {
    let { PricingList } = this.state;
    PricingList[index].pro_pricing.logistics = e;
    PricingList = [...PricingList];
    this.setState({
      PricingList
    },() => {
      
    });
  };
  // 更改Tmall国家
  changeTmallCountry = (e, index) => {
    let { PricingList } = this.state;
    PricingList[index].pro_pricing.tmall_country_code = e;
    PricingList = [...PricingList];
    this.setState({
      PricingList
    });
  };

  // 更改Tmall物流
  changeTmallCountryTrack = (e, index) => {
    let { PricingList } = this.state;
    PricingList[index].pro_pricing.tmall_logistics = e;
    PricingList = [...PricingList];
    this.setState({
      PricingList
    },() => {
      
    });
  };

  // 删除 Advanced
  deleteProItem = (index, ind) => {
    let { PricingList, pricingBtnDisabled } = this.state;
    const proItem = [
      {
        min: '0',
        max: '10',
        profit: '0',
        fix_profit: '0',
        minimum_profit: '0',
        is_shipping_cost: false,
        breakeven: '0'
      },
      {
        min: '10.01',
        max: '',
        profit: '0',
        fix_profit: '0',
        minimum_profit: '0',
        is_shipping_cost: false,
        breakeven: '0'
      }
    ];
    if (PricingList[index].pro_pricing.pro_item.length > 2) {
      PricingList[index].pro_pricing.pro_item.splice(ind, 1);
    } else {
      PricingList[index].program = false;
      PricingList[index].pricing_rank = 0;
      PricingList[index].pro_pricing.pro_item = proItem;
      this.hideModal(index);
      pricingBtnDisabled[index] = false;
    }

    PricingList = [...PricingList];
    pricingBtnDisabled = [...pricingBtnDisabled];

    this.setState({
      PricingList,
      pricingBtnDisabled
    }, () => {
      
    });
  };

  changeAdvancedRestPattern = (value, index, sta) => {
    let { PricingList, pricingBtnDisabled } = this.state;
    if (value || value == 0) {
      if (sta == 1) {
        PricingList[index].advanced_pricing.advanced_rest.pattern = `${value}`;
      } else {
        PricingList[
          index
        ].advanced_pricing.advanced_rest.compared_pattern = `${value}`;
      }
    } else if (sta == 1) {
      PricingList[index].advanced_pricing.advanced_rest.pattern = undefined;
    } else {
      PricingList[
        index
      ].advanced_pricing.advanced_rest.compared_pattern = undefined;
    }

    if(value == 'mul' && PricingList[index].advanced_pricing.advanced_rest.price == 0 && sta == 1){
      PricingList[index].advanced_pricing.advanced_rest.price = 1
    }else if(value == 'mul' && PricingList[index].advanced_pricing.advanced_rest.compared_price == 0 && sta == 2){
      PricingList[index].advanced_pricing.advanced_rest.compared_price = 1
    }

    pricingBtnDisabled[index] = false;

    PricingList = [...PricingList];
    pricingBtnDisabled = [...pricingBtnDisabled];
    this.setState({
      PricingList,
      pricingBtnDisabled
    }, () => {
      
    });
  };

  changeAdvancedRestPrice = (value, index, sta) => {
    let { PricingList, pricingBtnDisabled } = this.state;
    pricingBtnDisabled[index] = false;

    if (value || value == 0) {
      if (sta == 1) {
        PricingList[index].advanced_pricing.advanced_rest.price = `${value}`;
      } else {
        PricingList[
          index
        ].advanced_pricing.advanced_rest.compared_price = `${value}`;
      }
      // pricingBtnDisabled[index] = false;
    } else {
      if (sta == 1) {
        PricingList[index].advanced_pricing.advanced_rest.price = undefined;
      } else {
        PricingList[
          index
        ].advanced_pricing.advanced_rest.compared_price = undefined;
      }
      // pricingBtnDisabled[index] = true;
    }

    if(PricingList[index].advanced_pricing.advanced_rest.pattern == 'mul' && !value && sta == 1){
      PricingList[index].advanced_pricing.advanced_rest.price = 1
    }else if(PricingList[index].advanced_pricing.advanced_rest.compared_pattern == 'mul' && !value && sta == 2){
      PricingList[index].advanced_pricing.advanced_rest.compared_price = 1
    }

    PricingList = [...PricingList];
    pricingBtnDisabled = [...pricingBtnDisabled];
    this.setState({
      PricingList,
      pricingBtnDisabled
    }, () => {
      
    });
  };

  // 改变开关状态
  changeDisabledEnabled = index => {
    const { PricingList, activeKey } = this.state;
    const status = !PricingList[index].status;
    const list = [...PricingList];
    let activeKeyList = [...activeKey];

    if (!status) {
      activeKeyList = activeKeyList.filter(id => id !== list[index].store_id);
    } else {
      activeKeyList.push(list[index].store_id);
    }

    this.props.dispatch({
      type: 'dropshippersetting/changePricingStatus',
      payload: {
        data: {
          store_id: list[index].store_id,
          store_name: list[index].store_name,
          status
        },
        message: {
          success: `${list[index].store_name} pricing rule ${
            status ? 'Enabled' : 'Disabled'
          }`
        },
        callback: d => {
          if (d.code === 2000 || d.code === 2010) {
            this.props.dispatch({
              type: 'dropshippersetting/getPricingList'
            })
            list[index].status = status;
            this.setState({
              PricingList: list,
              activeKey: activeKeyList
            }, () => {
              this.props.refreshPriceStatus();
            });
          }
        }
      }
    });
  };

  changeProductCost = (e, index) => {
    let { PricingList } = this.state;
    PricingList[index].pro_pricing.destination_country_status =
      e.target.checked;

    PricingList = [...PricingList];
    this.setState({
      PricingList
    }, () => {
      
    });
  };

  changeCountryCost = (e, index) => {
    let { PricingList } = this.state;
    PricingList[index].pro_pricing.destination_country_code = e.target.checked;

    PricingList = [...PricingList];
    this.setState({
      PricingList
    }, () => {
      
    });
  };

  savePricingItem = index => {
    window.dataLayer.push({
      event: 'savePricingRuleEvent',
      type: 'saveClickIssue'
    });

    let { PricingList, pricingBtnDisabled, showModal } = this.state;
    const data = cloneDeep(PricingList[index]);
    const leng = data.advanced_pricing.advanced_item.length - 1;
    const leng2 = data.pro_pricing.pro_item.length - 1;

    //  2022-7-28 改 T 
    if (data.pro_pricing.country_code == '') {
          data.pro_pricing.country_code = 'ALA'
    }
    
    // 如果数组最后一个的max为空，删掉最后一个
    if (!data.advanced_pricing.advanced_item[leng].max) {
      data.advanced_pricing.advanced_item.splice(leng, 1);
    }

    if (!data.pro_pricing.pro_item[leng2].max) {
      data.pro_pricing.pro_item.splice(leng2, 1);
    }

    if (!data.pro_pricing.pro_rest.compared_pattern) {
      data.pro_pricing.pro_rest.compared_pattern = 'add';
    }
    data.pro_pricing.pro_item.forEach(item => {
      if (!item.compared_pattern) {
        item.compared_pattern = 'add';
      }
    });

    //检测计算出来的价格是不是大于原来的价格
    let retStop = null;
    let retStop2 = null;
    let retProStop = null;
    let defaultPricing = null;
    let BreakevenErr = null;

    const { default_pricing, 
      advanced_pricing: { compared_status, advanced_item }, 
      pro_pricing:{ pro_item, pro_rest } 
    } = data



    if(PricingList[index].pricing_rank ==0 && default_pricing.pattern == default_pricing.compared_pattern 
      && Number(default_pricing.price) > Number(default_pricing.compared_price)){
        // console.log(PricingList[index].pricing_rank,'00000000000000000000000');
        if(PricingList[index].default_pricing.compared_status){
          defaultPricing = true;
        }else{
          defaultPricing = false;
        }
    }

    if(PricingList[index].pricing_rank ==1){
      // console.log(PricingList[index].pricing_rank,'1111111111111111111111111111');
      advanced_item.map(item => {
        if(item.compared_pattern == item.pattern && (Number(item.price) > Number(item.compared_price))){
          if(PricingList[index].advanced_pricing.compared_status
            ){
            retStop = true
          }else{
            retStop = false
          }
        }
      })
    }

    if(PricingList[index].pricing_rank ==1){
      // console.log(PricingList[index].pricing_rank,'ccccc1111111111111111111111ccc');
      advanced_item.map(item => {
        if(PricingList[index].advanced_pricing.advanced_rest.compared_pattern ==PricingList[index].advanced_pricing.advanced_rest.pattern && 
        Number(PricingList[index].advanced_pricing.advanced_rest.price) > Number(PricingList[index].advanced_pricing.advanced_rest.compared_price)){
          if(PricingList[index].advanced_pricing.compared_status
            ){
            retStop2 = true
          }else{
            retStop2 = false
          }
        }
      })
    }

    if(PricingList[index].pricing_rank ==2){
      // console.log(PricingList[index].pricing_rank,'2222222222222222222222222222');
      pro_item.map(item => {
        if(item.compared_pattern == 'mul' && Number(item.compared_price) < 1){
          retProStop = true
        }
        if(Number(item.breakeven) >= 100){
          BreakevenErr = true
        }
      })

      if(Number(pro_rest.breakeven) >= 100){
        BreakevenErr = true
      }

    }

    if(BreakevenErr){
      message.error(intl.get('setting.pricing.inputerr100'))
      return
    }
    if(retStop || retStop2 || retProStop || defaultPricing){
      message.error(intl.get('setting.pricing.bigTit'))
      return
    }

  
    if(this.state.isCustomizeUser){
      data.pro_pricing.pro_item = data.pro_pricing.pro_item.map((item)=> {
        return {...item,minimum_profit:'0'}
      })
    }
    this.props.dispatch({
      type: 'dropshippersetting/savePricingItem',
      payload: {
        data,
        message: {
          success: intl.get('setting.pricing.save_succ')
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            pricingBtnDisabled[index] = true;
            showModal[index] = false;
            showModal = [...showModal];

            if (!PricingList[index].status) {
              this.changeDisabledEnabled(index);
            }

            PricingList = [...PricingList];
            pricingBtnDisabled = [...pricingBtnDisabled];
            this.setState({
              pricingBtnDisabled,
              PricingList,
              showModal
            }, () => {
              
            });
          }
        }
      }
    });
  };

  // Assign Cents 多选框
  centsPriceStatus = (e, sta) => {
    const { AssignCentsData } = this.state;
    if (sta == 1) {
      AssignCentsData.price_status = e.target.checked;
    } else {
      AssignCentsData.compared_status = e.target.checked;
    }
    this.setState({
      AssignCentsData,
      assignCentsDisabled: false
    });
  };

  // Assign Cents 输入框
  centsPriceCents = (value, sta) => {
    let { AssignCentsData, assignCentsDisabled } = this.state;

    if (value || value == 0) {
      if (sta == 1) {
        AssignCentsData.price_cents = `${value}`;
      } else {
        AssignCentsData.compared_cents = `${value}`;
      }
      assignCentsDisabled = false;
    } else {
      if (sta == 1) {
        AssignCentsData.price_cents = undefined;
      } else {
        AssignCentsData.compared_cents = undefined;
      }
      assignCentsDisabled = true;
    }

    this.setState({
      AssignCentsData,
      assignCentsDisabled
    });
  };

  // 保存saveAssignCents
  saveAssignCents = () => {
    const { AssignCentsData } = this.state;
    this.props.dispatch({
      type: 'dropshippersetting/saveAssignCents',
      payload: {
        data: AssignCentsData,
        message: {
          success: intl.get('setting.pricing.save_succ')
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              assignCentsDisabled: true
            });
          }
        }
      }
    });
  };

  // 店铺详情是否展开
  handleCollapse = v => {
    this.setState({
      activeKey: v
    });
  };

  // 设置是否应用currency
  changeUseCurrency = checked => {
    this.props.dispatch({
      type: 'dropshippersetting/pricingCurrency',
      payload: {
        data: {
          apply_currency_price_rule: checked
        }
      }
    });
  };

  showUpdateCurrency = () => {
    Modal.warning({
      title: intl.get('refresh_mask.title'),
      content: intl.get('refresh_mask.dec'),
      okText: intl.get('public.ok'),
      onOk: () => {
        this.props.dispatch({ type: 'login/updateUserInfo' });
        // window.location.href = '/app/settings?id=3'
      }
    });
  };

  showUpDataModal=()=>{
    this.setState({
      showUpdataPrcie:true,
    })
  }

  currentLanguage = getLanguage();
  updataConfirmWord='' //用户更新需要输入confirm

  inputUpdataConfirm = e => {
    this.updataConfirmWord = e.target.value;
  };

  upData=()=>{
    if (this.updataConfirmWord.trim().toLocaleLowerCase() !== confirmLanguage[this.currentLanguage]) {
      return;
    }   
    this.setState({
      buttonLoading:true,
      selectStory:[]
    })
    this.props.dispatch({
      type:'dropshippersetting/setUpdataPricing',
      payload:{
        data:{
          store_id:this.state.selectStory //所选店铺id的数组
        },
        callback:d=>{
          this.setState({
            buttonLoading:false
          })
          this.getStatus(this.state.allStoryId2)
          this.setState({
            showUpdataPrcie:false,
          })
        }
      }
    })
  }

  disconnctOk=()=>{
    this.setState({
      showConnect:false
    })
  }

  showConnectMoadl=()=>{
    this.setState({
      showConnect:true
    })
  }

  handleCheckbox = (value)=>{
    this.setState({
      selectStory:value
    })
  }

  /**
   * 
   * @param {*} id 店铺id
   * @param {*} index 店铺下标
   * @returns 店铺复选框状态 true/false
   */
  ifDisabled = (id,index)=>{
    if(this.state.storyIfUse[index]?.status !== 1){
      return true
    }else if(this.props.updataPriceStatus && this.props.updataPriceStatus?.data[id]?.description==="API has reached 1000000"){
      return true
    }else if(this.props.updataPriceStatus && this.props.updataPriceStatus?.data[id]?.status === 1 && !this.props.updataPriceStatus?.data[id]?.total){
      return true
    }else if(this.props.updataPriceStatus && this.props.updataPriceStatus?.data[id]?.status===1){
      return false
    }else{
      return true
    }
  }

  /**
   * 
   * @param {*} id 店铺id
   * @param {*} index 店铺下标
   * @returns 复选框悬浮展示的文案
   */
  showTitle = (id,index)=>{
    if(this.state.storyIfUse[index]?.status !== 1){  //店铺不可用
      return intl.get('public.shopUnavailable')
    }else if(this.props.updataPriceStatus && this.props.updataPriceStatus?.data[id]?.description==="API has reached 1000000"){  //接口超限
      return intl.get('public.InterfaceTransfinite')
    }else if(this.props.updataPriceStatus && this.props.updataPriceStatus?.data[id]?.status === 1 && !this.props.updataPriceStatus?.data[id]?.total){ //没有可推送商品
      return intl.get('public.noproduct')
    }else if(this.props.updataPriceStatus && this.props.updataPriceStatus?.data[id]?.status === 1){  //可用状态
      return intl.get('public.updata_price')
    }else if(this.props.updataPriceStatus && this.props.updataPriceStatus?.data[id]?.status === 5){  //完成状态
      let nowtime = Date.parse(new Date())/1000
      let creattime = this.props.updataPriceStatus?.data[id]?.create
      let usetime = 2592000 - (nowtime - creattime)
      let useday = Math.ceil(usetime / 86400)
      return `${intl.get('public.useAgain')}  ${useday}  ${intl.get('public.day')}`
    }else if(this.props.updataPriceStatus && this.props.updataPriceStatus?.data[id]?.status === 4){  //暂停状态 店铺超限
      return intl.get('public.pause')
    }else if(this.props.updataPriceStatus && this.props.updataPriceStatus?.data[id]?.status === 0){  
      return intl.get('public.exceeds')
    }else{
      return intl.get('public.synchronous')   //loading状态
    }
  }

  modalTitle = ()=>{
    let arr = []
    this.state.selectStory?.forEach(item=>{
      if(this.props.updataPriceStatus?.data?.[item]){
        arr.push(this.props.updataPriceStatus?.data[item])
      }
    })
    let count = arr?.reduce((inital,item)=>{
      return inital+item.total
    },0)
    if(count >= 100000){
      return intl.get('public.goodsTooMuch')
    }else{
      return intl.get('public.updata_price')
      
    }
  }

  render() {
    const {
      userInfo: { stores },
      dropshippersetting: { currencyChecked, countrys, logistics_services ,logistics_services_tm},
      customizeCur
      // paymentPlan: { type, status }
    } = this.props;
    const { type = 0, status } = JSON.parse(localStorage.getItem('payment'))
    const {
      PricingList,
      AssignCentsData,
      assignCentsDisabled,
      pricingBtnDisabled,
      AdvancedErrTxt1,
      AdvancedErrTxt2,
      ProErrTxt1,
      ProErrTxt2,
      activeKey,
      showModal,
      showUpdataPrcie,
      showConnect,
      clickStoreId,
      storyIfUse,
      selectStory,
      buttonLoading,
      isCustomizeUser
    } = this.state;


    const hasSetCurrency = stores.some(item => item.ali_currency !== undefined);

    let howToUsePricingLink =
      'https://help.dsers.com/basic-pricing-rule-for-shopify/';
    if (getLanguage() === 'fr-FR') {
      howToUsePricingLink =
        'https://help.dsers.com/basic-pricing-rule-for-shopify/';
    }

    if (getLanguage() === 'pt-PT') {
      howToUsePricingLink =
        'https://help.dsers.com/basic-pricing-rule-for-shopify/';
    }
    return (
      <>
      <Title title={intl.get('setting.title_pricing_rule')} description={intl.get('setting.pricing_rule_dsec')}/>
         <div 
          className={styles.pricingRuleCont}>
          { PricingList.length ?
          <div className={styles.pricingRuleBox}>
            {/* currency */}

            <div>
              <div className={styles.pricingRuleTitle}>{intl.get('setting.pricing.pricing_rule_new_title')}</div>
              <div className={styles.pricingRuleCurrency}>
                <p className={styles.pricingRuleCurrencyDec}>
                    {intl.get('setting.pricing.pricing_rule_new_dsec')}
                </p>
              </div>
            </div>
            
            {/* <p className={styles.dsspF}>
              {intl.getHTML('setting.pricing.description', {
                href: howToUsePricingLink
              })}
            </p> */}
            
            <div
              className={styles.pricingList}
              style={
                PricingList.length != 0
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            >
              <Collapse activeKey={activeKey} onChange={this.handleCollapse}>
                {PricingList?.map((pricingItem, index) => {
                  if ([1, 4].includes(type) || status !== 2) {
                    if (!pricingItem.program && pricingItem.pricing_rank == 2) {
                      // 用户从advanced套餐降级 如果已选advanced则切换为 Basic
                      pricingItem.pricing_rank = 0;
                    }
                  }

                  if (
                    (!pricingItem.program && pricingItem.pricing_rank == 1) ||
                    (pricingItem.program && pricingItem.pricing_rank == 2)
                  ) {
                    // 当standard和advanced都没有被选中的时候 默认展开 basic
                    pricingItem.program = false;
                    pricingItem.pricing_rank = 0;
                  }

                  const showBasic =
                    !pricingItem.program && pricingItem.pricing_rank == 0;
                  const showStandard =
                    pricingItem.program && pricingItem.pricing_rank == 1;
                  const showAdvanced =
                    !pricingItem.program && pricingItem.pricing_rank == 2;
                  const disabledStatus = this.props.updataPriceStatus?.data ? this.props.updataPriceStatus?.data[pricingItem.store_id]?.status === 2 || this.props.updataPriceStatus?.data[pricingItem.store_id]?.status ===3  ? true :  false : null
                  return (
                    <Panel
                      disabled={!pricingItem.status}
                      header={
                        <span className={styles.pricingBox}>
                          <i class="material-icons notranslate">storefront</i>
                          {pricingItem.store_name}
                        </span>
                      }
                      extra={          
                      <div className={styles.extra} >                 
                        {
                        pricingBtnDisabled[index] || !pricingItem.status ? (
                          <Switch
                            // checkedChildren={intl.get('public.on')}
                            // unCheckedChildren={intl.get('public.off')}
                            checked={pricingItem.status}
                            disabled={this.props.updataPriceStatus?.data ? this.props.updataPriceStatus?.data[pricingItem.store_id]?.status === 2 || this.props.updataPriceStatus?.data[pricingItem.store_id]?.status ===3  ? true :  false : null} //店铺状态为2/3时按钮不可用
                            onChange={() => {
                              this.changeDisabledEnabled(index);
                            }}
                            onClick={(c, e) => {
                              e.stopPropagation();
                            }}
                          />
                        ) : (
                          <Button
                            type="primary"
                            className={styles.saveBtn}
                            disabled={this.props.updataPriceStatus?.data ? this.props.updataPriceStatus?.data[pricingItem.store_id]?.status === 2 || this.props.updataPriceStatus?.data[pricingItem.store_id]?.status ===3 ? true :  false : null} //店铺状态为2/3时按钮不可用
                            onClick={e => {
                              e.stopPropagation();
                              this.savePricingItem(index);
                            }}
                          >
                            {intl.get('public.save')}
                          </Button>
                        )
                      }
                        </div>
                      }
                      key={pricingItem.store_id}
                      showArrow={false}
                    >
                      <div className={styles.pricingCont}>
                        <div className={styles.pricingCont}>
                          <div className={styles.titleBox}>
                            <Radio
                              checked={showBasic}
                              onChange={e => {
                                this.checked = {
                                  basic:true,
                                  standard:false,
                                  advanced:false
                                }
                                this.BasicShow(e.target.checked, index);
                              }}
                              disabled={this.props.updataPriceStatus?.data ? this.props.updataPriceStatus?.data[pricingItem.store_id]?.status === 2 || this.props.updataPriceStatus?.data[pricingItem.store_id]?.status ===3  ? true :  false : null} //店铺状态为2/3时按钮不可用
                            >
                              <div className={styles.title}>
                                {intl.get('setting.pricing.basicpricerule')}
                                <span>
                                  {intl.getHTML('setting.pricing.basic_more')}
                                </span>
                              </div>
                            </Radio>
                          </div>
                          <div className={styles.contentBox}>
                            <BasicRule
                              index={index}
                              pricingItem={pricingItem}
                              changePricingPattern={this.changePricingPattern}
                              changePricingPrice={this.changePricingPrice}
                              pricingComparedShow={this.pricingComparedShow}
                            />
                            {showBasic ? null : (
                              <div className={styles.mask}></div>
                            )}
                          </div>

                          <div className={styles.titleBox}>
                            <Radio
                              checked={showStandard}
                              onChange={e => {
                                this.checked = {
                                  basic:false,
                                  standard:true,
                                  advanced:false
                                }
                                this.AdvancedShow(e.target.checked, index);
                              }}
                              disabled={this.props.updataPriceStatus?.data ? this.props.updataPriceStatus?.data[pricingItem.store_id]?.status === 2 || this.props.updataPriceStatus?.data[pricingItem.store_id]?.status ===3   ? true :  false : null} //店铺状态为2/3时按钮不可用
                            >
                              <div className={styles.title}>
                              {intl.get('setting.pricing.standardpricerule')}
                                <span>
                                  {intl.getHTML(
                                    'setting.pricing.standard_more'
                                  )}
                                </span>
                              </div>
                            </Radio>
                          </div>
                          <div className={styles.contentBox}>
                            <StandardRule
                              index={index}
                              pricingItem={pricingItem}
                              AdvancedComparedPriceShow={
                                this.AdvancedComparedPriceShow
                              }
                              AdvancedMinMax={this.AdvancedMinMax}
                              changeAdvancedPattern={this.changeAdvancedPattern}
                              changeAdvancedPrice={this.changeAdvancedPrice}
                              changeAdvancedRestPattern={
                                this.changeAdvancedRestPattern
                              }
                              changeAdvancedRestPrice={
                                this.changeAdvancedRestPrice
                              }
                              deleteAdvancedItem={this.deleteAdvancedItem}
                              AdvancedErrTxt1={AdvancedErrTxt1}
                              AdvancedErrTxt2={AdvancedErrTxt2}
                            />
                            {showStandard ? null : (
                              <div className={styles.mask}></div>
                            )}
                          </div>
                          <div className={styles.titleBox}>
                            <Radio
                              checked={showAdvanced}
                              onChange={e => {
                                this.checked = {
                                  basic:false,
                                  standard:false,
                                  advanced:true
                                }
                                this.showCheckAd(e.target.checked, index);
                                this.checkType();
                              }}
                              disabled={this.props.updataPriceStatus?.data ? this.props.updataPriceStatus?.data[pricingItem.store_id]?.status === 2 || this.props.updataPriceStatus?.data[pricingItem.store_id]?.status ===3  ? true :  false : null} //店铺状态为2/3时按钮不可用
                            >
                              <div className={styles.title}>
                              {intl.get('setting.pricing.advancedpricerule')}
                                <span>
                                  {intl.getHTML(
                                    'setting.pricing.advanced_more'
                                  )}
                                </span>
                              </div>
                            </Radio>
                          </div>
                          <div className={styles.contentBox}>
                            <div className={styles.advBox}>
                              <p className={styles.advBoxT}>
                              {isCustomizeUser && pricingItem.store_id == customizeCur.storeId ?
                              "Price value = [(Product Cost + Fixed Profit) × (1+ Profit %) + Shipping Cost] / (1 - Breakeven %)" :
                              intl.getHTML('setting.pricing.formula_text_1')}
                              </p>
                              {(!isCustomizeUser ||  pricingItem.store_id != customizeCur.storeId ) && <p className={styles.advBoxR}>
                              {intl.getHTML('setting.pricing.formula_text_reminder')}
                              </p>}

                              <div className={styles.advLine}>
                                <p className={styles.advLineT}>
                                  {intl.get('setting.pricing.setpricing')}
                                </p>
                                <p
                                  //style={{cursor:disabledStatus?'not-allowed':'pointer'}}
                                  className={styles.icon}
                                  onClick={() => {
                                    this.ProShow(true, index,disabledStatus);
                                  }}
                                >
                                  <i class="material-icons notranslate">
                                    settings
                                  </i>
                                </p>
                              </div>
                            </div>
                            {showAdvanced ? null : (
                              <div className={styles.mask}></div>
                            )}
                          </div>
                          <AdvancedModel
                            visible={showModal[index]}
                            pricingItem={pricingItem}
                            disabledStatus={disabledStatus}
                            isCustomizeUser={this.state.isCustomizeUser && pricingItem.store_id == customizeCur.storeId}
                            ProMinMax={this.ProMinMax}
                            changeProParam={this.changeProParam}
                            changeProRestParam={this.changeProRestParam}
                            changeShippingCost={this.changeShippingCost}
                            changeRestShippingCost={this.changeRestShippingCost}
                            AllSelectShippingCost={this.AllSelectShippingCost}
                            cancelAdvanced={this.cancelAdvanced}
                            deleteProItem={this.deleteProItem}
                            savePricingItem={this.savePricingItem}
                            ProErrTxt1={ProErrTxt1}
                            ProErrTxt2={ProErrTxt2}
                            index={index}
                            allCountry={countrys}
                            alltrackTM={logistics_services_tm}
                            alltrack={logistics_services}
                            changeFixShoppingCost={this.changeFixShoppingCost}
                            changeShipppingStatus={this.changeShipppingStatus}
                            changeCountry={this.changeCountry}
                            changeCountryTrack={this.changeCountryTrack}
                            ProComparedPriceShow={this.ProComparedPriceShow}
                            changeProPattern={this.changeProPattern}
                            changeProRestPattern={this.changeProRestPattern}
                            changeProductCost={this.changeProductCost}
                            changeCountryCost={this.changeCountryCost}
                            changeTmallCountry={this.changeTmallCountry}
                            changeTmallCountryTrack={this.changeTmallCountryTrack}
                          />
                        </div>
                      </div>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
            <div style={{marginTop:'20px'}}>
              <div className={styles.pricingRuleTitle}>{intl.get('setting.pricing.currency')}</div>
              <div className={styles.pricingRuleCurrency}>
                <p className={styles.pricingRuleCurrencyDec}>
                  {hasSetCurrency ? (
                     intl.get('setting.pricing.apply_to_mes')
                  ) : (
                    <>
                      {intl.get('setting.pricing.apply_go')}
                      <a
                        href="/app/settings?id=3"
                        target="_blank"
                        onClick={this.showUpdateCurrency}
                      >
                        {intl.get('setting.pricing.apply_btn')}
                      </a>
                      {intl.get('setting.pricing.apply_to')}
                    </>
                  )}
                </p>
                <div className={styles.pricingRuleCurrencyCheckbox}>
                  <Switch
                    disabled={!hasSetCurrency}
                    checked={currencyChecked}
                    onChange={this.changeUseCurrency}
                  />
                </div>
              </div>
            </div>
            {/* ASSIGN CENT */}
            <div
              className={styles.pricingFoot}
              style={
                PricingList.length != 0
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            >
              <p className={styles.pricingFootTitle}>
                {intl.get('setting.pricing.assign_cents')}
              </p>
              <div className={styles.pricingFootCont}>
                <p className={styles.pricingFootContTxt}>
                  {intl.get('setting.pricing.assign_cents_description')}
                </p>
                <div className={styles.pricingFootInp}>
                  <div className={styles.pricingFootInp1}>
                    <Checkbox
                      onChange={e => {
                        this.centsPriceStatus(e, 1);
                      }}
                      checked={AssignCentsData.price_status}
                    >
                      
                    </Checkbox>
                    <div className={styles.cents}>{intl.get('setting.pricing.assign_cents')}</div>
                    <InputNumber
                      min={0}
                      max={99}
                      precision={0}
                      value={AssignCentsData.price_cents}
                      disabled={!AssignCentsData.price_status}
                      style={
                        AssignCentsData.price_cents ||
                        AssignCentsData.price_cents == 0 ||
                        !AssignCentsData.price_status
                          ? {}
                          : { borderColor: '#f5222d' }
                      }
                      onChange={value => {
                        this.centsPriceCents(value, 1);
                      }}
                    />
                  </div>
                  <div className={styles.pricingFootInp1}>
                    <Checkbox
                      onChange={e => {
                        this.centsPriceStatus(e, 2);
                      }}
                      checked={AssignCentsData.compared_status}
                    >
                      
                    </Checkbox>
                    <div className={styles.cents}>{intl.get('setting.pricing.assign_compared_at_cents')}</div>
                    <InputNumber
                      min={0}
                      max={99}
                      precision={0}
                      value={AssignCentsData.compared_cents}
                      disabled={!AssignCentsData.compared_status}
                      style={
                        AssignCentsData.compared_cents ||
                        AssignCentsData.compared_cents == 0 ||
                        !AssignCentsData.compared_status
                          ? {}
                          : { borderColor: '#f5222d' }
                      }
                      onChange={value => {
                        this.centsPriceCents(value, 2);
                      }}
                    />
                  </div>
                </div>
                <div className={styles.pricingFootBtn}>
                  <Button
                    type="primary"
                    disabled={assignCentsDisabled}
                    onClick={this.saveAssignCents}
                    data="dx"
                  >
                    {intl.get('public.save')}
                  </Button>
                </div>
              </div>
            </div>
          </div> 
          :
             <div className={styleList.noData}>
             <img  src={require('../../../../../assets/setting/noData.png')} />
             <a href='https://apps.shopify.com/dsers'>{intl.get('setting.tracking.add_a_shopify_store')}</a>
           </div> 
           }
        </div>
    
   
      </>
    );
  }
}
