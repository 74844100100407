import React,{useEffect, useState}  from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'dva';
import { Switch } from 'antd';
import personicon from 'assets/setting/personicon.png';
import { CommonDataCollect } from 'utils/utils'
import { GuildeAction, LinkStoreAction } from 'actions'
import styles from './Index.less'
 const PackageTracking = ({plan}) =>{
    const dispatch=useDispatch()
    const [showFeature,isShowFeature] = useState(true)
    useEffect(()=>{
        getPackageTracking()
    },[])
    useEffect(()=>{
      if([1,4,8,10].includes(plan.type)){
        isShowFeature(false)
      }
    },[plan])
    const [pageTrack,setPageTrack] = useState([])
    const getPackageTracking = () =>{
        dispatch({
            type: 'dropshippersetting/getTrackingList',
            payload: {
              callback: d => {
                if(d.code == 2000 && d.data && Array.isArray(d.data)){
                  setPageTrack([...d.data])
                }
               
              }
            }
          });
    }
    const changeTrackingStatus = (id,value) =>{
      if([1,10, 4, 8,13,14,16,17,18,19,20].includes(plan?.type)){
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'Tracking')
        return
      } 
      // if(!showFeature){
      //   window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Setting-Tracking&${Date.now()}`, true);
      //   return
      // }
      // 如果开启的话 打点
       CommonDataCollect({
          action: value  ? "Tracking_status" : "Tracking_close",
          event_type:'overlimit_pop'
        });
        dispatch({
         type: 'dropshippersetting/setTrackingList',
         payload: {
          data: {
            store_id: id,
            status:value
          },
          callback: d => {
            if(d.code == 2000){
              getPackageTracking()
            }
          }
        }
      });
    }
    return (<div className={styles.packageTrackBox}>
      {
        pageTrack.map((item,index)=>{
          return (
          <div className={styles.packageTrackItem}>
            <div className={styles.packageTrackFont}>
            <i class="material-icons notranslate">storefront</i>
              {item.store_name}
            </div>
            <Switch
              onChange={(e)=>{
                changeTrackingStatus(item.store_id,e)
              }} 
              checked={showFeature ? item.status : false}>
              </Switch>
              </div>
            )
        })  
      } 
      </div>)
}
export default connect(({user }) => ({
   plan: user.plan,
  }))(PackageTracking);