import React, { Component } from 'react';

import intl from 'react-intl-universal';

import classnames from 'classnames';

import { Get, Post } from 'utils/request';

import {
  Form,
  Input,
  Select,
  Collapse,
  Button,
  message,
  Modal,
  Checkbox,
  Switch,
  Tooltip
} from 'antd';

import styles from './index.less';

import britishIndianOceanTerritory from '../../../../../../../assets/nationalflag/britishIndianOceanTerritory.svg';
import { emailRegExp } from 'utils/constants';

import paypalIcon from 'assets/setting/paypal.png';
import stripeIcon from 'assets/setting/stripe.png';

const { Option } = Select;
const { Panel } = Collapse;

@Form.create()
class WorkingArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnDisabled: true,
      loading: false,
      checkedGenerate: false,
      checkedRevoke: false,
      mb_status: true,
      mb_close_pop: false,
      save_model: false,
      erpKey: false,
      erpStatus: false,
      paymentMethods: [],
      add_item:{},
      ema_value:''
    };
  }
  componentDidMount() {
    const { item } = this.props;
    if (item.action == 1) {
      this.setState({
        mb_status: true
      });
    }

    const initialPaymentMethods = [];
    if (item.pay_pal) {
      initialPaymentMethods.push({
        value: item.pay_pal,
        type: 'paypal'
      });
    }

    if (item.stripe_contact_email) {
      initialPaymentMethods.push({
        value: item.stripe_contact_email,
        type: 'stripe'
      });
    }

    if (!initialPaymentMethods.length) {
      initialPaymentMethods.push({
        value: '',
        type: 'paypal'
      });
    }
    this.setState({
      paymentMethods: initialPaymentMethods
    });
  }

  validatePayments() {
    const { paymentMethods } = this.state;
    const newItems = [];
    let isValid = true;
    for (const item of paymentMethods) {
      const newItem = { ...item };
      newItems.push(newItem);
      if (!newItem.value?.trim()) {
        isValid = false;
        newItem.status = 'error';
        newItem.help = 'Email is required';
        continue;
      }

      if (!emailRegExp.test(newItem.value)) {
        isValid = false;
        newItem.status = 'error';
        newItem.help = 'Invalid email format';
        continue;
      }

      newItem.status = undefined;
      newItem.help = undefined;
    }

    this.setState({
      paymentMethods: newItems
    });

    return isValid;
  }

  onPaymentMethodChange(value, index) {
    const { paymentMethods } = this.state;
    const newList = [...paymentMethods];
    const newItem = { ...newList[index] };
    newList[index] = newItem;
    newItem.type = value;
    this.setState(
      {
        paymentMethods: newList
      },
      () => {
        this.beModified();
      }
    );
  }

  onPaymentMethodEmailChange(value, index) {
    const { paymentMethods } = this.state;
    const newList = [...paymentMethods];
    const newItem = { ...newList[index] };
    newList[index] = newItem;
    newItem.value = value;

    this.setState(
      {
        paymentMethods: newList
      },
      () => {
        this.beModified();
      }
    );
  }

  handleSubmit = async (e, item) => {
    const {
      checkedGenerate,
      checkedRevoke,
      mb_status,
      paymentMethods,
      save_model,
      add_item
    } = this.state;

    if (Object.prototype.toString.call(e) == '[object Object]') {
      e.preventDefault();
    }
    const validatePaymentsRes = this.validatePayments();

    if (!validatePaymentsRes) {
      this.setState({
        save_model:false
      });
      return;
    }

    const paypal = paymentMethods.find(i => i.type === 'paypal')?.value || '';

    const stripe = paymentMethods.find(i => i.type === 'stripe')?.value || '';

    const { form } = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });

        const {
          AgencyID,
          Phone,
          PhoneCountryCode,
          // paypal,
          AgencyName
        } = values;

        let obj = {
          agency_name: AgencyName.trim(),
          agency_id: Number(AgencyID.split('^')[0]),
          agency_type: AgencyID.split('^')[1],
          phone: Phone,
          phone_country_code: PhoneCountryCode,
          pay_pal: paypal,
          stripe_contact_email: stripe
        };

        if (item?.id) {
          if (obj.agency_type !== 'DIANXIAOMI') {
            obj.action = 2;
          }
          obj = Object.assign(item, obj);
        }

        if (checkedGenerate) {
          obj.action = 1;
        } else if (checkedRevoke) {
          obj.action = 2;
        }

        if (obj.agency_type == 'MABANG') {
          if (mb_status) {
            obj.action = 1;
          } else {
            obj.action = 2;
          }
        }
        // if(save_model === false){
          const result = await Post('/set/agency', {
            data: obj
          });
          if (result.code === 2000) {
            // message.success('success');
            const { dispatch } = this.props;
            this.setState({
              btnDisabled: true
            });
  
            dispatch({ type: 'global/getAgencyList' });
  
            // 添加成功后删除添加内容  修改内容时将名称前后空格去掉
            if (!item?.id) {
              const { setFieldsValue } = this.props.form;
              const emptyFields = {};
              Object.keys(values).forEach(item => {
                if (item !== 'PhoneCountryCode') {
                  emptyFields[item] = undefined;
                } else {
                  emptyFields[item] = 'CN';
                }
              });
              setFieldsValue(emptyFields);
              this.setState({
                paymentMethods: [{ type: 'paypal', value: '' }]
              });
            } else {
              const { setFieldsValue } = this.props.form;
              const emptyFields = {};
              Object.keys(values).forEach(item => {
                if (item === 'AgencyName') {
                  emptyFields[item] = values[item].trim();
                } else {
                  emptyFields[item] = values[item];
                }
              });
              setFieldsValue(emptyFields);
            }
          }
  
        // }
        
        
        this.setState({
          loading: false,
          btnDisabled: true,
          checkedGenerate: false,
          checkedRevoke: false,
          erpKey: false,
          save_model:false
        });
      }
    });
  };

  beModified = async (e, isDXM) => {
    const { form } = this.props;
    try {
      const { AgencyID, AgencyName } = await form.getFieldsValue();
      const valueError = await form.getFieldsError();
      const validatePaymentsRes = this.validatePayments();
      console.log(validatePaymentsRes);
      if (
        AgencyID &&
        AgencyName &&
        !valueError.AgencyID &&
        !valueError.AgencyName &&
        validatePaymentsRes === true
      ) {
        this.setState({
          btnDisabled: false
        });
      } else {
        this.setState({
          btnDisabled: true
        });
      }
    } catch (error) {
      console.log(error);
    }

    if (isDXM) {
      let erpName = e.split('^')[1];
      this.setState({
        erpKey: erpName === 'DIANXIAOMI',
        erpStatus: erpName !== 'DIANXIAOMI'
      });
    }
  };

  changeGenerate = async event => {
    this.setState({
      btnDisabled: false,
      checkedGenerate: event.target.checked
    });
  };

  changeRevoke = async event => {
    this.setState({
      btnDisabled: false,
      checkedRevoke: event.target.checked
    });
  };

  changeMaBangStatus = (e, item) => {
    if (e) {
      this.setState(
        {
          mb_status: true
        },
        () => {
          this.handleSubmit(e, item);
        }
      );
    } else {
      this.setState({
        mb_close_pop: true
      });
    }
  };

  duplicateContent = async (item, name) => {
    let copyContent = '';
    if (name === 'MB') {
      copyContent = `URL: ${item.url}\nConsumer Key: ${item.code}\nConsumer Secret: ${item.secret}\n如需获取帮助请查看:${item.help_url}`;
    } else {
      copyContent = `URL: ${item.url}\nAPI Key: ${item.code}\n如需获取帮助请查看:${item.help_url}`;
    }
    try {
      await navigator.clipboard.writeText(copyContent);
      message.success('Copy succeed.');
    } catch (error) {
      message.error('Copy failed, please try again.');
      console.log(error);
    }
  };

  showApiKey = (
    item,
    erpStatus,
    erpKey,
    checkedGenerate,
    checkedRevoke,
    loading
  ) => {
    if (erpStatus) {
      return null;
    }
    const { mb_status } = this.state;
    if (item.agency_type === 'DIANXIAOMI' || erpKey) {
      return (
        <div className={styles.apiKey}>
          {item.action === 1 ? (
            <>
              <Checkbox
                checked={checkedRevoke}
                onChange={this.changeRevoke}
                disabled={loading}
              >
                Revoke DIANXIAOMI URL&API Key
              </Checkbox>

              <div className={styles.describe}>
                <span onClick={() => this.duplicateContent(item, 'DXM')}>
                  Click here to copy URL&API Key
                </span>{' '}
                and send to Agency to complete authorization.{' '}
                <Tooltip
                  placement="top"
                  title="Please send the copied content to this supplier. The supplier will complete the authorization."
                >
                  <span>
                    <i className="material-icons">help_outline</i>
                  </span>
                </Tooltip>
              </div>
              <div className={styles.data}>
                <div className={styles.item}>
                  <span>URL:</span>
                  <span>{item.url}</span>
                </div>
                <div className={styles.item}>
                  <span>API Key:</span>
                  <span>{item.code}</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <Checkbox
                checked={checkedGenerate}
                disabled={loading}
                onChange={this.changeGenerate}
              >
                Generate DIANXIAOMI URL&API Key
              </Checkbox>
            </>
          )}
        </div>
      );
    }
    if (item.agency_type == 'MABANG') {
      return (
        <div className={styles.apiKey}>
          <div className={styles.mb_topstatus}>
            <div className={styles.mb_topstatus_tip}>
              Apply MABANG URL,Consumer Key & Consumer Secret
              <Tooltip
                placement="top"
                title="After enabling this feature, the system will automatically generate a set of authorization information for you when the supplier authorizes your store. If this feature is turned off, the authorization information will become invalid and be deleted. Turning on this function again will generate new authorization information."
              >
                <span style={{ marginLeft: '10px' }}>
                  <i className="material-icons" style={{ fontSize: '18px' }}>
                    help_outline
                  </i>
                </span>
              </Tooltip>
            </div>
            <Switch
              checked={item.action == 1 ? true : false}
              onChange={e => {
                this.changeMaBangStatus(e, item);
              }}
            />
          </div>
          {item.action === 1 && (
            <>
              <div className={styles.describe}>
                <span onClick={() => this.duplicateContent(item, 'MB')}>
                  Click here to copy URL,Consumer Key&Consumer Secret
                </span>{' '}
                and send to Agency to complete authorization.{' '}
                <Tooltip
                  placement="top"
                  title="Please send the copied content to this supplier. The supplier will complete the authorization."
                >
                  <span>
                    <i className="material-icons">help_outline</i>
                  </span>
                </Tooltip>
              </div>
              <div className={styles.mb_data}>
                <div className={styles.item}>
                  <span>URL:</span>
                  <span style={{ whiteSpace: 'nowrap' }}>{item.url}</span>
                </div>
                <div className={styles.item}>
                  <span>Consumer Key:</span>
                  <span>{item.code}</span>
                </div>
                <div className={styles.item}>
                  <span>Consumer Secret:</span>
                  <span>{item.secret}</span>
                </div>
              </div>
            </>
          )}
        </div>
      );
    }
  };

  render() {
    const {
      item,
      index,
      worldTelephoneAreaCode,
      erpData,
      form,
      workingAreaData
    } = this.props;
    const { getFieldDecorator, getFieldsValue } = form;

    const {
      btnDisabled,
      loading,
      checkedGenerate,
      checkedRevoke,
      erpKey,
      mb_close_pop,
      erpStatus,
      paymentMethods,
      save_model,
      ema_value
    } = this.state;

    const prefixSelector = getFieldDecorator('PhoneCountryCode', {
      initialValue: item?.phone_country_code || 'CN'
    })(
      <Select
        placeholder={intl.get('setting.agency.phonePlaceholder')}
        onChange={e => this.beModified(e, true)}
        showSearch
        style={{ width: 100 }}
        optionLabelProp="label"
        dropdownMatchSelectWidth={false}
        optionFilterProp="children"
        filterOption={(input, option) => {
          return (
            option.props.children.props.children[2]
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0 ||
            option.props.children.props.children[4]
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          );
        }}
        getPopupContainer={triggerNode =>
          document.getElementById(`${index}settingAgencyForm`) || document.body
        }
      >
        {worldTelephoneAreaCode.map(item => (
          <Option
            value={item.country_code}
            className={styles.countryCodeStyle}
            key={item.country_code}
            label={
              <div className={styles.optionLabelProp}>
                <img
                  src={item.country_flag || britishIndianOceanTerritory}
                  alt=""
                />{' '}
                {item.country_calling_code}
              </div>
            }
          >
            <>
              <img
                src={item.country_flag || britishIndianOceanTerritory}
                alt=""
              />{' '}
              {item.country_name} {item.country_calling_code}
            </>
          </Option>
        ))}
      </Select>
    );

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };

    let selectInitialValue;

    if (item?.id) {
      selectInitialValue = `${item.agency_id}^${item.agency_type}`;
    }



    return (
      <div id={`${index}settingAgencyForm`}>
        <Form {...formItemLayout} >
          <Form.Item
            label={
              <>
                <Tooltip
                  getPopupContainer={() =>
                    document.getElementById(`${index}settingAgencyForm`) ||
                    document.body
                  }
                  title={intl.get('setting.agency.name_tip')}
                >
                  {intl.get('setting.agency.name')}
                </Tooltip>
                <p>*</p>
              </>
            }
          >
            {getFieldDecorator('AgencyName', {
              rules: [
                {
                  required: true,
                  message: `${intl.get('setting.agency.nameErr')}`
                },
                {
                  pattern: /(?!^$)([^\s])/,
                  message: `${intl.get('setting.agency.nameErr')}`
                },
                {
                  validator: (rule, value, callback) => {
                    let flag = true;
                    workingAreaData.forEach(ele => {
                      if (
                        Object.keys(ele).length &&
                        item.id !== ele.id &&
                        value === ele.agency_name
                      ) {
                        flag = false;
                        callback('The same agency name already exists.');
                      } else if (
                        Object.keys(ele).length &&
                        item.id !== ele.id &&
                        value.trim() === ele.agency_name
                      ) {
                        flag = false;
                        callback('The same agency name already exists.');
                      }
                    });
                    if (flag) {
                      callback();
                    }
                  }
                }
              ],
              initialValue: item?.agency_name
            })(
              <Input
                placeholder={intl.get('setting.agency.name')}
                style={{ width: 190 }}
                onChange={this.beModified}
                maxLength={20}
                autoComplete="off"
              />
            )}
          </Form.Item>

          <Form.Item
            label={
              <>
                <Tooltip
                  getPopupContainer={() =>
                    document.getElementById(`${index}settingAgencyForm`) ||
                    document.body
                  }
                  title={intl.get('setting.agency.erp_tip')}
                >
                  {intl.get('setting.agency.erp')}
                </Tooltip>
                <p>*</p>
              </>
            }
          >
            {getFieldDecorator('AgencyID', {
              rules: [
                {
                  required: true,
                  message: `${intl.get('setting.agency.erpErr')}`
                }
              ],
              initialValue: selectInitialValue
            })(
              <Select
                placeholder={intl.get('setting.agency.erpPlaceholder')}
                style={{ width: 190 }}
                disabled={!!item?.id}
                onChange={this.beModified}
                getPopupContainer={triggerNode =>
                  document.getElementById(`${index}settingAgencyForm`) ||
                  document.body
                }
              >
                {erpData.map(ele => (
                  <Option
                    value={`${ele.agency_id}^${ele.agency_name}`}
                    key={`${ele.agency_id}^${ele.agency_name}`}
                  >
                    {ele.agency_name}
                  </Option>
                ))}
              </Select>
            )}

            {this.showApiKey(
              item,
              erpStatus,
              erpKey,
              checkedGenerate,
              checkedRevoke,
              loading
            )}
          </Form.Item>
          {/* payment methods */}
          <Form.Item
            label={
              <>
                <Tooltip
                  getPopupContainer={() =>
                    document.getElementById(`${index}settingAgencyForm`) ||
                    document.body
                  }
                  title={intl.get('setting.agency.agencyPaymentTip')}
                >
                  {intl.get('setting.agency.paymentMethod')}
                </Tooltip>
                <p>*</p>
              </>
            }
          >
            {paymentMethods.map((i, paymentIndex) => (
              <div key={`payment_method_${paymentIndex}`}>
                <div className={styles.paymentMethodItem}>
                  <Form.Item help={i.help} validateStatus={i.status}>
                    <Input
                      addonBefore={
                        <Select
                          getPopupContainer={triggerNode =>
                            document.getElementById(
                              `${index}settingAgencyForm`
                            ) || document.body
                          }
                          onChange={e =>
                            this.onPaymentMethodChange(e, paymentIndex)
                          }
                          value={i.type}
                        >
                          <Select.Option
                            disabled={paymentMethods.find(
                              i => i.type === 'paypal'
                            )}
                            value="paypal"
                          >
                            <span
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                style={{ marginRight: 4 }}
                                width="20"
                                height="20"
                                src={paypalIcon}
                              />{' '}
                              Paypal
                            </span>
                          </Select.Option>
                          <Select.Option
                            disabled={paymentMethods.find(
                              i => i.type === 'stripe'
                            )}
                            value="stripe"
                          >
                            <span
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                style={{ marginRight: 4 }}
                                width="20"
                                height="20"
                                src={stripeIcon}
                              />{' '}
                              Stripe
                            </span>
                          </Select.Option>
                        </Select>
                      }
                      value={i.value}
                      placeholder={intl.get(
                        i.type === 'paypal'
                          ? 'setting.agency.paypalPlaceholder'
                          : 'setting.agency.agencyContactEmail'
                      )}
                      style={{ width: 348 }}
                      onChange={e =>{
                        this.setState({ema_value:e.target.value});
                        this.onPaymentMethodEmailChange(
                          e.target.value,
                          paymentIndex
                        )
                      }}
                      maxLength={50}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <div className={styles.paymentMethodOperation}>
                    {paymentMethods?.length === 1 ? (
                      <span
                        onClick={() =>
                          this.setState(
                            { 
                              paymentMethods: [
                                ...paymentMethods,
                                {
                                  type: ['paypal', 'stripe'].find(type =>
                                    paymentMethods.find(
                                      payment => payment.type !== type
                                    )
                                  ),
                                  value: '',
                                 
                                }
                              ]
                            },
                            () => this.beModified()
                          )
                        }
                        className="material-icons notranslate"
                      >
                        add
                      </span>
                    ) : (
                      <span
                        onClick={() =>{
                          this.setState(
                            { 
                              paymentMethods: paymentMethods.filter(
                                (_, _index) => _index !== paymentIndex
                              ),
                            },
                            () => this.beModified()
                          )
                        }}
                        className="material-icons notranslate"
                      >
                        delete
                      </span>
                    )}
                  </div>
                </div>

                {item.stripe_contact_email && i.type === 'stripe' ? (
                  <div className={styles.stripeTip}>
                    <span>{intl.get('setting.agency.stripeAccount')}: </span>
                    <span>
                      {item.stripe_account_email ? (
                        item.stripe_account_email
                      ) : (
                        <span style={{ color: '#F44436' }}>
                          {intl.get('setting.agency.stripeNoAuth')}
                        </span>
                      )}
                    </span>
                  </div>
                ) : null}
              </div>
            ))}
          </Form.Item>

          <Form.Item
            label={
              <>
                <Tooltip
                  getPopupContainer={() =>
                    document.getElementById(`${index}settingAgencyForm`) ||
                    document.body
                  }
                  title={intl.get('setting.agency.phone_tip')}
                >
                  {intl.get('setting.agency.phone')}
                </Tooltip>
              </>
            }
          >
            {getFieldDecorator('Phone', {
              rules: [
                {
                  pattern: /^(0|[1-9][0-9]*)$/,
                  message: `${intl.get('setting.agency.phoneErr')}`
                }
              ],
              initialValue: item?.phone
            })(
              <Input
                addonBefore={prefixSelector}
                style={{ width: 348 }}
                onChange={this.beModified}
                maxLength={20}
                autoComplete="off"
              />
            )}
          </Form.Item>

          <div className={styles.button}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={btnDisabled || loading}
                loading={loading}
                onClick={() => {  
                  this.setState({save_model:true,add_item:item})
                }} 
                >
                {intl.get('mapping.verified.save')}
              </Button>
            </Form.Item>
          </div>
        </Form>

        <Modal
        visible={save_model}
        title={intl.get('setting.agency.savetitle')}
        open={ ()=>{
          this.setState({save_model:true})
        }}
        onCancel={
          ()=>{
            this.setState({save_model:false})
          }
        }
        footer={
          <Button type="primary" 
          onClick={(e) => {  
            this.handleSubmit(e,this.state.add_item);
            // this.setState({save_model:false})
          }} 
          >
          { intl.get('mapping.verified.save')}
          </Button>
        }
      >
          { intl.get('setting.agency.savetext',{email:ema_value})}
        
      </Modal>


      
        <Modal
          visible={mb_close_pop}
          title="Deactivate Authorization"
          onCancel={() => {
            this.setState({
              mb_close_pop: false
            });
          }}
          footer={
            <>
              <Button
                type="primary"
                onClick={e => {
                  this.setState(
                    {
                      mb_close_pop: false,
                      btnDisabled: false,
                      mb_status: false
                    },
                    () => {
                      this.handleSubmit(e, item);
                    }
                  );
                }}
              >
                CONFIRM
              </Button>
            </>
          }
        >
          Do you want to deactivate the authorization information? After
          deactivating this feature, the authorization information will become
          invalid and be deleted. Turning on this function again will generate
          new authorization information.
        </Modal>
      </div>
    );
  }
}

export default WorkingArea;
