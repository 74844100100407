import React from 'react'
import './setting.css'

import intl from 'react-intl-universal';

export default function Title(props) {
  return (
    <div className='contitles'>
      <div >{props.title}</div>
      <div style={{display:props.description?'block':'none'}} className='contitles-des dec'>{props.description}</div>
      <div style={{display:props.description2?'block':'none',color:'#FF8F00'}} className='contitles-des'>{props.description2}</div>
    </div>
  )
}
