import React, { Component } from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import {
  Button,
  Card,
  Checkbox,
  Row,
  Col,
  Collapse,
  DatePicker,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Radio, 
  Select,
  Switch,
  Tooltip,
  Alert,
  InputNumber
} from 'antd';

import moment from 'moment-timezone';
import qs from 'qs';
import { ConfigProvider, Tabs } from 'antd';

import className from 'classnames';

import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';

import { isFreshEntrust } from 'utils/constants';
import withTracker from '../../../common/tracker';
import reactGaEvent from '../../../utils/GAEvent';
import BillingBalance from './Common/BillingBalance/Index';
import Pricing from './Common/Pricing/index';
import Tracking from './Common/Tracking/Tracking';
import PackageTracking from './Common/PackageTracking/Index';
import VerifiedSupplier from './Common/VerifiedSupplier/Index';
import Account from './Common/Account/Index';
import ProductShipping from './Common/Shipping/ProductShipping';
import Notification from './Common/Notification/Index';
import PriceUpdate from './Common/PriceUpdate/Index';
import Supply from './Common/Supply';
import Report from './Common/Report';
import CancelRequestFulfillment from './Common/CancelRequestFulfillment/CancelRequestFulfillment';
import ActivationEmail from './Common/Account/ActivationEmail';
import OrderSplit from '../component/orderSplit'
import StaffAccount from './Common/StaffAccount/StaffAccount.jsx'
import WhyDisconnect from 'components/WhyDisconnect/WhyDisconnect';
import Currencies from './Common/Stores/Currencies';
import Title from './Title';
import { customizeUpdate, getCustomize } from '../../../services/customize';
import { CAM002 } from 'components/Cam';

import styles from './List.less';
import './setting.css';
import styless from './Common/Account/index.less';
import { Post, Get } from '../../../utils/request';
import { getLanguage } from '../../../utils/utils';
import { confirmLanguage } from 'common/sysconfig';

import sel from 'assets/setting/selected.png';
import loadingIcon from 'assets/setting/loadingIcon.png';
import settingicon from 'assets/setting/settingicon.png';
import personicon from 'assets/setting/personicon.png';
import editicon from 'assets/setting/editicon.png';
import leftExpend from 'assets/setting/left_expend.png'
import { GuildeAction, LinkStoreAction } from 'actions'
import _ from 'lodash';
import GuideRefresh from '../../../components/GuideRefresh'

import { CommonDataCollect } from '../../../utils/utils';
import ProductLimit from 'components/ProductLimit';
import Payments from './Common/Payments/Index';

import Supplier from './Common/Supplier'
import { Put } from 'utils/request';
import { updateAgencyOrderGlobalMessage } from 'services/dropshippersetting';
import { PlaceAutomaticly } from './Common/Order/PlaceAutomaticly';
import { AgencyTax } from './Common/Order/AgencyTax';

const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;
const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Search } = Input;
const InputGroup = Input.Group;

/**
 *
 *
 *  为了覆盖之前的逻辑, tabChangeSta 参数值需要在此记录  做容错处理
 *
 *  8 Account
 *  1 Shipping
 *  2 Tracking
 *  3 Notification
 *  5 Pricing Rule
 *  9 Report
 *  4 Other
 *
 *
 */

let OrderSplitArr = [0, 1, 4, 8, 10];

const { type: orderType = 0 } = JSON.parse(localStorage.getItem('payment'));

// 价格自动同步适用的套餐类型 2: advanced 3: pro 5: business 6: enterprise
const acceptOrder = [2, 3, 5, 6, 8, 9,10];

const customizeData = [
  {
    label: intl.get('setting.product.customize_all'),
    value: 1
  },
  {
    label: intl.get('setting.product.customize_specifications'),
    value: 2
  },
  {
    label: intl.get('setting.product.customize_overview'),
    value: 3
  }
];

const priceruleMap = {  //pricerule相应套餐可使用次数
  1: 1,
  2: 4,
  3: 10,
  4: 1 ,
  5: 10,
  6: 10,
  7: 4,
  8: 1,
  9: 10,
  10: 1
}
const menuMap = {
  '1':intl.get('setting.tab_account'),//pp
  '2': intl.get('setting.tab_sub_account'),
  '14':"Payments",
  '3':intl.get('setting.tab_stores'),//pp
  '4':intl.get('setting.tab_product'),
  '5':intl.get('setting.tab_pricing_rule'),//pp
  '6':intl.get('setting.tab_shipping'),//pp
  '7':intl.get('setting.tab_order'),
  '8':intl.get('setting.tab_package_tracking'),//pp
  '9':intl.get('setting.tab_package_tracking'),
  '10':intl.get('setting.tab_notification'),//pp
  '11':intl.get('setting.title_synchronization'),//pp
  '12':intl.get('setting.tab_aff'),
  '13':intl.get('setting.tab_package_tracking'),
  '14':intl.get('setting.tab_sub_supplier'),
}
const orderFailCountrys = ["NL","FI"];
@connect(({ dropshippersetting, loading, topBtn, login, global, user,auth }) => {
  let country = dropshippersetting.countrys;
  if (country.findIndex(i => i.Code === 'Global') === -1) {
    country = country.slice();
    country.unshift({ Code: 'Global', Name: 'Global' });
  }
  return {
    countrys: country,
    updataPriceStatus: dropshippersetting.storyPriceStatus,
    logistics_services: dropshippersetting.logistics_services,
    logistics_services_tm:dropshippersetting.logistics_services_tm,
    tracking_company: dropshippersetting.tracking_company,
    admitadCheckCode: dropshippersetting.admitadCheckCode,
    userInfo: login.userInfo,
    loading: loading.models.dropshippersetting,
    topBtn,
    syncLoading: loading.effects['topBtn/isSync'],
    shippingLoaing: loading.effects['dropshippersetting/postShippingMethod'],
    timezoneRegion: global.timezoneRegion,
    unsupportedShipping: global.unsupportedShipping,
    plan: user.plan,
    vat: global.vat,
    newUserInfo: user.newUserInfo,
    isOberlo: auth?.isOberloUser,
    confirmPlan:topBtn?.fromOberloPlan,
    syncStoreStatus:dropshippersetting.syncStoresStatus,
    productLimitStatus:topBtn.productLimit,
    TMCurrentMess:global.TmallBoard
  };
})
@withTracker
@Form.create()
export default class DropshpperSettingList extends Component {
  showSupplyBadge = false;

  constructor(props) {
    super(props);
    this.state = {
      track: ['ePacket', undefined, undefined],
      country: [['Global', [undefined], false]],
      TM_country: [['Global', [undefined], false]],
      shippingSameTip: [['', '', '']], // 选择相同物流时的记录
      storename: 'all',
      shippingChangeValue: 1,
      tabChangeSta: 1,
      textAreaVal: '',
      agencyMessageVal: '',
      iossVal: '',
      isShow: false,
      generalBtnDisabled: true,
      agencyMessageButtonDisabled: true,
      iossBtnDisabled: true,
      savePhoneBtnDisabled: true,
      errorText: '',
      countryErrorTip: false,
      countryErrorName: '',
      countryAdv: [['Global', [true, '', '', 1], false]],
      countryAdvErrorTip: false,
      generalChecked: false,
      advancedChecked: false,
      Inventory1Checked: false,
      Inventory2DefaultVal: [],
      PriceDefaultVal: [],
      CancelledOrderDefaultVal: false,
      SkuDefaultVal: false,
      OrderDefaultVal: [],
      autoUpdateSaveDisabled: true,
      syncDate: null,
      loopSync: false, // 是否需要轮询
      otherChecked: false, // other里面的开关状态
      NAChecked: false, // 加NA
      shippingGlobalCollapseValue: 'q0', // settting > shipping Collapse Value
      shippingAdvancedCollapseValue: 'q0', // setting > shipping advanced Collapse 的值
      storeData: [],
      syncStore: undefined,
      syncTimeTip: false,
      showSupplyChainVerified: false, // 是否展示供应链相关
      showEditCashback: false, // 修改激活返点
      cashbackCodelink: 'https://alitems.site/g/',
      cashbackCode: undefined,
      cashbackAccount: undefined,
      cashbackCodeErrorMsg: undefined,
      cashbackAccountErrorMsg: undefined,
      hasSyncLock: false, // 点击 async 上锁，后端服务操作完成时间的不确定性，如果没同步完会报错，所以上锁
      syncShopifyNotesStatus: false,
      rangePickerValue: undefined,
      overridePhoneNumber: [],
      defaultPhoneNumber: [],
      overrideBtn: true,
      defaultBtn: true,
      skuCustomize: false,
      vatTextAreaVal: '',
      vatDisable: false,
      vatId: '',
      vatErrorMsg: '',
      vatTextErrorMsg: '',
      vatTextDisable: false,
      isVatShow:false,
      isIossShow:false,
      collapseOptions: [],
      disconnectShopifyStoreId:undefined,
      saveShipping:true,
      saveAdvanced:true,
      orderHidden:false,
      showSubStaff:false,
      showUpdataPrcie:false,
      spStores:[],
      isCsvUser: this.props.userInfo.status.toString().substring(3, 4) === '1',
      TabsList : [
        {
          name: intl.get('setting.tab_account'),
          key:'tab1',
          dis: true,
          isSelected: false
        },
        {
          name:intl.get('setting.tab_sub_account'),
          key:'tab2',
          dis:this.isGetChildAccount(),  //admin的子账户可以使用
          isSelected:false
        },
        {
          name: intl.get('setting.tab_sub_supplier'), 
          key:'tab12',
          dis: true,
          isSelected:false
        },
        {
          name:"Payments",
          key:'tab13',
          dis:true,
          isSelected:false
        },
        {
          name: intl.get('setting.tab_stores'),
          key:'tab3',
          dis: true,
          isSelected: false
        },
        {
          name: intl.get('setting.tab_product'),
          key:'tab4',
          dis: true,
          isSelected: false
        },
        {
          name: intl.get('setting.tab_pricing_rule'),
          key:'tab5',
          dis: true,
          isSelected: false
        },
        {
          name: intl.get('setting.tab_shipping'),
          key:'tab6',
          dis: true,
          isSelected: true
        },
        {
          name: intl.get('setting.tab_order'),
          key:'tab7',
          dis: true,
          isSelected: false
        },
        {
          name: intl.get('setting.tab_package_tracking'),
          key:'tab8',
          dis: true,
          isSelected: false
        },

        {
          name: intl.get('setting.tab_notification'),
          key:'tab9',
          dis: true,
          isSelected: false
        },
        {
          name: intl.get('setting.title_synchronization'),
          key:'tab10',
          dis: true,
          isSelected: false
        },
        {
          name:intl.get('setting.tab_aff'),
          key:'tab11',
          dis:this.props.userInfo.code_type === 1 && this.props.userInfo.can_use_cashback && !this.props.newUserInfo?.user_role ? true :false,
          isSelected:false
        },
      ],
      containerPage: intl.get('setting.tab_shipping'),
      selectStory: [], //所选更新店铺列表
      storyIfUse: [], //所有店铺列表
      buttonLoading: false,
      allStoryId2: '',
      taxableStatus: false,
      itemFulfillChecked: false,
      showRefreshPop:false,
      location_data:[],
      orderSplitData:[],
      orderSplitPopShow:false,
      currentPS: "AE",
      currentMessPS:"AE",
      new_send_cost_able: 0,
      orderMessageStatus:false,
      agencyPlaceOrderAutomaticly: false,
    };
    // //tab样式
    // if((this.props.userInfo.code_type === 1 && this.props.userInfo.can_use_cashback && !this.props.newUserInfo?.user_role)){
    //   this.setState({
    //     TabsList:this.state.TabsList.filter((i)=>{
    //       return i.name != intl.get('setting.tab_aff')
    //     })
    //   });
    //   console.log(this.state.TabsList,'vvvvvvvvvvvvvvvvv');
    // }

    // 上线 30 天后不再展示
    const tmp = window.localStorage.getItem('view_supply_page');
    if (isFreshEntrust) {
      if (!tmp) {
        window.localStorage.setItem('view_supply_page', Date.now());
        this.showSupplyBadge = true;
      }
    } else {
      // eslint-disable-next-line
      tmp && window.localStorage.removeItem('view_supply_page', Date.now());
    }
  }

  NoDataPosition = () =>{
       return (
        <div className={styles.noData}>
        <img  src={require('../../../assets/setting/noData.png')} />
        <a href='https://apps.shopify.com/dsers'>{intl.get('setting.tracking.add_a_shopify_store')}</a>
        </div>
       )
  }
  componentDidMount() {
    // if(JSON.parse(localStorage.getItem('payment')).type != 3){
    //   this.setSkuCustomize(false)
    // }


    const spStores = this.props.userInfo.stores || [];
    this.setState({
      spStores
    });
    if (spStores.length) {
      this.getShopifyStoreStatus(0, spStores);
    }
    this.getStoreStatus();
    const {
      dispatch,
      timezoneRegion,
      history: {
        location: { search: linkSearch }
      }
    } = this.props;

    this.getTaxRevenueData();


    //当前店铺tooken过期，请点击setting重新授权
    if (linkSearch.includes('tabChangeSta')) {
      if (/(\d+)/g.test(linkSearch)) {
        this.setState({
          tabChangeSta: RegExp.$1
        });
      }
    }

    dispatch({
      type: 'global/getVat',
      payload: {
        data: {},
        callback: d => {
          this.setState({
            vatId: d?.data?.id,
            vatTextAreaVal: d?.data?.company
          },()=>{
            if(this.state.vatId || this.state.vatTextAreaVal){
                     this.setState({
                      isVatShow : true
                     })
            }
          });
        }
      }
    });

    this.getUrl();
    this.getGeneral();
    this.initialBaseCtrl();
    // this.getCuntryAndTrack();
    this.getAllPhoneNumber(1);
    this.getAllPhoneNumber(2);
    this.getSkuCustomize();
    // this.getOtherSta();
    // this.getShowSupplierTab();
    this.getSyncShopifyOrderDefaultTime();
    this.getOrderSplit();
    this.getOrderSplitData()
    this.getUstomization();
    this.getStoryIfuse();
    this.selectTabsHandler()
    this.getsettingLocation()
    this.initAgencySettings();
    
    const { userInfo } = this.props;

    const isCsvUser = userInfo.status.toString().substring(3, 4) === '1';

    this.setState({
      storeData: userInfo.stores || [],
      showEditCashback: !userInfo.code
    });

    if (timezoneRegion) {
      this.syncStartTime = moment()
        .subtract(29, 'days')
        .tz(timezoneRegion)
        .set({ hour: '0', minute: '0', second: '0' })
        .unix();
      this.syncEndTime = moment()
        .tz(timezoneRegion)
        .set({ hour: '23', minute: '59', second: '59' })
        .unix();
    }

    if (!this.props.newUserInfo?.user_role) {
      this.state.TabsList[1].dis = true
      this.setState({
        showSubStaff:true,
        TabsList:[...this.state.TabsList]
      })
    }

    if (!window.TMALL_USER) {
      let CAINIAO = this.state.TabsList.map((item, index) => {
        if(["tab13"].includes(item.key)){
                  return {...item,dis:false}
        }
        return item
      })
      this.setState({
        TabsList:[...CAINIAO]
      })
    }
    
    if (this.state.isCsvUser) {
      let CsvUserTab = this.state.TabsList.map((item, index) => {
        if(!["tab1","tab6", "tab12"].includes(item.key)){
                  return {...item,dis:false}
        }
        return {...item,dis:true}
      })
      this.setState({
        TabsList:[...CsvUserTab]
      })
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    if (this.props.timezoneRegion !== prevProps.timezoneRegion) {
      this.getSyncShopifyOrderDefaultTime();
    }
    if (this.props.userInfo.stores.length !== prevProps.userInfo.stores.length) {
      const spStores = this.props.userInfo.stores || [];
      this.setState({
        spStores
      });
      if (spStores.length) {
        this.getShopifyStoreStatus(0, spStores);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // 组件Props或者state改变时触发，true：更新，false：不更新
    if (
      nextState.spStores &&
      nextState.spStores.length == 0 &&
      `${nextState.status}`.length == '8' &&
      `${nextState.status}`.substring(3, 4) == 0
    ) {
      window.location.href = '/app/bind/platform';
    }
    return true;
  }

  initAgencySettings = () => {
    this.props.dispatch({
      type: 'dropshippersetting/getAgencySettings',
      payload: {
        data: {},
        callback: d => {
          console.log(d, 11111);
          if(d?.code === 2000) {
            this.setState({
              agencyMessageVal: d?.data?.desc || '',
              agencyPlaceOrderAutomaticly: d?.data?.AutomaticallyPlaceStatus || false
            })
          }
        }
      }
    });
  } 

  // 初始化 setting 选中页面
  selectTabsHandler = ()=>{
    const mentTabId = window.location.search?.split('=')[1];
    if(mentTabId && menuMap.hasOwnProperty(mentTabId)){
      if(!(mentTabId == 2 && !([2,3,5,6,7].includes(this.props.plan?.type) && !this.props.newUserInfo?.user_role) ||
      mentTabId == 12 && !(this.props.userInfo.code_type === 1 && this.props.userInfo.can_use_cashback && !this.props.newUserInfo?.user_role)
      )){
        this.setState({
          containerPage:menuMap[mentTabId],
          TabsList: this.state.TabsList.reduce((v, i) => {
            if (i.name == menuMap[mentTabId]) {
              i.isSelected = true;
              v.push(i);
            } else {
              i.isSelected = false;
              v.push(i);
            }
            return v
          }, [])
        }, () => {
          console.log('默认打开的值为', this.state.TabsList, mentTabId)
        })
      }
    }
  }
  
  // 初始化 setting location
  getsettingLocation = async () =>{
    try {
      const res = await Get('/fulfillment/set/location_management');
      if(res.code == 2000 && res.data?.length){
               this.setState({
                    location_data : res?.data
               })
      }
    } catch (error) {
      console.log('fetch error', error);
    }
  }

  // 检测 shopify store 授权状态是否可用
  getShopifyStoreStatus = (index, spStores) => {
    this.props.dispatch({
      type: 'dropshippersetting/shopifyStatus',
      payload: {
        data: {
          store_id: spStores[index].id
        },
        cancelMessage: true,
        callback: d => {
          this.setStoreUpdate(d, index); // 设置店铺应不应该从新授权
          if (spStores.length > index + 1) {
            this.getShopifyStoreStatus(index + 1, spStores);
          }
        }
      }
    });
  };

    // 检测 shopify 单个store 授权状态是否可用
    getShopifyStoreStatusSingle = (index, spStores) => {
      this.props.dispatch({
        type: 'dropshippersetting/shopifyStatus',
        payload: {
          data: {
            store_id: spStores[index].id
          },
          cancelMessage: true,
          callback: d => {
            this.setStoreUpdate(d, index); 
          }
        }
      });
    };

  // 设置店铺应不应该从新授权
  setStoreUpdate = (d, index) => {
    const { spStores } = this.state;
    let flag = false;

    // 故障判斷
    if (d.code == 5001) {
      spStores[index] && (spStores[index].break = true);
    } else {
      spStores[index] && (spStores[index].break = false);
    }
    if (d.code != 2000) {
      flag = true;
    } else if (d.data.is_available != true) {
      flag = true;
    }

    if (flag) {
      spStores[index] && (spStores[index].update = flag);
      this.setState({
        spStores: [...spStores]
      });
      return
    }
    //------------------------------------------------------------------------------------//
    //新增 sp 授权逻辑
    if(spStores[index] && spStores[index]?.news){
      spStores[index].news = (d?.data?.news && d.data.scope_ok != true) ? d?.data?.news : 0;
    }
    this.setState({
      spStores:[...spStores]
    })
  };

  get isValidateCashback() {
    const {
      cashbackAccount,
      cashbackCode,
      cashbackCodeErrorMsg,
      cashbackAccountErrorMsg
    } = this.state;
    return (
      cashbackAccount &&
      cashbackAccount.trim() &&
      cashbackCode &&
      cashbackCode.trim() &&
      !cashbackCodeErrorMsg &&
      !cashbackAccountErrorMsg
    );
  }

  getStoryIfuse = () => {
    const that = this;
    this.props.dispatch({
      type: 'guidance/getInfo',
      payload: {
        callback: d => {
          if (d.code === 2000) {
            that.setState({
              storyIfUse: d.data.stores
            });
          }
        }
      }
    });
  };
/**
 * 判断子账号入口是否显示
 * @returns  state
 */
  isGetChildAccount = () => {
    if (this.props.isOberlo && this.props.confirmPlan?.compare && [1,4,8].includes(this.props.confirmPlan?.dsers)) {
      return false
    }
    if (!this.props.newUserInfo?.user_role) {
      return true
    }
      return false
  }
  /**
   *
   * @param {*} id 店铺id
   * @param {*} index 店铺下标
   * @returns 店铺复选框状态 true/false
   */
  ifDisabled = (id, index) => {
    if (this.state.storyIfUse[index]?.status !== 1) {
      return true;
    } else if (
      this.props.updataPriceStatus?.data &&
      this.props.updataPriceStatus?.data[id]?.description ===
        'API has reached 1000000'
    ) {
      return true;
    } else if (
      this.props.updataPriceStatus?.data &&
      this.props.updataPriceStatus?.data[id]?.status === 1 &&
      !this.props.updataPriceStatus?.data[id]?.total
    ) {
      return true;
    }else if(this.props.syncStoreStatus?.plist && !this.props.syncStoreStatus?.plist[index]?.status){
      return true;
    }
     else if (
      this.props.updataPriceStatus?.data &&
      this.props.updataPriceStatus?.data[id]?.remain_count &&
      (this.props.updataPriceStatus?.data[id]?.status === 1 ||
      this.props.updataPriceStatus?.data[id]?.status === 5)
    ) {
      return false;
    }else if(
      this.props.updataPriceStatus?.data &&
      !this.props.updataPriceStatus?.data[id]?.remain_count &&
      this.props.updataPriceStatus?.data[id]?.status === 5
    ){
      return false
    } else {
      return true;
    }
  };

  /**
   *
   * @param {*} id 店铺id
   * @param {*} index 店铺下标
   * @returns 复选框悬浮展示的文案
   */
  showTitle = (id, index) => {
    if (this.state.storyIfUse[index]?.status !== 1) {
      //店铺不可用
      return intl.get('public.shopUnavailable');
    } else if (
      this.props.updataPriceStatus?.data &&
      this.props.updataPriceStatus?.data[id]?.description ===
        'API has reached 1000000'
    ) {
      //接口超限
      return intl.get('public.InterfaceTransfinite');
    } else if (
      this.props.updataPriceStatus?.data &&
     (this.props.updataPriceStatus?.data[id]?.status === 1 ||
      this.props.updataPriceStatus?.data[id]?.status === 5) &&
      !this.props.updataPriceStatus?.data[id]?.total
    ) {
      //没有可推送商品
      return intl.get('public.noproduct');
    } else if (
      this.props.updataPriceStatus?.data &&
      this.props.updataPriceStatus?.data[id]?.remain_count &&
      this.props.syncStoreStatus?.plist && this.props.syncStoreStatus?.plist[index]?.status &&
      (this.props.updataPriceStatus?.data[id]?.status === 1 ||
      this.props.updataPriceStatus?.data[id]?.status === 5)
    ) {
      //可用状态
      return `${intl.get('setting.update_pro_pr_des')}  ${intl.get('setting.update_pro_pr_des_time_1')} ${priceruleMap[orderType]} ${intl.get('setting.update_pro_pr_des_time_2')}`
    } else if (
      this.props.updataPriceStatus?.data &&
      this.props.updataPriceStatus?.data[id]?.remain_count == 0 &&
      this.props.updataPriceStatus?.data[id]?.status === 5
    ) {
      //完成状态
      let creattime = this.props.updataPriceStatus?.data[id]?.remain_time;
      let useday = Math.ceil(creattime / 86400);
      return <span>
        {intl.get('public.useAgain')}  {useday}  {intl.get('public.day')}. {intl.getHTML('public.jumpPrice')}
      </span>;
    } else if (
      this.props.updataPriceStatus?.data &&
      this.props.updataPriceStatus?.data[id]?.status === 4
    ) {
      //暂停状态 店铺超限
      return intl.get('public.pause');
    } else if (
      this.props.updataPriceStatus?.data &&
      this.props.updataPriceStatus?.data[id]?.status === 0
    ) {
      return intl.get('public.exceeds');
    }else if(this.props.syncStoreStatus?.plist && !this.props.syncStoreStatus?.plist[index]?.status){
      return intl.getHTML('public.priceruleDis');
    }
    else {
      return intl.get('public.synchronous'); //loading状态
    }
  };

  // 是否是供应链用户 用以显示 BillingBalance Pricing 两个tab
  getShowSupplierTab = () => {
    this.props.dispatch({
      type: 'dropshippersetting/showSupplierTab',
      payload: {
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              showSupplyChainVerified: d.data.supply_status
            });
          }
        }
      }
    });
  };

  getOrderSplit = async () => {
    let response = await Get('/set/order_setting/split_switch');
    if (response.code == 2000) {
      this.setState({
        orderHidden: response.data.switch
      });
    }
  };
  
  getOrderSplitData = async () =>{
    let res =  await Get('/set/order_setting/split_list');
    if (res.code === 2000) {
        this.setState({orderSplitData:res.data ? res.data : []})
    }
  }

  setOrderSplit = async params => {
    let response = await Post('/set/order_setting/split_switch', {
      data: params
    });
    if (response.code == 2000) {
      message.success(response.msg);
      this.getOrderSplit();
    }
  };

  getUstomization = async () => {
    let response = await Post('/set/order/get');
    if (response.code == 2000) {
      this.setState({
        itemFulfillChecked: response.data.custom_fulfill_switch
      });
    }
  }

  setUstomization = async params => {
    let response = await Post('/set/order/update', {
      data: params
    });
    if (response.code == 2000) {
      message.success(response.msg);
    }
  };

  getUrl = () => {
    const parse = qs.parse(location.search.slice(1));
    if (parse.id || parse.id == 0) {
      this.tabChange(parse.id);
    }
  };

  // 删除General里的国家出现的提示框
  showGeneralModal(index) {
    confirm({
      title: 'warning',
      content: 'Are you sure you want to remove this setting?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        this.deleteCountry(index);
      },
      onCancel() {}
    });
  }

  // 删除Advanced里的国家出现的提示框
  showAdvancedModal(index) {
    confirm({
      title: 'warning',
      content: 'Are you sure you want to remove this setting?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        this.deleteCountryAdvanced(index);
      },
      onCancel() {}
    });
  }

  // 获取留言和电话号码
  getGeneral = () => {
    this.props.dispatch({
      type: 'dropshippersetting/getGeneral',
      payload: {
        data: {
          ref: 'setting'
        },
        callback: d => {
         if (d.code == 2000) {
            this.setState({
              textAreaVal: d.data.desc || '',
              NAChecked: d.data.IsNa,
              syncShopifyNotesStatus: d.data.SyncShopifyNotesStatus,
              otherChecked: d.data.SyncOrderNoStatus,
              orderMessageStatus:d.data?.SyncCustomizationStatus && ![1,4,8,10,12,13,14,15,16,17].includes(this.props?.plan?.type)
            });
          }
        }
      }
    });
    this.props.dispatch({
      type: 'dropshippersetting/getIoss',
      payload: {
        callback: d => {
          if (d.code == 2000) {
            this.setState({
              iossVal: d.data || ''
            },()=>{
              if(this.state.iossVal){
                  this.setState({isIossShow:true})
              }
            });
          }
        }
      }
    });
  };

  // 筛选国家物流信息
  filterSaveCountry = (type) => {
    const obj = {};

    let { country } = this.state;

    if (type == 'TM') {
    country = this.state.TM_country;
    }
    country = country.filter(item => item[0]);

    country.forEach(item => {
      item[1] = item[1].filter(i => i);
    });

    country.forEach(item => {
      obj[item[0]] = item[1];
    });

    return obj;
  };

  // Avvanced筛选国家物流信息
  filterSaveCountryAdv = () => {
    const advanced_list = [];

    let { countryAdv } = this.state;

    countryAdv = countryAdv.filter(item => item[0]);

    countryAdv.forEach(item => {
      try {
        const [
          countryCode,
          [isAvaliable, deliveryDay, shippingCost, benchmark]
        ] = item;
        const obj = {
          country_code: countryCode,
          is_available: isAvaliable,
          delivery_day: Number(deliveryDay),
          shipping_cost: shippingCost === '' ? '' : Number(shippingCost),
          benchmark
        };
        advanced_list.push(obj);
      } catch (error) {
        console.log(error);
      }
    });
    return advanced_list;
  };

  // 保存物流
  saveCountryAndTrack = () => {
    const { track } = this.state;
    let { currentPS } = this.state;
    let country = currentPS == 'TM' ? this.state.TM_country : this.state.country;
    country = [...country];
    const shipping_default = track.filter(item => {
      return item;
    });
    let params = {}
    if (currentPS == 'AE') {
      params.shipping_country_list = this.filterSaveCountry('AE');
      params.update_supplier_type = 0
    }
    if (currentPS == 'TM') {
      params.tmall_shipping_country_list = this.filterSaveCountry('TM');
      params.update_supplier_type = 1
    }


    this.props.dispatch({
      type: 'dropshippersetting/saveTrack',
      payload: {
        data: {
          shipping_default,
          ...params
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            country.forEach(item => {
              if (!item[1].includes(undefined)) {
                item[1].push(undefined);
              }
            });
            if (currentPS == 'TM') {
              this.setState({ TM_country:country, saveShipping: true });
            } else {
              this.setState({ country, saveShipping: true });
            }
          }
        },
        message: {
          success: intl.get('setting.billing.save_succ'),
          error: 'save failure'
        }
      }
    });

    reactGaEvent({
      category: 'Setting',
      action: 'Shipping_General_Save'
    });
  };

  // 更改国家下的物流
  changeCountryTrack = (v, trackIndex, countryIndex) => {
    const { currentPS } = this.state
    let country = currentPS == 'TM' ? this.state.TM_country : this.state.country;
    country[countryIndex][1][trackIndex] = v;
    country[countryIndex][2] = false;
    if (!country[countryIndex][1].includes(undefined)) {
      country[countryIndex][1].push(undefined);
    }
    country = [...country];
    currentPS == 'TM' ? this.setState({ TM_country: country, saveShipping: false }) :
                        this.setState({ country, saveShipping: false })
  };

  // 添加国家下的物流
  addCountryTrack = countryIndex => {
    let {shippingSameTip,currentPS } = this.state;
    let country = currentPS == 'TM' ? this.state.TM_country : this.state.country;
    country[countryIndex][1].push(undefined);
    country[countryIndex][2] = false;
    shippingSameTip[countryIndex].push('');
    country = [...country];
    currentPS == 'TM' ? this.setState({ TM_country: country, saveShipping: false }) :
      this.setState({ country, saveShipping: false })
  };

  // 删除国家下的物流
  deleteCountryTrack = (trackIndex, countryIndex) => {
    const { currentPS } = this.state
    let country = currentPS == 'TM' ? this.state.TM_country : this.state.country;
    country[countryIndex][1].splice(trackIndex, 1);
    country = [...country];
    currentPS == 'TM' ? this.setState({ TM_country: country, saveShipping: false }) :
    this.setState({ country, saveShipping: false })
  };

  countryErrorIndex = 0; // General设置物流时第几个选择了相同的国家
  countryErrorName = ''; // General设置物流时选择相同国家的国家name

  // General更改国家
  changeCountry = (v, index, option) => {
    this.countryErrorName = v;
    const { currentPS } = this.state
    let country = currentPS == 'TM' ? this.state.TM_country : this.state.country;

    for (let i = 0; i < country.length; i++) {
      if (country[i][0] == v) {
        this.countryErrorIndex = index;
        v = undefined;
      }
    }

    country[index][0] = v;
    country[index][2] = false;
    country = [...country];

    this.generalCheckedArr[currentPS][index] = v;

    if (!this.generalCheckedArr[currentPS].includes('')) {
      country.push([undefined, [undefined], false]);
      this.generalCheckedArr[currentPS].push('');
    }
    currentPS == 'TM' ? this.setState({ TM_country: country}) :
      this.setState({country})
    
    this.setState({
      countryErrorTip: !v,
      shippingGlobalCollapseValue: `q${index}`,
      saveShipping: false
    });
  };

  // General添加国家
  addCountry = () => {
    let {shippingSameTip,currentPS } = this.state;
    let country = currentPS == 'TM' ? this.state.TM_country : this.state.country;
    country.push([undefined, [undefined, undefined, undefined], false]);
    shippingSameTip.push(['', '', '']);
    country = [...country];
    shippingSameTip = [...shippingSameTip];
    this.generalCheckedArr[currentPS].push('');

    currentPS == 'TM' ? this.setState({ TM_country: country}) :
    this.setState({country})
    
    this.setState({
      shippingSameTip,
      saveShipping: false
    });
  };

  // General删除国家
  deleteCountry = (index, value) => {
    let { currentPS } = this.state;
    let country = currentPS == 'TM' ? this.state.TM_country : this.state.country;
    country.splice(index, 1);
    country = [...country];

    this.generalCheckedArr[currentPS] = this.generalCheckedArr[currentPS].filter(item => {
      return item != value;
    });

    currentPS == 'TM' ? this.setState({ TM_country: country},()=>{ this.saveCountryAndTrack()}) :
    this.setState({country},()=>{this.saveCountryAndTrack()})
  };

  generalCheckedArr = {AE:[],TM:[]}
  advancedCheckedArr = {AE:[],TM:[]};

  // Advanced更改国家
  changeCountryAdvanced = (v, index) => {
    let { countryAdv,currentPS } = this.state;

    countryAdv[index][0] = v;
    countryAdv[index][2] = false;

    this.advancedCheckedArr[currentPS][index] = v;

    if (!this.advancedCheckedArr[currentPS].includes('')) {
      countryAdv.push([undefined, [true, '', '', 1], false]);
      this.advancedCheckedArr[currentPS].push('');
    }

    countryAdv = [...countryAdv];

    this.setState({
      countryAdv,
      countryAdvErrorTip: !v,
      shippingAdvancedCollapseValue: `q${index}`,
      saveAdvanced: false
    });
  };

  // Advanced添加国家
  addCountryAdvanced = () => {
    let { countryAdv } = this.state;
    countryAdv.push([undefined, [true, '', '', 1], false]);
    countryAdv = [...countryAdv];
    this.setState({ countryAdv, saveAdvanced: false });
  };

  // Advanced删除国家
  deleteCountryAdvanced = index => {
    let { countryAdv,currentPS } = this.state;
    countryAdv.splice(index, 1);
    countryAdv = [...countryAdv];
    //修复del导致数据结构混乱
    this.advancedCheckedArr[currentPS].splice(index, 1);

    countryAdv = [...countryAdv];

    this.setState(
      {
        countryAdv,
        countryAdvErrorTip: false
      },
      () => {
        this.saveCountryAdvanced();
      }
    );
  };

  // Advanced保存物流信息
  saveCountryAdvanced = () => {
    let { countryAdv } = this.state;
    let flag = true;
    countryAdv = [...countryAdv];
    const advanced_list = this.filterSaveCountryAdv();
    const advSettings = {};
    advanced_list.forEach(item => {
      if (item.delivery_day == 0 || item.shipping_cost === '') {
        flag = false;
      }
      advSettings[item.country_code] = item;
    });

    if (!flag) {
      message.error('Some fields are incorrect, please check');
      return;
    }

    this.props.dispatch({
      type: 'dropshippersetting/saveTrack',
      payload: {
        data: {
          shipping_advanced: advSettings
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({ countryAdv, saveAdvanced: true });
          }
        },
        message: {
          success: intl.get('setting.shipping.save_succ'),
          error: intl.get('setting.shipping.save_failure')
        }
      }
    });

    reactGaEvent({
      category: 'Setting',
      action: 'shipping_Advanced_Save'
    });
  };

  // Advanced Delivery date 输入框发生变化时的回调
  onInputChangeDate = (value, index) => {
    let { countryAdv } = this.state;

    countryAdv[index][1][1] = value;
    countryAdv[index][2] = false;
    countryAdv = [...countryAdv];
    this.setState({ countryAdv, saveAdvanced: false });
  };

  // Advanced Shipping cost 输入框发生变化时的回调
  onInputChangeCost = (value, index) => {
    let { countryAdv } = this.state;

    countryAdv[index][1][2] = Number(value).toFixed(2) ;
    countryAdv[index][2] = false;
    countryAdv = [...countryAdv];
    this.setState({ countryAdv, saveAdvanced: false });
  };

  // Tracking information Available 是否选中
  advCheckboxChange = (checked, index) => {
    let { countryAdv } = this.state;
    countryAdv[index][1][0] = checked;
    countryAdv[index][2] = false;
    countryAdv = [...countryAdv];
    this.setState({ countryAdv, saveAdvanced: false });
  };

  // less cost 和 less day 单选按钮发生改变时的回调
  shippingChoiceChange = (e, index) => {
    let { countryAdv } = this.state;
    countryAdv[index][1][3] = e.target.value;
    countryAdv[index][2] = false;
    countryAdv = [...countryAdv];
    this.setState({ countryAdv, saveAdvanced: false });
  };

  // 添加物流
  addtrack = () => {
    let { track } = this.state;
    track.push(undefined);
    track = [...track];
    this.setState({ track });
  };

  // 删除物流
  deleteTrack = index => {
    let { track } = this.state;
    track.splice(index, 1);
    track = [...track];
    this.setState({ track });
  };

  // 更改物流
  changeTrack = (v, index) => {
    let { track } = this.state;
    track[index] = v;
    track = [...track];
    this.setState({ track });
  };

  // 设置税收
  setTaxRevenueData = () => {
    this.props.dispatch({
      type: 'dropshippersetting/setTaxRevenue',
      payload: {
        data: {
          tax_able: this.state.taxableStatus,
          new_send_cost_able: this.state.new_send_cost_able,
          custom_product_desc: this.state.customizeVal
        },
        callback: d => {
          if (d.code == 2000) {
            message.success('SUCCESS');
          }
        }
      }
    });
  };

  getCuntryAndTrack = () => {
    this.props.dispatch({
      type: 'dropshippersetting/cuntryandtrack',
      payload: {
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.getDefaultCountryAndTrack();
          }
        }
      }
    });
  };

  initialBaseCtrl = () => {
    this.props.dispatch({
      type: 'dropshippersetting/tracking',
      payload: {
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.getDefaultCountryAndTrack();
          }
        }
      }
    });
  };

  setShippingSameTip = list => {
    const arr = [];

    if (Object.values(list).length > 0) {
      for (const item of Object.entries(list)) {
        const len = item[1].length > 3 ? item[1].length : 3;
        const tip = new Array(len).fill('');
        arr.push(tip);
      }
    } else {
      arr.push(['', '', '']);
    }
    return arr;
  };

  setUserTrack = data => {
    const len = data.length;
    if (len < 3) {
      for (let i = len; i < 3; i++) {
        data.push(undefined);
      }
    }
    return data;
  };

  // 获取税收的历史数据
  getTaxRevenueData = () => {
    console.log(111);
    this.props.dispatch({
      type: 'dropshippersetting/getTaxRevenue',
      payload: {
        callback: d => {
          if (d.code == 2000) {
            this.setState({
              taxableStatus: d?.data?.tax_able,
              customizeVal: d?.data?.custom_product_desc,
              new_send_cost_able:d?.data?.new_send_cost_able || 0
            });
          }
        }
      }
    });
  };

  // 获取用户设置的国家和物流
  getDefaultCountryAndTrack = () => {
    this.props.dispatch({
      type: 'dropshippersetting/defaultCountryAndTrack',
      payload: {
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000 && d.data) {
            const track = this.setUserTrack(d.data.shipping_default);
            const country = this.setCountryMap(d.data.shipping_country_list,'AE');
            const TM_country = this.setCountryMap(d.data.tmall_shipping_country_list,'TM');
            const shippingSameTip = this.setShippingSameTip(
              d.data.shipping_country_list
            );
            const countryAdv = this.setCountryAdvMap(d.data.shipping_advanced);
            if (d.data.use_program == 0) {
              this.setState({
                generalChecked: true,
                advancedChecked: false,
                shippingChangeValue: 1
              });
            } else if (d.data.use_program == 1) {
              this.setState({
                advancedChecked: true,
                generalChecked: false,
                shippingChangeValue: 2
              });
            } else {
              this.setState({
                advancedChecked: false,
                generalChecked: false,
                shippingChangeValue: 1
              });
            }

            let stockStatus = [];
            let priceStatus = [];
            let orderStatus = [];
            let cancelledOrderStatus = false;
            let skuStatus = false;

            if (d.data.setting_notification.stock_to_shopify == 0) {
              this.setState({
                Inventory1Checked: false
              });
            } else {
              this.setState({
                Inventory1Checked: true
              });
            }

            if (d.data.setting_notification.stock_status == 0) {
              stockStatus = [];
            } else if (d.data.setting_notification.stock_status == 1) {
              stockStatus = ['1'];
            } else if (d.data.setting_notification.stock_status == 2) {
              stockStatus = ['2'];
            } else if (d.data.setting_notification.stock_status == 99) {
              stockStatus = ['1', '2'];
            }

            if (d.data.setting_notification.price_status == 0) {
              priceStatus = [];
            } else if (d.data.setting_notification.price_status == 1) {
              priceStatus = ['1'];
            } else if (d.data.setting_notification.price_status == 2) {
              priceStatus = ['2'];
            } else if (d.data.setting_notification.price_status == 99) {
              priceStatus = ['1', '2'];
            }

            if (d.data.setting_notification.order_status == 0) {
              orderStatus = [];
            } else if (d.data.setting_notification.order_status == 1) {
              orderStatus = ['1'];
            } else if (d.data.setting_notification.order_status == 2) {
              orderStatus = ['2'];
            } else if (d.data.setting_notification.order_status == 99) {
              orderStatus = ['1', '2'];
            }

            cancelledOrderStatus =
              d.data.setting_notification.disable_ae_order_cancel;

            skuStatus = d.data.setting_notification.disable_sku_changed;

            this.setState({
              track,
              country,
              TM_country,
              shippingSameTip,
              countryAdv,
              Inventory2DefaultVal: stockStatus,
              PriceDefaultVal: priceStatus,
              CancelledOrderDefaultVal: cancelledOrderStatus,
              SkuDefaultVal: skuStatus,
              OrderDefaultVal: orderStatus
            });
          }
        }
      }
    });
  };
  // ??
  setCountryMap = (data,type) => {
    const arr = [];
    if(data){
      for (const item of Object.entries(data)) {
        const track = item[1];
        track.push(undefined);
        item[1] = track;
        item[2] = true;
        arr.push(item); 
        this.generalCheckedArr[type].push(item[0]);
      }
    }
    arr.push([undefined, [undefined], false]);
    this.generalCheckedArr[type].push('');
    return arr;
  };

  // Advanced对返回的数据进行处理
  setCountryAdvMap = data => {
    const newArr = [];
    if(data){
      for (const item of Object.entries(data)) {
        const arr = [];
        arr.push(
          item[0],
          [
            item[1].is_available,
            item[1].delivery_day,
            item[1].shipping_cost,
            item[1].benchmark
          ],
          true
        );
        newArr.push(arr);
        this.advancedCheckedArr[this.state.currentPS].push(item[0]);
      }
    }
    newArr.push([undefined, [true, '', '', 1], false]);
    this.advancedCheckedArr[this.state.currentPS].push('');
    return newArr;
  };

  // General 和 Advanced 单选按钮发生改变时的回调
  shippingChange = e => {
    this.setState({
      shippingChangeValue: e.target.value
    });
  };

  // 切换shipping开关
  switchShippingSetting = () => {
    const { generalChecked, advancedChecked } = this.state;

    this.props.dispatch({
      type: 'dropshippersetting/postShippingMethod',
      payload: {
        data: {
          method: generalChecked || advancedChecked ? 2 : 0
        },
        callback: d => {
          if (d.code == 2000) {
            if (d.data.use_program == 0) {
              this.setState({
                generalChecked: true,
                advancedChecked: false,
                shippingChangeValue: 1
              });
            } else if (d.data.use_program == 1) {
              this.setState({
                advancedChecked: true,
                generalChecked: false,
                shippingChangeValue: 2
              });
            } else {
              this.setState({
                advancedChecked: false,
                generalChecked: false,
                shippingChangeValue: 1
              });
            }
          }
        }
      }
    });
  };

  // shipping 国家选择
  switchAdvancedNew = v => {
    this.setState({
      shippingChangeValue: v ? 2 : 1,
      generalChecked: !v,
      advancedChecked: !!v
    });
    this.props.dispatch({
      type: 'dropshippersetting/postShippingMethod',
      payload: {
        data: {
          method: v ? 1 : 0
        },
        callback: d => {
          if (d.code == 0) {
            this.setState({
              shippingChangeValue: v ? 1 : 2,
              generalChecked: !!v,
              advancedChecked: !v
            });
          }
        }
      }
    });
  };

  // tab切换
  tabChange = sta => {
    if (sta === 'supply') {
      window.dataLayer.push({
        category: 'setting',
        action: 'open Mass Supply tab'
      });
    }

    this.setState({
      tabChangeSta: sta
    });
  };

  // other 获取开关状态
  getOtherSta = () => {
    this.props.dispatch({
      type: 'dropshippersetting/getOtherSta',
      payload: {
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              otherChecked: d.data.sync_status
            });
          }
        }
      }
    });
  };

  // other 设置开关状态
  postOtherSta = checked => {
    this.setState({
      otherChecked: checked
    });
    this.props.dispatch({
      type: 'dropshippersetting/postOtherSta',
      payload: {
        data: {
          status: checked
        }
      }
    });
  };

  // other 设置NA开关状态
  postOtherNASta = checked => {
    this.setState({
      NAChecked: checked
    });
    this.props.dispatch({
      type: 'dropshippersetting/postOtherNASta',
      payload: {
        data: {
          is_na: checked
        },
        callback: d => {
          if (d.code !== 2010) {
            this.setState({
              NAChecked: false
            });
          }
        }
      }
    });
  };

  // textarea 留言
  textAreaChange = e => {
    if (e.target.value !== this.state.textAreaVal) {
      this.setState({
        generalBtnDisabled: false
      });
    }
    this.setState({
      textAreaVal: e.target.value
    });
  };

  // save 留言
  saveMessageClick = () => {
    this.props.dispatch({
      type: 'dropshippersetting/general',
      payload: {
        data: {
          desc: this.state.textAreaVal
        },
        callback: d => {
          if (d.code === 2010) {
            this.setState({
              generalBtnDisabled: true
            });
            message.success({
              content: intl.get('setting.other.message_ae_sellers'),
              style: {
                border: '1px solid red'
              }
            });
          } else {
            // message.error("Authorization failure");
          }
        }
      }
    });
  };

  agencyMessageChange = e => {
    if (e.target.value !== this.state.agencyMessageVal) {
      this.setState({
        agencyMessageButtonDisabled: false
      });
    }
    this.setState({
      agencyMessageVal: e.target.value
    });
  };

  saveAgencyMessage = async () => {
    try {
      const res = await updateAgencyOrderGlobalMessage({ data: { desc: this.state.agencyMessageVal}});
      if(res.code === 2010) {
        message.success('Update success');
        this.setState({
          agencyMessageButtonDisabled: true
        })
      } else {
        message.error('Update failed');
      }
    } catch (error) {
      message.error('Update failed');
    }
  }

  // ioss input
  iossValChange = e => {
    if (e.target.value !== this.state.iossVal) {
      this.setState({
        iossBtnDisabled: false
      });
    }
    this.setState({
      iossVal: e.target.value
    });
  };
  // save ioss
  saveIossMessage = () => {
    if (
      /([\u30a1-\u30f6\u3041-\u3093\uFF00-\uFFFF\u4e00-\u9fa5\u4e00-\u9fa5\uac00-\ud7ff])/.test(
        this.state.iossVal
      )
    ) {
      this.setState({
        isShow: true
      });
      return;
    } else {
      this.setState({
        iossVal: this.state.iossVal.trim()
      });
      this.props.dispatch({
        type: 'dropshippersetting/saveIoss',
        payload: {
          data: {
            IOSS: this.state.iossVal.trim()
          },
          callback: d => {
            if (d.code === 2010) {
              this.setState({
                iossBtnDisabled: true
              });
              message.success('save success');
            }
          }
        }
      });
      this.setState({
        isShow: false
      });
    }
  };
  // ???
  phoneNoChange = e => {
    this.setState({
      savePhoneBtnDisabled: false
    });

    this.setState({
      d: e.target.value
    });
  };

  // days必须是正整数
  validDate = (rule, value, callback) => {
    const reg = /^[1-9]\d*$/;
    if (!reg.test(value)) {
      // 正则验证
      if (value != '') {
        callback(intl.get('setting.shipping.advanced.delivery_period_error')); // 校验未通过 Please enter a positive integer
      }
    }
    callback(); // 校验通过
  };

  // cost最多可输入两位小数
  validCost = (rule, value, callback) => {
    const reg = /^[+]{0,1}(\d+)+([.]{1}[0-9]{1,2})?$/;
    if (!reg.test(value)) {
      // 正则验证
      if (value != '') {
        callback(intl.get('setting.other.cost_error')); // 校验未通过
      }
    }
    callback(); // 校验通过
  };

  // Auto Updates 多选按钮Inventory1
  Inventory1Change = e => {
    this.setState({
      Inventory1Checked: e.target.checked
    });
  };
  // Auto Updates 多选按钮Inventory2
  Inventory2Change = checkedValues => {
    this.setState(
      {
        Inventory2DefaultVal: checkedValues ? ['1'] : [],
        autoUpdateSaveDisabled: false
      },
      () => {
        this.autoUpdateSave();
      }
    );
  };
  // Auto Updates 多选按钮Price
  PriceChange = checkedValues => {
    // ['1']  []
    this.setState(
      {
        PriceDefaultVal: checkedValues ? ['1'] : [],
        autoUpdateSaveDisabled: false
      },
      () => {
        this.autoUpdateSave();
      }
    );
  };

  // notification cancelled switch
  CancelledOrderChange = checked => {
    this.setState(
      {
        CancelledOrderDefaultVal: !checked,
        autoUpdateSaveDisabled: false
      },
      () => {
        this.autoUpdateSave();
      }
    );
  };

  // notification SKU switch
  SkuChange = checked => {
    this.setState(
      {
        SkuDefaultVal: !checked,
        autoUpdateSaveDisabled: false
      },
      () => {
        this.autoUpdateSave();
      }
    );
  };

  // 保存auto update
  autoUpdateSave = () => {
    const {
      Inventory1Checked,
      Inventory2DefaultVal,
      PriceDefaultVal,
      CancelledOrderDefaultVal,
      SkuDefaultVal,
      OrderDefaultVal
    } = this.state;
    let stockStatus = 0;
    let priceStatus = 0;
    let orderStatus = 0;
    let cancelledOrderStatus = false;
    let skuStatus = false;

    if (Inventory2DefaultVal.length == 0) {
      stockStatus = 0;
    } else if (
      Inventory2DefaultVal.length == 1 &&
      Inventory2DefaultVal[0] == 1
    ) {
      stockStatus = 1;
    } else if (
      Inventory2DefaultVal.length == 1 &&
      Inventory2DefaultVal[0] == 2
    ) {
      stockStatus = 2;
    } else if (Inventory2DefaultVal.length == 2) {
      stockStatus = 99;
    }

    if (PriceDefaultVal.length == 0) {
      priceStatus = 0;
    } else if (PriceDefaultVal.length == 1 && PriceDefaultVal[0] == 1) {
      priceStatus = 1;
    } else if (PriceDefaultVal.length == 1 && PriceDefaultVal[0] == 2) {
      priceStatus = 2;
    } else if (PriceDefaultVal.length == 2) {
      priceStatus = 99;
    }

    cancelledOrderStatus = CancelledOrderDefaultVal;

    skuStatus = SkuDefaultVal;

    if (OrderDefaultVal.length == 0) {
      orderStatus = 0;
    } else if (OrderDefaultVal.length == 1 && OrderDefaultVal[0] == 1) {
      orderStatus = 1;
    } else if (OrderDefaultVal.length == 1 && OrderDefaultVal[0] == 2) {
      orderStatus = 2;
    } else if (OrderDefaultVal.length == 2) {
      orderStatus = 99;
    }

    this.props.dispatch({
      type: 'dropshippersetting/settingNotification',
      payload: {
        data: {
          setting: {
            stock_to_shopify: Inventory1Checked ? 1 : 0,
            stock_status: stockStatus,
            price_status: priceStatus,
            order_status: orderStatus,
            disable_ae_order_cancel: cancelledOrderStatus,
            disable_sku_changed: skuStatus
          }
        },
        callback: d => {
          if (d.code == 2010) {
            this.setState({
              autoUpdateSaveDisabled: true
            });
            if (
              d.data?.setting_notification.order_status == 1 ||
              d.data?.setting_notification.price_status == 1 ||
              d.data?.setting_notification.stock_status == 1
            ) {
              //
            } else {
              localStorage.setItem('notificationToken', 'no');
            }
            message.success(intl.get('setting.tracking.save_succ'));
          } else {
            message.error(intl.get('setting.tracking.save_fail'));
          }
        }
      }
    });
  };

  // 设置是否正在同步订单
  setIsSync = status => {
    if (status) {
      this.setState({
        isSync: true,
        loopSync: true
      });
    } else {
      this.setState({
        isSync: false,
        loopSync: false
      });
    }
  };

  // Sync Shopify orders
  subSyncDate = () => {
    const { syncStore } = this.state;
    console.log(this.state)
    console.log(this.props)
    console.log(syncStore)

    const { userInfo: { user_id  } } = this.props

    CommonDataCollect({
      user_id: user_id,
      store_id: syncStore,
      event_type: 'subscription_analysis',
      action: 'sync_shopify_orders',
    })

    this.props.dispatch({
      type: 'topBtn/isSync',
      payload: {
        data: {
          store_id: syncStore,
          start_at: this.syncStartTime,
          end_at: this.syncEndTime
        },
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            message.success(intl.get('setting.other.create_success'));
            localStorage.setItem('syncStoreId', d.msg);
            this.setState({
              isSync: true,
              hasSyncLock: true,
              loopSync: true,
              syncStore: undefined
            });
          } else {
            message.error(d.msg);
          }
        }
      }
    });
  };

  showSupplierUsers = []; // 可以展示供应链商品的user id

  // 同步时间变化
  changeSyncPicker = v => {
    if (v.length === 0) {
      this.syncStartTime = 0;
      this.syncEndTime = 0;
      this.setState({
        rangePickerValue: undefined
      });
      return;
    }

    const { timezoneRegion } = this.props;
    const start = v[0].set({ hour: 0, minute: 0, second: 0 });
    const end = v[1].set({ hour: 23, minute: 59, second: 59 });

    if (timezoneRegion) {
      start.tz(timezoneRegion);
      end.tz(timezoneRegion);
    }

    const startSecond = start.unix();
    const endSecond = end.unix();
    const moreThan30Days = endSecond - startSecond > 86400 * 30;

    this.syncStartTime = startSecond;
    this.syncEndTime = endSecond;

    this.setState({
      syncTimeTip: moreThan30Days,
      hasSyncLock: false,
      rangePickerValue: [start, end]
    });
  };

  // 改变同步店铺
  changeSyncStore = v => {
    this.setState({
      hasSyncLock: false,
      syncStore: v
    });
  };

  // 同步时间不可选的范围
  disabledDate = current => {
    return (
      current &&
      (current <
        moment()
          .subtract(2, 'months')
          .subtract(1, 'days') ||
        current > moment().endOf('day'))
    );
  };

  syncStartTime = 0;
  syncEndTime = 0;

  // shipping  手风琴的值
  changeGlobalCollapseValue = v => {
    this.setState({
      shippingGlobalCollapseValue: v
    });
  };

  // shipping advanced 手风琴的值
  changeAdvancedCollapseValue = v => {
    this.setState({
      shippingAdvancedCollapseValue: v
    });
  };

  // 国家下拉列表
  countryFn = (
    allcountry,
    value,
    changeCountry,
    index,
    countryLength,
    category,
    deleteCountry,
    shippingChangeValue
  ) => {
    // 国家下拉
    return (
      <div
        onClick={e => {
          e.stopPropagation();
        }}
        className={styles.collapseHeadTool}
      >
        {/* <span>{index + 1}. </span> */}
        <Select
          size="small"
          value={value}
          disabled={this.state.currentPS != 'AE' ? false : shippingChangeValue != 1 }
          className={styles.selects}
          placeholder={intl.get('setting.choose_country_placeholder')}
          filterOption={(i, o) =>
            o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
          }
          getPopupContainer={triggerNode => triggerNode.parentNode}
          showSearch
          onChange={(e, o) => {
            changeCountry(e, index, o);
          }}
        >
          {allcountry.map(country => {
            return (
              <Option
                key={country.Name}
                value={country.Code}
                disabled={
                  category == 'advanced'
                    ? this.advancedCheckedArr[this.state.currentPS].includes(country.Code)
                    : this.generalCheckedArr[this.state.currentPS].includes(country.Code)
                }
              >
                {country.Name}
              </Option>
            );
          })}
        </Select>
        {countryLength > 1 && value ? (
          <Tooltip placement="top" title="Delete country setting">
            <span>
              <i
                class="material-icons"
                onClick={() => {
                  deleteCountry(index, value);
                }}
              >
                delete
              </i>
            </span>
          </Tooltip>
        ) : null}
      </div>
    );
  };
  countryFn2 = (
    allcountry,
    value,
    changeCountry,
    index,
    countryLength,
    category,
    deleteCountry,
    shippingChangeValue
  ) => {
    // 国家下拉
    return (
      <div
        onClick={e => {
          e.stopPropagation();
        }}
        className={styles.collapseHeadTool}
      >
        {/* <span>{index + 1}. </span> */}
        <Select
          size="small"
          value={value}
          disabled={shippingChangeValue != 2}
          // className={styles.selects}
          placeholder={intl.get('setting.choose_country_placeholder')}
          filterOption={(i, o) =>
            o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
          }
          getPopupContainer={triggerNode => triggerNode.parentNode}
          showSearch
          onChange={(e, o) => {
            changeCountry(e, index, o);
          }}
        >
          {allcountry.map(country => {
            return (
              <Option
                key={country.Name}
                value={country.Code}
                disabled={
                  category == 'advanced'
                    ? this.advancedCheckedArr[this.state.currentPS].includes(country.Code)
                    : this.generalCheckedArr[this.state.currentPS].includes(country.Code)
                }
              >
                {country.Name}
              </Option>
            );
          })}
        </Select>
        {countryLength > 1 && value ? (
          <Tooltip placement="top" title="Delete country setting">
            <span>
              <i
                class="material-icons"
                onClick={() => {
                  deleteCountry(index, value);
                }}
              >
                delete
              </i>
            </span>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  // setting shipping advanced 国家列表
  advancedPanel = (
    item,
    index,
    allcountry,
    countryLength,
    shippingChangeValue
  ) => {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Panel
        header={this.countryFn2(
          allcountry,
          item[0],
          this.changeCountryAdvanced,
          index,
          countryLength,
          'advanced',
          this.deleteCountryAdvanced,
          shippingChangeValue
        )}
        key={`q${index}`}
        className={styles.countryPanel}
      >
        <Col key={index}>
          <div className={styles.addOptionCard}>
            <div className={styles.selectBorder}>
              <div className={styles.addtrack}>
                <div className="tracking_box">
                  <div>
                    {intl.get(
                      'setting.shipping.advanced.tracking_information_available'
                    )}
                  </div>
                  <div>
                    <Switch
                      checked={item[1][0]}
                      onChange={e => {
                        this.advCheckboxChange(e, index);
                      }}
                    />
                  </div>
                </div>

                <Form>
                  <FormItem
                    label={
                      <span>
                        <i>
                          {intl.get(
                            'setting.shipping.advanced.delivery_period'
                          )}
                        </i>
                        <Tooltip
                          title={intl.get(
                            'setting.shipping.advanced.delivery_period_tip'
                          )}
                        >
                          <Icon type="question-circle" theme="outlined" />
                        </Tooltip>
                      </span>
                    }
                  >
                    {/* {getFieldDecorator(`date${index}`, {
                      initialValue: item[1][1]
                      // rules: [
                      //   {
                      //     message: intl.get(
                      //       'setting.shipping.advanced.delivery_period_error'
                      //     )
                      //   },
                      //   {
                      //     validator: this.validDate
                      //   }
                      // ]
                    })( */}
                      <InputNumber
                        value={ item[1][1] }
                        style={{ width: 88,border: '1px solid #9e9e9e' }}
                        formatter={value => `${value} days`}
                        parser={value =>
                          value
                            .replace('days', '')
                            .replace(/\s/g, '')
                            .replace('-', '')
                        }
                        min={0}
                        max={999}
                        onChange={e => {
                          this.onInputChangeDate(e, index);
                        }}
                      />
                    {/* )} */}
                  </FormItem>
                  <FormItem
                    label={intl.get('setting.shipping.advanced.shipping_cost')}
                  >
                    {/* {getFieldDecorator(`cost${index}`, {
                      initialValue: item[1][2]
                      // rules: [
                      //   {
                      //     message: intl.get(
                      //       'setting.shipping.advanced.shipping_cost_error'
                      //     )
                      //   },
                      //   {
                      //     validator: this.validCost
                      //   }
                      // ]
                    })( */}
                      <InputNumber
                        value={item[1][2]}
                        min={0}
                        max={9999}
                        
                        style={{ width: 88 }}
                        style={{ border: '1px solid #9e9e9e' }}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value =>
                          value.replace(/\$\s?|(,*)/g, '').replace('-', '')
                        }
                        onChange={e => {
                          this.onInputChangeCost(e, index);
                        }}
                      />
                    {/* )} */}
                  </FormItem>
                </Form>

                <div className={styles.shippingWrap}>
                  <RadioGroup
                    value={item[1][3]}
                    onChange={e => {
                      this.shippingChoiceChange(e, index);
                    }}
                    className={styles.shippingRadio}
                  >
                    <Radio value={1}>
                      <p>
                        {intl.get('setting.shipping.advanced.tip')},{' '}
                        {intl.get('setting.shipping.advanced.i_need')}{' '}
                        {intl.get('setting.shipping.advanced.less_cost')}
                      </p>
                    </Radio>
                    <Radio value={2}>
                      <p>
                        {intl.get('setting.shipping.advanced.tip')},{' '}
                        {intl.get('setting.shipping.advanced.i_need')}{' '}
                        {intl.get('setting.shipping.advanced.less_day')}
                      </p>
                    </Radio>
                  </RadioGroup>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Panel>
    );
  };

  // setting shipping General 国家列表
  countryPanel = (
    item,
    index,
    alltrack,
    alltrack_tm,
    countryUnsupportMap,
    allcountry,
    countryLength,
    shippingChangeValue
  ) => {
    // 如果是Oberlo用户则增加 【Cheapest】 物流
    if (this.props.isOberlo) {
      alltrack = [...alltrack,{Name:'Cheapest shipping method', ServiceName:'CHEAPEST_SHIPPING_METHOD', NameChinese:"Cheapest shipping method",id: 99999, level: "其它"}]
    }
    if(item[1].includes('CHEAPEST_SHIPPING_METHOD')){
      item[1]  = Array.from(new Set(['CHEAPEST_SHIPPING_METHOD',...item[1]]))
    }
    return (
      <Panel
        header={this.countryFn(
          allcountry,
          item[0],
          this.changeCountry,
          index,
          countryLength,
          'general',
          this.deleteCountry,
          shippingChangeValue
        )}
        key={`q${index}`}
        className={styles.countryPanel}
      >
        {item[1].map((shipping, idx) => {
          const unsupportedSelected = countryUnsupportMap[shipping];
          return (
            <div className="li_select" key={idx}>
              {/* <Col span={4}>Option {idx + 1}</Col> */}
              <div className="li_select_checked">
                <Select
                  size="small"
                  value={shipping}
                  placeholder={intl.get('setting.shipping.general.placeholder')}
                  showSearch
                  disabled={this.state.currentPS != 'AE' ? false : shippingChangeValue != 1 }
                  filterOption={(i, o) =>
                    o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
                  }
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  className={unsupportedSelected ? styles.unsupportOption : ''}
                  onChange={v => {
                    this.changeCountryTrack(v, idx, index);
                  }}
                >
                  {(this.state.currentPS == 'TM' ? alltrack_tm : alltrack).map((r, f) => {
                    return !countryUnsupportMap[r.ServiceName] ? (
                      <Option
                        key={r.ServiceName}
                        value={r.ServiceName}
                        disabled={!!item[1].includes(r?.ServiceName)}
                        title={r.Name}
                      >
                        {r.Name}
                      </Option>
                    ) : null;
                  })}
                </Select>
              </div>
              {item[1].length > 1 && shipping ? (
                <div className="li_select_checked_delete">
                  <i
                    class="material-icons"
                    onClick={() => {
                      this.deleteCountryTrack(idx, index);
                    }}
                  >
                    delete
                  </i>
                </div>
              ) : null}
            </div>
          );
        })}
      </Panel>
    );
  };

  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 }
    }
  };

  // 获取电话列表
  getAllPhoneNumber = (type, delData = []) => {
    const { dispatch, countrys } = this.props;
    const defauletData = [
      {
        country_code: 'Global',
        phone_number: '',
        country: 'Global',
        phone_type: type
      }
    ];
    let data = [
      {
        country_code: undefined,
        phone_number: '',
        country: undefined,
        phone_type: type
      }
    ];
    dispatch({
      type: 'dropshippersetting/GetUserAllPhoneSet',
      payload: {
        data: {
          phone_type: type
        },
        callback: d => {
          if (d.code === 2000 || d.code === 2010) {
            // 是否有设置全球电话
            let global =
              d.data?.filter(item => item.country_code === 'Global') || [];
            // 找到所有非全球电话设置
            const newData =
              d.data?.filter(item => item.country_code !== 'Global') || [];
            // 初始阶段末尾国家选项
            data = global ? data : defauletData;
            // const data = d.data;
            // 配置国家全称
            d.data?.forEach(item => {
              countrys.forEach(i => {
                if (item.country_code === i.Code) {
                  item.country = i.Name;
                }
              });
            });
            const PhoneNumber = [...global, ...newData, ...data];
            const setData = d.data ? PhoneNumber : defauletData;
            console.log(
              setData,
              delData.length === 0 ? setData : newData,
              delData
            );
            if (type === 1) {
              this.setState({
                overridePhoneNumber: delData.length === 0 ? this.orderFailTel(setData) : this.orderFailTel(delData)
              });
            } else {
              this.setState({
                defaultPhoneNumber: delData.length === 0 ? this.orderFailTel(setData) : this.orderFailTel(delData)
              });
            }
          }
        }
      }
    });
  };

  // 更改 user info 里的status 最后一位 如果是1就改成0
  setUserInfoStatus = () => {
    this.props.dispatch({
      type: 'dropshippersetting/userStatus',
      payload: {
        data: {
          status: 11000000
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.reloadUserInfo();
            localStorage.removeItem('errorCashbackCodeTip');
          }
        }
      }
    });
  };

  // 更新userinfo
  reloadUserInfo = () => {
    this.props.dispatch({
      type: 'dropshippersetting/reloadUserInfo'
    });
  };

  // 要不要展示cashbackcode错误提示
  cashbackCodeIsWrong = () => {
    const status = `${this.props.userInfo.status}`;
    let flag = false;

    if (status.charAt(status.length - 1) == 1) {
      flag = true;
    }

    return flag;
  };

  // 修改返点code
  handleEditCashbackConfirm = () => {
    confirm({
      title: intl.get('setting.other.change_code_wraming'),
      cancelText: intl.get('public.cancel'),
      okText: intl.get('public.ok'),
      onOk: () => {
        this.setState({
          showEditCashback: true
        });
      }
    });
  };

  // 展示商品搜索弹窗
  showProductSearch = () => {
    this.props.dispatch({
      type: 'dropshippersetting/showModal'
    });
  };

 // 改变location触发方法
 locationChange = async (item,index) =>{
  if(index == 0) { return }
  let param = {location:item.location,status:!item.status}
  const res = await Post('/fulfillment/set/location_management',{data:param});    
  this.getsettingLocation()
 }
   
  handleCodeChange = e => {
    const tmp = e.target.value;
    this.setState({
      cashbackCode: tmp,
      cashbackCodeErrorMsg: !/^[0-9a-z]+$/.test(tmp)
        ? intl.get('setting.other.cash_back_code_error')
        : undefined
    });
  };

  handleAccountChange = e => {
    const tmp = e.target.value;
    if (tmp.length > 56) {
      return;
    }

    this.setState({
      cashbackAccount: tmp,
      cashbackAccountErrorMsg: !tmp
        ? intl.get('setting.other.not_account_name')
        : undefined
    });
  };

  handleEditCashback = isCreate => {
    const { userInfo } = this.props;

    if (isCreate) {
      this.setState({
        showEditCashback: true
      });
    } else {
      this.setState({
        showEditCashback: true,
        cashbackCode: userInfo.code,
        cashbackAccount: userInfo.code_account
      });
    }
  };

  handleCancelEditCashback = () => {
    this.setState({
      showEditCashback: false
    });
  };

  handleSaveCashback = async () => {
    const { cashbackCode, cashbackAccount } = this.state;

    const params = {
      code: cashbackCode.trim(),
      account: cashbackAccount.trim(),
      is_oberlo_mgr: localStorage.getItem('noMigra') == 'false' ? true : false
    };

    try {
      const res = await Post('/admitad/code/update', { data: params });
      if (res.code === 2000) {
        this.props.dispatch({ type: 'dropshippersetting/admitadCheck' });
        this.setState({
          showEditCashback: false,
          cashbackCode: undefined,
          cashbackAccount: undefined
        });
      }
    } catch (error) {
      console.log('fetch error', error);
    }
  };

  handleResetCashback = () => {
    this.setState({
      cashbackCode: undefined,
      cashbackAccount: undefined
    });
  };

  renderCancelBtn = () => {
    const { cashbackAccount, cashbackCode } = this.state;
    return cashbackAccount !== undefined || cashbackCode !== undefined ? (
      <Button
        className={styles.saveBtn2}
        data="bk"
        onClick={this.handleResetCashback}
      >
        Cancel
      </Button>
    ) : null;
  };

  handleChangeSyncShopifyNotesStatus = checked => {
    this.setState({
      syncShopifyNotesStatus: checked
    });

    this.props.dispatch({
      type: 'dropshippersetting/syncShopifyNotesStatus',
      payload: {
        data: {
          sync_shopify_notes_status: checked
        }
      }
    });
  };
  handleChangeSyncShopifyMessageStatus = checked => {
    if ([1,4,8,10,12,13,14,15,16,17].includes(this.props?.plan?.type)) {
      CommonDataCollect({
        action: 'sync_custom_top',
        event_type:'overlimit_pop'
      });
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'SynchronizeShopifyInformation')
      return;
    }
    this.setShopifyMessageStatus(checked)
  }

  setShopifyMessageStatus = async checked => {
    let response = await Put('/set/order_customization_sync', {
      data: {
        sync_customization_status: checked ? true : false
      }
    });
    this.setState({
      orderMessageStatus: checked
    });
  };


  handleChangeSKUStatus = checked => {
    //判断是否是pro套餐
    // if(JSON.parse(localStorage.getItem('payment')).type != 3){
    //   this.props.dispatch(routerRedux.push('/pricing'))
    //   return
    // }
    this.setState({
      skuCustomize: checked
    });
    this.setSkuCustomize(checked);
  };

  setSkuCustomize = async checked => {
    let response = await Post('/set/sku_customize', {
      data: {
        status: checked ? 1 : 0
      }
    });
  };

  getSkuCustomize = async () => {
    let response = await Get('/set/sku_customize');
    if (response.code === 2000) {
      this.setState({
        skuCustomize: !response.data ? false : true
      });
    }
  };

  // 获取同步shopify订单的默认时间
  getSyncShopifyOrderDefaultTime = () => {
    const { timezoneRegion } = this.props;
    const { rangePickerValue } = this.state;
    let s =
      (rangePickerValue && rangePickerValue[0]) ||
      moment().subtract(15, 'days');
    let e = (rangePickerValue && rangePickerValue[1]) || moment();

    if (timezoneRegion) {
      s = s.tz(timezoneRegion);
      e = e.tz(timezoneRegion);
    }

    this.syncStartTime = s.unix();
    this.syncEndTime = e.unix();

    this.setState({
      rangePickerValue: [s, e]
    });
  };

  // 效验电话格式
  phoneNumberFormat = (i, value = '', data) => {
    console.log(i,value,data);

    const { overridePhoneNumber } = this.state;
    if (
      (data[i].country_code === 'SA' ||
        data[i].country_code === 'AE' ||
        data[i].country_code === 'IL') &&
      !(/^5\d{8}$/.test(value) || /^0\d{9}$/.test(value))
    ) {
      data[i].isShow = true;
      // data[i].tips = intl.get('setting.other.phone_rules_SA_AE_IL');
      data[i].tips = intl.get('setting.other.phone_rules_SA_AE_IL_new');
    } else if (data[i].country_code === 'FR' && !/^\d{9,10}$/.test(value)) {
      data[i].isShow = true;
      //data[i].tips = intl.get('setting.other.phone_rules_FR');
      data[i].tips = intl.get('setting.other.phone_rules_FR_new');
    } else if (data[i].country_code === 'PT' && !/^\d{9,10}$/.test(value)) {
      data[i].isShow = true;
      data[i].tips = intl.get('setting.other.phone_rules_PT');
    } else if (
      (data[i].country_code === 'BR' || data[i].country_code === 'JP') &&
      !/^\d{10,11}$/.test(value)
    ) {
      data[i].isShow = true;
      data[i].tips = intl.get('setting.other.phone_rules_BR_JP');
    } else if (
      data[i].country_code === 'KR' &&
    !(/^\s*(1|01)\s*-*(?:\s*[0-9]\s*-*){9}\s*$/.test(value))
    ) {
      data[i].isShow = true;
      data[i].tips = intl.get('setting.other.phone_rules_KR');
    } else if (data[i].country_code === 'IT' && !/^\d{9,10}$/.test(value)) {
      data[i].isShow = true;
      data[i].tips = intl.get('setting.other.phone_rules_IT');
    } else if (value.length < 5 || value.length > 16) {
      data[i].isShow = true;
      data[i].tips = intl.get('order.edit_address.enter_between', {
        start: 5,
        end: 16
      });
    } else if (!/^[\d-/]*$/.test(value)) {
      data[i].isShow = true;
      data[i].tips = intl.get('order.edit_address.only_num_hyphens_forward');
      // callback(intl.get('order.edit_address.only_num_hyphens_forward'));
    } else {
      data[i].isShow = false;
      data[i].tips = '';
    }
  };

  // 效验电话号码长度
  checkPhoneNumber = (e, i, type,trigger) => {
    console.log('change ', e, i, type)
    const { overridePhoneNumber, defaultPhoneNumber } = this.state;
    const data = type === 1 ? overridePhoneNumber : defaultPhoneNumber;
    const value = trigger == 'input' ? e.target.value.trim() : e;
    data[i].phone_number = value;
    if (value !== '') {
      this.phoneNumberFormat(i, value, data);
      const isbtn = data.filter(item => (item.isShow && !item.disabled)).length === 0;
      const phoneNumber = data.filter(item => item.phone_number).length !== 0;

      if (isbtn && type === 1 && phoneNumber) {
        this.setState({
          overrideBtn: false
        });
      } else {
        this.setState({
          overrideBtn: true
        });
      }
      if (isbtn && type === 2 && phoneNumber) {
        this.setState({
          defaultBtn: false
        });
      } else {
        this.setState({
          defaultBtn: true
        });
      }
    } else {
      data[i].isShow = false;
      data[i].tips = '';
    }

    if (type === 1) {
      this.setState({
        overridePhoneNumber: data
      });
    } else {
      this.setState({
        defaultPhoneNumber: data
      });
    }
  };

  // 删除覆盖国家电话
  delCountryCode = (countryCode, type, i) => {
    const { dispatch } = this.props;
    const { overridePhoneNumber, defaultPhoneNumber } = this.state;
    const data =
      type === 1 ? [...overridePhoneNumber] : [...defaultPhoneNumber];
    const newData = data.filter(item => countryCode !== item.country_code);
    if (!countryCode) {
      data.splice(i, 1);
      this.getAllPhoneNumber(type, data);
      return;
    }

    dispatch({
      type: 'dropshippersetting/delUserAllPhone',
      payload: {
        data: {
          country_code: countryCode,
          phone_type: type
        },
        callback: d => {
          if (d.code === 2000 || d.code === 2010) {
            message.success(d.msg);
            this.getAllPhoneNumber(type, newData);
          }
        }
      }
    });
  };

  // 更新覆盖国家电话
  upDataCountryCode = type => {
    const { dispatch } = this.props;
    const { overridePhoneNumber, defaultPhoneNumber } = this.state;
    const data = (type === 1 ? overridePhoneNumber : defaultPhoneNumber).filter(
      item => item.country_code && item.phone_number
    );
    dispatch({
      type: 'dropshippersetting/updataUserAllPhone',
      payload: {
        data,
        callback: d => {
          if (d.code === 2000) {
            if (type === 1) {
              this.setState({
                overrideBtn: true
              });
            } else {
              this.setState({
                defaultBtn: true
              });
            }
            this.getAllPhoneNumber(type);
          }
        }
      }
    });
  };

  // 选择要设置电话的国家
  handleCountryCodeChange = (e, i, type) => {
    const { countrys } = this.props;
    const { overridePhoneNumber, defaultPhoneNumber } = this.state;
    const Item = countrys.filter(item => item.Code === e);
    const data = type === 1 ? overridePhoneNumber : defaultPhoneNumber;
    data[i].country_code = Item[0].Code;
    data[i].country = Item[0].Name;
    data[i].country_tip = this.someCountryTips(data[i].country)
    if (type === 1) {
      this.setState({
        overridePhoneNumber: [...data]
      });
    } else {
      this.setState({
        defaultPhoneNumber: [...data]
      });
    }
  };
 // 针对某些国家提供提示
  someCountryTips = (name) =>{
    let errorTop = ''
    switch(name){
      case 'Saudi Arabia':
      case 'United Arab Emirates':
      errorTop = intl.get('setting.other.override_phone_number_first');
      break;
      case 'Israel':
      errorTop = intl.get('setting.other.override_phone_number_second');  
      break;
      case 'France':
      case 'Portugal':
      case 'Italy':
      errorTop = intl.get('setting.other.override_phone_number_third');   
      break; 
      case 'Brazil':        
      case 'Japan': 
      errorTop = intl.get('setting.other.override_phone_number_fourth');  
      break;  
      case 'Korea':   
      errorTop = intl.get('setting.other.override_phone_number_fifth');    
      break;
      default:
      errorTop=''        
    }
    return errorTop
 }

  specialTelephoneStandard = () => {
    return (
      <div
        className={styles.phoneRulesBox}
        style={{ marginTop: '0px', paddingTop: '10px' }}
      >
        <p className={styles.phoneRulesTile}>
          {/* {intl.get('setting.other.override_phone_number_intro_start')} */}
          {intl.get('setting.other.override_phone_number_intro_start_new')}
        </p>
        <span className={styles.phoneRulesIcon}>
          <i class="material-icons notranslate">error_outline</i>
        </span>
        <ul className={styles.phoneRules}>
          {/* <li>{intl.get('setting.other.override_phone_number_uae')}</li>
          <li>{intl.get('setting.other.override_phone_number_fr')}</li>
          <li>{intl.get('setting.other.override_phone_number_pt')}</li>
          <li>{intl.get('setting.other.override_phone_number_br')}</li>
          <li>{intl.get('setting.other.override_phone_number_jp')}</li>
          <li>{intl.get('setting.other.override_phone_number_ko')}</li>
          <li>{intl.get('setting.other.override_phone_number_it')}</li> */}
          <li>{intl.get('setting.other.override_phone_number_first')}</li>
          <li>{intl.get('setting.other.override_phone_number_second')}</li>
          <li>{intl.get('setting.other.override_phone_number_third')}</li>
          <li>{intl.get('setting.other.override_phone_number_fourth')}</li>
          <li>{intl.get('setting.other.override_phone_number_fifth')}</li>
        </ul>
        {/* <p>{intl.get('setting.other.override_phone_number_intro_end')}</p> */}
      </div>
    );
  };
  // 默认区 新增城市选择
  onPush = (i, type) => {
    const { overridePhoneNumber, defaultPhoneNumber } = this.state;
    const data = type === 1 ? overridePhoneNumber : defaultPhoneNumber;
    const global = data.filter(item => item.country_code === 'Global') || [];
    if (global.length !== 0) {
      data.push({
        country_code: undefined,
        country: undefined,
        phone_number: '',
        phone_type: type
      });
    } else {
      data.push({
        country_code: 'Global',
        country: 'Global',
        phone_number: '',
        phone_type: type
      });
    }
    if (type === 1) {
      this.setState({
        overridePhoneNumber: [...data]
      });
    } else {
      this.setState({
        defaultPhoneNumber: [...data]
      });
    }
  };
  // 特殊处理国家失败电话号码
  orderFailTel = (handleData) =>{
    let data = handleData.map((item)=>{
      if(orderFailCountrys.includes(item.country_code)){
           item.isShow = true;
           item.disabled = true;
           item.tips = intl.get("setting.phone_number_des_tip")
      }
      return item
   })
  return data
  }


  // 复用 电话号/城市选择
  PhoneNumber = type => {
    const {
      defaultPhoneNumber,
      overridePhoneNumber,
      overrideBtn,
      defaultBtn
    } = this.state;
    const { countrys, form } = this.props;
    const { getFieldDecorator } = form;
    let countryList = countrys.filter(item=> !orderFailCountrys.includes(item.Code))
    const data = type === 1 ? overridePhoneNumber : defaultPhoneNumber;
    return (
      <div style={{ paddingTop: '10px' }}>
        {type == 1 ? (
          <>
            <h3>{intl.get('setting.other.override_phone_number')}</h3>
            <p>{intl.get('setting.other.override_phone_number_tip')}.</p>
          </>
        ) : (
          <>
            <h3>{intl.get('setting.other.default_phone_number')}</h3>
            <p>{intl.get('setting.other.default_phone_number_tip')}.</p>
          </>
        )}
        <Form className={styles.phone}>
          {data.map((item, i) => {
            return (
              <div key={item.country_code}>
                <FormItem>
                  {getFieldDecorator(`field${type}-${i}`, {
                    initialValue: {
                      country: item.country,
                      phone_number: item.phone_number
                    }
                  })(
                    <div className={styles.phoneCode}>
                      <div className={styles.phoneCodeGroup}>
                        <div className={styles.phoneCodeGroupItem}>
                          <Select
                           disabled={item.disabled}
                            getPopupContainer={triggerNode =>
                              triggerNode.parentNode
                            }
                            suffixIcon={
                              <img
                                src={require('../../../assets/newImportList/icon_expand_more-24px.png')}
                              ></img>
                            }
                            value={item.country}
                            onChange={e => {
                              this.handleCountryCodeChange(e, i, type)
                              this.checkPhoneNumber(item.phone_number, i, type,'select');
                              }
                            }
                            placeholder={intl.get(
                              'setting.other.default_phone_number_placeholder'
                            )}
                            filterOption={(input, option) =>
                              option.props.children.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                          >
                            {countryList.map(newItem => {
                              if (
                                data.find(i => {
                                  return i.country_code === newItem.Code;
                                })
                              ) {
                                return null;
                              }
                              return (
                                <Option
                                  key={`${newItem.Name}-${type}`}
                                  value={newItem.Code}
                                >
                                  <Tooltip title={newItem.Name}>
                                    {newItem.Name}
                                  </Tooltip>
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                        <div className={styles.phoneCodeGroupItem}>
                          <Input
                           disabled={item.disabled}
                            value={item.phone_number}
                            placeholder={item.country_tip}
                            onChange={e => {
                              this.checkPhoneNumber(e, i, type,'input');
                            }}
                            autoComplete="new-password"
                          />
                        </div>
                        <div className={styles.phoneCodeGroupItem}>
                          {data.length - 1 !== i ? (
                            <i
                              onClick={() => {
                                this.delCountryCode(item.country_code, type, i);
                              }}
                              class="material-icons"
                            >
                              delete
                            </i>
                          ) : (
                            <i
                              onClick={() => this.onPush(i, type)}
                              class="material-icons"
                            >
                              add
                            </i>
                          )}
                        </div>
                      </div>
                      <p style={{ color: 'red' }}>
                        {item.isShow ? item.tips : null}
                      </p>
                    </div>
                  )}
                </FormItem>
              </div>
            );
          })}
        </Form>
        <p className={styles.generalText}>
          <Button
            type="primary"
            onClick={() => this.upDataCountryCode(type)}
            disabled={type === 1 ? overrideBtn : defaultBtn}
            className={styles.saveBtn}
            data="dx"
          >
            {intl.get('public.save')}
          </Button>
        </p>
      </div>
    );
  };
  // 增值税ID
  onChangeVatId = e => {
    const { vatTextAreaVal } = this.state;
    const val = e.target.value.replace(/[^0-9]/g, '');

    this.setState({
      vatId: val.trim()
    });

    if (!val) {
      if (!vatTextAreaVal) {
        this.setState({
          vatTextDisable: true,
          vatDisable: true,
          vatTextErrorMsg: ''
        });
        return;
      }
      this.setState({
        vatErrorMsg: intl.get('setting.other.vat_input_verify'),
        vatDisable: false
      });
      return;
    }
    if (val.length < 8) {
      this.setState({
        vatErrorMsg: intl.get('setting.other.vat_id_verify'),
        vatDisable: false
      });
      return;
    }

    if (vatTextAreaVal) {
      this.setState({
        vatTextDisable: true,
        vatDisable: true
      });
    } else {
      this.setState({
        vatTextDisable: false,
        vatDisable: false
      });
    }

    this.setState({
      vatErrorMsg: '',
      vatDisable: true
    });
  };

  // company name 输入框
  vatTextAreaChange = e => {
    const { vatId } = this.state;
    const val = e.target.value;
    this.setState({
      vatTextAreaVal: val.trim()
    });

    if (!val) {
      if (!vatId) {
        this.setState({
          vatTextDisable: true,
          vatDisable: true,
          vatErrorMsg: ''
        });
        return;
      }
      this.setState({
        vatTextErrorMsg: intl.get('setting.other.vat_company'),
        vatTextDisable: false
      });
      return;
    }

    if (vatId.length > 7 && vatId.length < 13) {
      this.setState({
        vatTextDisable: true,
        vatDisable: true
      });
    } else {
      this.setState({
        vatTextDisable: false,
        vatDisable: false
      });
    }

    this.setState({
      vatTextErrorMsg: '',
      vatTextDisable: true
    });
  };
  // save 增值税
  saveVatClick = async () => {
    const { vatId, vatTextAreaVal, vatErrorMsg, vatTextErrorMsg } = this.state;

    //都为空的情况
    // if (!vatId && !vatTextAreaVal) {
    //   this.saveVat({
    //     id: vatId,
    //     company: vatTextAreaVal
    //   });
    // }

    // //id为空
    // if (!vatId && vatTextAreaVal) {
    //   message.error(intl.get('setting.other.vat_input_verify'));
    //   return;
    // }

    // // textVal在
    // if (!vatTextAreaVal && vatId) {
    //   message.error(intl.get('setting.other.vat_company'));
    //   return;
    // }

    // //id在
    // if (vatId && vatTextAreaVal) {
    //   if (!/\d{8,12}/g.test(vatId)) {
    //     message.error(intl.get('setting.other.vat_id_verify'));
    //     return;
    //   } else {
    this.saveVat({
      id: vatId,
      company: vatTextAreaVal
    });
    //   }
    // }
  };

  saveVat = async data => {
    const response = await Post('/set/vat', {
      data: data
    });

    if (response.code === 2010) {
      message.success(response.msg);
      this.setState({
        vatDisable: false
      });
    }
  };

  currentLanguage = getLanguage();
  updataConfirmWord = ''; //用户更新需要输入confirm

  inputUpdataConfirm = e => {
    this.updataConfirmWord = e.target.value;
  };

  upData = () => {
    if (
      this.updataConfirmWord.trim().toLocaleLowerCase() !==
      confirmLanguage[this.currentLanguage]
    ) {
      return;
    }
    this.setState({
      buttonLoading: true,
      selectStory: []
    });
    this.props.dispatch({
      type: 'dropshippersetting/setUpdataPricing',
      payload: {
        data: {
          store_id: this.state.selectStory //所选店铺id的数组
        },
        callback: d => {
          this.setState({
            buttonLoading: false
          });
          this.getStatus(this.state.allStoryId2);
          this.setState({
            showUpdataPrcie: false
          });
        }
      }
    });
  };
  showUpDataModal = () => {
    
    let flag = false

    this.state.selectStory.forEach(item=>{
      if(!this.props.updataPriceStatus?.data[item].remain_count){
          flag = true
      }
    })
    CommonDataCollect({
      action: 'click_manual_update_price_button',
      event_type:'subscribe_cam_01'
    });
    if (flag) {
      CommonDataCollect({
        action: 'Manual update price-top',
        event_type:'overlimit_pop'
      });
      // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Manual update price&${Date.now()}`, true);
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'ManuallyUpdatePrice')
    }else{
      
      this.setState({
        showUpdataPrcie: true
      });
    }
  };

  //店铺状态轮询
  getStoreStatus = list => {
    let allStoryId = '';
    JSON.parse(localStorage.getItem('store'))?.stores.forEach(item => {
      allStoryId += item.id + ',';
    });
    allStoryId = allStoryId.substring(0, allStoryId.length - 1);
    this.setState({
      allStoryId2: allStoryId
    });
    this.getStatus(allStoryId);
    const that = this;
    this.interval = setInterval(() => {
      this.getStatus(allStoryId);
    }, 60000);
  };

  changeCustomize = e => {
    this.setState(
      {
        customizeVal: e
      },
      () => {
        this.props.dispatch({
          type: 'dropshippersetting/setTaxRevenue',
          payload: {
            data: {
              tax_able: this.state.taxableStatus,
              custom_product_desc: e,
              new_send_cost_able: this.state.new_send_cost_able
            },
            callback: d => {
              if (d.code == 2000) {
                message.success('SUCCESS');
              }
            }
          }
        });
      }
    );
  };

  getStatus = storyid => {
    this.props.dispatch({
      type: 'dropshippersetting/updataPricing',
      payload: {
        data: {
          store_ids: storyid
        }
      }
    });
  };

  handleSetProduct = e => {
    this.setState(
      {
        taxableStatus: e
      },
      () => {
        this.setTaxRevenueData();
      }
    );
  };

  handleSetProductCost = e => {
    if(e.target.value === 2 && [1,4,8,10,12,13,14,15,16,17].includes(this.props?.plan?.type)){
      this.setState({
        isModalVisiable:true
      })
      return
    }
    this.setState(
      {
        new_send_cost_able: e.target.value
      },
      async () => {
        this.props.dispatch({
          type: 'dropshippersetting/setTaxRevenue',
          payload: {
            data: {
              new_send_cost_able: e.target.value,
              tax_able: this.state.taxableStatus,
              custom_product_desc: this.state.customizeVal
            },
            callback: d => {
              if (d.code == 2000) {
                message.success('SUCCESS');
              }
            }
          }
        });
      }
    );
  }

  // 更新shopify授权
  goShopify = (shop_name, openNewTab) => {
    let dataParam = openNewTab ? { shop_name, refresh_new: true } : { shop_name };
    this.props.dispatch({
      type: 'dropshippersetting/updateShopfiy',
      payload: {
        data: dataParam,
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            if (d.msg == 'bind store quota err') {
              localStorage.setItem('passivity', 'true');
              //  跳转订阅 && 弹窗拦截
              // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `l-${Date.now()}`, true);
              //  self.location.href = '/app/pricing?select=1';
              window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'MultiStore')
              //这里修改
                }
            if (openNewTab) {
              window.open(d.data.url, "_blank");
              this.setState({
                showRefreshPop:true
              })
            } else {
               window.location.href = d.data.url;
            }
          } else {
            message.error(d.msg);
          }
        }
      }
    });
  };

  // 展示卸载shopify店铺弹窗
  handleDisconnect = storeid => {
    this.setState({
      disconnectShopifyStoreId: storeid
    });

    window.dataLayer.push({
      event: 'setting-account-click',
      type: 'shopify-store-disconnect'
    });
  };

  // 隐藏卸载shopify店铺弹窗
  handleUnDisconnect = () => {
    this.setState({
      disconnectShopifyStoreId: undefined
    });

    window.dataLayer.push({
      event: 'setting-account-modal-click',
      type: 'stay'
    });
  };

  // 卸载shopify店铺成功回调
  handleDisconnectCallback = () => {
    this.setState({
      disconnectShopifyStoreId: undefined
      // showDisconnectCallback: true
    });
  };

  handleCheckbox = value => {
    this.setState({
      selectStory: value
    });
  };

  tabsChangeHandler = name => {
    return () => {
      this.setState({
        TabsList: this.state.TabsList.reduce((v, i) => {
          if (i.name == name) {
            i.isSelected = true;
            v.push(i);
          } else {
            i.isSelected = false;
            v.push(i);
          }
          return v
        }, [])
      });
      this.changeItems(name)
      switch (name) {
        case intl.get('setting.tab_account'):
          this.setState({
            containerPage: intl.get('setting.tab_account')
          });
          break;
        case intl.get('setting.tab_sub_account'):
          this.setState({
            containerPage: intl.get('setting.tab_sub_account')
          });
          break; 
        case intl.get('setting.tab_sub_supplier'):
          this.setState({
            containerPage: intl.get('setting.tab_sub_supplier')
          }); 
          break;
        case intl.get('setting.tab_package_tracking'):
            this.setState({
              containerPage: intl.get('setting.tab_package_tracking')
            });
        break;
        case intl.get('setting.tab_stores'):
          this.setState({
            containerPage: intl.get('setting.tab_stores')
          });
          break;
        case intl.get('setting.tab_product'):
          this.setState({
            containerPage: intl.get('setting.tab_product')
          });
          break;
        case intl.get('setting.tab_pricing_rule'):
          this.setState({
            containerPage: intl.get('setting.tab_pricing_rule')
          });
          break;
        case intl.get('setting.tab_shipping'):
          this.setState({
            containerPage: intl.get('setting.tab_shipping')
          });
          break;
        case intl.get('setting.tab_order'):
          this.setState({
            containerPage: intl.get('setting.tab_order')
          });
          break;
        case intl.get('setting.tab_paypal'):
          this.setState({
            containerPage: intl.get('setting.tab_paypal')
          });
          break;
        case intl.get('setting.tab_tracking'):
          this.setState({
            containerPage: intl.get('setting.tab_tracking')
          });
          break;
        case intl.get('setting.tab_notification'):
          this.setState({
            containerPage: intl.get('setting.tab_notification')
          });
          break;
        case intl.get('setting.title_synchronization'):
          this.setState({
            containerPage: intl.get('setting.title_synchronization')
          });
          break;
        case intl.get('setting.tab_aff'):
          this.setState({
            containerPage: intl.get('setting.tab_aff')
          });
          break;
        case "Payments":
            this.setState({
              containerPage: "Payments"
            });
            break;
        default:
          this.setState({
            containerPage: intl.get('setting.tab_shipping')
          });
          break;
      }
    };
  };
  // Oberlo用户切换settings Add~
  changeItems = (name) => {
    if (!this.props.isOberlo) { return; }
     var settingItem = [{
        "order":intl.get('setting.tab_order')
     },{
        "pricingRule":intl.get('setting.tab_pricing_rule')
     },{
        "paypal":intl.get('setting.tab_paypal')
     },{
        "shipping":intl.get('setting.tab_shipping')
     },{
       "shipConfirm":intl.get('setting.tab_tracking')
     },{
       "autoUpdate":intl.get('setting.title_synchronization')
     }
    ];

    window.EVENT.emit(GuildeAction.OblerloGuide_Card, '', true);

    settingItem.map((item,index)=>{
      if (Object.values(item)[0] === name) {
          window.EVENT.emit(GuildeAction.OblerloGuide_Card, Object.keys(item)[0], true);
         }
    })
  }

  modalTitle = () => {
    let arr = [];
    this.state.selectStory?.forEach(item => {
      if (this.props.updataPriceStatus?.data?.[item]) {
        arr.push(this.props.updataPriceStatus?.data[item]);
      }
    });
    let count = arr?.reduce((inital, item) => {
      return inital + item.total;
    }, 0);
    if (count >= 100000) {
      return intl.get('public.goodsTooMuch');
    } else {
      return intl.get('public.updata_price');
    }
  };

  // tabs渲染
  tabRenderHandler=()=>{
const arr = (this.props.userInfo.code_type === 1 && this.props.userInfo.can_use_cashback && !this.props.newUserInfo?.user_role) ? this.state.TabsList : this.state.TabsList.filter((i)=>{
  return i.name != intl.get('setting.tab_aff')
})

    return arr.map(i => {
      return (
        <div
          key={i.name}
          className={this.state.isCsvUser && i.key == "tab6" ? "tabboxsNoBorder" : "tabboxs"}
          style={{ display: i?.dis ? 'block' : 'none' }}
        >
          <div
            className="tabsname"
            onClick={this.tabsChangeHandler(i.name)}
          >
            <div style={{ color: i.isSelected ? '#ff8f00' : '' }}>
              {i.name} {i.name === 'Product' && this.props.productLimitStatus ? <span className={styles.redDot}></span> : null}
            </div>
          </div>
        </div>
      );
    })
    // return (this.props.userInfo.code_type === 1 && this.props.userInfo.can_use_cashback && !this.props.newUserInfo?.user_role) ?
    // this.state.TabsList.map(i => {
    //   return (
    //     <div
    //       key={i.name}
    //       className="tabboxs"
    //       style={{ display: i?.dis ? 'block' : 'none' }}
    //     >
    //       <div
    //         className="tabsname"
    //         onClick={this.tabsChangeHandler(i.name)}
    //       >
    //         <div style={{ color: i.isSelected ? '#ff8f00' : '' }}>
    //           {i.name}
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }) :
    // this.state.TabsList.filter((i)=>{
    //   return i.name != intl.get('setting.tab_aff')
    // }).map(i => {
    //   return (
    //     <div
    //       key={i.name}
    //       className="tabboxs"
    //       style={{ display: i?.dis ? 'block' : 'none' }}
    //     >
    //       <div
    //         className="tabsname"
    //         onClick={this.tabsChangeHandler(i.name)}
    //       >
    //         <div style={{ color: i.isSelected ? '#ff8f00' : '' }}>
    //           {i.name}
    //         </div>
    //       </div>
    //     </div>
    //   );
    // })
  }
  onChangeItemFulfillChecked = checked => {
    this.setState({
      itemFulfillChecked: checked
    })
    this.setUstomization({
      custom_fulfill_switch:checked
    })
  }

  refreshPriceStatus = ()=>{
    this.ChildComponent?.refreshStatusHandler();
  }

  changeplatTrigger = (type) => {
    this.setState({ currentPS: type, shippingGlobalCollapseValue: 'q0' }) 
  }

  changeplatMessTrigger = (type)=>{
    this.setState({ currentMessPS: type }) 
  }

  updatePlanModalHandler=()=>{
    this.setState({
      isModalVisiable:false
    })
  }

  render() {
    const {
      countrys = [],
      logistics_services = [],
      logistics_services_tm=[],
      unsupportedShipping,
      tracking_company = [],
      admitadCheckCode,
      syncLoading,
      userInfo,
      cashbackCodeLoading,
      form,
      TMCurrentMess,
      plan: { type }
    } = this.props;
    const isCsvUser = userInfo.status.toString().substring(3, 4) === '1';

    const stores = userInfo.stores || [];

    const {
      country,
      TM_country,
      tabChangeSta,
      textAreaVal,
      iossVal,
      generalBtnDisabled,
      iossBtnDisabled,
      savePhoneBtnDisabled,
      errorText,
      shippingChangeValue,
      countryAdv,
      generalChecked,
      advancedChecked,
      Inventory2DefaultVal,
      PriceDefaultVal,
      CancelledOrderDefaultVal,
      SkuDefaultVal,
      autoUpdateSaveDisabled,
      otherChecked,
      NAChecked,
      storeData,
      syncStore,
      syncTimeTip,
      shippingGlobalCollapseValue,
      shippingAdvancedCollapseValue,
      showSupplyChainVerified,
      showEditCashback,
      cashbackCode,
      cashbackCodeErrorMsg,
      cashbackAccount,
      hasSyncLock,
      cashbackAccountErrorMsg,
      syncShopifyNotesStatus,
      rangePickerValue,
      skuCustomize,
      vatTextAreaVal,
      disconnectShopifyStoreId,
      vatDisable,
      vatId,
      vatErrorMsg,
      vatTextErrorMsg,
      showSubStaff,
      selectStory,
      storyIfUse,
      showUpdataPrcie,
      buttonLoading,
      taxableStatus,
      spStores,
      customizeVal,
      itemFulfillChecked,
      new_send_cost_able,
      currentPS,
      currentMessPS
    } = this.state;

    let howToUseAdmintLink =
      'https://help.dsers.com/admitad-cashback-for-shopify/';
    if (getLanguage() === 'fr-FR') {
      howToUseAdmintLink =
        'https://help.dsers.com/admitad-cashback-for-shopify/';
    }

    const { getFieldDecorator } = form;
    let gap = 0;

    if (rangePickerValue) {
      gap = (rangePickerValue[1] - rangePickerValue[0]) / 86400000;
    }

    return (
      <>
        <div className="newSetting_box">
          <div className="headTitle">
            {`${intl.get('setting.subaccount.Setting')} > ${
              this.state.TabsList.filter(i => {
                return i.isSelected == true;
              })[0]?.name
            }`}
          </div>
          <div className="tabbody">
            <div className="tabsnames">{this.tabRenderHandler()}</div>
            <div className="tabscontainer">
              {/* account折叠面板 */}
              <div
                style={{
                  display:
                    this.state.containerPage == intl.get('setting.tab_account')
                      ? 'block'
                      : 'none'
                }}
              >
                <Account userInfo={userInfo} isCsvUser={this.state.isCsvUser} />
              </div>
              {/* stores */}
              {!this.state.isCsvUser && (
                <>
                  <div
                    style={{
                      display:
                        this.state.containerPage ==
                        intl.get('setting.tab_stores')
                          ? 'block'
                          : 'none'
                    }}
                  >
                    <WhyDisconnect
                      shopifyStoreId={disconnectShopifyStoreId}
                      unDisconnect={this.handleUnDisconnect}
                      disconnectCallback={this.handleDisconnectCallback}
                    />
                    <Title
                      title={intl.get('setting.title_sp_store')}
                      description={intl.get('setting.sp_stores_des')}
                    />
                    <div className={styless.otherCont}>
                      <div className={styless.otherSwitch}>
                        <div className={styless.storeList}>
                          {/* <p>
                    <span className={styles.storeListTitle}>
                      {intl.get('setting.account.store_name')}
                      <Tooltip title={intl.get('setting.account.store_name_tip')}>
                        <Icon type="info-circle" />
                      </Tooltip>
                    </span>
                    <span className={styles.storeListTitle}>
                      {intl.get('setting.account.status')}
                    </span>
                  </p> */}
                          {spStores.length === 0
                            ? this.NoDataPosition()
                            : spStores.map((item, index) => {
                                return (
                                  <p key={item.id}>
                                    <span
                                      className={className({
                                        [styless.troubleStore]: item.update
                                      })}
                                    >
                                      <span className={styles.sp_stores}>
                                        <span className={styles.sp_stores_name}>
                                          {item?.shop_name}
                                        </span>
                                        <span
                                          className={styles.sp_stores_domain}
                                        >
                                          Domain: {item?.domain}
                                        </span>
                                      </span>
                                      <></>
                                      <span
                                        className={
                                          item.break
                                            ? 'connectedBreak'
                                            : item.update
                                            ? 'connectedError connectedErrorStyle'
                                            : 'connecteSuccess connecteSuccessStyle'
                                        }
                                      >
                                        {item.break ? (
                                          <>
                                            {intl.get(
                                              'setting.refresh_store_tag'
                                            )}
                                          </>
                                        ) : item.update ? (
                                          intl.get(
                                            'setting.refresh_not_connected'
                                          )
                                        ) : (
                                          intl.get('setting.account.connected')
                                        )}
                                        {item.break ? (
                                          <Tooltip
                                            title={intl.getHTML(
                                              'setting.refresh_store_tag_tip'
                                            )}
                                          >
                                            <Icon type="question-circle" />
                                          </Tooltip>
                                        ) : null}
                                        {!item.break && item.update ? (
                                          <Tooltip
                                            title={intl.get(
                                              'setting.account.shopify_stores_re_authorized'
                                            )}
                                          >
                                            <Icon type="question-circle" />
                                          </Tooltip>
                                        ) : null}
                                      </span>
                                    </span>
                                    <span>
                                      {item.break && (
                                        <Tooltip
                                          title={intl.get(
                                            'setting.refresh_store_icon_tip'
                                          )}
                                        >
                                          <img
                                            className="breakIcon"
                                            onClick={() => {
                                              this.getShopifyStoreStatusSingle(
                                                index,
                                                spStores
                                              );
                                            }}
                                            src={require('../../../assets/setting/break.png')}
                                          />
                                        </Tooltip>
                                      )}
                                    </span>
                                    <span>
                                      {item.update && !item.break ? (
                                        <a
                                          className={styless.updateShopifyStore}
                                          onClick={() =>
                                            this.goShopify(
                                              item.shop_name,
                                              false
                                            )
                                          }
                                        >
                                          <i class="material-icons notranslate">
                                            sync
                                          </i>
                                        </a>
                                      ) : null}
                                      {item?.news > 0 && !item.break && (
                                        <>
                                          <Tooltip
                                            title={intl.get(
                                              'setting.account.authorize_tips'
                                            )}
                                          >
                                            <span
                                              className={styles.goShopifyUpdate}
                                              onClick={() => {
                                                this.goShopify(
                                                  item.shop_name,
                                                  true
                                                );
                                              }}
                                            >
                                              <img
                                                src={require('../../../assets/clock.png')}
                                              />
                                              <span
                                                className={
                                                  styles.numShopifyIcon
                                                }
                                              >
                                                {item?.news > 99
                                                  ? '99+'
                                                  : item?.news}
                                              </span>
                                            </span>
                                            <span
                                              className={
                                                styles.goShopifyUpdateBtn
                                              }
                                              onClick={() => {
                                                this.goShopify(
                                                  item.shop_name,
                                                  true
                                                );
                                              }}
                                            >
                                              {intl.get(
                                                'setting.account.authorize_name'
                                              )}
                                            </span>
                                          </Tooltip>
                                        </>
                                      )}
                                      <a
                                        className={styless.updateShopifyStore}
                                        data-storeid={item.id}
                                        onClick={() => {
                                          this.handleDisconnect(item.id);
                                        }}
                                      >
                                        {/* {intl.get('setting.account.disconnect')} */}
                                        <i class="material-icons notranslate">
                                          delete
                                        </i>
                                      </a>
                                    </span>
                                  </p>
                                );
                              })}
                        </div>
                      </div>
                    </div>
                    <Currencies />
                  </div>
                  {/* staff account折叠面板 */}
                  <div
                    style={{
                      display:
                        this.state.containerPage ==
                        intl.get('setting.tab_sub_account')
                          ? 'block'
                          : 'none'
                    }}
                  >
                    {showSubStaff ? (
                      <>
                        <Title
                          title={intl.get('setting.subaccount.add')}
                          description={intl.get('setting.subaccount.sendemail')}
                        />
                        <div className={styles.boxstyles}>
                          <StaffAccount></StaffAccount>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              )}

              {/* Supplier 折叠面板 */}
              <div
                style={{
                  display:
                    this.state.containerPage ==
                    intl.get('setting.tab_sub_supplier')
                      ? 'block'
                      : 'none'
                }}
              >
                <Supplier isCsvUser={!this.state.isCsvUser} />
              </div>

              {/* product */}

              {!this.state.isCsvUser && (
                <>
                  <div
                    style={{
                      display:
                        this.state.containerPage ==
                        intl.get('setting.tab_product')
                          ? 'block'
                          : 'none'
                    }}
                  >
                    <Title title={intl.get('setting.title_product')} />
                    <div
                      className={styles.boxstyles}
                      style={{ overflow: 'visible' }}
                    >
                      <div
                        className={styles.otherSwitch}
                        style={{ paddingTop: '10px', marginTop: '0px' }}
                      >
                        <h3>{intl.get('setting.other.aliSku')}</h3>
                        <p className={styles.dp}>
                          {intl.get('setting.other.aliSku_desc')}
                        </p>
                        <div className={styles.switchWrap}>
                          <Switch
                            checked={skuCustomize}
                            onChange={this.handleChangeSKUStatus}
                          />
                        </div>
                      </div>
                      <div className={styles.separateLines} />
                      <div className={styles.otherSwitch}>
                        <h3>{intl.get('setting.other.set_product_title')}</h3>
                        <p className={styles.dp}>
                          {intl.get('setting.other.set_product_subtitle')}
                        </p>
                        <div
                          className={styles.switchWrap}
                          style={{ top: '25px' }}
                        >
                          <Switch
                            checked={taxableStatus}
                            onChange={this.handleSetProduct}
                          />
                        </div>
                      </div>
                      <div className={styles.separateLines} />
                      <div className={styles.otherSwitch}>
                        <h3>
                          {intl.get('setting.product.shipping_cost_title')}
                        </h3>
                        <p className={styles.dp}>
                          {intl.get('setting.product.customize_con')}
                        </p>
                        <div>
                          <RadioGroup
                            className={styles.radiostyle}
                            onChange={this.handleSetProductCost}
                            value={new_send_cost_able}
                          >
                            {/* <Radio value={0}>
                              {intl.get('setting.notification.do_nothing')}
                            </Radio> */}
                            <Radio value={1}>
                              <span>
                                {intl.get('product.productCostToShopify')}
                                <Tooltip
                                  placement="right"
                                  // getPopupContainer={triggerNode => triggerNode.parentNode}
                                  title={intl.getHTML(
                                    'setting.product.shipping_cost_tip1'
                                  )}
                                >
                                  <Icon
                                    type="question-circle"
                                    theme="outlined"
                                    style={{
                                      marginLeft: 2
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            </Radio>
                            <Radio value={2}>
                              {intl.get(
                                'product.productCostAndShippingCostToShopify'
                              )}
                              <Tooltip
                                placement="bottom"
                                getPopupContainer={triggerNode =>
                                  triggerNode.parentNode
                                }
                                overlayClassName={styles.shippingTipTooptip}
                                title={intl.getHTML(
                                  'setting.product.shipping_cost_tip2'
                                )}
                              >
                                <Icon
                                  type="question-circle"
                                  theme="outlined"
                                  style={{
                                    marginLeft: 2
                                  }}
                                />
                              </Tooltip>
                            </Radio>
                          </RadioGroup>
                        </div>
                        {/* <p className={styles.dp}>
                    {intl.get('product.productCostAe')}
                    </p>
                    <div className={styles.switchWrap} style={{top:'25px'}} >
                      <Switch
                        checked={new_send_cost_able}
                        onChange={this.handleSetProductCost}
                      />
                    </div> */}
                      </div>
                      <div className={styles.separateLines} />
                      <div className={styles.otherSwitch}>
                        <h3>{intl.get('setting.product.customize_title')}</h3>
                        <p className={styles.dp} style={{ maxWidth: '300px' }}>
                          {intl.get('setting.product.customize_con')}
                        </p>
                        <div className={styles.customizeSelect}>
                          <Select
                            className={styles.customizeStoreSelect}
                            style={{ marginRight: '0px' }}
                            dropdownMatchSelectWidth={false}
                            value={customizeVal}
                            onChange={this.changeCustomize}
                            getPopupContainer={triggerNode =>
                              triggerNode.parentNode
                            }
                          >
                            {customizeData.map(store => (
                              <Option value={store.value} key={store.value}>
                                <span>{store.label}</span>
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className={styles.separateLines} />
                      <div
                        className={styles.otherSwitch}
                        style={{ marginBottom: '15px' }}
                      >
                        <h3> {intl.get('setting.product.location_title')}</h3>
                        <p
                          className={styles.dpLocation}
                          style={{ width: '620px' }}
                        >
                          {intl.get('setting.product.location_content')}
                        </p>
                      </div>

                      {this.state.location_data.map((item, index) => {
                        return (
                          <div>
                            <div
                              style={{ color: '#000000' }}
                              className={styles.set_locationPart}
                            >
                              <div>{item.location}</div>
                              <Switch
                                checked={item.status}
                                onChange={() => {
                                  this.locationChange(item, index);
                                }}
                              />
                            </div>
                            {index != this.state.location_data.length - 1 && (
                              <div
                                className={styles.separateLines}
                                style={{ margin: '0px 0' }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <ProductLimit></ProductLimit>
                  </div>
                </>
              )}
              {/* shipping折叠面板 */}
              <div
                style={{
                  display:
                    this.state.containerPage == intl.get('setting.tab_shipping')
                      ? 'block'
                      : 'none'
                }}
              >
                <div className={styles.shippingBox}>
                  <div className={styles.trackingBox}>
                    <div className="shippng_desc_box">
                      <div className="shipping_title">
                        {intl.get('setting.title_shipping')}
                      </div>
                      <div className="shippng_desc">
                        <p>{intl.get('setting.shipping.description')}</p>
                        <div className="switch_d">
                          <Switch
                            checked={generalChecked || advancedChecked}
                            onChange={this.switchShippingSetting}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.trackingCardChange}>
                      <span
                        onClick={() => {
                          this.changeplatTrigger('AE');
                        }}
                        className={
                          currentPS == 'AE'
                            ? styles.ps_active
                            : styles.ps_common
                        }
                      >
                        AliExpress
                      </span>
                      {window.TMALL_USER ? (
                        <span
                          onClick={() => {
                            this.changeplatTrigger('TM');
                          }}
                          className={
                            currentPS == 'TM'
                              ? styles.ps_active
                              : styles.ps_common
                          }
                        >
                          Tmall
                        </span>
                      ) : null}
                    </div>
                    <div className={styles.trackingCont}>
                      <div className={styles.turnOnWrap}>
                        {!generalChecked && !advancedChecked ? (
                          <div className={styles.turnOnDialog} />
                        ) : null}
                        {/* 选国家开始 */}
                        <Radio.Group
                          onChange={e => {
                            this.switchAdvancedNew(
                              e.target.value == 1 ? false : true
                            );

                            this.setState({
                              shippingChangeValue: e.target.value
                            });
                          }}
                          value={
                            currentPS != 'AE'
                              ? 1
                              : this.state.shippingChangeValue
                          }
                        >
                          <div className="countryList">
                            <div className="radios_box">
                              <Radio value={1} />
                            </div>
                            <div className={styles.trackMain}>
                              <div className={styles.trackMainTitle}>
                                <h3>
                                  {intl.get('setting.shipping.general.title')}
                                </h3>
                                <p>
                                  {intl.get(
                                    'setting.shipping.general.description'
                                  )}
                                </p>
                              </div>
                              <div
                                className={styles.mainCont}
                                style={{ width: '580px' }}
                              >
                                {this.state.shippingChangeValue == 1 ||
                                currentPS != 'AE' ? null : (
                                  <div className="zask"></div>
                                )}
                                <Collapse
                                  expandIconPosition="right"
                                  accordion
                                  activeKey={shippingGlobalCollapseValue}
                                  onChange={this.changeGlobalCollapseValue}
                                >
                                  {(currentPS == 'AE'
                                    ? country
                                    : TM_country
                                  ).map((item, index) => {
                                    let countryUnsupportMap =
                                      unsupportedShipping[item[0]] || {};
                                    countryUnsupportMap = {
                                      ...countryUnsupportMap,
                                      ...(unsupportedShipping.Global || {})
                                    };
                                    return this.countryPanel(
                                      item,
                                      index,
                                      logistics_services,
                                      logistics_services_tm,
                                      countryUnsupportMap,
                                      countrys,
                                      country.length,
                                      this.state.shippingChangeValue
                                    );
                                  })}
                                </Collapse>
                                <div className={styles.saveBtnWrap}>
                                  <Button
                                    disabled={this.state.saveShipping}
                                    className={styles.saveBtn}
                                    onClick={this.saveCountryAndTrack}
                                    data="dx"
                                  >
                                    {intl.get('public.save')}
                                  </Button>
                                </div>
                              </div>
                            </div>
                            {/* 选国家结束 */}
                          </div>
                          {/* Advanced开始 */}
                          {currentPS == 'AE' && (
                            <div className="countryList">
                              <div className="radios_box">
                                <Radio value={2} />
                              </div>
                              <div className={styles.trackMain}>
                                <div className={styles.trackMainTitle}>
                                  <h3>
                                    {intl.get(
                                      'setting.shipping.advanced.title'
                                    )}
                                  </h3>
                                  <p>
                                    {intl.get(
                                      'setting.shipping.advanced.description'
                                    )}
                                  </p>
                                </div>
                                <div className={styles.trackTitle}>
                                  <div
                                    className={styles.mainCont}
                                    style={{ width: '580px' }}
                                  >
                                    {this.state.shippingChangeValue ==
                                    2 ? null : (
                                      <div className="zask"></div>
                                    )}
                                    <Collapse
                                      expandIconPosition="right"
                                      accordion
                                      activeKey={shippingAdvancedCollapseValue}
                                      onChange={
                                        this.changeAdvancedCollapseValue
                                      }
                                    >
                                      {countryAdv.map((item, index) => {
                                        return this.advancedPanel(
                                          item,
                                          index,
                                          countrys,
                                          countryAdv.length,
                                          this.state.shippingChangeValue
                                        );
                                      })}
                                    </Collapse>
                                    <div className={styles.saveBtnWrap}>
                                      <Button
                                        disabled={this.state.saveAdvanced}
                                        className={styles.saveBtn}
                                        onClick={this.saveCountryAdvanced}
                                        data="dx"
                                      >
                                        {intl.get('public.save')}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* Advanced结束  */}
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                  {!this.state.isCsvUser && (
                    <>
                      <Title
                        title={intl.get('setting.title_add_global_shipping')}
                        description={intl.get(
                          'setting.add_global_shipping_des'
                        )}
                      />
                      <div className={`productShipping`}>
                        <div>
                          {/* <div className={`trackTitle`}>
                      <h3>{intl.get('setting.shipping.product.title')}</h3>
                      <p>{intl.get('setting.shipping.product.dec')}</p>
                    </div> */}
                          <div
                            onClick={this.showProductSearch}
                            className="searchProduct"
                          >
                            <div className="iscon">
                              <i class="material-icons notranslate">search</i>
                            </div>
                            <div className="shippingNaw">
                              {intl.get('setting.shipping.product.placeholder')}
                              <p>
                                {intl.get(
                                  'setting.shipping.product.shortcut_open'
                                )}
                              </p>
                            </div>
                            <div className="isycon">
                              <i class="material-icons notranslate">
                                arrow_forward_ios
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ProductShipping />
                    </>
                  )}
                </div>
              </div>
              {/* order折叠面板 */}
              {!this.state.isCsvUser && (
                <>
                  <div
                    style={{
                      display:
                        this.state.containerPage ==
                        intl.get('setting.tab_order')
                          ? 'block'
                          : 'none'
                    }}
                  >
                    {type == 9 ? (
                      <div className={styles.otherCont}>
                        <Title
                          title={'Order Fulfillment Rules'}
                          description={null}
                        />
                        <div className={styles.boxstyles}>
                          <div className={styles.otherSwitch}>
                            <p>
                              After the feature is activated, the rules for
                              fulfilling orders on the DSers are: After an item
                              in a Shopify order has a tracking number, we will
                              fulfill that item in the order and send an email
                              for each of the item to your customer.
                            </p>
                            <div className={styles.switchWrap}>
                              <Switch
                                checked={itemFulfillChecked}
                                onChange={this.onChangeItemFulfillChecked}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className={styles.otherCont}>
                      <Title title={intl.get('setting.title_leave_message')} />
                      <div className={styles.trackingCardChangeBox} style={{borderBottomRightRadius: 12, borderBottomLeftRadius: 12}}>
                        <div className={styles.trackingCardTitle}>
                          {currentMessPS == 'TM'
                            ? intl.get('setting.title_leave_message_tmall')
                            : currentMessPS === 'AE' ? intl.get('setting.title_leave_message_ae') : 'Agency suppliers will get this message together with your order information.'}
                        </div>

                        <div className={styles.boxstyles}>
                          <Tabs
                            animated={false}
                            activeKey={currentMessPS}
                            onChange={e => this.changeplatMessTrigger(e)}
                          >
                            <Tabs.TabPane key="AE" tab="AliExpress">
                              <TextArea
                                style={{ marginTop: '16px' }}
                                rows={4}
                                placeholder={intl.get(
                                  'setting.other.message_placeholder'
                                )}
                                value={textAreaVal}
                                maxLength="1000"
                                onChange={e => {
                                  this.textAreaChange(e);
                                }}
                              />
                              <p className={styles.generalTextLength}>
                                {1000 - textAreaVal.length * 1}/1000
                              </p>
                              <p className={styles.generalText}>
                                <Button
                                  type="primary"
                                  onClick={this.saveMessageClick}
                                  disabled={generalBtnDisabled}
                                  className={styles.saveBtn}
                                  data="dx"
                                >
                                  {intl.get('public.save')}
                                </Button>
                              </p>
                            </Tabs.TabPane>

                            {!isCsvUser && window.TMALL_USER ? <Tabs.TabPane tab="TMall" key="TM">
                              <TextArea
                                style={{ marginTop: '16px' }}
                                rows={4}
                                placeholder={intl.get(
                                  'setting.other.message_placeholder'
                                )}
                                value={TMCurrentMess}
                                maxLength="70"
                                disabled
                              />
                              <p className={styles.generalTextLength}>
                                {70 - TMCurrentMess.length * 1}/70
                              </p>
                              <p className={styles.generalText}>
                                <Button
                                  type="primary"
                                  className={styles.saveBtn}
                                  disabled={true}
                                >
                                  {intl.get('public.save')}
                                </Button>
                              </p>
                            </Tabs.TabPane> : null}
                            
                            {!isCsvUser && window.CAINIAO_USER ? <Tabs.TabPane tab="Agency" key="AGENCY">
                              <TextArea
                                style={{ marginTop: '16px' }}
                                rows={4}
                                placeholder={intl.get(
                                  'setting.other.message_placeholder'
                                )}
                                value={this.state.agencyMessageVal}
                                maxLength="1000"
                                onChange={this.agencyMessageChange}
                              />
                              <p className={styles.generalTextLength}>
                                {1000 - this.state.agencyMessageVal.length * 1}/1000
                              </p>
                              <p className={styles.generalText}>
                                <Button
                                  type="primary"
                                  className={styles.saveBtn}
                                  disabled={this.state.agencyMessageButtonDisabled}
                                  onClick={this.saveAgencyMessage}
                                >
                                  {intl.get('public.save')}
                                </Button>
                              </p>
                            </Tabs.TabPane> : null}

                          </Tabs>
                        </div>
                      </div>
                      
                      <Title
                        title={intl.get('setting.title_phone_number_op')}
                        description={intl.getHTML('setting.phone_number_des')}
                      />
                      <div
                        className={styles.boxstyles}
                        style={{ overflow: 'visible' }}
                      >
                        {this.PhoneNumber(1)}
                        <div className="splitline"></div>
                        {/* 默认手机号 */}
                        {this.PhoneNumber(2)}
                      </div>
                      {OrderSplitArr.includes(type) ||
                      isCsvUser ||
                      (!this.state.orderHidden &&
                        !this.state.orderSplitData?.length) ? null : (
                        <>
                          <div
                            className={styles.boxstyles}
                            style={{
                              borderTopLeftRadius: '12px',
                              borderTopRightRadius: '12px',
                              overflow: 'visible'
                            }}
                          >
                            <div className="contitless">
                              {intl.get('setting.order.title')}
                            </div>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <p style={{ paddingRight: 24 }}>
                                {intl.get('setting.order.desc')}
                              </p>
                              <Switch
                                checked={this.state.orderHidden}
                                onChange={e => {
                                  if (
                                    this.state.orderHidden &&
                                    !this.state.orderSplitData?.length
                                  ) {
                                    this.setState({ orderSplitPopShow: true });
                                  } else {
                                    this.setOrderSplit({
                                      switch: !this.state.orderHidden
                                    });
                                    this.setState({
                                      orderHidden: !this.state.orderHidden
                                    });
                                  }
                                }}
                              />
                            </div>
                            <OrderSplit
                              countrys={countrys}
                              orderVal={this.state.orderSplitData}
                              orderHidden={this.state.orderHidden}
                              setOrderSplitList={async params => {
                                let response = await Post(
                                  '/set/order_setting/split_list',
                                  {
                                    data: params
                                  }
                                );
                                if (response?.code === 2000) {
                                  message.success(response.msg);
                                  this.getOrderSplitData();
                                }
                              }}
                            />
                          </div>
                        </>
                      )}
                      <Title
                        title={intl.get('setting.title_order_management')}
                      />
                      <div className={styles.boxstyles}>
                        <div className={styles.otherSwitch}>
                          <h3>
                            {intl.get(
                              'setting.other.canceled_orders_optimization'
                            )}
                          </h3>
                          <p>
                            {intl.get(
                              'setting.other.canceled_orders_optimization_tip'
                            )}
                          </p>
                          <div className={styles.switchWrap}>
                            <Switch
                              checked={NAChecked}
                              onChange={checked => {
                                this.postOtherNASta(checked);
                              }}
                            />
                          </div>
                        </div>
                        <div className={styles.separateLines} />
                        <CancelRequestFulfillment />
                      </div>
                      <Title title={intl.get('setting.title_tax')} />
                      <div className={styles.boxstyles}>
                        {/* ioss开始 */}
                        <h3 style={{ marginTop: '10px' }}>
                          {intl.get('setting.other.ioss_message')}
                        </h3>

                        <div
                          className={
                            this.state.isIossShow
                              ? styles.taxMiddleActive
                              : styles.taxMiddle
                          }
                        >
                          <p className={styles.dp}>
                            {intl.getHTML('setting.other.ioss_message_tip')}
                            <Tooltip
                              title={intl.getHTML(
                                'setting.other.ioss_message_info'
                              )}
                            >
                              <Icon
                                style={{ marginLeft: 5, marginRight: 5 }}
                                type="question-circle"
                                theme="outlined"
                              />
                            </Tooltip>
                            {intl.getHTML('setting.other.ioss_message_more')}
                          </p>

                          <img
                            onClick={() => {
                              this.setState({
                                isIossShow: !this.state.isIossShow
                              });
                            }}
                            src={leftExpend}
                          />
                        </div>
                        {this.state.isIossShow && (
                          <>
                            <Input
                              className={styles.inoutBc}
                              value={iossVal}
                              placeholder="IOSS ID"
                              onChange={e => {
                                this.iossValChange(e);
                              }}
                              maxLength={20}
                            />
                            <p style={{ color: 'red' }}>
                              {this.state.isShow
                                ? intl.get('setting.other.ioss_message_error')
                                : null}
                            </p>
                            <p className={styles.generalText}>
                              <Button
                                type="primary"
                                onClick={this.saveIossMessage}
                                disabled={iossBtnDisabled}
                                className={styles.saveBtn}
                                data="dx"
                              >
                                {intl.get('public.save')}
                              </Button>
                            </p>
                          </>
                        )}
                        {/* VAT */}
                        {this.state.isVatShow && (
                          <>
                            <div className={styles.separateLines} />
                            <h3>VAT</h3>
                            <p>{intl.getHTML('setting.other.vat_title')}</p>
                            <Input
                              className={styles.inoutBc}
                              value={vatId}
                              disabled
                              onChange={this.onChangeVatId}
                              placeholder={intl.get(
                                'setting.other.plan_info_id'
                              )}
                            />
                            {vatErrorMsg ? (
                              <p style={{ fontSize: 12, color: 'red' }}>
                                {vatErrorMsg}
                              </p>
                            ) : null}
                            <TextArea
                              placeholder={intl.get(
                                'setting.other.plan_info_name'
                              )}
                              style={{ marginTop: 15 }}
                              rows={4}
                              disabled
                              value={vatTextAreaVal}
                              maxLength="128"
                              onChange={e => {
                                this.vatTextAreaChange(e);
                              }}
                            />
                            <p
                              className={styles.generalTextLength}
                              style={{
                                display: 'inline-block',
                                float: 'right'
                              }}
                            >
                              {128 - vatTextAreaVal.length * 1}/128
                            </p>
                            {vatTextErrorMsg ? (
                              <p
                                style={{
                                  fontSize: 12,
                                  color: 'red',
                                  display: 'inline-block'
                                }}
                              >
                                {vatTextErrorMsg}
                              </p>
                            ) : null}
                            <p style={{ clear: 'both' }}></p>
                            <p className={styles.generalText}>
                              <Button
                                type="primary"
                                onClick={this.saveVatClick}
                                disabled={true}
                                className={styles.saveBtn}
                                data="dx"
                              >
                                {intl.get('public.save')}
                              </Button>
                            </p>
                          </>
                        )}
                      </div>

                      {/* {!isCsvUser && window.CAINIAO_USER ? <PlaceAutomaticly /> : null} */}

                      {/* {!isCsvUser && window.CAINIAO_USER ? <AgencyTax /> : null} */}
                    </div>

                    <Modal
                      visible={this.state.orderSplitPopShow}
                      width={560}
                      footer={
                        <div>
                          <Button
                            onClick={() => {
                              this.setOrderSplit({
                                switch: !this.state.orderHidden
                              });
                              this.setState({
                                orderHidden: !this.state.orderHidden,
                                orderSplitPopShow: false
                              });
                            }}
                            type="primary"
                          >
                            {intl.get('setting.other.order_split_pop_btn')}
                          </Button>
                        </div>
                      }
                      onCancel={() => {
                        this.setState({ orderSplitPopShow: false });
                      }}
                      title={intl.get('setting.other.order_split_pop_title')}
                    >
                      <div>
                        {intl.get('setting.other.order_split_pop_content')}
                      </div>
                    </Modal>
                  </div>
                  {/* Package Tracking折叠面板 */}
                  <div
                    style={{
                      display:
                        this.state.containerPage ==
                        intl.get('setting.tab_package_tracking')
                          ? 'block'
                          : 'none'
                    }}
                  >
                    <div className={styles.trackingBox}>
                      <Title
                        title={intl.get('setting.title_tracking')}
                        description={intl.get('setting.title_tracking_tip')}
                      />
                      <PackageTracking />
                    </div>

                    <div className={styles.trackingBox}>
                      <Title
                        description={intl.get(
                          'setting.tracking.track_to_paypal_desc'
                        )}
                        title={intl.get('setting.title_paypal')}
                      />
                      <Tracking
                        tabname="PayPal"
                        shippingCarrierList={tracking_company}
                      />
                    </div>

                    <div className={styles.trackingBox}>
                      <Title
                        description={intl.get(
                          'setting.title_shipping_confirmation_des'
                        )}
                        title={intl.get('setting.title_shipping_confirmation')}
                      />
                      <Tracking
                        tabname="Shipping confirmation"
                        shippingCarrierList={tracking_company}
                      />
                    </div>
                  </div>
                  {/* notification折叠面板 */}
                  <div
                    style={{
                      display:
                        this.state.containerPage ==
                        intl.get('setting.tab_notification')
                          ? 'block'
                          : 'none'
                    }}
                  >
                    <Title title={intl.get('setting.title_notification')} />
                    <div className={styles.boxstyles}>
                      <div className={'UpdatesCont'}>
                        <p className="Notification_feature">
                          {intl.get(
                            'setting.notification.notification_feature'
                          )}
                        </p>
                        <div className={'UpdatesTitle'}>
                          <p>{intl.get('setting.notification.description')}</p>
                        </div>
                        <div className={'Updates2'}>
                          <div className="Updates2Icon">
                            <span class="material-icons notranslate">
                              local_mall
                            </span>
                          </div>
                          <div className="Updates2Title">
                            <h4>
                              {intl.get('setting.notification.inventory')}
                            </h4>
                            <div className={'uodateP'}>
                              <span>
                                {intl.get(
                                  'setting.notification.inventory_description'
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="checkSwi">
                            <Switch
                              checked={
                                Inventory2DefaultVal.length ? true : false
                              }
                              onChange={this.Inventory2Change}
                            />
                          </div>
                        </div>
                        <div className={`Updates2`}>
                          <div className="Updates2Icon">
                            <span class="material-icons notranslate">
                              monetization_on
                            </span>
                          </div>
                          <div className="Updates2Title">
                            <h4>{intl.get('setting.notification.price')}</h4>
                            <div className={`uodateP`}>
                              <span>
                                {intl.get(
                                  'setting.notification.price_description'
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="checkSwi">
                            <Switch
                              checked={PriceDefaultVal.length ? true : false}
                              onChange={this.PriceChange}
                            />
                          </div>
                        </div>
                        <div className={`Updates2`}>
                          <div className="Updates2Icon">
                            <i class="material-icons notranslate">assignment</i>
                          </div>
                          <div className="Updates2Title">
                            <h4>
                              {intl.get('setting.notification.canceled_order')}
                            </h4>
                            <div className={`uodateP`}>
                              <span>
                                {intl.get(
                                  'setting.notification.canceled_order_description'
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="checkSwi">
                            <Switch
                              checked={!CancelledOrderDefaultVal}
                              onChange={this.CancelledOrderChange}
                            />
                          </div>
                        </div>
                        <div className={`Updates2`}>
                          <div className="Updates2Icon">
                            <i class="material-icons notranslate">local_mall</i>
                          </div>
                          <div className="Updates2Title">
                            <h4>{intl.get('setting.notification.sku')}</h4>
                            <div className={`uodateP`}>
                              <span>
                                {intl.get(
                                  'setting.notification.sku_description'
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="checkSwi">
                            <Switch
                              checked={!SkuDefaultVal}
                              onChange={this.SkuChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Synchronization控制面板 */}
                  <div
                    style={{
                      display:
                        this.state.containerPage ==
                        intl.get('setting.title_synchronization')
                          ? 'block'
                          : 'none'
                    }}
                  >
                    <>
                      <Title
                        title={intl.get('setting.notification.auto_updates')}
                        description={intl.get(
                          'setting.notification.auto_updates_tip'
                        )}
                      />
                      <div className={styles.boxstyles}>
                        <Notification orderType={orderType} />
                      </div>
                    </>
                    <>
                      <Title
                        title={intl.get('setting.title_automatic')}
                        description={intl.get('setting.automatic_des')}
                      />
                      <div className={styles.boxstyles}>
                        <PriceUpdate
                          orderType={orderType}
                          onRef={ref => {
                            this.ChildComponent = ref;
                          }}
                        />
                      </div>
                    </>
                    {syncTimeTip ? (
                      <p className={styles.syncTimeTip}>
                        {intl.get('setting.other.sync_shopify_orders_error')}
                      </p>
                    ) : null}
                    <Title
                      title={
                        <div>
                          {intl.get('setting.title_update_pro_pr')}(
                          {priceruleMap[orderType]}times/30days)
                        </div>
                      }
                      description={
                        <div>{intl.getHTML('setting.updateProductPrice')}</div>
                      }
                    />
                    <div className={styles.boxstyles}>
                      <div className={styles.pricingPushRule}>
                        <div
                          style={{ marginTop: '16px' }}
                          className={
                            storyIfUse.length
                              ? styles.pricingPushRuleBox
                              : styles.pricingPushNoRuleBox
                          }
                        >
                          <Checkbox.Group
                            className={styles.boxgroup}
                            onChange={this.handleCheckbox}
                            value={selectStory}
                          >
                            {storyIfUse.map((item, index) => {
                              return (
                                <div className={styles.boxitem} key={item.id}>
                                  <Tooltip
                                    placement="top"
                                    title={this.showTitle(item.id, index)}
                                  >
                                    <Checkbox
                                      value={item.id}
                                      disabled={this.ifDisabled(item.id, index)}
                                    ></Checkbox>
                                  </Tooltip>
                                  <span className={styles.boxitem_name}>
                                    {item.shop_name}
                                  </span>
                                  {storyIfUse[index].status === 1 ? (
                                    this.props.updataPriceStatus.data &&
                                    this.props.updataPriceStatus.data[item.id]
                                      ?.remain_time ? (
                                      <span className={styles.useNumber}>
                                        {' '}
                                        {
                                          this.props.updataPriceStatus.data[
                                            item.id
                                          ]?.remain_count
                                        }
                                        /{priceruleMap[orderType]}{' '}
                                        <span>
                                          ( until{' '}
                                          {moment(
                                            (parseInt(
                                              new Date().valueOf() / 1000
                                            ) +
                                              this.props.updataPriceStatus.data[
                                                item.id
                                              ]?.remain_time) *
                                              1000
                                          ).format('YYYY-MM-DD')}
                                          )
                                        </span>
                                      </span>
                                    ) : (
                                      <span className={styles.useNumber}>
                                        {priceruleMap[orderType]}
                                        <span>(30days)</span>
                                      </span>
                                    )
                                  ) : (
                                    <span className={styles.notConnect}>
                                      Not connected
                                    </span>
                                  )}
                                  {this.props.updataPriceStatus?.data ? (
                                    this.props.updataPriceStatus?.data[item.id]
                                      ?.status === 2 ||
                                    this.props.updataPriceStatus?.data[item.id]
                                      ?.status === 3 ? (
                                      <Tooltip
                                        placement="top"
                                        title={intl.get('public.synchronous')}
                                      >
                                        <img
                                          src={loadingIcon}
                                          alt=""
                                          className={styles.loadingIcon}
                                        />
                                      </Tooltip>
                                    ) : null
                                  ) : null}
                                </div>
                              );
                            })}
                          </Checkbox.Group>
                        </div>
                        <div className={styles.pricingPushRuleStart}>
                          <Button
                            className={
                              selectStory.length
                                ? styles.pricingPushRuleButton
                                : styles.pricingPushRuleButtonDisable
                            }
                            disabled={selectStory.length ? false : true}
                            onClick={this.showUpDataModal}
                          >
                            {intl.get('public.start')}
                          </Button>
                        </div>
                      </div>
                    </div>

                    {showUpdataPrcie ? (
                      <Modal
                        // className={styles.blackProductConfirmModal}
                        visible={showUpdataPrcie}
                        footer={null}
                        closable={false}
                        maskClosable={false}
                      >
                        <div className={styles.wrap}>
                          <div className={styles.content}>
                            <div className={styles.title}>
                              {intl.get('setting.product_price')}
                            </div>
                            <h5 className={styles.dswqf}>
                              {this.modalTitle()}
                              {intl.get('public.please_enter')}
                            </h5>
                            <div>
                              <Input
                                placeholder={intl.get(
                                  'product.input_confirm_tip2'
                                )}
                                onChange={this.inputUpdataConfirm}
                              />
                            </div>
                            <div className={styles.btns}>
                              <Button
                                data="bk"
                                onClick={() => {
                                  this.setState({
                                    showUpdataPrcie: false
                                  });
                                }}
                              >
                                {intl.getHTML('public.cancel')}
                              </Button>
                              <Button
                                data="dx"
                                type="primary"
                                onClick={this.upData}
                                loading={buttonLoading}
                              >
                                {intl.getHTML('public.yes')}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    ) : null}
                    <Title
                      title={intl.get('setting.title_sync_shop_order')}
                      description={intl.get('setting.sync_shop_order_des')}
                    />
                    <div className={styles.boxstyless}>
                      {/* <h3>{intl.get('setting.other.sync_shopify_orders')}</h3>
                <p>{intl.get('setting.other.sync_shopify_orders_tip')}</p> */}
                      <div className={styles.syncBox}>
                        <Select
                          suffixIcon={
                            <div>
                              <i class="material-icons notranslate">
                                storefront
                              </i>
                              <img
                                src={require('../../../assets/newImportList/icon_expand_more-24px.png')}
                              ></img>
                            </div>
                          }
                          className={styles.syncStoreSelect}
                          dropdownMatchSelectWidth={false}
                          value={syncStore}
                          onChange={this.changeSyncStore}
                          getPopupContainer={triggerNode =>
                            triggerNode.parentNode
                          }
                          placeholder={intl.get(
                            'setting.other.select_store_placeholder'
                          )}
                        >
                          {storeData.map(store => (
                            <Option value={store.id} key={store.id}>
                              <span>{store.shop_name}</span>
                            </Option>
                          ))}
                        </Select>
                        <div
                          style={{ position: 'relative' }}
                          className={styles.syncIconW}
                        >
                          <RangePicker
                            format="YYYY / MM / DD"
                            allowClear={false}
                            suffixIcon={
                              <span>
                                <i
                                  style={{ fontSize: 18, marginTop: '-2px' }}
                                  className="material-icons"
                                >
                                  calendar_today
                                </i>
                              </span>
                            }
                            className={styles.syncPicker}
                            onChange={this.changeSyncPicker}
                            disabledDate={this.disabledDate}
                            value={rangePickerValue}
                            getCalendarContainer={trigger =>
                              trigger.parentElement
                            }
                          />
                        </div>
                        {this.state.rangePickerValue && this.state.syncStore ? (
                          <span
                            className={styles.sync}
                            onClick={debounce(() => this.subSyncDate(), 800)}
                          >
                            <i class="material-icons notranslate">sync</i>
                          </span>
                        ) : (
                          <Tooltip
                            placement="top"
                            title={intl.get('setting.disablestext')}
                          >
                            <span className={styles.syncDisable}>
                              <i class="material-icons notranslate">sync</i>
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    <Title
                      title={intl.get('setting.title_order_details_sync')}
                    />
                    <div className={styles.boxstyles}>
                      <div className={styles.otherSwitch}>
                        <h3>
                          {intl.get('setting.other.sync_ali_order_number')}
                        </h3>
                        <p>
                          {intl.get('setting.other.sync_ali_order_number_tip')}
                        </p>
                        <div className={styles.switchWrap}>
                          <Switch
                            // checkedChildren={intl.get('public.on')}
                            // unCheckedChildren={intl.get('public.off')}
                            checked={otherChecked}
                            onChange={checked => {
                              this.postOtherSta(checked);
                            }}
                          />
                        </div>
                      </div>

                      <div className={styles.separateLines} />
                      <div className={styles.otherSwitch}>
                        <h3>
                          {intl.get(
                            'setting.other.synchronize_shopify_order_notes'
                          )}
                        </h3>
                        <p className={styles.dp}>
                          {intl.get(
                            'setting.other.synchronize_shopify_order_notes_tip'
                          )}
                        </p>
                        <div className={styles.switchWrap}>
                          <Switch
                            // checkedChildren={intl.get('public.on')}
                            // unCheckedChildren={intl.get('public.off')}
                            checked={syncShopifyNotesStatus}
                            onChange={this.handleChangeSyncShopifyNotesStatus}
                          />
                        </div>
                      </div>
                      <div className={styles.separateLines} />
                      <div className={styles.otherSwitch}>
                        <h3>
                          {intl.get(
                            'setting.other.synchronize_shopify_order_message'
                          )}
                        </h3>
                        <p className={styles.dp}>
                          {intl.get(
                            'setting.other.synchronize_shopify_order_message_tip'
                          )}
                        </p>
                        <div className={styles.switchWrap}>
                          <Switch
                            checked={this.state.orderMessageStatus}
                            onChange={this.handleChangeSyncShopifyMessageStatus}
                          />
                        </div>
                      </div>
                      {/* <div className={styles.separateLine} /> */}
                      {/* <CancelRequestFulfillment />
                  <div className={styles.separateLine} /> */}
                      {/* <div className={styles.otherSwitch}>
                    <h3>{intl.get('setting.other.aliSku')}</h3>
                    <p className={styles.dp}>
                      {intl.get('setting.other.aliSku_desc')}
                    </p>
                    <div className={styles.switchWrap}>
                      <Switch
                        checkedChildren={intl.get('public.on')}
                        unCheckedChildren={intl.get('public.off')}
                        checked={skuCustomize}
                        onChange={this.handleChangeSKUStatus}
                      />
                    </div>
                  </div> */}
                    </div>
                  </div>
                  {/* pricing折叠面板 */}
                  <div
                    style={{
                      display:
                        this.state.containerPage ==
                        intl.get('setting.tab_pricing_rule')
                          ? 'block'
                          : 'none'
                    }}
                  >
                    <Pricing refreshPriceStatus={this.refreshPriceStatus} />
                  </div>
                  {/* Shipping confirmation折叠面板 */}
                  <div
                    style={{
                      display:
                        this.state.containerPage ==
                        intl.get('setting.tab_tracking')
                          ? 'block'
                          : 'none'
                    }}
                  ></div>
                  {/* Affiliate program折叠面板 */}
                  <div
                    style={{
                      display:
                        this.state.containerPage ==
                          intl.get('setting.tab_aff') &&
                        userInfo.code_type === 1 &&
                        userInfo.can_use_cashback &&
                        !this.props.newUserInfo?.user_role
                          ? 'block'
                          : 'none'
                    }}
                  >
                    <Title
                      title={intl.get('setting.title_affiliate_program')}
                    />
                    <div className={styles.boxstyles}>
                      <div className={styles.otherCont}>
                        {userInfo.code_type === 1 &&
                        userInfo.can_use_cashback &&
                        !this.props.newUserInfo?.user_role ? (
                          <div>
                            <Select
                              className={styles.cashbackTypeSelect}
                              defaultValue="Admitad"
                            >
                              <Option value="Admitad" key="Admitad">
                                <span>Admitad</span>
                              </Option>
                            </Select>
                            {/* 关于Admitad模块的处理 */}
                            {showEditCashback ? (
                              <div className={styles.affiliateWrap}>
                                <div className={styles.admintcon}>
                                  <div className={styles.admitadedit}>
                                    <div className={styles.link}>
                                      <img src={personicon} alt="" />
                                      <div>
                                        <p className={styles.title}>
                                          {intl.get(
                                            'setting.other.new_affiliate_tip'
                                          )}
                                        </p>
                                        <p className={styles.text}>
                                          <a
                                            href={howToUseAdmintLink}
                                            target="_blank"
                                          >
                                            {intl.get(
                                              'setting.other.why_affiliate'
                                            )}
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                    <Button
                                      type="primary"
                                      href="https://store.admitad.com/en/promo/?ref=8e3xmmxnjp"
                                      target="_blank"
                                    >
                                      {intl.get(
                                        'setting.other.get_affiliate_btn'
                                      )}
                                    </Button>
                                  </div>
                                </div>
                                <div className={styles.admintcon}>
                                  <div className={styles.link}>
                                    <img src={settingicon} alt="" />
                                    <div>
                                      <p className={styles.title}>
                                        {intl.get(
                                          'setting.other.set_affiliate'
                                        )}
                                      </p>
                                      <p className={styles.text}>
                                        <a
                                          href={howToUseAdmintLink}
                                          target="_blank"
                                        >
                                          {intl.get('setting.other.how_to_set')}
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className={styles.admintcon}>
                                  <div className={styles.link}>
                                    <img src={editicon} alt="" />
                                    <div>
                                      <p className={styles.title}>
                                        {intl.get(
                                          'setting.other.fill_affiliate'
                                        )}
                                      </p>
                                      <p className={styles.text}>
                                        <a
                                          href={howToUseAdmintLink}
                                          target="_blank"
                                        >
                                          {intl.get('setting.other.where_code')}
                                        </a>
                                      </p>
                                      <div className={styles.cashbackForm}>
                                        <div className={styles.codeArea}>
                                          <p className={styles.title}>
                                            {intl.get('setting.affiliate_code')}
                                          </p>
                                          <div className={styles.mains}>
                                            <div className={styles.input1}>
                                              <Input
                                                style={{
                                                  borderTopRightRadius: 'none',
                                                  borderBottomRightRadius:
                                                    'none'
                                                }}
                                                disabled
                                                placeholder="Code"
                                                value={
                                                  this.state.cashbackCodelink
                                                }
                                              />
                                            </div>
                                            <div className={styles.input2}>
                                              <Input
                                                onChange={this.handleCodeChange}
                                                placeholder="Code"
                                                value={cashbackCode}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <p className={styles.errorTip}>
                                          {cashbackCodeErrorMsg}
                                        </p>
                                        <div className={styles.codeArea}>
                                          <p className={styles.title}>
                                            {intl.get(
                                              'setting.account.account_name'
                                            )}
                                          </p>
                                          <div className={styles.main}>
                                            <div>
                                              <Input
                                                onChange={
                                                  this.handleAccountChange
                                                }
                                                placeholder="Admitad account name"
                                                value={cashbackAccount}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <p className={styles.errorTip}>
                                          &nbsp;{cashbackAccountErrorMsg}
                                        </p>
                                        <div className={styles.c_operation}>
                                          <div
                                            className={styles.generalText}
                                            style={{ marginRight: 12 }}
                                          >
                                            {/* 编辑 or 新增 */}
                                            {userInfo.code ? (
                                              <Button
                                                onClick={
                                                  this.handleCancelEditCashback
                                                }
                                                className={styles.saveBtn2}
                                                data="bk"
                                              >
                                                Cancel
                                              </Button>
                                            ) : (
                                              this.renderCancelBtn()
                                            )}
                                          </div>
                                          <div className={styles.generalText}>
                                            <Button
                                              type="primary"
                                              onClick={this.handleSaveCashback}
                                              className={styles.saveBtn}
                                              disabled={
                                                !this.isValidateCashback
                                              }
                                              data="dx"
                                            >
                                              Save
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className={styles.affiliateCodeWrap}>
                                {this.cashbackCodeIsWrong() ? (
                                  <Alert
                                    message="Warning"
                                    description={
                                      <p>
                                        Your Admitad cashback is stopping now.
                                        Please change a new code assigned to
                                        DSers.{' '}
                                        <a
                                          href={howToUseAdmintLink}
                                          target="_blank"
                                        >
                                          Check why and how
                                        </a>
                                      </p>
                                    }
                                    type="warning"
                                    showIcon
                                  />
                                ) : null}
                                {/* <h3 style={{ marginBottom: 12 }}>{intl.get('setting.other.affiliate_desc')}</h3> */}
                                {admitadCheckCode === 6 ? (
                                  <div className={styles.maskMsg}>
                                    <div className={styles.mask_text}>
                                      {intl.get('setting.admitadAccount1')}
                                      &nbsp;
                                      <a
                                        href={howToUseAdmintLink}
                                        target="_blank"
                                      >
                                        {intl.get('setting.admitadAccountLink')}
                                      </a>
                                      &nbsp;
                                      {intl.get('setting.admitadAccount2')}
                                    </div>
                                    <Button
                                      onClick={() =>
                                        this.handleEditCashback(true)
                                      }
                                      className={styles.saveBtn}
                                    >
                                      {intl.get('setting.affiliate_code_btn')}
                                    </Button>
                                  </div>
                                ) : null}
                                <div className={styles.cashbackArea}>
                                  <div className={styles.codeArea}>
                                    <p className={styles.title}>
                                      {intl.get('setting.affiliate_code')}
                                    </p>
                                    <div className={styles.main}>
                                      <div>
                                        <Input
                                          disabled
                                          onChange={this.handleCodeChange}
                                          placeholder="Code"
                                          value={userInfo.code}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {localStorage.getItem('noMigra') ==
                                  'false' ? null : (
                                    <div className={styles.codeArea}>
                                      <p className={styles.title}>
                                        Account Name
                                      </p>
                                      <div className={styles.main}>
                                        <div>
                                          {!userInfo.code_account ? (
                                            <p className={styles.grayMsg}>
                                              {intl.get(
                                                'setting.other.not_account_name'
                                              )}
                                            </p>
                                          ) : (
                                            <Input
                                              disabled
                                              onChange={
                                                this.handleAccountChange
                                              }
                                              placeholder="Admitad account name"
                                              value={userInfo.code_account}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {/* <div className={styles.c_a_item}>
                              <p>{intl.get('setting.affiliate_code')}</p>
                              <p>
                                https://alitems.site/g/
                                <span className={styles.url}>
                                  {userInfo.code}
                                </span>
                              </p>
                            </div>
                            <div className={styles.c_a_item}>
                              <p>Account Name</p>
                              {!userInfo.code_account ? (
                                <p className={styles.grayMsg}>
                                  {intl.get('setting.other.not_account_name')}
                                </p>
                              ) : (
                                <p>{userInfo.code_account}</p>
                              )}
                            </div> */}
                                  <div className={styles.generalText}>
                                    <Button
                                      type="primary"
                                      onClick={() => this.handleEditCashback()}
                                      className={styles.saveBtn}
                                      data="dx"
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null}

                        {/* 关于Admitad模块的处理 */}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* Payments折叠面板 */}
              <div
                style={{
                  display:
                    this.state.containerPage == 'Payments' ? 'block' : 'none'
                }}
              >
                <Payments />
              </div>
            </div>
          </div>
          <GuideRefresh visible={this.state.showRefreshPop} />
        </div>
        {this.state.isModalVisiable ? (
          <CAM002
            camType={'SendProductCostToShopify'}
            closeDetail={this.updatePlanModalHandler}
          />
        ) : null}
        {/* 新的UI end */}
      </>
    );
  }
}
