import React from 'react';

import { Icon, Select, Input, Checkbox, InputNumber, Alert,Tooltip, Switch } from 'antd';
import intl from 'react-intl-universal';

import styles from './pricing.less';

const { Option } = Select;
const InputGroup = Input.Group;

const StandardRule = ({
  index,
  pricingItem,
  deleteAdvancedItem,
  AdvancedComparedPriceShow,
  AdvancedMinMax,
  changeAdvancedPattern,
  changeAdvancedPrice,
  changeAdvancedRestPattern,
  changeAdvancedRestPrice,
  AdvancedErrTxt1,
  AdvancedErrTxt2
}) => {
  return (
    <>
      {/* <div className={styles.more}>
        {intl.getHTML('setting.pricing.standard_more')}
      </div> */}
      <div className={styles.AdvancedCont}>
        <table>
          <thead>
            <tr>
              <th>{intl.get('setting.pricing.cost_range')}</th>
              <th>{intl.get('setting.pricing.price')}</th>
              <th>
                <Checkbox
                    checked={pricingItem.advanced_pricing.compared_status}
                    style={{marginRight:"6px"}}
                    onChange={e => {
                      AdvancedComparedPriceShow(e, index);
                    }}
                  />
                  {intl.get('setting.pricing.compared_price')}
                  <span style={{marginLeft:5}}>
                    <Tooltip

                      title={intl.get('setting.pricing.compared_ar')}
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </span>
              </th>
              {/* <th style={{textAlign:'right'}}>
                <Switch
                  checked={pricingItem.advanced_pricing.compared_status}
                  onChange={e => {
                    AdvancedComparedPriceShow(e, index);
                  }}
                />
              </th> */}
            </tr>
          </thead>
          <tbody>
            {pricingItem.advanced_pricing.advanced_item.map((item, ind) => {
              return (
                <tr key={ind}>
                  <td>
                    <InputNumber
                      min={0}
                      precision={2}
                      style={
                        item.min ||
                        item.min == 0 ||
                        (!item.min &&
                          ind + 1 ==
                            pricingItem.advanced_pricing.advanced_item.length)
                          ? {}
                          : { borderColor: '#f5222d' }
                      }
                      value={item.min}
                      onChange={value => {
                        AdvancedMinMax(value, index, ind, 'min');
                      }}
                      className={styles.AdvancedMinNumInput}
                    />
                    <span style={{display:'inline-block',marginLeft:8,marginRight:8}}> - </span>
                    <InputNumber
                      min={0}
                      precision={2}
                      style={
                        item.max ||
                        item.max == 0 ||
                        (!item.max &&
                          ind + 1 ==
                            pricingItem.advanced_pricing.advanced_item.length)
                          ? {}
                          : { borderColor: '#f5222d' }
                      }
                      value={item.max}
                      onChange={value => {
                        AdvancedMinMax(value, index, ind, 'max');
                      }}
                      className={styles.AdvancedMinNumInput}
                    />
                  </td>
                  <td>
                    <InputGroup compact>
                      <Select
                        suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                        disabled={
                          !item.max &&
                          ind + 1 ==
                            pricingItem.advanced_pricing.advanced_item.length
                        }
                        value={item.pattern}
                        onChange={value => {
                          changeAdvancedPattern(value, index, ind, 1);
                        }}
                      >
                        <Option key="1" value="add">
                          <Icon type="plus" />
                        </Option>
                        <Option key="2" value="mul">
                          <Icon type="close" />
                        </Option>
                      </Select>
                      <InputNumber
                        min={0}
                        precision={2}
                        style={
                          item.price || item.price == 0
                            ? {}
                            : { borderColor: '#f5222d' }
                        }
                        disabled={
                          !item.max &&
                          ind + 1 ==
                            pricingItem.advanced_pricing.advanced_item.length
                        }
                        value={item.price}
                        onChange={value => {
                          changeAdvancedPrice(value, index, ind, 1);
                        }}
                        className={styles.AdvancedPriceNumInput}
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <div
                      className={styles.AdvancedComparedPrice}
                      // style={
                      //   pricingItem.advanced_pricing.compared_status
                      //     ? { display: 'block' }
                      //     : { display: 'none' }
                      // }
                    >
                      <InputGroup compact>
                        <Select
                          suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                          disabled={
                            (!item.max &&
                            ind + 1 ==
                              pricingItem.advanced_pricing.advanced_item.length) || !pricingItem.advanced_pricing.compared_status
                          }
                          value={item.compared_pattern}
                          onChange={value => {
                            changeAdvancedPattern(value, index, ind, 2);
                          }}
                        >
                          <Option key="1" value="add">
                            <Icon type="plus" />
                          </Option>
                          <Option key="2" value="mul">
                            <Icon type="close" />
                          </Option>
                        </Select>
                        <InputNumber
                          min={0}
                          precision={2}
                          style={
                            item.compared_price || item.compared_price == 0
                              ? {}
                              : { borderColor: '#f5222d' }
                          }
                          disabled={
                            (!item.max &&
                            ind + 1 ==
                              pricingItem.advanced_pricing.advanced_item.length)|| !pricingItem.advanced_pricing.compared_status
                          }
                          value={item.compared_price}
                          onChange={value => {
                            changeAdvancedPrice(value, index, ind, 2);
                          }}
                          className={styles.AdvancedPriceNumInput}
                        />
                      </InputGroup>
                    </div>
                  </td>
                  <td style={{textAlign:'right',lineHeight:'2px'}}>
                    <i class="material-icons notranslate" onClick={() => {
                        deleteAdvancedItem(index, ind);
                      }}>delete</i>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>
                <span style={{ marginRight: 6, fontSize: 14 }}>
                  {intl.get('setting.pricing.rest_price')}
                </span>
              </td>
              <td>
                <InputGroup compact>
                  <Select
                    suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                    value={pricingItem.advanced_pricing.advanced_rest.pattern}
                    onChange={value => {
                      changeAdvancedRestPattern(value, index, 1);
                    }}
                  >
                    <Option key="1" value="add">
                      <Icon type="plus" />
                    </Option>
                    <Option key="2" value="mul">
                      <Icon type="close" />
                    </Option>
                  </Select>
                  <InputNumber
                    min={0}
                    precision={2}
                    style={
                      pricingItem.advanced_pricing.advanced_rest.price ||
                      pricingItem.advanced_pricing.advanced_rest.price == 0
                        ? {}
                        : { borderColor: '#f5222d' }
                    }
                    value={pricingItem.advanced_pricing.advanced_rest.price}
                    onChange={value => {
                      changeAdvancedRestPrice(value, index, 1);
                    }}
                    className={styles.AdvancedPriceNumInput}
                  />
                </InputGroup>
              </td>
              <td>
                <div
                  className={styles.AdvancedComparedPrice}
                  // style={
                  //   pricingItem.advanced_pricing.compared_status
                  //     ? { display: 'block' }
                  //     : { display: 'none' }
                  // }
                >
                  <InputGroup compact>
                    <Select
                      suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                      value={
                        pricingItem.advanced_pricing.advanced_rest.compared_pattern
                      }
                      disabled={!pricingItem.advanced_pricing.compared_status}
                      onChange={value => {
                        changeAdvancedRestPattern(value, index, 2);
                      }}
                    >
                      <Option key="1" value="add">
                        <Icon type="plus" />
                      </Option>
                      <Option key="2" value="mul">
                        <Icon type="close" />
                      </Option>
                    </Select>
                    <InputNumber
                      min={0}
                      precision={2}
                      style={
                        pricingItem.advanced_pricing.advanced_rest.compared_price ||
                        pricingItem.advanced_pricing.advanced_rest.compared_price == 0
                          ? {}
                          : { borderColor: '#f5222d' }
                      }
                      value={
                        pricingItem.advanced_pricing.advanced_rest.compared_price
                      }
                      disabled={!pricingItem.advanced_pricing.compared_status}
                      onChange={value => {
                        changeAdvancedRestPrice(value, index, 2);
                      }}
                      className={styles.AdvancedPriceNumInput}
                    />
                  </InputGroup>
                </div>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
      {AdvancedErrTxt1[index] || AdvancedErrTxt2[index] ? (
        <Alert
          message={
            <div>
              {AdvancedErrTxt1[index] ? (
                <p>{intl.get('setting.pricing.ranges_overlap')}</p>
              ) : (
                ''
              ) /* 范围重叠 */}
              {AdvancedErrTxt2[index] ? (
                <p>{intl.get('setting.pricing.ranges_start_greater_end')}</p>
              ) : (
                ''
              ) /* 成本范围结束值必须大于起始值 */}
            </div>
          }
          type="error"
          showIcon
          className={styles.AdvancedErr}
        />
      ) : null}
    </>
  );
};

export default StandardRule;
