import { Button, Checkbox, Col, Form, Input, Radio, Row, Select } from 'antd';
import { connect } from 'dva';
import React from 'react';
import styles from './Index.less';
import PostCodeValidate from './PostCodeValidate';

const Option = Select.Option;

@connect(({ dropshippersetting, loading }) => ({
  dropshippersetting,
  loading: loading.models.dropshippersetting
}))
@Form.create()
export default class PrimaryContact extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      PostCodeMaxLength: '',
      PostCodeError: '',
      PostCodeErrorTxt: ''
    };
  }

  changePostCode = e => {
    // 更改post_code
    this.post_code = e.target.value;
    const result = PostCodeValidate(this.country, e.target.value);
    this.setState({
      ...result
    });
  };

  changeCountry = v => {
    // 更改国家
    this.country = v;
    const result = PostCodeValidate(v, this.post_code);
    this.setState({
      ...result
    });
  };

  changeUse = () => {
    this.whichOneAddress = !this.props.useBilling;
    this.props.changeWhichOneAddress(!this.props.useBilling);
  };

  whichOneAddress = false;
  country = '';
  post_code = '';

  render() {
    const {
      form,
      country,
      data = {},
      savePrimarycontact,
      useBilling,
      primarySampleStatus,
      changeSamplePrimary
    } = this.props;

    const { PostCodeMaxLength, PostCodeError, PostCodeErrorTxt } = this.state;

    const { getFieldDecorator, validateFields } = form;

    this.country = data.country;
    this.post_code = data.post_code;

    const onValidateForm = () => {
      validateFields((err, values) => {
        if (PostCodeError === 'error') {
          return;
        }
        if (!err) {
          const newData = values;
          newData.billing_info_status = !this.whichOneAddress ? 1 : 0;
          savePrimarycontact(newData);
        }
      });
    };

    return (
      <div className={styles.section}>
        <h3>Primary contact</h3>
        <Button
          className={styles.positionBtn}
          onClick={onValidateForm}
          style={{ top: 536 }}
        >
          SAVE
        </Button>

        <Form layout="vertical" hideRequiredMark name="name1">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Name">
                {getFieldDecorator('name', {
                  initialValue: data.name,
                  rules: [{ required: true, message: 'Please enter a Name' }]
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  initialValue: data.email,
                  rules: [
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!'
                    },
                    { required: true, message: 'Please input E-mail!' }
                  ]
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="Phone">
                {getFieldDecorator('phone', {
                  initialValue: data.phone,
                  rules: [{ required: true, message: 'Please enter a Name' }]
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="City">
                {getFieldDecorator('city', {
                  initialValue: data.city,
                  rules: [{ required: true, message: 'Please enter a city' }]
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item label="Company">
                {getFieldDecorator('company', {
                  initialValue: data.company
                })(<Input placeholder="" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Country">
                {getFieldDecorator('country', {
                  initialValue: data.country,
                  rules: [{ required: true, message: 'Please enter a country' }]
                })(
                  <Select showSearch onChange={this.changeCountry}>
                    {country.map(item => <Option key={item}>{item}</Option>)}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Address">
                {getFieldDecorator('address', {
                  initialValue: data.address,
                  rules: [{ required: true, message: 'Please enter a address' }]
                })(<Input placeholder="" />)}
              </Form.Item>
              <Form.Item
                label="Post code"
                validateStatus={PostCodeError}
                help={PostCodeErrorTxt}
              >
                {getFieldDecorator('post_code', {
                  initialValue: data.post_code
                })(
                  <Input
                    maxLength={PostCodeMaxLength}
                    onChange={this.changePostCode}
                  />
                )}
              </Form.Item>
              <Form.Item label="Province">
                {getFieldDecorator('province', {
                  initialValue: data.province,
                  rules: [
                    { required: true, message: 'Please enter a province' }
                  ]
                })(<Input placeholder="" />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <p>
          <Checkbox checked={!useBilling} onChange={this.changeUse}>
            Use as billing address
          </Checkbox>
        </p>
        <p>
          <Radio onChange={changeSamplePrimary} checked={primarySampleStatus}>
            Send sample to this address
          </Radio>
        </p>
      </div>
    );
  }
}
