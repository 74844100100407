import { Button, Icon, Input, message, Modal, Select, Upload } from 'antd';
import { connect } from 'dva';
import React, { Fragment } from 'react';
import { BASE_URL } from '../../../../../common/sysconfig';
import { getAuthorityHeader } from '../../../../../utils/authority';

const { TextArea } = Input;
const Option = Select.Option;

@connect(({ dropshippersetting, loading }) => ({
  dropshippersetting,
  loading: loading.models.dropshippersetting
}))
export default class StoreTool extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      fileList: [],
      canSave: true
    };
  }

  componentDidMount() {
    const { data = {} } = this.props;
    if (data.log_src) {
      this.setState({
        fileList: [
          {
            uid: -1,
            name: 'logo',
            status: 'done',
            url: data.log_src
          }
        ]
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    this.data.note = data.note;
    this.data.package_request = data.package_request;
    this.data.log_src = data.log_src;
    this.changeData = { ...this.data };
    this.setSaveBtnDisabled();
  }

  handleCancel = () => {
    this.setState({
      previewVisible: false,
      previewImage: ''
    });
  };

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  handleChange = ({ fileList }) => {
    const list = [fileList[fileList.length - 1]];
    if (list[0].size / 1024 / 1024 > 1) {
      return;
    }
    list.forEach(file => {
      const { response } = file;
      const fileData = file;
      if (response && response.data) {
        const imageSrc = response.data[0];
        fileData.url = imageSrc;
        this.changeData.log_src = imageSrc;
        this.setSaveBtnDisabled();
      }
    });
    this.setState({
      fileList: [...list]
    });
  };

  beforeUpload = file => {
    const isLt1M = file.size / 1024 / 1024 > 1;
    if (isLt1M) {
      message.error('Image must smaller than 1MB!');
    }
    return !isLt1M;
  };

  handleRemove = () => {
    this.setState({
      fileList: []
    });
    this.changeData.log_src = '';
    this.setSaveBtnDisabled();
  };

  changeSelect = v => {
    this.changeData.package_request = v;
    this.setSaveBtnDisabled();
  };

  changeNote = e => {
    this.changeData.note = e.target.value;
    this.setSaveBtnDisabled();
  };

  setSaveBtnDisabled = () => {
    const { canSave } = this.state;
    const flag = JSON.stringify(this.changeData) == JSON.stringify(this.data);
    if (canSave != flag) {
      this.setState({
        canSave: flag
      });
    }
  };

  saveSupplier = () => {
    const { data = {} } = this.props;
    this.props.saveSupplier(Object.assign(data, this.changeData));
  };

  packageRequest = [
    {
      key: 0,
      name: 'Bag (default and free)'
    },
    {
      key: 1,
      name: 'Box ($0.2/pacakge)'
    },
    {
      key: 2,
      name: 'Box with logo ($0.2/pacakge)'
    },
    {
      key: 3,
      name: 'Box with card ($0.2/pacakage)'
    },
    {
      key: 4,
      name: 'Box with logo and card ($0.2/package)'
    }
  ];

  data = {};
  changeData = {};

  render() {
    const { data = {} } = this.props;

    const { previewVisible, previewImage, fileList, canSave } = this.state;

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const params = { store_id: data.store_id };
    const headers = {
      ...getAuthorityHeader()
    };

    return (
      <Fragment>
        <h4>Package request</h4>
        <Select
          style={{ width: '50%', marginBottom: '20px' }}
          dropdownMatchSelectWidth={false}
          defaultValue={data.package_request}
          onChange={this.changeSelect}
        >
          {this.packageRequest.map(item => (
            <Option value={item.key} key={item.key}>
              {item.name}
            </Option>
          ))}
        </Select>
        <h4>Logo file</h4>
        <Upload
          action={`${BASE_URL}/supply/setting/logo`}
          listType="picture-card"
          accept="image/*"
          fileList={fileList}
          data={params}
          name="files"
          headers={headers}
          beforeUpload={this.beforeUpload}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onRemove={this.handleRemove}
        >
          {uploadButton}
        </Upload>
        <h4>Note</h4>
        <TextArea
          autosize={false}
          style={{ marginBottom: '20px' }}
          maxLength={1000}
          defaultValue={data.note}
          onChange={this.changeNote}
        />
        <p style={{ textAlign: 'right', margin: '0 0 20px' }}>
          <Button disabled={canSave} onClick={this.saveSupplier}>
            Save
          </Button>
        </p>

        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Fragment>
    );
  }
}
