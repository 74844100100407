import React,{ useState } from 'react';

import {
  Icon,
  Select,
  Checkbox,
  Tooltip,
  InputNumber,
  Input,
  Alert,
  Modal,
  Radio,
  Button
} from 'antd';
import intl from 'react-intl-universal';

import styles from './AdvancedModel.less';

const { Option } = Select;
const InputGroup = Input.Group;
const supportCountry = [
  {
    name: 'United States',
    code: 'US'
  }
];

const countryMap = {
  US: 'United States'
};

const shippingCostValue = item => {
  let value = true;
  if (!item?.pro_pricing?.pro_rest?.is_shipping_cost) {
    value = false;
  } else {
    item?.pro_pricing?.pro_item?.forEach((i, index) => {
      if (index < item.pro_pricing.pro_item.length - 1 && !i.is_shipping_cost) {
        value = false;
      }
    });
  }
  return value;
};

const AdvancedModel = ({
  visible,
  cancelAdvanced,
  index,
  disabledStatus,
  pricingItem,
  ProMinMax,
  changeProParam,
  changeProRestParam,
  changeShippingCost,
  changeRestShippingCost,
  AllSelectShippingCost,
  deleteProItem,
  savePricingItem,
  ProErrTxt1,
  ProErrTxt2,
  allCountry,
  alltrack,
  alltrackTM,
  changeFixShoppingCost,
  changeShipppingStatus,
  changeCountry,
  changeTmallCountry,
  changeCountryTrack,
  changeTmallCountryTrack,
  ProComparedPriceShow,
  changeProPattern,
  changeProRestPattern,
  changeProductCost,
  changeCountryCost,
  isCustomizeUser
}) => {

  const [ check, useCheck ] = useState(false)
  return (
    <Modal
      visible={visible}
      width={900}
      centered
      title={intl.get('setting.pricing.advanced')}
      okText={intl.get('public.save')}
      onCancel={() => cancelAdvanced(index)}
      onOk={() => savePricingItem(index)}
      closable={false}
      className={styles.proModal}
      footer={[
        <Button key="submit" type="primary"  onClick={()=>savePricingItem(index)}>
        
      </Button>,
        <Button key="submit" type="primary"  disabled={disabledStatus} onClick={()=>savePricingItem(index)}>
          {intl.get('public.save')}
        </Button>,
      ]}

    >
      <div className={styles.proContain}>
        <div className={styles.formulaContent}>
         <div className={styles.formulaText}>
          <span className={styles.pot} />
          {isCustomizeUser ?
              "Price value = [(Product Cost + Fixed Profit) × (1+ Profit %) + Shipping Cost] / (1 - Breakeven %)" :
              intl.getHTML('setting.pricing.formula_text_1')
           }
        </div>
       {!isCustomizeUser &&
         <div className={styles.formulaReminder}>
          {intl.getHTML('setting.pricing.formula_text_reminder')}
        </div>
        }
        <div className={styles.formulaText} style={{marginTop:'8px'}}>
          <span className={styles.pot} />
          {intl.getHTML('setting.pricing.formula_text_2')}
        </div> 
        </div>
        <div className={styles.proTable}>
          <table>
            <thead>
              <tr className={styles.firstLine}>
                <th style={{ paddingLeft: 5 }}>
                  {intl.get('setting.pricing.advanced_cost_range')}
                  <span style={{ color: '#F44436' }}>*</span>
                </th>
                <th>
                  {intl.get('setting.pricing.profit')}
                  <span style={{ color: '#F44436' }}>*</span>
                </th>
                <td>
                  {/* <Icon type="plus" style={{ fontSize: 10, fontWeight: 300 }} /> */}
                </td>
                <th>{intl.get('setting.pricing.fixed_profit')}</th>
                {!isCustomizeUser && <th>{intl.get('setting.pricing.min_profit')}</th>}
                <th style={{ width: 158 }}>
                  <Checkbox
                    style={{ paddingLeft: '3px' }}
                    onChange={e => {
                      AllSelectShippingCost(e, index);
                    }}
                    checked={shippingCostValue(pricingItem)}
                  >
                    {intl.get('setting.pricing.shipping_cost')}
                  </Checkbox>
                  <Tooltip
                    title={intl.getHTML('setting.pricing.shippingCostReminder')}
                  >
                    <Icon type="question-circle" />
                  </Tooltip>
                </th>
                <th>
                  {intl.get('setting.pricing.breakeven')}
                  <Tooltip
                    title={intl.get('setting.pricing.breakevenReminder')}
                  >
                    <Icon type="question-circle" style={{ paddingLeft: 4 }} />
                  </Tooltip>
                </th>
                <th>
                  <Checkbox
                    style={{ paddingLeft: '3px' }}
                    onChange={e => {
                      ProComparedPriceShow(e, index);
                    }}
                    checked={pricingItem.pro_pricing.compare_status}
                  >
                    {intl.get('setting.pricing.compared_price')}
                  </Checkbox>
                  <span style={{marginLeft:5}}>
                      <Tooltip
                        title={intl.get('setting.pricing.compared_ar')}
                      >
                        <Icon type="info-circle" />
                      </Tooltip>
                      </span>
                </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {pricingItem?.pro_pricing?.pro_item?.map((item, ind) => {
                return (
                  <tr key={ind}>
                    <td style={{ paddingLeft: 5 }}>
                      <InputNumber
                        min={0}
                        precision={2}
                        style={
                          item.min ||
                          item.min == 0 ||
                          (!item.min &&
                            ind + 1 == pricingItem.pro_pricing.pro_item.length)
                            ? {}
                            : { borderColor: '#f5222d', background: '#F5F5F5' }
                        }
                        value={item.min}
                        onChange={value => {
                          ProMinMax(value, index, ind, 'min');
                        }}
                      />
                      <span> - </span>
                      <InputNumber
                        min={0}
                        precision={2}
                        style={
                          item.max ||
                          item.max == 0 ||
                          (!item.max &&
                            ind + 1 == pricingItem.pro_pricing.pro_item.length)
                            ? {}
                            : { borderColor: '#f5222d' }
                        }
                        value={item.max}
                        onChange={value => {
                          ProMinMax(value, index, ind, 'max');
                        }}
                      />
                    </td>
                    <td>
                      <InputNumber
                        min={0}
                        precision={1}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        style={
                          item.profit || item.profit == 0
                            ? {}
                            : { borderColor: '#f5222d' }
                        }
                        disabled={
                          !item.max &&
                          ind + 1 == pricingItem.pro_pricing.pro_item.length
                        }
                        value={item.profit}
                        onChange={value => {
                          changeProParam(value, index, ind, 1);
                        }}
                      />
                    </td>
                    <td>
                      <Icon type="plus" style={{ fontSize: 10 }} />
                    </td>
                    <td>
                      <InputNumber
                        min={0}
                        style={
                          item.fix_profit || item.fix_profit == 0
                            ? {}
                            : { borderColor: '#f5222d' }
                        }
                        disabled={
                          !item.max &&
                          ind + 1 == pricingItem.pro_pricing.pro_item.length
                        }
                        value={item.fix_profit}
                        onChange={value => {
                          changeProParam(value, index, ind, 2);
                        }}
                      />
                    </td>
                   {!isCustomizeUser && <td>
                      <InputNumber
                        min={0}
                        style={
                          item.minimum_profit || item.minimum_profit == 0
                            ? {}
                            : { borderColor: '#f5222d' }
                        }
                        disabled={
                          !item.max &&
                          ind + 1 == pricingItem.pro_pricing.pro_item.length
                        }
                        value={item.minimum_profit}
                        onChange={value => {
                          changeProParam(value, index, ind, 3);
                        }}
                      />
                    </td>}
                    <td>
                      <Checkbox
                        checked={item.is_shipping_cost}
                        style={{ paddingLeft: '3px' }}
                        disabled={
                          !item.max &&
                          ind + 1 == pricingItem.pro_pricing.pro_item.length
                        }
                        onChange={e => {
                          changeShippingCost(e, index, ind);
                        }}
                      >
                        {intl.get('setting.pricing.add_shipping_cost')}
                      </Checkbox>
                    </td>
                    <td>
                      <div className={item?.checked ? styles.borderRed : null}>
                        <InputNumber
                          min={0}
                          max={100}
                          precision={1}
                          formatter={value => `${value}%`}
                          parser={value => value.replace('%', '')}
                          style={
                            item.breakeven || item.breakeven == 0
                              ? {}
                              : { borderColor: '#f5222d' }
                          }
                          disabled={
                            !item.max &&
                            ind + 1 == pricingItem.pro_pricing.pro_item.length
                          }
                          value={item.breakeven}
                          onChange={value => {
                            changeProParam(value, index, ind, 4);
                          }}
                        />
                        {
                          item?.checked ? <Tooltip
                          title={intl.getHTML('setting.pricing.inputerr100')}
                        >
                          <Icon style={{fontSize:12,color:'rgba(0,0,0,0.5)',marginLeft:2}} type="question-circle" />
                        </Tooltip> : null
                        }
                      </div>
                    </td>
                    <td>
                      <div
                        // style={
                        //   pricingItem.pro_pricing.compare_status
                        //     ? { display: 'block', paddingLeft: 3 }
                        //     : { display: 'none', paddingLeft: 3 }
                        // }
                      >
                        <InputGroup compact>
                          <Select
                            suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            disabled={
                              (!item.max &&
                              ind + 1 == pricingItem.pro_pricing.pro_item.length)|| !pricingItem.pro_pricing.compare_status
                            }
                            style={{ height: 32 }}
                            value={item.compared_pattern || 'add'}
                            onChange={value => {
                              changeProPattern(value, index, ind, 5);
                            }}
                          >
                            <Option key="1" value="add">
                              <Icon type="plus" />
                            </Option>
                            <Option key="2" value="mul">
                              <Icon type="close" />
                            </Option>
                          </Select>
                          <InputNumber
                            min={0}
                            precision={2}
                            style={{ borderRadius: '0 4px  4px 0' }}
                            disabled={
                              (!item.max &&
                              ind + 1 == pricingItem.pro_pricing.pro_item.length) || !pricingItem.pro_pricing.compare_status
                            }
                            value={item.compared_price}
                            defaultValue={0}
                            onChange={value => {
                              changeProParam(value, index, ind, 5);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </td>
                    <td>
                      {/* <Icon
                        type="close"
                        onClick={() => {
                          deleteProItem(index, ind);
                        }}
                        style={
                          !item.max &&
                          ind + 1 == pricingItem.pro_pricing.pro_item.length
                            ? { display: 'none', paddingRight: 5 }
                            : { display: 'block', paddingRight: 5 }
                        }
                      /> */}
                      <i onClick={() => {
                          deleteProItem(index, ind);
                        }} class="material-icons">delete</i>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td style={{ paddingLeft: 5, paddingBottom: 5 }}>
                  <span
                    style={{
                      marginRight: 30,
                      fontSize: 12,
                      height: 20,
                      fontWeight: 400
                    }}
                  >
                    {intl.get('setting.pricing.advanced_rest_price')}
                  </span>
                </td>
                <td>
                  <InputNumber
                    min={0}
                    precision={1}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                    style={
                      pricingItem.pro_pricing?.pro_rest?.profit ||
                      pricingItem.pro_pricing?.pro_rest?.profit == 0
                        ? {}
                        : { borderColor: '#f5222d' }
                    }
                    value={pricingItem.pro_pricing?.pro_rest?.profit}
                    onChange={value => {
                      changeProRestParam(value, index, 1);
                    }}
                  />
                </td>
                <td>
                  <Icon type="plus" style={{ fontSize: 10 }} />
                </td>
                <td>
                  <InputNumber
                    min={0}
                    style={
                      pricingItem.pro_pricing?.pro_rest?.fix_profit ||
                      pricingItem.pro_pricing?.pro_rest?.fix_profit == 0
                        ? {}
                        : { borderColor: '#f5222d' }
                    }
                    value={pricingItem.pro_pricing?.pro_rest?.fix_profit}
                    onChange={value => {
                      changeProRestParam(value, index, 2);
                    }}
                  />
                </td>
                {!isCustomizeUser && <td>
                  <InputNumber
                    min={0}
                    style={
                      pricingItem.pro_pricing?.pro_rest?.minimum_profit ||
                      pricingItem.pro_pricing?.pro_rest?.minimum_profit == 0
                        ? {}
                        : { borderColor: '#f5222d' }
                    }
                    value={pricingItem.pro_pricing?.pro_rest?.minimum_profit}
                    onChange={value => {
                      changeProRestParam(value, index, 3);
                    }}
                  />
                </td>}
                <td>
                  <Checkbox
                    style={{ paddingLeft: '3px' }}
                    checked={pricingItem.pro_pricing?.pro_rest?.is_shipping_cost}
                    onChange={e => {
                      changeRestShippingCost(e, index);
                    }}
                  >
                    {intl.get('setting.pricing.add_shipping_cost')}
                  </Checkbox>
                </td>
                <td>
                  {/* 最下边的输入框 */}
                  <div className={check ? styles.borderRed : null}>
                    <InputNumber
                      min={0}
                      defaultValue={0}
                      precision={1}
                      formatter={value => `${value}%`}
                      parser={value => value.replace('%', '')}
                      style={
                        pricingItem.pro_pricing?.pro_rest?.breakeven ||
                        pricingItem.pro_pricing?.pro_rest?.breakeven == 0
                          ? {}
                          : { borderColor: '#f5222d' }
                      }
                      value={pricingItem.pro_pricing?.pro_rest?.breakeven}
                      onChange={value => {
                        if(value >= 100){
                          useCheck(true)
                        }else{
                          useCheck(false)
                        }
                        changeProRestParam(value, index, 4);
                      }}
                    />
                    {
                          check ? <Tooltip
                          title={intl.getHTML('setting.pricing.inputerr100')}
                        >
                          <Icon style={{fontSize:12,color:'rgba(0,0,0,0.5)',marginLeft:2}} type="question-circle" />
                        </Tooltip> : null
                        }
                  </div>
                </td>
                <td>
                  <div
                    className={styles.ProComparedPrice}
                    // style={
                    //   pricingItem.pro_pricing?.compare_status
                    //     ? { display: 'block', paddingLeft: 3 }
                    //     : { display: 'none', paddingLeft: 3 }
                    // }
                  >
                    <InputGroup compact>
                      <Select
                        suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        value={
                          pricingItem.pro_pricing.pro_rest?.compared_pattern ||
                          'add'
                        }
                        disabled={!pricingItem.pro_pricing?.compare_status}
                        onChange={value => {
                          changeProRestPattern(value, index, 5);
                        }}
                        style={{ height: 32 }}
                      >
                        <Option key="1" value="add">
                          <Icon type="plus" />
                        </Option>
                        <Option key="2" value="mul">
                          <Icon type="close" />
                        </Option>
                      </Select>
                      <InputNumber
                        min={0}
                        precision={2}
                        disabled={!pricingItem.pro_pricing?.compare_status}
                        style={{ borderRadius: '0 4px  4px 0' }}
                        value={pricingItem.pro_pricing.pro_rest?.compared_price}
                        onChange={value => {
                          changeProRestParam(value, index, 5);
                        }}
                        className={styles.ProPriceNumInput}
                      />
                    </InputGroup>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {ProErrTxt1[index] ? (
        <Alert
          message={
            <div>
              {ProErrTxt1[index] ? (
                <p>{intl.get('setting.pricing.ranges_overlap')}</p>
              ) : (
                ''
              )}
            </div>
          }
          type="error"
          showIcon
          className={styles.ProErr}
        />
      ) : null}
      {ProErrTxt2[index] ? (
        <Alert
          message={
            <div>
              {ProErrTxt2[index] ? (
                <p>{intl.get('setting.pricing.ranges_start_greater_end')}</p>
              ) : (
                ''
              )}
            </div>
          }
          type="error"
          showIcon
          className={styles.ProErr}
        />
      ) : null}
      <div className={styles.shipping}>
        <div className={styles.shippingCost}>
          <div className={styles.title}>
            <span>{intl.get('setting.pricing.shipping_title')}</span>
            <Tooltip title={intl.getHTML('setting.pricing.shipping_reminder')}>
              <Icon type="question-circle" />
            </Tooltip>
          </div>
          <Radio.Group
            onChange={e => changeShipppingStatus(e, index)}
            value={pricingItem.pro_pricing?.fix_ship_cost_status}
          >
            <Radio value={1} className={styles.shippingCostSettingRadio} style={{ paddingTop: 9 }}>
              <div className={styles.platformselect}>
                <span className={styles.span}> 
                  <span className={styles.platformLogo}>
                    {/* <img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')} alt="" /> */}
                    AliExpress
                  </span>
                  <Select
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    value={pricingItem.pro_pricing?.country_code || 'ALA'}
                    disabled={pricingItem.pro_pricing?.fix_ship_cost_status == 0}
                    placeholder={intl.get('setting.choose_country_placeholder')}
                    suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                    filterOption={(i, o) =>
                      o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
                    }
                    showSearch
                    onChange={(e, o) => {
                      changeCountry(e, index, o);
                    }}
                    style={{ width: 129, marginRight: 8 }}
                  >
                  {allCountry.map(country => {
                    return (
                      <Option key={country.Name} value={country.Code}>
                        {country.Name}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  style={{minWidth:200}}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                  value={pricingItem.pro_pricing?.logistics || 'FPXDHLEU1_CZ'}
                  disabled={pricingItem.pro_pricing?.fix_ship_cost_status == 0}
                  placeholder={intl.get('setting.shipping.general.placeholder')}
                  showSearch
                  filterOption={(i, o) =>
                    o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
                  }
                  onChange={v => {
                    changeCountryTrack(v, index);
                  }}
                >
                  {alltrack.map((r, f) => {
                    return (
                      <Option
                        key={r.ServiceName}
                        value={r.ServiceName}
                        title={r.Name}
                      >
                        {r.Name}
                      </Option>
                    );
                  })}
                </Select>
                </span>
                {window.TMALL_USER ? <span className={styles.span}> 
                  <span className={styles.platformLogo}>
                    {/* <img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')} alt="" /> */}
                    Tmall
                  </span>
                  <Select
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    value={pricingItem.pro_pricing?.tmall_country_code || 'ALA'}
                    disabled={pricingItem.pro_pricing?.fix_ship_cost_status == 0}
                    placeholder={intl.get('setting.choose_country_placeholder')}
                    suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                    filterOption={(i, o) =>
                      o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
                    }
                    showSearch
                    onChange={(e, o) => {
                      changeTmallCountry(e, index, o);
                    }}
                    style={{ width: 129, marginRight: 8 }}
                  >
                  {allCountry.map(country => {
                    return (
                      <Option key={country.Name} value={country.Code}>
                        {country.Name}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  style={{minWidth:200}}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                  value={pricingItem.pro_pricing?.tmall_logistics || ''}
                  disabled={pricingItem.pro_pricing?.fix_ship_cost_status == 0}
                  placeholder={intl.get('setting.shipping.general.placeholder')}
                  showSearch
                  filterOption={(i, o) =>
                    o.props.children.toLowerCase().indexOf(i.toLowerCase()) >= 0
                  }
                  onChange={v => {
                    changeTmallCountryTrack(v, index);
                  }}
                >
                  {alltrackTM.map((r, f) => {
                    return (
                      <Option
                        key={r.ServiceName}
                        value={r.ServiceName}
                        title={r.Name}
                      >
                        {r.Name}
                      </Option>
                    );
                  })}
                </Select>
                </span>:null}
              </div>
            </Radio>
            <br />
            <Radio value={0} style={{ paddingTop: 16, width: 129 }}>
              <InputNumber
                formatter={value => `${value}$`}
                parser={value => value.replace('$', '')}
                style={{ width: 129, height: 30, lineHeight: '28px' }}
                min={0}
                disabled={pricingItem.pro_pricing?.fix_ship_cost_status == 1}
                value={pricingItem.pro_pricing?.fix_ship_cost}
                placeholder={intl.get('setting.pricing.fixed_cost')}
                onChange={value => changeFixShoppingCost(value, index)}
              />
            </Radio>
          </Radio.Group>
        </div>
        <div className={styles.productCost}>
          <div className={styles.title}>
            <span>{intl.get('setting.pricing.product_cost_setting')}</span>
            {/* <Tooltip
              title={intl.getHTML('setting.pricing.product_cost_reminder')}
            >
              <Icon type="question-circle" />
            </Tooltip> */}
          </div>
          {/* <Checkbox
            checked={pricingItem.pro_pricing?.destination_country_status}
            disabled={false}
            onChange={e => {
              changeProductCost(e, index);
            }}
          >
            <Select
              
              suffixIcon={<img src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
              disabled={
                pricingItem.pro_pricing?.destination_country_status == false
              }
              style={{ width: 129, marginRight: 8 }}
              value={
                countryMap[pricingItem.pro_pricing?.destination_country_code]
              }
              onChange={v => {
                changeCountryCost(v, index);
              }}
            >
              {supportCountry.map((country, i) => {
                return <option key={i}>{country.name}</option>;
              })}
            </Select>
          </Checkbox> */}

          <div 
          className='updateProductCost'
          dangerouslySetInnerHTML={{
            __html:intl.get('setting.pricing.product_cost_settingVal')
          }}> 
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AdvancedModel;
