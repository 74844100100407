import React, { useReducer, useState } from 'react';
import { Modal, Select, Divider, Checkbox, Button, Result } from 'antd';
import { connect } from 'dva';

import styles from './AccountModal.less';

const plainOptions = ['Apple', 'Pear', 'Orange'];

const initialState = {
  checkedList: [],
  indeterminate: true,
  checkAll: false
};

function reducer(state, action) {
  switch (action.type) {
    case 'checkAll':
      return {
        checkedList: action.payload ? plainOptions : [],
        indeterminate: false,
        checkAll: action.payload
      };
    case 'update':
      return {
        checkedList: action.payload,
        indeterminate:
          !!action.payload.length &&
          action.payload.length < plainOptions.length,
        checkAll: action.payload.length === plainOptions.length
      };
    default:
      throw new Error();
  }
}

const AccountModal = ({ visible, data, onCancel }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [submitted, setSubmitted] = useState(false);

  function handleCancel() {
    onCancel();
  }

  function handleSubmit() {
    setSubmitted(true);
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      width={650}
      maskClosable={false}
      keyboard={false}
      onCancel={handleCancel}
      bodyStyle={{ padding: '32px 48px' }}
      destroyOnClose
    >
      {submitted ? (
        <Result
          status="success"
          title="Success!"
          subTitle={
            <div>
              It takes a while to get data from Facebook.
              <br />
              Please check the Report menu later.
            </div>
          }
          extra={
            <Button type="primary" onClick={handleCancel}>
              Got it
            </Button>
          }
        />
      ) : (
        <>
          <h3>Select Facebook Ad account</h3>
          <Divider />
          <p>Please select the accounts you would like to include</p>
          <div style={{ marginBottom: 12 }}>
            <Checkbox
              indeterminate={state.indeterminate}
              onChange={e =>
                dispatch({ type: 'checkAll', payload: e.target.checked })
              }
              checked={state.checkAll}
            >
              Select / Deselect All
            </Checkbox>
          </div>
          <div>
            <Checkbox.Group
              value={state.checkedList}
              onChange={value => dispatch({ type: 'update', payload: value })}
            >
              {plainOptions.map(i => (
                <div key={i} className={styles.checkItem}>
                  <Checkbox value={i}>{i}</Checkbox>
                  <Select
                    placeholder="Map a store"
                    size="small"
                    className={styles.select}
                  >
                    <Select.Option value="1">Map a store</Select.Option>
                  </Select>
                </div>
              ))}
            </Checkbox.Group>
          </div>
          <Divider />
          <div className={styles.footer}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              style={{ marginLeft: 12 }}
              onClick={handleSubmit}
            >
              Connect
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default connect(({ login }) => ({
  userInfo: login.userInfo
}))(React.memo(AccountModal));
